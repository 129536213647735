import React, { useCallback, useState } from 'react';
import { Form, Button, Checkbox, Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const AccountForm = ({ data, onSubmit, organization, onCancel }) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit]
	);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, user: data?.user?._id }}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			<FormItem
				label={`Type`}
				name="type"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.type.required',
							defaultMessage: 'Please input type',
						}),
					},
				]}>
				<Select
					placeholder="Please select type"
					style={{ width: '100%' }}
					optionFilterProp="title"
					disabled={data && data.type}
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value={'credit-card'} title={'Credit Card'}>
						Credit Card
					</Option>
					<Option value={'bank-ocbc'} title={'Bank OCBC'}>
						Bank OCBC
					</Option>
					<Option value={'bank-dbs'} title={'Bank DBS'}>
						Bank DBS
					</Option>
				</Select>
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.type !== currentValues.type
				}>
				{({ getFieldValue }) => {
					return getFieldValue('type') === 'bank-ocbc' ||
						getFieldValue('type') === 'bank-dbs' ? (
						<>
							<FormItem
								label={intl.formatMessage({
									id: 'common.accountNumber',
									defaultMessage: 'Account Number',
								})}
								name="accountNumber"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.accountNumber.required',
											defaultMessage:
												'Please input account number',
										}),
									},
								]}>
								<Input
									placeholder={intl.formatMessage({
										id: 'common.accountNumber',
										defaultMessage: 'Account Number',
									})}
								/>
							</FormItem>
							<FormItem
								label={intl.formatMessage({
									id: 'common.bankCode',
									defaultMessage: 'Bank Code',
								})}
								name="bankCode"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.bankCode.required',
											defaultMessage:
												'Please input bank code',
										}),
									},
								]}>
								<Input
									placeholder={intl.formatMessage({
										id: 'common.bankCode',
										defaultMessage: 'Bank Code',
									})}
								/>
							</FormItem>
							<FormItem
								label={intl.formatMessage({
									id: 'common.branchCode',
									defaultMessage: 'Branch Code',
								})}
								name="branchCode"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.branchCode.required',
											defaultMessage:
												'Please input branch code',
										}),
									},
								]}>
								<Input
									placeholder={intl.formatMessage({
										id: 'common.branchCode',
										defaultMessage: 'Branch Code',
									})}
								/>
							</FormItem>
							<FormItem
								label={intl.formatMessage({
									id: 'common.payeeName',
									defaultMessage: 'Payee Name',
								})}
								name="payeeName"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.payeeName.required',
											defaultMessage:
												'Please input payee name',
										}),
									},
								]}>
								<Input
									placeholder={intl.formatMessage({
										id: 'common.payeeName',
										defaultMessage: 'Payee Name',
									})}
								/>
							</FormItem>
						</>
					) : null;
				}}
			</Form.Item>

			<FormItem name="isDefault" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isDefault',
						defaultMessage: 'Is Default',
					})}
				</Checkbox>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, users, organization }) => {
	return {
		auth,
		users,
		organization,
	};
};

export default connect(mapStateToProps, {})(AccountForm);
