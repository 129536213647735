import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import PositionForm from '../Form';
import { fetchPosition, updatePosition } from '@resources/Position/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		fetchPosition,
		position,
		updatePosition,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchPosition(visible);
		};
		visible && fetchData();
	}, [fetchPosition, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updatePosition(position._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.position.update',
							defaultMessage:
								'An existing Position entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[position]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.position',
					defaultMessage: 'Update Position',
				})}
			</Title>
			{!isEmpty(position) && (
				<PositionForm
					data={position}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	position,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		position,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchPosition,
	updatePosition,
})(Update);
