import { API_ROOT } from '@config/api';
import {
	SET_STORAGES,
	SET_STORAGE,
	ADD_STORAGE,
	UPDATE_STORAGE,
	CLEAR_STORAGE,
	CLEAR_STORAGES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchStorages = () => (dispatch) =>
	request('get', `${API_ROOT}/storages`)
		.then((response) => {
			dispatch({
				type: SET_STORAGES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchStoragesByOrganization = (organization) => (dispatch) =>
	request('get', `${API_ROOT}/storages/organization/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_STORAGES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchStorage = (id) => (dispatch) =>
	request('get', `${API_ROOT}/storages/${id}`)
		.then((response) => {
			dispatch({
				type: SET_STORAGE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createStorage = (data) => (dispatch) =>
	request('post', `${API_ROOT}/storages`, data)
		.then((response) => {
			dispatch({
				type: ADD_STORAGE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateStorage = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/storages/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_STORAGE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearStorage = () => (dispatch) =>
	dispatch({
		type: CLEAR_STORAGE,
	});

const clearStorages = () => (dispatch) =>
	dispatch({
		type: CLEAR_STORAGES,
	});

export {
	fetchStorages,
	fetchStoragesByOrganization,
	fetchStorage,
	createStorage,
	updateStorage,
	clearStorage,
	clearStorages,
};
