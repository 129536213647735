import { API_ROOT } from '@config/api';
import {
	SET_VENUES,
	SET_VENUE,
	ADD_VENUE,
	UPDATE_VENUE,
	CLEAR_VENUE,
	CLEAR_VENUES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchVenues = () => (dispatch) =>
	request('get', `${API_ROOT}/venues`)
		.then((response) => {
			dispatch({
				type: SET_VENUES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchVenuesByOrganization = (organization) => (dispatch) =>
	request('get', `${API_ROOT}/venues/organization/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_VENUES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchVenue = (id) => (dispatch) =>
	request('get', `${API_ROOT}/venues/${id}`)
		.then((response) => {
			dispatch({
				type: SET_VENUE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createVenue = (data) => (dispatch) =>
	request('post', `${API_ROOT}/venues`, data)
		.then((response) => {
			dispatch({
				type: ADD_VENUE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateVenue = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/venues/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_VENUE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearVenue = () => (dispatch) =>
	dispatch({
		type: CLEAR_VENUE,
	});

const clearVenues = () => (dispatch) =>
	dispatch({
		type: CLEAR_VENUES,
	});

export {
	fetchVenues,
	fetchVenuesByOrganization,
	fetchVenue,
	createVenue,
	updateVenue,
	clearVenue,
	clearVenues,
};
