import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Comment = ({ comment, onClick, onEdit, onDelete }) => {
	const user = comment?.createdBy || {};
	return (
		<Box onClick={() => onClick && onClick()}>
			<Section className="mb-3" hoverable shadowed bordered>
				<Row type="flex" className="login-links" gutter={24}>
					<Col md={19}>
						<Typography.Text>
							{user.firstName} {user.lastName}
						</Typography.Text>
						<Typography.Paragraph>
							{comment.content}
						</Typography.Paragraph>
					</Col>
					<Col md={5}>
						<Box textAlign="right">
							{onEdit && (
								<Button
									size="small"
									type="text"
									onClick={() => onEdit && onEdit(comment)}>
									<EditFilled style={{ color: '#FFCC00' }} />
								</Button>
							)}
							{onDelete && (
								<Button
									size="small"
									type="text"
									onClick={() =>
										onDelete && onDelete(comment)
									}>
									<DeleteFilled
										style={{ color: '#FF0000' }}
									/>
								</Button>
							)}
						</Box>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Comment;
