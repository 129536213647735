import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	Container,
	Section,
	Title,
	View,
} from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	createTithe,
	fetchTithes,
	fetchTithesByOrganization,
	clearTithes,
} from '@resources/Tithe/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { TitheItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';
import TitheForm from './Form';

const { Content } = Layout;

const Tithe = (props) => {
	const {
		tithes,
		history,
		fetchTithes,
		fetchTithesByOrganization,
		clearTithes,
		createTithe,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearTithes();
			organization?._id
				? await fetchTithesByOrganization(organization?._id)
				: await fetchTithes();
		};
		fetchData();

		return () => clearTithes();
	}, [clearTithes, fetchTithes, fetchTithesByOrganization, organization]);

	const handleClick = (tithe) => {
		history.push(
			`/${tithe.organization.alias}/tithes/${tithe._id}/details`
		);
	};

	const handleSubmit = (data) => {
		return createTithe(data)
			.then((response) => {
				history.push(
					organization?.alias
						? `/${organization?.alias}/stripe/checkout/${response.data.data.transaction}`
						: `/stripe/checkout/${response.data.data.transaction}`
				);
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.tithe.management',
						defaultMessage: 'Tithe Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Box py={50}>
						<TitheForm {...props} onSubmit={handleSubmit} />
					</Box>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.tithes"
									defaultMessage="Tithes"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.tithe"
									defaultMessage="New Tithe"
								/>
							</Button>
						</Col>
					</Row>
					<Section className="mb-5">
						{tithes?.map((tithe, index) => (
							<TitheItem
								key={`tithe-${index}`}
								tithe={tithe}
								onClick={handleClick}
							/>
						))}
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, tithes }) => {
	return {
		organization,
		tithes,
	};
};

export default connect(mapStateToProps, {
	fetchTithes,
	fetchTithesByOrganization,
	clearTithes,
	createTithe,
})(Tithe);
