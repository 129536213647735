import { API_ROOT } from '@config/api';
import {
	SET_RENTABLES,
	SET_RENTABLE,
	ADD_RENTABLE,
	UPDATE_RENTABLE,
	CLEAR_RENTABLE,
	CLEAR_RENTABLES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchRentables = () => (dispatch) =>
	request('get', `${API_ROOT}/rentables`)
		.then((response) => {
			dispatch({
				type: SET_RENTABLES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchRentablesByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/rentables/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_RENTABLES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchRentable = (id) => (dispatch) =>
	request('get', `${API_ROOT}/rentables/${id}`)
		.then((response) => {
			dispatch({
				type: SET_RENTABLE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createRentable = (data) => (dispatch) =>
	request('post', `${API_ROOT}/rentables`, data)
		.then((response) => {
			dispatch({
				type: ADD_RENTABLE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateRentable = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/rentables/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_RENTABLE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearRentable = () => (dispatch) =>
	dispatch({
		type: CLEAR_RENTABLE,
	});

const clearRentables = () => (dispatch) =>
	dispatch({
		type: CLEAR_RENTABLES,
	});

export {
	fetchRentables,
	fetchRentablesByOrganizationModule,
	fetchRentable,
	createRentable,
	updateRentable,
	clearRentable,
	clearRentables,
};
