import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Group = ({ group, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col flex={1} onClick={() => onClick && onClick(group)}>
						<Typography.Title level={3}>
							{group.name}
						</Typography.Title>
						<Typography.Text level={3}>
							{group.description}
						</Typography.Text>
					</Col>
					<Col style={{ width: 50, textAlign: 'right' }}>
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(group)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
						<br />
						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(group)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Group;
