import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchReviews,
	fetchReviewsByOrganizationModule,
	clearReviews,
} from '@resources/Review/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { ReviewItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Review = (props) => {
	const {
		reviews,
		fetchReviews,
		fetchReviewsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearReviews,
		match,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('review');
			await clearReviews();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchReviewsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchReviews();
		};

		func();
	}, [
		fetchReviews,
		fetchReviewsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearReviews,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return reviews.map((review) => {
			return {
				key: review._id,
				...review,
			};
		});
	}, [reviews]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.review.management',
						defaultMessage: 'Review Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.reviews"
									defaultMessage="Reviews"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.review"
									defaultMessage="New Review"
								/>
							</Button>
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((review, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									key={`empty-post-${index}`}
									className="mt-4">
									<ReviewItem
										review={review}
										onEdit={() => setUpdate(review._id)}
									/>
								</Col>
							))}
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	reviews,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		reviews,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchReviews,
	fetchReviewsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearReviews,
})(Review);
