import { API_ROOT } from '@config/api';
import {
	SET_SUBMODULES,
	SET_SUBMODULE,
	ADD_SUBMODULE,
	UPDATE_SUBMODULE,
	CLEAR_SUBMODULE,
} from './actionTypes';
import request from '@common/utils/request';

const fetchSubmodules = (type) => (dispatch) =>
	request('get', `${API_ROOT}/submodules`)
		.then((response) => {
			dispatch({
				type: SET_SUBMODULES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSubmodulesByModuleCode = (code) => (dispatch) =>
	request('get', `${API_ROOT}/submodules/module/code/${code}`)
		.then((response) => {
			dispatch({
				type: SET_SUBMODULES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSubmodule = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/submodules/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SUBMODULE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createSubmodule = (data) => (dispatch) =>
	request('post', `${API_ROOT}/submodules`, data)
		.then((response) => {
			dispatch({
				type: ADD_SUBMODULE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateSubmodule = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/submodules/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SUBMODULE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearSubmodule = () => (dispatch) =>
	dispatch({
		type: CLEAR_SUBMODULE,
	});
export {
	fetchSubmodules,
	fetchSubmodulesByModuleCode,
	fetchSubmodule,
	createSubmodule,
	updateSubmodule,
	clearSubmodule,
};
