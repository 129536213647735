import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import MenuForm from '../Form';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { updateOrganization } from '@common/resources/Organization/actions';

const Update = (props) => {
	const { menu, visible, onCancel, organization, updateOrganization } = props;
	const intl = useIntl();
	const [data, setData] = useState();

	useEffect(() => {
		visible && setData(visible);
	}, [visible]);

	const handleSubmit = useCallback(
		(values) => {
			return updateOrganization(organization._id, values)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.menu.update',
							defaultMessage:
								'An existing Menu entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[menu]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.menu',
					defaultMessage: 'Update Menu',
				})}
			</Title>
			{!isEmpty(data) && (
				<MenuForm
					data={data}
					onSubmit={handleSubmit}
					type={data.category}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	menu,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		menu,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, { updateOrganization })(Update);
