import React, { useState } from 'react';
import { Button, Row, Col, Collapse } from 'antd';
import { connect } from 'react-redux';
import { WarningFilled } from '@ant-design/icons';
import { CrewInviteForm, ParticipantInviteForm } from './Update';

const { Panel } = Collapse;

const Action = ({ actions, entity, refresh }) => {
	const [crewInvite, setCrewInvite] = useState(false);
	const [participantInvite, setParticipantInvite] = useState(false);
	const handleRespond = (type, id) => {
		switch (type) {
			case 'crew':
				setCrewInvite(id);
				break;
			case 'participant':
				setParticipantInvite(id);
				break;
			default:
		}
	};

	return actions && actions.length > 0 ? (
		<Collapse>
			<Panel
				header={
					<span>
						<WarningFilled /> You have {actions.length} actions that
						requires your attention for this {entity}
					</span>
				}
				key="1">
				<CrewInviteForm
					visible={crewInvite}
					onSuccess={() => refresh && refresh()}
					onCancel={() => setCrewInvite(false)}
				/>

				<ParticipantInviteForm
					visible={participantInvite}
					onSuccess={() => refresh && refresh()}
					onCancel={() => setParticipantInvite(false)}
				/>
				{actions.map((action) => {
					return (
						<Row gutter={24}>
							<Col flex={1}>{action.message}</Col>
							<Col>
								<Button
									size="small"
									type="primary"
									onClick={() =>
										handleRespond(action.type, action._id)
									}>
									Respond
								</Button>
							</Col>
						</Row>
					);
				})}
			</Panel>
		</Collapse>
	) : null;
};

const mapStateToProps = ({ actions }) => {
	return {
		actions,
	};
};

export default connect(mapStateToProps, {})(Action);
