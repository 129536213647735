let env, backendHost, appType;

const hostname = window && window.location && window.location.hostname;
const domain = process.env.REACT_APP_DOMAIN;
const protocol = window.location.protocol;
let subdomain = hostname.split('.')[0];

if (subdomain === `staging`) {
	env = `staging.api.${domain}`;
} else if (subdomain === `uat`) {
	env = `uat.api.${domain}`;
} else if (subdomain === `www` || subdomain === process.env.REACT_APP_PRODUCT) {
	env = `api.${domain}`;
} else if (/^qa/.test(hostname)) {
	env = `api.${domain}`;
} else {
	env = process.env.REACT_APP_BACKEND_HOST || 'localhost:5000';
}

if (
	hostname === `${process.env.REACT_APP_PRODUCT}.${domain}` ||
	hostname === `staging.${process.env.REACT_APP_PRODUCT}.${domain}` ||
	hostname === `uat.${process.env.REACT_APP_PRODUCT}.${domain}`
) {
	// backendHost = `${env}/${process.env.REACT_APP_PRODUCT}`;
	backendHost = `${env}`;
	appType = process.env.REACT_APP_PRODUCT;
} else {
	// backendHost = `${env}/${process.env.REACT_APP_PRODUCT}`;
	backendHost = `${env}`;
	appType = process.env.REACT_APP_PRODUCT;
}

export const API_ROOT = `${protocol}//${backendHost}/api/v1`;
export const API_MEDIA = `${API_ROOT}/medias/upload`;
export const API_ENV = env;
export const APP_TYPE = appType;
