import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Menu = ({ menu, crews, onEdit, onAddMenuMember, onDelete }) => {
	return (
		<Box className="p-3">
			<Section shadowed>
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{menu.type === 'submenu'
								? menu.subtitle
								: menu.modules[0].title}
						</Typography.Title>
					</Col>

					<Box textAlign="right">
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(menu)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(menu)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Box>
				</Row>

				{menu.type === 'submenu' &&
					menu?.modules?.map((module) => <Box>{module.title}</Box>)}
			</Section>
		</Box>
	);
};

export default Menu;
