import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchMembers,
	fetchMembersByOrganization,
	clearMembers,
} from '@resources/Member/actions';
import isEmpty from 'lodash.isempty';

const FormItem = Form.Item;
const { Option } = Select;

const MemberSelectForm = (props) => {
	const {
		members,
		organization,
		fetchMembers,
		fetchMembersByOrganization,
		clearMembers,
		filter = () => true,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await clearMembers();
			organization?._id
				? await fetchMembersByOrganization(organization?._id)
				: await fetchMembers();
		};
		fetchData();
	}, [organization]);

	const renderMemberOptions = useCallback(() => {
		if (!isEmpty(members)) {
			return members.filter(filter).map((member, index) => {
				return (
					<Option
						value={member._id}
						key={`member-${index}`}
						title={`${member?.user?.firstName} ${member?.user?.lastName}`}>
						{member?.user?.firstName} {member?.user?.lastName}
					</Option>
				);
			});
		}
	}, [members]);

	return (
		<>
			<FormItem
				label={'Member'}
				name={'member'}
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.member.required',
							defaultMessage: 'Please input member',
						}),
					},
				]}>
				<Select
					style={{ width: '100%' }}
					placeholder="Please select member"
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderMemberOptions()}
				</Select>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, members }) => {
	return {
		organization,
		members,
	};
};

export default connect(mapStateToProps, {
	fetchMembers,
	fetchMembersByOrganization,
	clearMembers,
})(MemberSelectForm);
