import React from 'react';
import { Form, Radio } from 'antd';
const FormItem = Form.Item;

const FieldRadio = ({ data }) => {
	const { label, name, required, options } = data || {};

	return (
		<FormItem label={label} name={name} required={required}>
			<Radio.Group placeholder={`Please select ${label}`}>
				{options.map((option, idx) => (
					<Radio key={`option-${idx}`} value={option.value}>
						{option.option}
					</Radio>
				))}
			</Radio.Group>
		</FormItem>
	);
};

export default FieldRadio;
