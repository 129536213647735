import { API_ROOT } from '@config/api';
import {
	SET_PREFERENCES,
	SET_PREFERENCE,
	ADD_PREFERENCE,
	UPDATE_PREFERENCE,
	CLEAR_PREFERENCE,
	CLEAR_PREFERENCES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchPreferences = (filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/preferences?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_PREFERENCES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPreferenceByUser = (filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/preferences/user?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_PREFERENCE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPreferenceByOrganization = (id, filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/preferences/organization/${id}?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_PREFERENCE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPreference = (id) => (dispatch) =>
	request('get', `${API_ROOT}/preferences/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PREFERENCE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createPreference = (data) => (dispatch) =>
	request('post', `${API_ROOT}/preferences`, data)
		.then((response) => {
			dispatch({
				type: ADD_PREFERENCE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updatePreference = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/preferences/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PREFERENCE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearPreference = () => (dispatch) =>
	dispatch({
		type: CLEAR_PREFERENCE,
	});

const clearPreferences = () => (dispatch) =>
	dispatch({
		type: CLEAR_PREFERENCES,
	});

export {
	fetchPreferences,
	fetchPreferenceByUser,
	fetchPreferenceByOrganization,
	fetchPreference,
	createPreference,
	updatePreference,
	clearPreference,
	clearPreferences,
};
