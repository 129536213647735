import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
	fetchUsersMembers,
	searchUsersMembers,
	clearUsers,
} from '@resources/User/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const GuestForm = ({
	users,
	data,
	onSubmit,
	searchUsersMembers,
	organization,
	onCancel,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [type, setType] = useState(
		data ? (data.user ? 'registered' : 'nonregistered') : undefined
	);
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			values['organization'] = organization?._id;
			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	const renderUserOptions = useCallback(() => {
		if (!isEmpty(users)) {
			return users.map((user, index) => {
				return (
					<Option
						value={user._id}
						key={`user-${index}`}
						title={`${user.firstName} ${user.lastName}`}>
						{user.firstName} {user.lastName}
					</Option>
				);
			});
		} else {
			return (
				data &&
				data.user && (
					<Option
						value={data.user._id}
						key={`user-0`}
						title={`${data.user.firstName} ${data.user.lastName}`}>
						{data.user.firstName} {data.user.lastName}
					</Option>
				)
			);
		}
	}, [users]);

	const handleTypeChange = (value) => {
		setType(value);
	};

	const handleSearch = (value) => {
		const search = async () => {
			await searchUsersMembers(value);
		};

		if (value) search();
		else clearUsers();
	};

	const handleChange = (value) => {
		const search = async () => {
			await fetchUsersMembers();
		};

		if (value) search();
		else clearUsers();
	};

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, user: data?.user?._id, type }}>
			<FormItem
				label={`Type`}
				name="type"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.type.required',
							defaultMessage: 'Please input type',
						}),
					},
				]}>
				<Select
					onChange={handleTypeChange}
					placeholder="Please select type"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="registered">Registered User</Option>
					<Option value="nonregistered">Non-registered User</Option>
				</Select>
			</FormItem>
			{type === 'nonregistered' && (
				<>
					<FormItem
						label={intl.formatMessage({
							id: 'common.firstName',
							defaultMessage: 'First name',
						})}
						name="firstName"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.validation.firstName.required',
									defaultMessage: 'Please input first name',
								}),
							},
						]}>
						<Input
							placeholder={intl.formatMessage({
								id: 'common.firstName',
								defaultMessage: 'First name',
							})}
						/>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'common.lastName',
							defaultMessage: 'Last name',
						})}
						name="lastName"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.validation.lastName.required',
									defaultMessage: 'Please input last name',
								}),
							},
						]}>
						<Input
							placeholder={intl.formatMessage({
								id: 'common.lastName',
								defaultMessage: 'Last name',
							})}
						/>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'common.email',
							defaultMessage: 'Email',
						})}
						name="email"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'form.validation.email.required',
									defaultMessage: 'Please input email',
								}),
							},
						]}>
						<Input
							placeholder={intl.formatMessage({
								id: 'common.email',
								defaultMessage: 'Email',
							})}
						/>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'common.phone',
							defaultMessage: 'Phone',
						})}
						name="phone">
						<Input
							placeholder={intl.formatMessage({
								id: 'common.phone',
								defaultMessage: 'Phone',
							})}
						/>
					</FormItem>
				</>
			)}

			{type === 'registered' && (
				<FormItem
					label={`User`}
					name="user"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: 'form.validation.user.required',
								defaultMessage: 'Please input user',
							}),
						},
					]}>
					<Select
						showSearch
						placeholder="Please select user"
						style={{ width: '100%' }}
						optionFilterProp="title"
						onSearch={handleSearch}
						onChange={handleChange}
						defaultActiveFirstOption={false}
						showArrow={false}
						filterOption={false}
						notFoundContent={null}>
						{renderUserOptions()}
					</Select>
				</FormItem>
			)}
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ users, organization }) => {
	return {
		users,
		organization,
	};
};

export default connect(mapStateToProps, { searchUsersMembers })(GuestForm);
