import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { AccountSelectForm } from '../../Form';

const FormItem = Form.Item;

const ChurchPreferenceForm = ({ organization, formItemLayout = {} }) => {
	const intl = useIntl();

	return (
		<>
			<Typography.Title level={4}>Church Preferences</Typography.Title>
			<Form.List name="church" wrapperCol={{ span: 24 }}>
				{() => (
					<>
						<FormItem
							label={intl.formatMessage({
								id: 'common.cellGroup',
								defaultMessage: 'Cell Group',
							})}
							name="cellGroup"
							help="Specify your own name for your cell group. e.g. Life Group, Care Group, etc..."
							rules={[]}
							{...formItemLayout}>
							<Input
								placeholder={intl.formatMessage({
									id: 'common.cellGroup',
									defaultMessage: 'Cell Group',
								})}
							/>
						</FormItem>
						<AccountSelectForm
							organizationId={organization?._id}
							formItemProps={{
								name: 'titheAccount',
								label: 'Tithe Account',
								help:
									'Select a specific account to where you want your organization member tithes to go to',
								...formItemLayout,
							}}
						/>
					</>
				)}
			</Form.List>
		</>
	);
};

const mapStateToProps = ({ organization, accounts }) => {
	return {
		organization,
		accounts,
	};
};

export default connect(mapStateToProps, {})(ChurchPreferenceForm);
