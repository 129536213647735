import { API_ROOT } from '@config/api';
import {
	SET_FORMS,
	SET_FORM,
	ADD_FORM,
	UPDATE_FORM,
	CLEAR_FORM,
} from './actionTypes';
import request from '@common/utils/request';

const fetchForms = (type) => (dispatch) =>
	request('get', `${API_ROOT}/forms`)
		.then((response) => {
			dispatch({
				type: SET_FORMS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchFormsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/forms/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FORMS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchForm = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/forms/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FORM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createForm = (data) => (dispatch) =>
	request('post', `${API_ROOT}/forms`, data)
		.then((response) => {
			dispatch({
				type: ADD_FORM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateForm = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/forms/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_FORM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearForm = () => (dispatch) =>
	dispatch({
		type: CLEAR_FORM,
	});

export {
	fetchForms,
	fetchFormsByOrganization,
	fetchForm,
	createForm,
	updateForm,
	clearForm,
};
