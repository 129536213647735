import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { Editor } from '@common/components';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchProducts } from '@resources/Product/actions';
import { CONTENT_TYPES } from '@common/constants';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};

const tailLayout = {
	wrapperCol: { offset: 6, span: 14 },
};

const ContentProductListForm = ({
	auth,
	contentTypes,
	products,
	data,
	page,
	onSubmit,
	onCancel,
	fetchProducts,
}) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await fetchProducts();
		};
		fetchData();
	}, []);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			const convertedValues = { ...values };
			convertedValues['page'] = page._id;
			convertedValues['type'] = contentTypes.filter(
				(contentType) =>
					contentType.alias === CONTENT_TYPES.PRODUCT_LIST
			)[0]._id;

			onSubmit(convertedValues)
				.then(() => {})
				.catch((err) => {})
				.finally(() => setLoading(false));
		},
		[onSubmit, setLoading]
	);

	const renderProductOptions = useCallback(() => {
		if (products) {
			return products.map((product, index) => {
				return (
					<Option
						value={product._id}
						key={`product-${index}`}
						title={product.title}>
						{product.title}
					</Option>
				);
			});
		}
	}, [products]);

	return (
		<Form
			name="paragraph-form"
			{...formItemLayout}
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.subtitle',
					defaultMessage: 'Subtitle',
				})}
				name="subtitle">
				<Input
					placeholder={intl.formatMessage({
						id: 'common.subtitle',
						defaultMessage: 'Subtitle',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.detail',
					defaultMessage: 'Detail',
				})}
				name="detail"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.detail.required',
							defaultMessage: 'Please input detail',
						}),
					},
				]}>
				<Editor />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.products',
					defaultMessage: 'Products',
				})}
				name="products">
				<Select
					mode="multiple"
					placeholder={intl.formatMessage({
						id: 'form.placeholder.products',
						defaultMessage: 'Please select one or more product',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderProductOptions()}
				</Select>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.image.position',
					defaultMessage: 'Image Position',
				})}
				name="position"
				initialValue={null}>
				<Select
					placeholder={intl.formatMessage({
						id: 'form.placeholder.app',
						defaultMessage: 'Please select an app',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value={null} title="None">
						{intl.formatMessage({
							id: 'common.none',
							defaultMessage: 'None',
						})}
					</Option>
					<Option value={'left'} title="Left">
						{intl.formatMessage({
							id: 'common.left',
							defaultMessage: 'Left',
						})}
					</Option>
					<Option value={'center'} title="Center">
						{intl.formatMessage({
							id: 'common.center',
							defaultMessage: 'Center',
						})}
					</Option>
					<Option value={'right'} title="Right">
						{intl.formatMessage({
							id: 'common.right',
							defaultMessage: 'Right',
						})}
					</Option>
				</Select>
			</FormItem>

			<FormItem {...tailLayout} name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem wrapperCol={{ span: 12, offset: 6 }} className="mt-5">
				<Button type="secondary" onPress={onCancel && onCancel}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, contentTypes, products }) => {
	return {
		auth,
		contentTypes,
		products,
	};
};

export default connect(mapStateToProps, { fetchProducts })(
	ContentProductListForm
);
