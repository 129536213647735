import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchOrganization } from '@common/resources/Organization/actions';
import { fetchFacility, clearFacility } from '@resources/Facility/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, View, Title } from '@common/components';
import { Affix, Menu, Typography } from 'antd';

const FacilityInfo = ({
	facility,
	submodules,
	fetchFacility,
	fetchSubmodulesByModuleCode,
	organization,
	history,
	match,
}) => {
	const { facilityId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchFacility(facilityId);
			await fetchSubmodulesByModuleCode('facility');
		};

		fetchData();
	}, [fetchFacility]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization.alias
					? `/${organization.alias}/facilities/${facilityId}/details`
					: `/facilities/${facilityId}/details`
			);
		} else {
			history.push(
				organization.alias
					? `/${organization.alias}/facilities/${facilityId}/details/${e.key}`
					: `/facilities/${facilityId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<View>
			<Container>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{facility.title}
					</Title>
					<Typography.Title level={2}>Facility</Typography.Title>
				</Box>
				<Affix offsetTop={100}>
					<Menu
						onClick={handleClick}
						mode="horizontal"
						selectedKeys={[current]}>
						<Menu.Item key="details">Details</Menu.Item>
						{submodules &&
							submodules.map((submodule) => (
								<Menu.Item key={submodule.url}>
									{submodule.title}
								</Menu.Item>
							))}
					</Menu>
				</Affix>
			</Container>
		</View>
	);
};

const mapStateToProps = ({ facility, submodules, organization }) => {
	return {
		facility,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchFacility,
	fetchSubmodulesByModuleCode,
	fetchOrganization,
	clearFacility,
})(FacilityInfo);
