import { API_ROOT } from '@config/api';
import {
	SET_PAGES,
	SET_PAGE,
	ADD_PAGE,
	UPDATE_PAGE,
	CLEAR_PAGE,
} from './actionTypes';
import request from '@common/utils/request';

const fetchPages = (type) => (dispatch) =>
	request('get', `${API_ROOT}/pages`)
		.then((response) => {
			dispatch({
				type: SET_PAGES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPagesByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/pages/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PAGES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPage = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/pages/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PAGE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createPage = (data) => (dispatch) =>
	request('post', `${API_ROOT}/pages`, data)
		.then((response) => {
			dispatch({
				type: ADD_PAGE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updatePage = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/pages/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PAGE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearPage = () => (dispatch) =>
	dispatch({
		type: CLEAR_PAGE,
	});

export {
	fetchPages,
	fetchPagesByOrganization,
	fetchPage,
	createPage,
	updatePage,
	clearPage,
};
