import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import TeamForm from '../Form';
import { fetchTeam, updateTeam, clearTeam } from '@resources/Team/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchTeam,
		team,
		updateTeam,
		visible,
		clearTeam,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchTeam(visible);
		};
		visible ? fetchData() : clearTeam();
	}, [fetchTeam, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateTeam(team._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.team.update',
							defaultMessage:
								'An existing Team entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[team]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.team',
					defaultMessage: 'Update Team',
				})}
			</Title>
			{!isEmpty(team) && (
				<TeamForm
					data={team}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	team,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		team,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchTeam,
	updateTeam,
	fetchModulesBySubmoduleCode,
	clearTeam,
})(Update);
