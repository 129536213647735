import React from 'react';
import { Box, Section, Image } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import {
	UserOutlined,
	CalendarOutlined,
	EnvironmentOutlined,
	FieldTimeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const UpcomingEvent = ({ event, onClick, onEdit, onDelete }) => {
	const startDate = event?.date && moment(event?.date[0]).format('DD MMM');
	const endDate = event?.date && moment(event?.date[1]).format('DD MMM');
	return (
		<Box className="p-5">
			<Section className="mb-3" p={0} hoverable shadowed>
				<Row gutter={24}>
					<Col md={12}>
						{event.thumbnail && (
							<Image src={event.thumbnail?.path} width="100%" />
						)}
					</Col>
					<Col>
						<Box p={20}>
							<Box
								mb={20}
								onClick={() => onClick && onClick(event)}>
								<Typography.Title level={4}>
									{event.title}
								</Typography.Title>
								{event.date && event.time && (
									<>
										<Box>
											<CalendarOutlined />{' '}
											{startDate === endDate
												? startDate
												: `${startDate} - ${endDate}`}
										</Box>
										<Box>
											<FieldTimeOutlined />{' '}
											{moment(event?.time[0]).format(
												'HH:mm'
											)}
											{' - '}
											{moment(event?.time[1]).format(
												'HH:mm'
											)}
										</Box>
									</>
								)}
								<Box>
									<EnvironmentOutlined />{' '}
									<Typography.Text>
										{event.address}
									</Typography.Text>
								</Box>
							</Box>
							<Row type="flex">
								<Col flex={1}>
									<Box display="flex" alignItems="center">
										<UserOutlined />{' '}
										{event.participantCount}
									</Box>
								</Col>
								<Col flex={1}>
									<Box textAlign="right">
										{onEdit && (
											<Button
												size="small"
												type="text"
												onClick={() =>
													onEdit && onEdit(event)
												}>
												<EditFilled
													style={{ color: '#FFCC00' }}
												/>
											</Button>
										)}
										{onDelete && (
											<Button
												size="small"
												type="text"
												onClick={() =>
													onDelete && onDelete(event)
												}>
												<DeleteFilled
													style={{ color: '#FF0000' }}
												/>
											</Button>
										)}
									</Box>
								</Col>
							</Row>
						</Box>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default UpcomingEvent;
