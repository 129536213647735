import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Rate } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchParticipantsByOrganizationModule } from '@resources/Participant/actions';
import { clearReview } from '@resources/Review/actions';
import { formErrorHandler } from '@common/utils/form';
import { FormaEmbed } from '@containers/Form';

const FormItem = Form.Item;

const ReviewForm = (props) => {
	const {
		module,
		data,
		onSubmit,
		clearReview,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		return () => clearReview();
	}, []);

	return (
		<Form
			form={form}
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, participant: data?.participant?._id }}>
			<Form.Item
				name="rating"
				label={intl.formatMessage({
					id: 'common.rating',
					defaultMessage: 'Rating',
				})}>
				<Rate />
			</Form.Item>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.detail',
					defaultMessage: 'Detail',
				})}
				name="detail"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.detail.required',
							defaultMessage: 'Please input detail',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.detail',
						defaultMessage: 'Detail',
					})}
				/>
			</FormItem>

			<Form.List name="extra" wrapperCol={{ span: 24 }}>
				{() => (
					<>
						<FormaEmbed form={props[module.code]?.forms?.review} />
					</>
				)}
			</Form.List>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, participants, event, organization }) => {
	return {
		auth,
		participants,
		event,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchParticipantsByOrganizationModule,
	clearReview,
})(ReviewForm);
