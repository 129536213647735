import { API_ROOT } from '@config/api';
import {
	SET_SERVICES,
	SET_SERVICE,
	ADD_SERVICE,
	UPDATE_SERVICE,
	CLEAR_SERVICE,
	CLEAR_SERVICES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchServices = (type) => (dispatch) =>
	request('get', `${API_ROOT}/services`)
		.then((response) => {
			dispatch({
				type: SET_SERVICES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchServicesPublic = (type) => (dispatch) =>
	request('get', `${API_ROOT}/services/public`)
		.then((response) => {
			dispatch({
				type: SET_SERVICES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchServicesByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/services/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SERVICES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchServicesByOrganizationPublic = (id) => (dispatch) =>
	request('get', `${API_ROOT}/services/organization/${id}/public`)
		.then((response) => {
			dispatch({
				type: SET_SERVICES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchService = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/services/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SERVICE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createService = (data) => (dispatch) =>
	request('post', `${API_ROOT}/services`, data)
		.then((response) => {
			dispatch({
				type: ADD_SERVICE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateService = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/services/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SERVICE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearServices = () => (dispatch) =>
	dispatch({
		type: CLEAR_SERVICES,
	});

const clearService = () => (dispatch) =>
	dispatch({
		type: CLEAR_SERVICE,
	});

export {
	fetchServices,
	fetchServicesPublic,
	fetchServicesByOrganization,
	fetchServicesByOrganizationPublic,
	fetchService,
	createService,
	updateService,
	clearServices,
	clearService,
};
