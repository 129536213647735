import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout, Typography } from 'antd';
import { setSite } from '@resources/Site/actions';
import { fetchEventsPublic } from '@resources/Event/actions';
import { fetchOrganizationsPublic } from '@common/resources/Organization/actions';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import qs from 'qs';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { Content } = Layout;

const Landing = ({
	events,
	organizations,
	history,
	location,
	setSite,
	fetchEventsPublic,
	fetchOrganizationsPublic,
}) => {
	const intl = useIntl();
	const queryStrings = qs.parse(location.search.slice(1));

	useEffect(() => {
		setSite(queryStrings.s);
		const fetchData = async () => {
			await fetchEventsPublic();
			await fetchOrganizationsPublic();
		};

		fetchData();
	}, []);

	const settingsOrganizations = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	const settingsUpcomingEvents = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	const handleClick = (orgId) => {
		history.push(`/${orgId}`);
	};

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Landing</title>
			</Helmet>
			<Container>
				<View>
					<Title size="lg">Landing</Title>
					<Slider {...settingsOrganizations}>
						{organizations &&
							organizations.map((organization) => (
								<Box className="p-5">
									<Section
										onClick={() =>
											handleClick(organization.alias)
										}
										className="my-5"
										shadowed
										hoverable>
										<Row
											type="flex"
											className="login-links">
											<Col>
												<Typography.Title level={3}>
													{organization.title}
												</Typography.Title>
												<Typography.Paragraph ellipsis>
													{organization.description}
												</Typography.Paragraph>
											</Col>
										</Row>
									</Section>
								</Box>
							))}
					</Slider>

					<Slider {...settingsUpcomingEvents}>
						{events &&
							events.map((event) => (
								<Box className="p-5">
									<Section className="my-5">
										<Row
											type="flex"
											justify="center"
											className="login-links">
											<Col>{event.title}</Col>
										</Row>
									</Section>
								</Box>
							))}
					</Slider>

					<Section className="my-5 container-body">
						<Row
							type="flex"
							justify="center"
							className="login-links">
							<Col>
								Don't have an account?{' '}
								<Link to="/register">
									{intl.formatMessage({
										id: 'common.signup',
										defaultMessage: 'Sign Up',
									})}
								</Link>
							</Col>
						</Row>
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ site, events, organizations }) => {
	return { site, events, organizations };
};

export default connect(mapStateToProps, {
	setSite,
	fetchEventsPublic,
	fetchOrganizationsPublic,
})(Landing);
