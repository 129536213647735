import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ClassForm from '../Form';
import { fetchClass, updateClass } from '@resources/Class/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
  const { fetchClass, classy, updateClass, visible, onCancel } = props;
  const intl = useIntl();
  useEffect(() => {
    const fetchData = async () => {
      await fetchClass(visible);
    };
    visible && fetchData();
  }, [fetchClass, visible]);

  const handleSubmit = useCallback(
    (data) => {
      return updateClass(classy._id, data)
        .then((response) => {
          popIn(
            'success',
            intl.formatMessage({
              id: 'message.success.class.update',
              defaultMessage:
                'An existing Class entry has been successfully updated',
            })
          );

          onCancel && onCancel();
        })
        .catch((err) => Promise.reject(err));
    },
    [classy]
  );

  return (
    <Modal visible={visible} onCancel={() => onCancel && onCancel()}>
      <Title>
        {intl.formatMessage({
          id: 'common.update.class',
          defaultMessage: 'Update Class',
        })}
      </Title>
      {!isEmpty(classy) && (
        <ClassForm data={classy} onSubmit={handleSubmit} {...props} />
      )}
    </Modal>
  );
};

const mapStateToProps = ({ classy }) => {
  return {
    classy,
  };
};

export default connect(mapStateToProps, {
  fetchClass,
  updateClass,
})(Update);
