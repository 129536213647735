import { API_ROOT } from '@config/api';
import {
	SET_GROUPS,
	SET_GROUP,
	ADD_GROUP,
	UPDATE_GROUP,
	CLEAR_GROUP,
	CLEAR_GROUPS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchGroups = (type) => (dispatch) =>
	request('get', `${API_ROOT}/groups`)
		.then((response) => {
			dispatch({
				type: SET_GROUPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGroupsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/groups/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_GROUPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGroupsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/groups/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_GROUPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGroup = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/groups/${id}`)
		.then((response) => {
			dispatch({
				type: SET_GROUP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createGroup = (data) => (dispatch) =>
	request('post', `${API_ROOT}/groups`, data)
		.then((response) => {
			dispatch({
				type: ADD_GROUP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateGroup = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/groups/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_GROUP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearGroup = () => (dispatch) =>
	dispatch({
		type: CLEAR_GROUP,
	});

const clearGroups = () => (dispatch) =>
	dispatch({
		type: CLEAR_GROUPS,
	});

export {
	fetchGroups,
	fetchGroupsByOrganization,
	fetchGroupsByOrganizationModule,
	fetchGroup,
	createGroup,
	updateGroup,
	clearGroup,
	clearGroups,
};
