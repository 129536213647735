import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Title } from '@common/components';
import { popIn } from '@common/utils/services';
import {
	fetchField,
	createField,
	updateField,
	clearField,
} from '@resources/Field/actions';
import { useIntl } from 'react-intl';
import {
	FieldDateForm,
	FieldTextForm,
	FieldNumberForm,
	FieldRadioForm,
	FieldSelectForm,
} from '@containers/Field/forms';
import { dataTranslation } from '@common/utils/translations';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const FormFieldForm = ({
	id,
	forma,
	field,
	fetchField,
	updateField,
	createField,
	clearField,
	type = {},
	visible,
	onCancel,
}) => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			if (visible && id) {
				await fetchField(id);
			}
		};
		fetchData();
		setIsLoading(false);
	}, [fetchField, visible, id]);

	const handleSubmit = useCallback(
		(data) => {
			if (id) {
				return updateField(id, data)
					.then((response) => {
						popIn(
							'success',
							intl.formatMessage({
								id: 'message.success.field.update',
								defaultMessage:
									'An existing Field entry has been successfully updated',
							})
						);
					})
					.catch((err) => Promise.reject(err));
			} else {
				return createField(data)
					.then((response) => {
						popIn(
							'success',
							intl.formatMessage({
								id: 'message.success.field.create',
								defaultMessage:
									'A new Field entry has been successfully created',
							})
						);
					})
					.catch((err) => Promise.reject(err));
			}
		},
		[forma, id]
	);

	const renderFieldForm = (type) => {
		switch (type) {
			case 'date':
				return (
					<FieldDateForm
						data={field}
						forma={forma}
						onSubmit={handleSubmit}
						onCancel={() => {
							clearField();
							onCancel && onCancel();
						}}
					/>
				);
			case 'text':
				return (
					<FieldTextForm
						data={field}
						forma={forma}
						onSubmit={handleSubmit}
						onCancel={() => {
							clearField();
							onCancel && onCancel();
						}}
					/>
				);
			case 'number':
				return (
					<FieldNumberForm
						data={field}
						forma={forma}
						onSubmit={handleSubmit}
						onCancel={() => {
							clearField();
							onCancel && onCancel();
						}}
					/>
				);

			case 'radio':
				return (
					<FieldRadioForm
						data={field}
						forma={forma}
						onSubmit={handleSubmit}
						onCancel={() => {
							clearField();
							onCancel && onCancel();
						}}
					/>
				);

			case 'select':
				return (
					<FieldSelectForm
						data={field}
						forma={forma}
						onSubmit={handleSubmit}
						onCancel={() => {
							clearField();
							onCancel && onCancel();
						}}
					/>
				);
			default:
				break;
		}
	};

	return (
		<Modal
			visible={visible}
			footer={null}
			onCancel={() => {
				clearField();
				onCancel && onCancel();
			}}>
			<Title>
				{dataTranslation(intl, 'fieldType', type.translation)}
			</Title>
			{isLoading && <Spin indicator={antIcon} />}

			{visible && !isLoading && id && field
				? renderFieldForm(field && field.type && field.type.alias)
				: visible && !isLoading && type && renderFieldForm(type.alias)}
		</Modal>
	);
};

const mapStateToProps = ({ forma, fieldTypes, field, fields }) => {
	return {
		forma,
		field,
		fieldTypes,
		fields,
	};
};

export default connect(mapStateToProps, {
	fetchField,
	createField,
	updateField,
	clearField,
})(FormFieldForm);
