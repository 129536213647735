import React from 'react';
import { Box, Section, Image } from '@common/components';
import { Badge, Button, Row, Col, Typography, Space } from 'antd';

const Ticket = ({ product, quantity = 0, onClick, onAssign }) => {
	return (
		quantity > 0 && (
			<Box className="p-3" onClick={() => onClick && onClick()}>
				<Badge count={quantity}>
					<Section className="mb-3" p={0} hoverable shadowed>
						<Row>
							<Col xs={8} md={8}>
								{product?.thumbnail?.path && (
									<Image
										src={product?.thumbnail?.path}
										width="100%"
									/>
								)}
							</Col>
							<Col xs={16} md={16}>
								<Box p={20} textAlign="left">
									<Space direction="vertical">
										<Typography.Text strong>
											{product.name}
										</Typography.Text>
										<Row>
											<Col>
												<Typography.Text>
													{product?.currency?.symbol}{' '}
													{product?.price}
												</Typography.Text>
											</Col>
										</Row>
									</Space>
									<Box textAlign="right">
										<Button
											type="primary"
											onClick={() =>
												onAssign && onAssign(product)
											}>
											Assign
										</Button>
									</Box>
								</Box>
							</Col>
						</Row>
					</Section>
				</Badge>
			</Box>
		)
	);
};

export default Ticket;
