import { API_ROOT } from '@config/api';
import {
	SET_TITHES,
	SET_TITHE,
	ADD_TITHE,
	UPDATE_TITHE,
	CLEAR_TITHE,
	CLEAR_TITHES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchTithes = () => (dispatch) =>
	request('get', `${API_ROOT}/tithes`)
		.then((response) => {
			dispatch({
				type: SET_TITHES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTithesByOrganization = (organization) => (dispatch) =>
	request('get', `${API_ROOT}/tithes/organization/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_TITHES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTithe = (id) => (dispatch) =>
	request('get', `${API_ROOT}/tithes/${id}`)
		.then((response) => {
			dispatch({
				type: SET_TITHE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createTithe = (data) => (dispatch) =>
	request('post', `${API_ROOT}/tithes`, data)
		.then((response) => {
			dispatch({
				type: ADD_TITHE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateTithe = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/tithes/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TITHE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearTithe = () => (dispatch) =>
	dispatch({
		type: CLEAR_TITHE,
	});

const clearTithes = () => (dispatch) =>
	dispatch({
		type: CLEAR_TITHES,
	});

export {
	fetchTithes,
	fetchTithesByOrganization,
	fetchTithe,
	createTithe,
	updateTithe,
	clearTithe,
	clearTithes,
};
