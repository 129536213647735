import {
  SET_CLASSES,
  SET_CLASS,
  CLEAR_CLASSES,
  CLEAR_CLASS,
  ADD_CLASS,
  UPDATE_CLASS,
  REMOVE_CLASS,
} from './actionTypes';

export const classy = (state = {}, action) => {
  switch (action.type) {
    case SET_CLASS:
      return action.payload;
    case UPDATE_CLASS:
      if (state._id.toString() !== action.payload._id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_CLASS:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_CLASSES:
      return action.payload;
    case ADD_CLASS:
      return [...state, action.payload];
    case UPDATE_CLASS:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_CLASS:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_CLASSES:
      return [];
    default:
      return state;
  }
};
