import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPrayer } from '@resources/Prayer/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Container } from '@common/components';
import { Menu, PageHeader } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const PrayerInfo = ({
	prayer,
	submodules,
	fetchPrayer,
	fetchSubmodulesByModuleCode,
	history,
	match,
	organization,
}) => {
	const { prayerId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchPrayer(prayerId);
			await fetchSubmodulesByModuleCode('prayer');
		};

		fetchData();
	}, [fetchPrayer]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/details/prayers/${prayerId}/details`
					: `/details/prayers/${prayerId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/details/prayers/${prayerId}/details/${e.key}`
					: `/details/prayers/${prayerId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<PageHeader
				className="site-page-header"
				title={prayer.title}
				subTitle="Prayer"
			/>
			<Menu
				onClick={handleClick}
				mode="horizontal"
				selectedKeys={[current]}>
				<Menu.Item key="details" icon={<MailOutlined />}>
					Details
				</Menu.Item>
				{submodules &&
					submodules.map((submodule) => (
						<Menu.Item key={submodule.url} icon={<MailOutlined />}>
							{submodule.title}
						</Menu.Item>
					))}
			</Menu>
		</Container>
	);
};

const mapStateToProps = ({ prayer, submodules, organization }) => {
	return {
		prayer,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchPrayer,
	fetchSubmodulesByModuleCode,
})(PrayerInfo);
