import { API_ROOT } from '@config/api';
import request from '@common/utils/request';

const createMedia = (data, type, section) => (dispatch) => {
	const formData = new FormData();
	if (Array.isArray(data)) {
		data.forEach((d, index) => {
			formData.append(`files[${index}]`, d);
		});
	} else {
		formData.append('files[]', data);
	}
	return request(
		'post',
		`${API_ROOT}/medias/upload/${type}/${section}`,
		formData
	)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});
};

const fetchMedia = (id) => (dispatch) => {
	return request('get', `${API_ROOT}/medias/${id}`)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));
};

const deleteMedia = (id) => (dispatch) => {
	return request('delete', `${API_ROOT}/medias/${id}`)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});
};

export { createMedia, fetchMedia, deleteMedia };
