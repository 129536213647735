import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import ActivityForm from '../Form';
import {
	fetchActivity,
	updateActivity,
	clearActivity,
} from '@resources/Activity/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchActivity,
		activity,
		updateActivity,
		visible,
		clearActivity,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchActivity(visible);
		};
		visible ? fetchData() : clearActivity();
	}, [fetchActivity, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateActivity(activity._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.activity.update',
							defaultMessage:
								'An existing Activity entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[activity]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.activity',
					defaultMessage: 'Update Activity',
				})}
			</Title>
			{!isEmpty(activity) && (
				<ActivityForm
					data={activity}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	activity,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		activity,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchActivity,
	updateActivity,
	fetchModulesBySubmoduleCode,
	clearActivity,
})(Update);
