import { API_ROOT } from '@config/api';
import {
	SET_CREWS,
	SET_CREW,
	ADD_CREW,
	UPDATE_CREW,
	CLEAR_CREW,
	CLEAR_CREWS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchCrews = () => (dispatch) =>
	request('get', `${API_ROOT}/crews`)
		.then((response) => {
			dispatch({
				type: SET_CREWS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCrewsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/crews/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_CREWS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCrew = (id) => (dispatch) =>
	request('get', `${API_ROOT}/crews/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CREW,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createCrew = (data) => (dispatch) =>
	request('post', `${API_ROOT}/crews`, data)
		.then((response) => {
			dispatch({
				type: ADD_CREW,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateCrew = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/crews/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_CREW,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearCrew = () => (dispatch) =>
	dispatch({
		type: CLEAR_CREW,
	});

const clearCrews = () => (dispatch) =>
	dispatch({
		type: CLEAR_CREWS,
	});

export {
	fetchCrews,
	fetchCrewsByOrganizationModule,
	fetchCrew,
	createCrew,
	updateCrew,
	clearCrew,
	clearCrews,
};
