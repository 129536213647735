import React from 'react';
import { Box, Section, Text } from '@common/components';
import { Button, Row, Col, Typography, Tag } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

const Discussion = ({ discussion, onClick, onEdit, onDelete }) => {
	const creator = discussion?.createdBy;
	return (
		<Box className="p-5">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex">
					<Col md={12}>
						<Text>
							{creator?.firstName} {creator?.lastName}
						</Text>
						<Text>
							{moment(discussion.createdDate).format(
								'DD MMM YYYY'
							)}
						</Text>
					</Col>
					<Col md={12}></Col>
				</Row>
				<Row type="flex" className="login-links" gutter={24}>
					<Col flex={1} onClick={() => onClick && onClick()}>
						<Typography.Title level={4}>
							{discussion.title}
						</Typography.Title>

						<Typography.Paragraph>
							{discussion.detail}
						</Typography.Paragraph>
						{discussion?.crews?.map((crew) => (
							<Tag>
								{crew?.member?.user?.firstName}{' '}
								{crew?.member?.user?.lastName}
							</Tag>
						))}
					</Col>
					<Col style={{ width: 50 }}>
						<Box textAlign="right">
							{onEdit && (
								<Button
									size="small"
									type="text"
									onClick={() =>
										onEdit && onEdit(discussion)
									}>
									<EditFilled style={{ color: '#FFCC00' }} />
								</Button>
							)}
							{onDelete && (
								<Button
									size="small"
									type="text"
									onClick={() =>
										onDelete && onDelete(discussion)
									}>
									<DeleteFilled
										style={{ color: '#FF0000' }}
									/>
								</Button>
							)}
						</Box>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Discussion;
