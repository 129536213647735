import React from 'react';
import { Button } from 'antd';
import { ContentParagraph } from '@common/fragments/Content';
import { Box, Section } from '@common/components';
import { DragOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import './assets/styles.scss';

const PageContentItem = ({
	data,
	onEdit,
	onDelete,
	sortableHandle,
	isEditable,
}) => {
	const DragHandle = sortableHandle(() => (
		<div className="btn-drag">
			<DragOutlined />
		</div>
	));

	const renderContentForm = () => {
		switch (data.type.alias) {
			case 'paragraph':
				return <ContentParagraph data={data} />;
			default:
				break;
		}
	};

	return isEditable ? (
		<Box className={`fn-content-item-container`}>
			<Section hoverable bordered p={0}>
				<Box
					className={`fn-content-item-body ${
						data.isActive ? 'is-active' : ''
					}`}>
					{renderContentForm()}
				</Box>
			</Section>
			<Box textAlign="right" className="fn-content-item-actions">
				<DragHandle />
				<Button
					size="small"
					onClick={() => {
						onEdit && onEdit(data._id);
					}}>
					<EditFilled style={{ color: '#FFCC00' }} />
				</Button>
				&nbsp;
				<Button
					size="small"
					danger
					onClick={() => onDelete && onDelete(data._id)}>
					<DeleteFilled style={{ color: '#FF0000' }} />
				</Button>
			</Box>
		</Box>
	) : (
		renderContentForm()
	);
};

export default PageContentItem;
