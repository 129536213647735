import React from 'react';
import { Box, Section, Text } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

const Income = ({ income, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick(income)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links" align="middle">
					<Col flex={1}>
						<Row type="flex" align="middle">
							<Col md={4}>
								{moment(income?.date).format('DD MMM YYYY')}
							</Col>
							<Col md={4}>
								<Text fontSize={20}>{income.total}</Text>
							</Col>
							<Col md={6}>
								<Typography.Title
									level={4}
									style={{ margin: 0, lineHeight: 1 }}>
									{income.item?.title}
								</Typography.Title>
							</Col>
							<Col>
								<Typography.Title
									level={4}
									style={{ margin: 0, lineHeight: 1 }}>
									{income?.supplier?.name ||
										'No supplier specified'}
								</Typography.Title>
							</Col>
						</Row>
					</Col>
					<Col style={{ width: 50 }}>
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(income)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}

						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(income)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Income;
