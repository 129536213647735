import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import InvitationForm from '../forms/Invitation';
import {
	fetchParticipant,
	updateParticipant,
} from '@resources/Participant/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		fetchParticipant,
		participant,
		updateParticipant,
		visible,
		onSuccess,
		onCancel,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchParticipant(visible);
		};
		visible && fetchData();
	}, [fetchParticipant, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateParticipant(participant._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.participant.update',
							defaultMessage:
								'An existing Participant entry has been successfully updated',
						})
					);

					onSuccess && onSuccess();

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[participant]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.participant',
					defaultMessage: 'Update Participant',
				})}
			</Title>
			{visible && !isEmpty(participant) && (
				<InvitationForm
					data={participant}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ participant }) => {
	return {
		participant,
	};
};

export default connect(mapStateToProps, {
	fetchParticipant,
	updateParticipant,
})(Update);
