import { API_ROOT } from '@config/api';
import request from '@common/utils/request';

const authorizeStripe = (type, id, data) => (dispatch) =>
	request('post', `${API_ROOT}/stripe/authorize/${type}/${id}`, data)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const tokenStripe = (type, code, state) => (dispatch) =>
	request('post', `${API_ROOT}/stripe/token/${type}`, { code, state })
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const paymentMethods = () => (dispatch) =>
	request('get', `${API_ROOT}/stripe/payment-methods`)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

export { authorizeStripe, tokenStripe, paymentMethods };
