import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchItem, clearItem } from '@resources/Item/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, Title, View } from '@common/components';
import { Menu, Typography } from 'antd';

const ItemInfo = ({
	item,
	submodules,
	fetchItem,
	fetchSubmodulesByModuleCode,
	clearItem,
	history,
	match,
	organization,
}) => {
	const { itemId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchItem(itemId);
			await fetchSubmodulesByModuleCode('item');
		};

		fetchData();
	}, [fetchItem, fetchSubmodulesByModuleCode, clearItem]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/items/${itemId}/details`
					: `/items/${itemId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/items/${itemId}/details/${e.key}`
					: `/items/${itemId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<View>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{item.title}
					</Title>
					<Typography.Title level={2}>Item</Typography.Title>
				</Box>
				<Menu
					onClick={handleClick}
					mode="horizontal"
					selectedKeys={[current]}>
					<Menu.Item key="details">Details</Menu.Item>
					{submodules &&
						submodules.map((submodule) => (
							<Menu.Item key={submodule.url}>
								{submodule.title}
							</Menu.Item>
						))}
				</Menu>
			</View>
		</Container>
	);
};

const mapStateToProps = ({ item, submodules, organization }) => {
	return {
		item,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchItem,
	fetchSubmodulesByModuleCode,
	clearItem,
})(ItemInfo);
