import React from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ParticipantForm from '../Form';
import { createParticipant } from '@resources/Participant/actions';
import { useIntl } from 'react-intl';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Create = (props) => {
	const { module, createParticipant, visible, onCancel } = props;
	const intl = useIntl();

	const handleSubmit = (data) => {
		return createParticipant(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.participant.create',
						defaultMessage:
							'A new Participant has been successfully created',
					})
				);

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.participant',
					defaultMessage: 'Create Participant',
				})}
			</Title>
			<ParticipantForm
				onSubmit={handleSubmit}
				{...props}
				module={module}
			/>
		</Modal>
	);
};

const mapStateToProps = ({
	participants,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		participants,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	createParticipant,
	fetchModulesBySubmoduleCode,
})(Create);
