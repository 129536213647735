import React, { useCallback, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;

const CommentForm = (props) => {
	const { submodule, data, onSubmit, organization, onCancel } = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (submodule) {
				values['submodule'] = submodule.code;
				values[submodule.code] = submodule._id;
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	return (
		<Form
			name="position-form"
			layout="inline"
			onFinish={handleFinish}
			initialValues={{ ...data }}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.comment',
					defaultMessage: 'Comment',
				})}
				name="content"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.comment.required',
							defaultMessage: 'Please input Comment',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.comment',
						defaultMessage: 'Comment',
					})}
				/>
			</FormItem>

			<FormItem>
				<Button type="secondary" onClick={onCancel}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, members, organization }) => {
	return {
		auth,
		members,
		organization,
	};
};

export default connect(mapStateToProps)(CommentForm);
