import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
	fetchUsersMembers,
	fetchUser,
	searchUsersMembers,
	clearUsers,
} from '@resources/User/actions';
import { fetchPositionsByOrganization } from '@resources/Position/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const MemberForm = ({
	data,
	users,
	positions,
	onSubmit,
	fetchUsersMembers,
	fetchPositionsByOrganization,
	searchUsersMembers,
	clearUsers,
	organization,
	onCancel,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchPositionsByOrganization(organization?._id);
		};
		fetchData();
	}, [organization]);

	const renderUserOptions = useCallback(() => {
		if (!isEmpty(users)) {
			return users.map((user, index) => {
				return (
					<Option
						value={user._id}
						key={`user-${index}`}
						title={`${user.firstName} ${user.lastName}`}>
						{user.firstName} {user.lastName}
					</Option>
				);
			});
		} else {
			return (
				data &&
				data.user && (
					<Option
						value={data.user._id}
						key={`user-0`}
						title={`${data.user.firstName} ${data.user.lastName}`}>
						{data.user.firstName} {data.user.lastName}
					</Option>
				)
			);
		}
	}, [users]);

	const renderPositionOptions = useCallback(() => {
		if (positions) {
			return positions.map((position, index) => {
				return (
					<Option
						value={position._id}
						key={`position-${index}`}
						title={position.title}>
						{position.title}
					</Option>
				);
			});
		}
	}, [positions]);

	const handleSearch = (value) => {
		const search = async () => {
			await searchUsersMembers(value);
		};

		if (value) search();
		else clearUsers();
	};

	const handleChange = (value) => {
		const search = async () => {
			await fetchUsersMembers();
		};

		if (value) search();
		else clearUsers();
	};

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, user: data?.user?._id }}>
			<FormItem
				label={`User`}
				name="user"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.user.required',
							defaultMessage: 'Please input user',
						}),
					},
				]}>
				<Select
					showSearch
					placeholder="Please select user"
					style={{ width: '100%' }}
					optionFilterProp="title"
					onSearch={handleSearch}
					onChange={handleChange}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={false}
					notFoundContent={null}>
					{renderUserOptions()}
				</Select>
			</FormItem>
			<FormItem
				label={`Positions`}
				name="positions"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.position.required',
							defaultMessage: 'Please input position',
						}),
					},
				]}>
				<Select
					mode="multiple"
					placeholder="Please select position"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderPositionOptions()}
				</Select>
			</FormItem>
			<FormItem name="isInvited" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isInvited',
						defaultMessage: 'Is Invited',
					})}
				</Checkbox>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, users, positions, organization }) => {
	return {
		auth,
		users,
		positions,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchUsersMembers,
	fetchPositionsByOrganization,
	searchUsersMembers,
	fetchUser,
	clearUsers,
})(MemberForm);
