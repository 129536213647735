import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchFacilities,
	fetchFacilitiesByOrganization,
	clearFacilities,
} from '@resources/Facility/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { FacilityItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Facility = (props) => {
	const {
		facilities,
		history,
		fetchFacilities,
		fetchFacilitiesByOrganization,
		clearFacilities,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearFacilities();
			organization?._id
				? await fetchFacilitiesByOrganization(organization?._id)
				: await fetchFacilities();
		};
		fetchData();

		return () => clearFacilities();
	}, [
		clearFacilities,
		fetchFacilities,
		fetchFacilitiesByOrganization,
		organization,
	]);

	const handleClick = (facility) => {
		history.push(
			`/${facility.organization.alias}/facilities/${facility._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.facility.management',
						defaultMessage: 'Facility Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.facilities"
									defaultMessage="Facilities"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.facility"
									defaultMessage="New Facility"
								/>
							</Button>
						</Col>
					</Row>
					<Box className="mb-5">
						{facilities?.map((facility, index) => (
							<FacilityItem
								key={`facility-${index}`}
								facility={facility}
								onClick={handleClick}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, facilities }) => {
	return {
		organization,
		facilities,
	};
};

export default connect(mapStateToProps, {
	fetchFacilities,
	fetchFacilitiesByOrganization,
	clearFacilities,
})(Facility);
