import { API_ROOT } from '@config/api';
import {
	SET_DEPARTMENTS,
	SET_DEPARTMENT,
	ADD_DEPARTMENT,
	UPDATE_DEPARTMENT,
	CLEAR_DEPARTMENT,
	CLEAR_DEPARTMENTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchDepartments = () => (dispatch) =>
	request('get', `${API_ROOT}/departments`)
		.then((response) => {
			dispatch({
				type: SET_DEPARTMENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchDepartmentsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/departments/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_DEPARTMENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchDepartment = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/departments/${id}`)
		.then((response) => {
			dispatch({
				type: SET_DEPARTMENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createDepartment = (data) => (dispatch) =>
	request('post', `${API_ROOT}/departments`, data)
		.then((response) => {
			dispatch({
				type: ADD_DEPARTMENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateDepartment = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/departments/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_DEPARTMENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearDepartment = () => (dispatch) =>
	dispatch({
		type: CLEAR_DEPARTMENT,
	});

const clearDepartments = () => (dispatch) =>
	dispatch({
		type: CLEAR_DEPARTMENTS,
	});

export {
	fetchDepartments,
	fetchDepartmentsByOrganization,
	fetchDepartment,
	createDepartment,
	updateDepartment,
	clearDepartment,
	clearDepartments,
};
