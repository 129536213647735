import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography, Tag } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Participant = ({ participant, onClick, onEdit, onDelete }) => {
	const user = participant.user;

	return (
		<Box
			className="p-3"
			onClick={() => onClick && onClick()}
			textAlign="left">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{user?.firstName} {user?.lastName}
						</Typography.Title>
						{participant.product && (
							<Tag color="blue">{participant.product.name}</Tag>
						)}
						<Tag
							color={
								participant.status === 'rsvp'
									? 'green'
									: participant.status === 'invited'
									? 'orange'
									: 'red'
							}>
							{participant.status}
						</Tag>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(participant)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(participant)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Participant;
