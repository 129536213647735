import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ExpenseForm from '../Form';
import {
	fetchTransaction,
	updateTransactionExpense,
} from '@common/resources/Transaction/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		fetchTransaction,
		transaction,
		updateTransactionExpense,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchTransaction(visible);
		};
		visible && fetchData();
	}, [fetchTransaction, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateTransactionExpense(transaction._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.transaction.update',
							defaultMessage:
								'An existing Transaction entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[transaction]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.transaction',
					defaultMessage: 'Update Transaction',
				})}
			</Title>
			{!isEmpty(transaction) && (
				<ExpenseForm
					data={transaction}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	transaction,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		transaction,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchTransaction,
	updateTransactionExpense,
})(Update);
