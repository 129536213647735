import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography, Tag } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Member = ({ member, onClick, onEdit, onDelete }) => {
	const user = member?.user;

	return (
		<Box className="p-5" onClick={() => onClick && onClick()}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{user?.firstName} {user?.lastName}
						</Typography.Title>
						<Tag
							color={
								member.status === 'rsvp'
									? 'green'
									: member.status === 'invited'
									? 'orange'
									: 'red'
							}>
							{member.status}
						</Tag>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(member)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(member)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Member;
