import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchOrganization } from '@common/resources/Organization/actions';
import {
	fetchStocks,
	fetchStocksByOrganization,
	updateStock,
	clearStocks,
} from '@resources/Stock/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { PurchaseItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';
import { popIn } from '@common/utils/services';

const { Content } = Layout;

const Purchase = (props) => {
	const {
		stocks,
		siteTheme,
		fetchStocks,
		fetchStocksByOrganization,
		clearStocks,
		updateStock,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearStocks();

			organization?._id
				? await fetchStocksByOrganization(organization?._id, {
						status: { $in: ['backlog', 'progress'] },
						type: 'purchase',
				  })
				: await fetchStocks();
		};

		func();
	}, [
		fetchStocks,
		fetchStocksByOrganization,
		clearStocks,
		organization,
		siteTheme,
	]);

	const parseDataSource = useCallback(() => {
		return stocks
			.filter((stock) => stock.status !== 'completed')
			.map((stock) => {
				return {
					key: stock._id,
					...stock,
				};
			});
	}, [stocks]);

	const handleComplete = async (purchase) => {
		await updateStock(purchase._id, {
			organization: organization?._id,
			status: 'completed',
		});
		popIn('success', 'A new stock purchase has been completed');
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.purchase.management',
						defaultMessage: 'Purchase Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.purchases"
									defaultMessage="Purchases"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.purchase"
									defaultMessage="New Purchase"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((stock, index) => (
							<PurchaseItem
								key={`stock-${index}`}
								stock={stock}
								onEdit={() => setUpdate(stock._id)}
								onComplete={handleComplete}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	stocks,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
	siteTheme,
}) => {
	return {
		stocks,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
		siteTheme,
	};
};

export default connect(mapStateToProps, {
	fetchOrganization,
	fetchStocks,
	fetchStocksByOrganization,
	clearStocks,
	updateStock,
})(Purchase);
