import { API_ROOT } from '@config/api';
import {
	SET_TRANSACTION_CATEGORIES,
	SET_TRANSACTION_CATEGORY,
	ADD_TRANSACTION_CATEGORY,
	UPDATE_TRANSACTION_CATEGORY,
	CLEAR_TRANSACTION_CATEGORY,
	CLEAR_TRANSACTION_CATEGORIES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchTransactionCategories = (type) => (dispatch) =>
	request('get', `${API_ROOT}/transactionCategories`)
		.then((response) => {
			dispatch({
				type: SET_TRANSACTION_CATEGORIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTransactionCategory = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/transactionCategories/${id}`)
		.then((response) => {
			dispatch({
				type: SET_TRANSACTION_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createTransactionCategory = (data) => (dispatch) =>
	request('post', `${API_ROOT}/transactionCategories`, data)
		.then((response) => {
			dispatch({
				type: ADD_TRANSACTION_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateTransactionCategory = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/transactionCategories/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TRANSACTION_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearTransactionCategory = () => (dispatch) =>
	dispatch({
		type: CLEAR_TRANSACTION_CATEGORY,
	});

const clearTransactionCategories = () => (dispatch) =>
	dispatch({
		type: CLEAR_TRANSACTION_CATEGORIES,
	});

export {
	fetchTransactionCategories,
	fetchTransactionCategory,
	createTransactionCategory,
	updateTransactionCategory,
	clearTransactionCategory,
	clearTransactionCategories,
};
