import { API_ROOT } from '@config/api';
import {
	SET_CURRENCIES,
	SET_CURRENCY,
	ADD_CURRENCY,
	UPDATE_CURRENCY,
	CLEAR_CURRENCY,
	CLEAR_CURRENCIES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchCurrencies = (type) => (dispatch) =>
	request('get', `${API_ROOT}/currencies`)
		.then((response) => {
			dispatch({
				type: SET_CURRENCIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCurrency = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/currencies/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CURRENCY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createCurrency = (data) => (dispatch) =>
	request('post', `${API_ROOT}/currencies`, data)
		.then((response) => {
			dispatch({
				type: ADD_CURRENCY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateCurrency = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/currencies/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_CURRENCY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearCurrency = () => (dispatch) =>
	dispatch({
		type: CLEAR_CURRENCY,
	});

const clearCurrencies = () => (dispatch) =>
	dispatch({
		type: CLEAR_CURRENCIES,
	});

export {
	fetchCurrencies,
	fetchCurrency,
	createCurrency,
	updateCurrency,
	clearCurrency,
	clearCurrencies,
};
