import { API_ROOT } from '@config/api';
import {
	SET_GUESTS,
	SET_GUEST,
	ADD_GUEST,
	UPDATE_GUEST,
	CLEAR_GUEST,
	CLEAR_GUESTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchGuests = () => (dispatch) =>
	request('get', `${API_ROOT}/guests`)
		.then((response) => {
			dispatch({
				type: SET_GUESTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGuestsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/guests/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_GUESTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGuest = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/guests/${id}`)
		.then((response) => {
			dispatch({
				type: SET_GUEST,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createGuest = (data) => (dispatch) =>
	request('post', `${API_ROOT}/guests`, data)
		.then((response) => {
			dispatch({
				type: ADD_GUEST,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateGuest = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/guests/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_GUEST,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearGuest = () => (dispatch) =>
	dispatch({
		type: CLEAR_GUEST,
	});

const clearGuests = () => (dispatch) =>
	dispatch({
		type: CLEAR_GUESTS,
	});

export {
	fetchGuests,
	fetchGuestsByOrganization,
	fetchGuest,
	createGuest,
	updateGuest,
	clearGuest,
	clearGuests,
};
