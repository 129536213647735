import {
	SET_VENUES,
	SET_VENUE,
	CLEAR_VENUES,
	CLEAR_VENUE,
	ADD_VENUE,
	UPDATE_VENUE,
	REMOVE_VENUE,
} from './actionTypes';

export const venue = (state = {}, action) => {
	switch (action.type) {
		case SET_VENUE:
			return action.payload;
		case UPDATE_VENUE:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_VENUE:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_VENUES:
			return action.payload;
		case ADD_VENUE:
			return [...state, action.payload];
		case UPDATE_VENUE:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_VENUE:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_VENUES:
			return [];
		default:
			return state;
	}
};
