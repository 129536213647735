import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchMissions,
	fetchMissionsByOrganization,
	clearMissions,
} from '@resources/Mission/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { MissionItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Mission = (props) => {
	const {
		missions,
		history,
		fetchMissions,
		fetchMissionsByOrganization,
		clearMissions,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearMissions();
			organization?._id
				? await fetchMissionsByOrganization(organization?._id)
				: await fetchMissions();
		};
		fetchData();

		return () => clearMissions();
	}, [
		clearMissions,
		fetchMissions,
		fetchMissionsByOrganization,
		organization,
	]);

	const handleClick = (mission) => {
		history.push(
			`/${mission.organization.alias}/missions/${mission._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.mission.management',
						defaultMessage: 'Mission Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.missions"
									defaultMessage="Missions"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.mission"
									defaultMessage="New Mission"
								/>
							</Button>
						</Col>
					</Row>
					<Section className="mb-5">
						{missions?.map((mission, index) => (
							<MissionItem
								key={`mission-${index}`}
								mission={mission}
								onClick={handleClick}
							/>
						))}
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, missions }) => {
	return {
		organization,
		missions,
	};
};

export default connect(mapStateToProps, {
	fetchMissions,
	fetchMissionsByOrganization,
	clearMissions,
})(Mission);
