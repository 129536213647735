import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import MemberForm from '../Form';
import { fetchMember, updateMember } from '@resources/Member/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchMember, venue, updateMember, visible, onCancel } = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchMember(visible);
		};
		visible && fetchData();
	}, [fetchMember, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateMember(venue._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.venue.update',
							defaultMessage:
								'An existing Member entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[venue]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.venue',
					defaultMessage: 'Update Member',
				})}
			</Title>
			{!isEmpty(venue) && (
				<MemberForm data={venue} onSubmit={handleSubmit} {...props} />
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	venue,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		venue,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchMember,
	updateMember,
})(Update);
