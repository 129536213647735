import { API_ROOT } from '@config/api';
import {
	SET_ENTITIES,
	SET_ENTITY,
	ADD_ENTITY,
	UPDATE_ENTITY,
	CLEAR_ENTITY,
	CLEAR_ENTITIES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchEntities = () => (dispatch) =>
	request('get', `${API_ROOT}/entities`)
		.then((response) => {
			dispatch({
				type: SET_ENTITIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEntitiesByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/entities/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ENTITIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEntity = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/entities/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ENTITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createEntity = (data) => (dispatch) =>
	request('post', `${API_ROOT}/entities`, data)
		.then((response) => {
			dispatch({
				type: ADD_ENTITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateEntity = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/entities/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ENTITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearEntity = () => (dispatch) =>
	dispatch({
		type: CLEAR_ENTITY,
	});

const clearEntities = () => (dispatch) =>
	dispatch({
		type: CLEAR_ENTITIES,
	});

export {
	fetchEntities,
	fetchEntitiesByOrganization,
	fetchEntity,
	createEntity,
	updateEntity,
	clearEntity,
	clearEntities,
};
