import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchStorages,
	fetchStoragesByOrganization,
	clearStorages,
} from '@resources/Storage/actions';
import {
	fetchSpaces,
	fetchSpacesByOrganization,
	clearSpaces,
} from '@resources/Space/actions';
import isEmpty from 'lodash.isempty';
import CreateStorageModal from '@containers/Storage/Create';
import CreateSpaceModal from '@containers/Space/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const StorageSelectForm = (props) => {
	const {
		form,
		storages,
		spaces,
		organization,
		fetchStorages,
		fetchStoragesByOrganization,
		clearStorages,
		fetchSpaces,
		fetchSpacesByOrganization,
		clearSpaces,
		formItemProps,
	} = props;
	const intl = useIntl();
	const { getFieldValue } = form;
	const [createStorage, setCreateStorage] = useState(false);
	const [createSpace, setCreateSpace] = useState(false);
	const [store, setStore] = useState(getFieldValue('storage'));
	const [module, setModule] = useState({ _id: 'storage', code: 'storage' });
	useEffect(() => {
		const fetchData = async () => {
			await clearStorages();
			organization?._id
				? await fetchStoragesByOrganization(organization?._id)
				: await fetchStorages();

			await clearSpaces();
			organization?._id
				? await fetchSpacesByOrganization(organization?._id)
				: await fetchSpaces();
		};
		fetchData();
	}, [organization]);

	const renderStorageOptions = useCallback(() => {
		if (!isEmpty(storages)) {
			return storages.map((storage, index) => {
				return (
					<Option
						value={storage._id}
						key={`storage-${index}`}
						title={storage?.name}>
						{storage?.name}
					</Option>
				);
			});
		}
	}, [storages]);

	const renderSpaceOptions = useCallback(() => {
		if (!isEmpty(spaces) && store) {
			return spaces
				.filter((space) => space.storage === store)
				.map((space, index) => {
					return (
						<Option
							value={space._id}
							key={`space-${index}`}
							title={space?.name}>
							{space?.name}
						</Option>
					);
				});
		}
	}, [spaces, store]);

	const onStorageChange = (value) => {
		setStore(value);
		setModule({
			_id: 'storage',
			code: 'storage',
			moduleId: value,
		});
	};

	return (
		<>
			<CreateStorageModal
				{...props}
				visible={createStorage}
				onCancel={() => setCreateStorage(false)}
			/>

			<CreateSpaceModal
				{...props}
				visible={createSpace}
				module={module}
				onCancel={() => setCreateSpace(false)}
			/>
			<FormItem
				label={`Storage`}
				required
				style={{ marginBottom: 0 }}
				{...formItemProps}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'storage'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.storage.required',
										defaultMessage: 'Please input storage',
									}),
								},
							]}>
							<Select
								onChange={onStorageChange}
								style={{ width: '100%' }}
								placeholder="Please select storage"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderStorageOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreateStorage(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>

			{getFieldValue('storage') && (
				<FormItem
					label={`Space`}
					required
					style={{ marginBottom: 0 }}
					{...formItemProps}>
					<Row type="flex" gutter={24}>
						<Col flex={1}>
							<FormItem name={'space'}>
								<Select
									disabled={
										renderSpaceOptions()?.length === 0
									}
									style={{
										width: '100%',
									}}
									placeholder="Please select space"
									optionFilterProp="title"
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}>
									{renderSpaceOptions()}
								</Select>
							</FormItem>
						</Col>
						<Col style={{ width: 50 }}>
							<Button onClick={() => setCreateSpace(true)}>
								<PlusOutlined />
							</Button>
						</Col>
					</Row>
				</FormItem>
			)}
		</>
	);
};

const mapStateToProps = ({ organization, storages, spaces }) => {
	return {
		organization,
		storages,
		spaces,
	};
};

export default connect(mapStateToProps, {
	fetchStorages,
	fetchStoragesByOrganization,
	clearStorages,

	fetchSpaces,
	fetchSpacesByOrganization,
	clearSpaces,
})(StorageSelectForm);
