import {
	SET_JOURNALS,
	SET_JOURNAL,
	CLEAR_JOURNALS,
	CLEAR_JOURNAL,
	ADD_JOURNAL,
	UPDATE_JOURNAL,
	REMOVE_JOURNAL,
} from './actionTypes';

export const journal = (state = {}, action) => {
	switch (action.type) {
		case SET_JOURNAL:
			return action.payload;
		case UPDATE_JOURNAL:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_JOURNAL:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_JOURNALS:
			return action.payload;
		case ADD_JOURNAL:
			return [...state, action.payload];
		case UPDATE_JOURNAL:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_JOURNAL:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_JOURNALS:
			return [];
		default:
			return state;
	}
};
