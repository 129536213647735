import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Board, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchTasks,
	fetchTasksByOrganizationModule,
	clearTasks,
	reorderTasks,
} from '@resources/Task/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { TaskItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Task = (props) => {
	const {
		tasks,
		fetchTasks,
		fetchTasksByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearTasks,
		reorderTasks,
		match,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('task');
			await clearTasks();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchTasksByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchTasks();
		};

		func();

		return () => clearTasks();
	}, [
		fetchTasks,
		fetchModulesBySubmoduleCode,
		fetchTasksByOrganizationModule,
		clearTasks,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		const ta = tasks.map((task) => {
			return { id: task._id, ...task };
		});
		let progress = ta?.filter((task) => task.status === 'progress') || [];
		let backlog = ta?.filter((task) => task.status === 'backlog') || [];
		let completed = ta?.filter((task) => task.status === 'completed') || [];

		return { backlog, progress, completed };
	}, [tasks]);

	const handleReorder = (data) => {
		const reorderedTasks = [
			...data['backlog'].map((d) => {
				return { ...d, status: 'backlog' };
			}),
			...data['progress'].map((d) => {
				return { ...d, status: 'progress' };
			}),
			...data['completed'].map((d) => {
				return { ...d, status: 'completed' };
			}),
		];
		reorderTasks(reorderedTasks);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.crew.management',
						defaultMessage: 'Task Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>

			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.tasks"
									defaultMessage="Tasks"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.crew"
									defaultMessage="New Task"
								/>
							</Button>
						</Col>
					</Row>
				</Container>
				<Board
					initial={parseDataSource()}
					onReorder={handleReorder}
					listItem={TaskItem}
					onEdit={(item) => {
						setUpdate(item._id);
					}}
				/>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	tasks,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		tasks,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchTasks,
	fetchTasksByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearTasks,
	reorderTasks,
})(Task);
