import { API_ROOT } from '@config/api';
import {
	SET_INSTRUCTIONS,
	SET_INSTRUCTION,
	ADD_INSTRUCTION,
	UPDATE_INSTRUCTION,
	CLEAR_INSTRUCTION,
	CLEAR_INSTRUCTIONS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchInstructions = () => (dispatch) =>
	request('get', `${API_ROOT}/instructions`)
		.then((response) => {
			dispatch({
				type: SET_INSTRUCTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchInstructionsByOrganizationSubmodule = (
	organization,
	submodule,
	id
) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/instructions/organization/${organization}/submodule/${submodule}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_INSTRUCTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchInstruction = (id) => (dispatch) =>
	request('get', `${API_ROOT}/instructions/${id}`)
		.then((response) => {
			dispatch({
				type: SET_INSTRUCTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createInstruction = (data) => (dispatch) =>
	request('post', `${API_ROOT}/instructions`, data)
		.then((response) => {
			dispatch({
				type: ADD_INSTRUCTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateInstruction = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/instructions/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_INSTRUCTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearInstructions = () => (dispatch) =>
	dispatch({
		type: CLEAR_INSTRUCTIONS,
	});

const clearInstruction = () => (dispatch) =>
	dispatch({
		type: CLEAR_INSTRUCTION,
	});

export {
	fetchInstructions,
	fetchInstructionsByOrganizationSubmodule,
	fetchInstruction,
	createInstruction,
	updateInstruction,
	clearInstructions,
	clearInstruction,
};
