import React from 'react';
import { Box, Section, Text } from '@common/components';
import { Button, Row, Col, Tag, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

const Stock = ({ stock, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick(stock)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links" align="middle">
					<Col flex={1}>
						<Row type="flex" align="middle" gutter={24}>
							<Col md={4}>
								{moment(stock?.date).format('DD MMM YYYY')}
							</Col>
							<Col md={4}>
								{stock.type === 'purchase' ? (
									<Tag size="large" color="green">
										Purchase
									</Tag>
								) : (
									<Tag size="large" color="red">
										Sale
									</Tag>
								)}
							</Col>
							<Col md={4}>
								<Text fontSize={20}>{stock.quantity}</Text>
							</Col>
							<Col md={6}>
								<Typography.Title
									level={4}
									style={{ margin: 0, lineHeight: 1 }}>
									{stock?.type === 'purchase'
										? stock?.supplier?.name
										: `${stock?.customer?.user?.firstName ||
												stock?.customer
													?.firstName} ${stock
												?.customer?.user?.lastName ||
												stock?.customer?.lastName}`}
								</Typography.Title>
							</Col>

							<Col md={6}>
								{stock?.storage?.name
									? `${stock?.storage?.name} | ${stock?.space?.name}`
									: `No storage location specified`}
							</Col>
						</Row>
					</Col>
					<Col style={{ width: 30 }}>
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(stock)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
					</Col>
				</Row>
				<Box textAlign="right">
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(stock)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Stock;
