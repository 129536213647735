import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Select, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchCrewsByOrganizationModule } from '@resources/Crew/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

const TaskForm = (props) => {
	const {
		module,
		crews,
		data,
		onSubmit,
		fetchCrewsByOrganizationModule,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchCrewsByOrganizationModule(
				organization?._id,
				module._id,
				match?.params[`moduleId`]
			);
		};
		!isEmpty(module) && fetchData();
	}, [module, organization]);

	const renderMemberOptions = useCallback(() => {
		if (!isEmpty(crews)) {
			return crews.map((crew, index) => {
				return (
					<Option
						value={crew._id}
						key={`crew-${index}`}
						title={crew.title}>
						{crew?.member?.user?.firstName}{' '}
						{crew?.member?.user?.lastName}
					</Option>
				);
			});
		} else {
			return (
				data &&
				data.crew &&
				data.crew?.member?.user && (
					<Option
						value={data.crew._id}
						key={`user-0`}
						title={`${data.crew?.member?.user?.firstName} ${data.crew?.member?.user?.lastName}`}>
						{data.crew?.member?.user?.firstName}{' '}
						{data.crew?.member?.user?.lastName}
					</Option>
				)
			);
		}
	}, [crews]);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	return (
		<Form
			preserve={false}
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				crew: data?.crew?._id,
				deadline: moment(data?.deadline),
			}}>
			<FormItem
				label={`Crew`}
				name="crew"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.member.required',
							defaultMessage: 'Please input member',
						}),
					},
				]}>
				<Select
					placeholder="Please select member"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderMemberOptions()}
				</Select>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.detail',
					defaultMessage: 'Detail',
				})}
				name="detail"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.detail.required',
							defaultMessage: 'Please input detail',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.detail',
						defaultMessage: 'Detail',
					})}
				/>
			</FormItem>
			<FormItem
				label={`Status`}
				name="status"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.status.required',
							defaultMessage: 'Please input status',
						}),
					},
				]}>
				<Select
					placeholder="Please select status"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value={'backlog'} title={'Backlog'}>
						Backlog
					</Option>
					<Option value={'progress'} title={'Progress'}>
						Progress
					</Option>
					<Option value={'completed'} title={'Completed'}>
						Completed
					</Option>
				</Select>
			</FormItem>
			<Form.Item label="Deadline" name="deadline">
				<DatePicker disabledDate={disabledDate} />
			</Form.Item>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, crews, organization }) => {
	return {
		auth,
		crews,
		organization,
	};
};

export default connect(mapStateToProps, { fetchCrewsByOrganizationModule })(
	TaskForm
);
