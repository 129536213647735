import { API_ROOT } from '@config/api';
import {
	SET_MISSIONS,
	SET_MISSION,
	ADD_MISSION,
	UPDATE_MISSION,
	CLEAR_MISSIONS,
	CLEAR_MISSION,
} from './actionTypes';
import request from '@common/utils/request';

const fetchMissions = () => (dispatch) =>
	request('get', `${API_ROOT}/missions`)
		.then((response) => {
			dispatch({
				type: SET_MISSIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchMissionsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/missions/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MISSIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchMission = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/missions/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MISSION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createMission = (data) => (dispatch) =>
	request('post', `${API_ROOT}/missions`, data)
		.then((response) => {
			dispatch({
				type: ADD_MISSION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateMission = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/missions/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_MISSION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearMissions = () => (dispatch) =>
	dispatch({
		type: CLEAR_MISSIONS,
	});

const clearMission = () => (dispatch) =>
	dispatch({
		type: CLEAR_MISSION,
	});

export {
	fetchMissions,
	fetchMissionsByOrganization,
	fetchMission,
	createMission,
	updateMission,
	clearMissions,
	clearMission,
};
