import { API_ROOT } from '@config/api';
import {
	SET_EVENTS,
	SET_EVENT,
	ADD_EVENT,
	UPDATE_EVENT,
	CLEAR_EVENT,
	CLEAR_EVENTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchEvents = (type) => (dispatch) =>
	request('get', `${API_ROOT}/events`)
		.then((response) => {
			dispatch({
				type: SET_EVENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEventsPublic = (type) => (dispatch) =>
	request('get', `${API_ROOT}/events/public`)
		.then((response) => {
			dispatch({
				type: SET_EVENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEventsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/events/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_EVENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEventsByOrganizationPublic = (id) => (dispatch) =>
	request('get', `${API_ROOT}/events/organization/${id}/public`)
		.then((response) => {
			dispatch({
				type: SET_EVENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEvent = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/events/${id}`)
		.then((response) => {
			dispatch({
				type: SET_EVENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createEvent = (data) => (dispatch) =>
	request('post', `${API_ROOT}/events`, data)
		.then((response) => {
			dispatch({
				type: ADD_EVENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateEvent = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/events/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_EVENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearEvents = () => (dispatch) =>
	dispatch({
		type: CLEAR_EVENTS,
	});

const clearEvent = () => (dispatch) =>
	dispatch({
		type: CLEAR_EVENT,
	});

export {
	fetchEvents,
	fetchEventsPublic,
	fetchEventsByOrganization,
	fetchEventsByOrganizationPublic,
	fetchEvent,
	createEvent,
	updateEvent,
	clearEvents,
	clearEvent,
};
