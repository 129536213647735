import React, { useCallback, useState, useEffect } from 'react';
import { Form, InputNumber, Button, Checkbox, Select, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchSpacesByOrganizationModule } from '@resources/Space/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

const BookingForm = (props) => {
	const {
		module,
		spaces,
		data,
		onSubmit,
		fetchSpacesByOrganizationModule,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchSpacesByOrganizationModule(
				organization?._id,
				module.code,
				match?.params[`moduleId`]
			);
		};
		!isEmpty(organization) && !isEmpty(module) && fetchData();
	}, [module, organization]);

	const renderSpaceOptions = useCallback(() => {
		if (!isEmpty(spaces)) {
			return spaces.map((space, index) => {
				return (
					<Option
						value={space._id}
						key={`space-${index}`}
						title={space?.name}>
						{space?.name}
					</Option>
				);
			});
		}
	}, [spaces]);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	return (
		<Form
			layout="vertical"
			form={form}
			name="position-form"
			onFinish={handleFinish}
			initialValues={{ ...data, space: data?.space?._id }}>
			<FormItem
				label={`Space`}
				name={'space'}
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.space.required',
							defaultMessage: 'Please input space',
						}),
					},
				]}>
				<Select
					style={{ width: 180 }}
					placeholder="Please select space"
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderSpaceOptions()}
				</Select>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker disabledDate={disabledDate} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.duration',
					defaultMessage: 'Duration',
				})}
				name="duration"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.duration.required',
							defaultMessage: 'Please input duration',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.hours',
						defaultMessage: 'Hours',
					})}
				/>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, spaces, organization }) => {
	return {
		auth,
		spaces,
		organization,
	};
};

export default connect(mapStateToProps, { fetchSpacesByOrganizationModule })(
	BookingForm
);
