import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Box, Image, Section, HTML } from '../../components';

const Paragraph = ({ data, id }) => {
	const { subtitle, detail, image, position } = data || {};
	const text = (
		<>
			<Box textAlign={position === 'center' ? 'center' : 'left'}>
				<Typography.Title level={2}>{subtitle}</Typography.Title>
				<HTML>{detail}</HTML>
			</Box>
		</>
	);

	const media = image && <Image width="100%" src={image.path} />;

	const body = () => {
		if (position && position !== 'center' && image) {
			return (
				<Row>
					<Col md={12}>
						<Box pr={10}>{position === 'left' ? media : text}</Box>
					</Col>
					<Col md={12}>
						<Box pl={10}>{position === 'left' ? text : media}</Box>
					</Col>
				</Row>
			);
		}

		return text;
	};

	return <Section id={id}>{body()}</Section>;
};

export default Paragraph;
