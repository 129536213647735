import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import InstructionForm from '../Form';
import {
	fetchInstruction,
	updateInstruction,
} from '@resources/Instruction/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		fetchInstruction,
		instruction,
		updateInstruction,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchInstruction(visible);
		};
		visible && fetchData();
	}, [fetchInstruction, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateInstruction(instruction._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.instruction.update',
							defaultMessage:
								'An existing Instruction entry has been successfully updated',
						})
					);
					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[instruction]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.instruction',
					defaultMessage: 'Update Instruction',
				})}
			</Title>
			{!isEmpty(instruction) && (
				<InstructionForm
					data={{
						...instruction,
					}}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		instruction: state.instruction,
	};
};

export default connect(mapStateToProps, {
	fetchInstruction,
	updateInstruction,
})(Update);
