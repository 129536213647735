import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchStocks,
	fetchStocksByOrganizationModule,
	clearStocks,
} from '@resources/Stock/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { StockItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Stock = (props) => {
	const {
		stocks,
		fetchStocks,
		fetchStocksByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearStocks,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});
	const [total, setTotal] = useState(0);

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('stock');
			await clearStocks();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchStocksByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchStocks();
		};

		func();
	}, [
		fetchStocks,
		fetchModulesBySubmoduleCode,
		fetchStocksByOrganizationModule,
		clearStocks,
		organization,
	]);

	useEffect(() => {
		let t = 0;
		const func = () => {
			stocks.forEach((stock) => {
				if (stock.type === 'purchase') {
					t += stock.quantity;
				} else {
					t -= stock.quantity;
				}
			});
		};
		func();
		setTotal(t);
	}, [stocks]);

	const parseDataSource = useCallback(() => {
		return stocks.map((stock) => {
			return {
				key: stock._id,
				...stock,
			};
		});
	}, [stocks]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.stock.management',
						defaultMessage: 'Stock Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.stock"
									defaultMessage="Stock"
								/>
								: {total}
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.stockItem"
									defaultMessage="New Stock Item"
								/>
							</Button>
						</Col>
					</Row>
					<Box className="mb-5">
						{parseDataSource() &&
							parseDataSource().map((stock, index) => (
								<StockItem
									stock={stock}
									onEdit={() => setUpdate(stock._id)}
								/>
							))}
					</Box>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	stocks,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
}) => {
	return {
		stocks,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchStocks,
	fetchStocksByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearStocks,
})(Stock);
