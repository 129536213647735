import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox, InputNumber, Switch } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearVenue } from '@resources/Venue/actions';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;

const ItemForm = (props) => {
	const { module, data, onSubmit, match, onCancel, organization } = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				itemTypes: data?.itemTypes?.map((itemType) => itemType._id),
			}}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.name',
					defaultMessage: 'Name',
				})}
				name="name"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.name.required',
							defaultMessage: 'Please input name',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.name',
						defaultMessage: 'Name',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.location',
					defaultMessage: 'Location',
				})}
				name="location"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.location.required',
							defaultMessage: 'Please input location',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.location',
						defaultMessage: 'Location',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.bookable',
					defaultMessage: 'Bookable',
				})}
				name="isBookable"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.isBookable !== currentValues.isBookable
				}>
				{({ getFieldValue }) => {
					return getFieldValue('isBookable') ? (
						<>
							<FormItem
								label={intl.formatMessage({
									id: 'common.bookingFee',
									defaultMessage: 'Booking Fee',
								})}
								name="bookFee"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.bookingFee.required',
											defaultMessage:
												'Please input booking fee',
										}),
									},
								]}>
								<InputNumber placeholder={0} />
							</FormItem>

							<FormItem
								label={intl.formatMessage({
									id: 'common.maxBookDuration',
									defaultMessage: 'Max Booking Duration',
								})}
								name="maxBookDuration"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.maxBookDuration.required',
											defaultMessage:
												'Please input max Booking Duration',
										}),
									},
								]}>
								<InputNumber
									placeholder={intl.formatMessage({
										id: 'common.hours',
										defaultMessage: 'Hours',
									})}
								/>
							</FormItem>
						</>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.capacity',
					defaultMessage: 'Capacity',
				})}
				name="capacity"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.capacity.required',
							defaultMessage: 'Please input capacity',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.capacity',
						defaultMessage: 'Capacity',
					})}
				/>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, itemTypes, organization }) => {
	return {
		auth,
		itemTypes,
		organization,
	};
};

export default connect(mapStateToProps, {
	clearVenue,
})(ItemForm);
