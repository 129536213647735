import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import TaskForm from '../Form';
import { fetchTask, updateTask, clearTask } from '@resources/Task/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		module,
		fetchTask,
		task,
		updateTask,
		clearTask,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchTask(visible);
		};

		visible ? fetchData() : clearTask();
	}, [fetchTask, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateTask(task._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.task.update',
							defaultMessage:
								'An existing Task entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[task]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.task',
					defaultMessage: 'Update Task',
				})}
			</Title>

			{!isEmpty(task) && (
				<TaskForm
					data={task}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	task,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		task,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchTask,
	updateTask,
	fetchModulesBySubmoduleCode,
	clearTask,
})(Update);
