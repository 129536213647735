import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import FacilityForm from '../Form';
import { fetchFacility, updateFacility } from '@resources/Facility/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		fetchFacility,
		facility,
		updateFacility,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchFacility(visible);
		};
		visible && fetchData();
	}, [fetchFacility, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateFacility(facility._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.facility.update',
							defaultMessage:
								'An existing Facility entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[facility]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.facility',
					defaultMessage: 'Update Facility',
				})}
			</Title>
			{!isEmpty(facility) && (
				<FacilityForm
					data={facility}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ facility }) => {
	return {
		facility,
	};
};

export default connect(mapStateToProps, {
	fetchFacility,
	updateFacility,
})(Update);
