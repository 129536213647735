import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Select, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchItems,
	fetchItemsByOrganization,
	clearItems,
} from '@resources/Item/actions';
import isEmpty from 'lodash.isempty';
import CreateItemModal from '@containers/Item/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const ItemSelectForm = (props) => {
	const {
		items,
		organization,
		fetchItems,
		fetchItemsByOrganization,
		clearItems,
		formItemProps,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			await clearItems();
			organization?._id
				? await fetchItemsByOrganization(organization?._id)
				: await fetchItems();
		};
		fetchData();
	}, [organization]);

	const renderItemOptions = useCallback(() => {
		if (!isEmpty(items)) {
			return items.map((item, index) => {
				return (
					<Option
						value={item._id}
						key={`item-${index}`}
						title={item?.title}>
						{item?.title}
					</Option>
				);
			});
		}
	}, [items]);

	return (
		<>
			<CreateItemModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<FormItem
				label={`Item`}
				required
				style={{ marginBottom: 0 }}
				{...formItemProps}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'item'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.item.required',
										defaultMessage: 'Please input item',
									}),
								},
							]}
							{...formItemProps}>
							<Select
								style={{ width: '100%' }}
								placeholder="Please select item"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderItemOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreate(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, items }) => {
	return {
		organization,
		items,
	};
};

export default connect(mapStateToProps, {
	fetchItems,
	fetchItemsByOrganization,
	clearItems,
})(ItemSelectForm);
