import { API_ROOT } from '@config/api';
import {
  SET_CLASSES,
  SET_CLASS,
  ADD_CLASS,
  UPDATE_CLASS,
  CLEAR_CLASSES,
  CLEAR_CLASS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchClasses = () => (dispatch) =>
  request('get', `${API_ROOT}/classes`)
    .then((response) => {
      dispatch({
        type: SET_CLASSES,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

const fetchClassesByOrganization = (id) => (dispatch) =>
  request('get', `${API_ROOT}/classes/organization/${id}`)
    .then((response) => {
      dispatch({
        type: SET_CLASSES,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

const fetchClass = (id, type) => (dispatch) =>
  request('get', `${API_ROOT}/classes/${id}`)
    .then((response) => {
      dispatch({
        type: SET_CLASS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

const createClass = (data) => (dispatch) =>
  request('post', `${API_ROOT}/classes`, data)
    .then((response) => {
      dispatch({
        type: ADD_CLASS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => Promise.reject(err));

const updateClass = (id, data) => (dispatch) =>
  request('post', `${API_ROOT}/classes/${id}`, data)
    .then((response) => {
      dispatch({
        type: UPDATE_CLASS,
        payload: response.data.data,
      });
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });

const clearClasses = () => (dispatch) =>
  dispatch({
    type: CLEAR_CLASSES,
  });

const clearClass = () => (dispatch) =>
  dispatch({
    type: CLEAR_CLASS,
  });

export {
  fetchClasses,
  fetchClassesByOrganization,
  fetchClass,
  createClass,
  updateClass,
  clearClasses,
  clearClass,
};
