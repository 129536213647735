import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { authorizeStripe } from '../../../resources/Stripe/actions';
import { fetchOrganization } from '../../../resources/Organization/actions';
import {
	fetchTransaction,
	completeTransaction,
} from '../../../resources/Transaction/actions';
import { Container, Title, View, Section } from '../../../components';
import CheckoutForm from './Form';
import { Col, Row, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import {
	ProductHeaderItem,
	ProductFormItem,
	TitheTableItem,
} from '@fragments/items';
import isEmpty from 'lodash.isempty';

const { Content } = Layout;

const Checkout = ({
	auth,
	siteTheme,
	transaction,
	fetchTransaction,
	completeTransaction,
	match,
	history,
	organization,
}) => {
	const intl = useIntl();
	const { transactionId } = match?.params || {};
	useEffect(() => {
		const fetchData = async () => {
			if (organization?._id) {
				await fetchTransaction(transactionId);
			}
		};

		fetchData();
	}, [siteTheme]);

	useEffect(() => {
		if (transaction.free) {
			if (transaction.module.code === 'tithe') {
				history.push(
					organization?.alias
						? `/${organization?.alias}/${transaction.module.url}`
						: `/${transaction.module.url}`
				);
			} else {
				history.push(
					organization?.alias
						? `/${organization?.alias}/${transaction.module.url}/${
								transaction[transaction.module.code]._id
						  }/details`
						: `/${transaction.module.url}/${
								transaction[transaction.module.code]._id
						  }/details`
				);
			}
		}
	}, [transaction]);

	const handleCheckout = (values) => {
		return completeTransaction(values)
			.then((response) => {
				if (transaction.module.code === 'tithe') {
					history.push(
						organization?.alias
							? `/${organization?.alias}/${transaction.module.url}`
							: `/${transaction.module.url}`
					);
				} else {
					history.push(
						organization?.alias
							? `/${organization?.alias}/${
									transaction.module.url
							  }/${
									transaction[transaction.module.code]._id
							  }/details`
							: `/${transaction.module.url}/${
									transaction[transaction.module.code]._id
							  }/details`
					);
				}

				return Promise.resolve(response);
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.payment',
						defaultMessage: 'Payment',
					})}
				</title>
			</Helmet>

			<View>
				<Container>
					<Title>
						<FormattedMessage
							id="common.payment"
							defaultMessage="Payment"
						/>
					</Title>

					<Row gutter={24}>
						<Col md={14}>
							<Section>
								{transaction?.event && <ProductHeaderItem />}
								{!isEmpty(transaction) &&
									transaction.items.map((item) =>
										item?.product ? (
											<ProductFormItem
												product={{
													...item.product,
													quantity: item.quantity,
													price: item.price,
												}}
											/>
										) : (
											<TitheTableItem
												tithe={item.tithe}
											/>
										)
									)}
							</Section>
						</Col>
						<Col md={10}>
							<Section hoverable shadowed>
								<CheckoutForm
									auth={auth}
									transaction={transaction}
									onSubmit={handleCheckout}
									onCancel={() => history.goBack()}
								/>
							</Section>
						</Col>
					</Row>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({ auth, siteTheme, transaction, organization }) => {
	return {
		auth,
		siteTheme,
		transaction,
		organization,
	};
};

export default connect(mapStateToProps, {
	authorizeStripe,
	fetchOrganization,
	fetchTransaction,
	completeTransaction,
})(Checkout);
