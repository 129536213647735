import { API_ROOT } from '@config/api';
import {
	SET_ACCESSES,
	SET_ACCESS,
	ADD_ACCESS,
	UPDATE_ACCESS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchAccesses = (type) => (dispatch) =>
	request('get', `${API_ROOT}/accesses`)
		.then((response) => {
			dispatch({
				type: SET_ACCESSES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAccess = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/accesses/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ACCESS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createAccess = (data) => (dispatch) =>
	request('post', `${API_ROOT}/accesses`, data)
		.then((response) => {
			dispatch({
				type: ADD_ACCESS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateAccess = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/accesses/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ACCESS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

export { fetchAccesses, fetchAccess, createAccess, updateAccess };
