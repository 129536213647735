import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography, Tag } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

const Programme = ({ programme, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-5" onClick={() => onClick && onClick()}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links" gutter={24}>
					<Col md={4}>
						<Box>
							<Typography.Text>
								{moment(programme.time[0]).format('HH:mm')} -{' '}
								{moment(programme.time[1]).format('HH:mm')}
							</Typography.Text>
						</Box>
						<Box>
							<Typography.Text>
								{moment(programme.date).format('DD MMM YYYY')}
							</Typography.Text>
						</Box>
					</Col>
					<Col md={15}>
						<Typography.Title level={4} style={{ marginBottom: 0 }}>
							{programme.title}
						</Typography.Title>
						<Typography.Paragraph>
							{programme.detail}
						</Typography.Paragraph>
						{programme?.crews?.map((crew) => (
							<Tag>
								{crew?.member?.user?.firstName}{' '}
								{crew?.member?.user?.lastName}
							</Tag>
						))}

						{programme?.teams?.map((team) => (
							<Tag>{team?.title}</Tag>
						))}
					</Col>
					<Col md={5}>
						<Box textAlign="right">
							{onEdit && (
								<Button
									size="small"
									type="text"
									onClick={() => onEdit && onEdit(programme)}>
									<EditFilled style={{ color: '#FFCC00' }} />
								</Button>
							)}
							{onDelete && (
								<Button
									size="small"
									type="text"
									onClick={() =>
										onDelete && onDelete(programme)
									}>
									<DeleteFilled
										style={{ color: '#FF0000' }}
									/>
								</Button>
							)}
						</Box>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Programme;
