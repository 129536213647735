import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FIELD_TYPES } from '@common/constants';

const FormItem = Form.Item;

const formItemLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};

const tailLayout = {
	wrapperCol: { offset: 6, span: 14 },
};

const FieldTextForm = ({
	auth,
	fieldTypes,
	data,
	forma,
	onSubmit,
	onCancel,
}) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			const convertedValues = { ...values };
			convertedValues['form'] = forma._id;
			convertedValues['type'] = fieldTypes.filter(
				(fieldType) => fieldType.alias === FIELD_TYPES.DATE
			)[0]._id;

			onSubmit(convertedValues)
				.then(() => {})
				.catch((err) => {})
				.finally(() => {
					setLoading(false);
					onCancel && onCancel();
				});
		},
		[onSubmit, fieldTypes, setLoading]
	);

	return (
		<Form
			name="paragraph-form"
			{...formItemLayout}
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.label',
					defaultMessage: 'Label',
				})}
				name="label">
				<Input
					placeholder={intl.formatMessage({
						id: 'common.label',
						defaultMessage: 'Label',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.name',
					defaultMessage: 'Name',
				})}
				name="name">
				<Input
					placeholder={intl.formatMessage({
						id: 'common.name',
						defaultMessage: 'Name',
					})}
				/>
			</FormItem>

			<FormItem {...tailLayout} name="required" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.required',
						defaultMessage: 'Is Required',
					})}
				</Checkbox>
			</FormItem>
			<FormItem wrapperCol={{ span: 12, offset: 6 }} className="mt-5">
				<Button type="secondary" onClick={onCancel && onCancel}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, fieldTypes, forma }) => {
	return {
		auth,
		fieldTypes,
		forma,
	};
};

export default connect(mapStateToProps, {})(FieldTextForm);
