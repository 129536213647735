import { API_ROOT } from '@config/api';
import {
	SET_MEMBERS,
	SET_MEMBER,
	ADD_MEMBER,
	UPDATE_MEMBER,
	CLEAR_MEMBER,
	CLEAR_MEMBERS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchMembers = () => (dispatch) =>
	request('get', `${API_ROOT}/members`)
		.then((response) => {
			dispatch({
				type: SET_MEMBERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchMembersByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/members/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MEMBERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchMember = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/members/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MEMBER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createMember = (data) => (dispatch) =>
	request('post', `${API_ROOT}/members`, data)
		.then((response) => {
			dispatch({
				type: ADD_MEMBER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateMember = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/members/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_MEMBER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearMember = () => (dispatch) =>
	dispatch({
		type: CLEAR_MEMBER,
	});

const clearMembers = () => (dispatch) =>
	dispatch({
		type: CLEAR_MEMBERS,
	});

export {
	fetchMembers,
	fetchMembersByOrganization,
	fetchMember,
	createMember,
	updateMember,
	clearMember,
	clearMembers,
};
