import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	Container,
	Title,
	View,
	Section,
} from '@common/components';
import { Row, Col, Layout, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesByApps } from '@resources/Module/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { Content } = Layout;

const Menu = (props) => {
	const { fetchModulesByApps, organization, siteTheme } = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await fetchModulesByApps(organization?.apps?.map((app) => app._id));
		};

		func();
	}, [fetchModulesByApps, organization, siteTheme]);

	const settingsOrganizations = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.crew.management',
						defaultMessage: 'Menu Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				visible={create}
				onCancel={() => {
					setCreate(false);
				}}
			/>

			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>

			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.menus"
									defaultMessage="Menus"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right"></Col>
					</Row>
					<Box mb={50}>
						<Typography.Title level={3}>
							Member Menu
						</Typography.Title>
						<Slider {...settingsOrganizations}>
							{organization.menus.member.map((menu) => (
								<MenuItem
									menu={menu}
									onEdit={() =>
										setUpdate({
											...menu,
											category: 'member',
										})
									}
								/>
							))}
							<Box className="p-3">
								<Section shadowed>
									<Button
										style={{ width: '100%' }}
										onClick={() => {
											setCreate('member');
										}}>
										Add Member Menu
									</Button>
								</Section>
							</Box>
						</Slider>
					</Box>

					<Box mb={50}>
						<Typography.Title level={3}>
							Customer Menu
						</Typography.Title>
						<Slider {...settingsOrganizations}>
							{organization.menus.customer.map((menu) => (
								<MenuItem
									menu={menu}
									onEdit={() =>
										setUpdate({
											...menu,
											category: 'customer',
										})
									}
								/>
							))}
							<Box className="p-3">
								<Section shadowed>
									<Button
										style={{ width: '100%' }}
										onClick={() => {
											setCreate('customer');
										}}>
										Add Customer Menu
									</Button>
								</Section>
							</Box>
						</Slider>
					</Box>

					<Box mb={50}>
						<Typography.Title level={3}>
							Public Menu
						</Typography.Title>
						<Slider {...settingsOrganizations}>
							{organization.menus.public.map((menu) => (
								<MenuItem
									menu={menu}
									onEdit={() =>
										setUpdate({
											...menu,
											category: 'public',
										})
									}
								/>
							))}
							<Box className="p-3">
								<Section shadowed>
									<Button
										style={{ width: '100%' }}
										onClick={() => {
											setCreate('public');
										}}>
										Add Public Menu
									</Button>
								</Section>
							</Box>
						</Slider>
					</Box>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	siteTheme,
	menus,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
	teams,
}) => {
	return {
		siteTheme,
		menus,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
		teams,
	};
};

export default connect(mapStateToProps, {
	fetchModulesByApps,
})(Menu);
