import React from 'react';
import { Box, Section, Text } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

const Activity = ({ activity, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-5" onClick={() => onClick && onClick()}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links" gutter={24}>
					<Col style={{ width: 80 }}>
						<Text fontSize={50} lineHeight={1}>
							{moment(activity.date).format('DD')}
						</Text>
						<Text fontSize={17}>
							{moment(activity.date).format('MMM YY')}
						</Text>
					</Col>
					<Col flex={1}>
						<Typography.Title level={4}>
							{activity.title}
						</Typography.Title>
						<Typography.Text>
							{activity.description}
						</Typography.Text>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(activity)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(activity)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Activity;
