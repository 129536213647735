import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ServiceForm from '../Form';
import { fetchService, updateService } from '@resources/Service/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import moment from 'moment';

const Update = (props) => {
	const { fetchService, service, updateService, visible, onCancel } = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchService(visible);
		};
		visible && fetchData();
	}, [fetchService, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateService(service._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.service.update',
							defaultMessage:
								'An existing Service entry has been successfully updated',
						})
					);
					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[service]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.service',
					defaultMessage: 'Update Service',
				})}
			</Title>
			{!isEmpty(service) && (
				<ServiceForm
					data={{
						...service,
						date: service.date.map((date) => moment(date)),
						time: service.time.map((time) => moment(time)),
					}}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		service: state.service,
	};
};

export default connect(mapStateToProps, { fetchService, updateService })(
	Update
);
