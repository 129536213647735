import { API_ROOT } from '@config/api';
import {
	SET_COMMENTS,
	SET_COMMENT,
	ADD_COMMENT,
	UPDATE_COMMENT,
	CLEAR_COMMENT,
	CLEAR_COMMENTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchComments = () => (dispatch) =>
	request('get', `${API_ROOT}/comments`)
		.then((response) => {
			dispatch({
				type: SET_COMMENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCommentsByOrganizationSubmodule = (organization, submodule, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/comments/organization/${organization}/submodule/${submodule}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_COMMENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchComment = (id) => (dispatch) =>
	request('get', `${API_ROOT}/comments/${id}`)
		.then((response) => {
			dispatch({
				type: SET_COMMENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createComment = (data) => (dispatch) =>
	request('post', `${API_ROOT}/comments`, data)
		.then((response) => {
			dispatch({
				type: ADD_COMMENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateComment = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/comments/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_COMMENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearComments = () => (dispatch) =>
	dispatch({
		type: CLEAR_COMMENTS,
	});

const clearComment = () => (dispatch) =>
	dispatch({
		type: CLEAR_COMMENT,
	});

export {
	fetchComments,
	fetchCommentsByOrganizationSubmodule,
	fetchComment,
	createComment,
	updateComment,
	clearComments,
	clearComment,
};
