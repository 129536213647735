import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import MinistryForm from '../Form';
import { fetchMinistry, updateMinistry } from '@resources/Ministry/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = ({
	fetchMinistry,
	ministry,
	updateMinistry,
	visible,
	onCancel,
}) => {
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchMinistry(visible);
		};
		visible && fetchData();
	}, [fetchMinistry, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateMinistry(ministry._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.ministry.update',
							defaultMessage:
								'An existing Ministry entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[ministry]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.ministry',
					defaultMessage: 'Update Ministry',
				})}
			</Title>
			{!isEmpty(ministry) && (
				<MinistryForm data={ministry} onSubmit={handleSubmit} />
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		ministry: state.ministry,
	};
};

export default connect(mapStateToProps, {
	fetchMinistry,
	updateMinistry,
})(Update);
