import { API_ROOT } from '@config/api';
import {
	SET_ACTIVITIES,
	SET_ACTIVITY,
	ADD_ACTIVITY,
	UPDATE_ACTIVITY,
	CLEAR_ACTIVITY,
	CLEAR_ACTIVITIES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchActivities = () => (dispatch) =>
	request('get', `${API_ROOT}/activities`)
		.then((response) => {
			dispatch({
				type: SET_ACTIVITIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchActivitiesByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/activities/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_ACTIVITIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchActivity = (id) => (dispatch) =>
	request('get', `${API_ROOT}/activities/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ACTIVITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createActivity = (data) => (dispatch) =>
	request('post', `${API_ROOT}/activities`, data)
		.then((response) => {
			dispatch({
				type: ADD_ACTIVITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateActivity = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/activities/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ACTIVITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearActivity = () => (dispatch) =>
	dispatch({
		type: CLEAR_ACTIVITY,
	});

const clearActivities = () => (dispatch) =>
	dispatch({
		type: CLEAR_ACTIVITIES,
	});

export {
	fetchActivities,
	fetchActivitiesByOrganizationModule,
	fetchActivity,
	createActivity,
	updateActivity,
	clearActivity,
	clearActivities,
};
