import React from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import CellGroupForm from '../Form';
import { createCellGroup } from '@resources/CellGroup/actions';
import { useIntl } from 'react-intl';

const Create = (props) => {
	const { createCellGroup, visible, onCancel } = props;
	const intl = useIntl();
	const handleSubmit = (data) => {
		return createCellGroup(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.cellGroup.create',
						defaultMessage:
							'A new Cell Group has been successfully created',
					})
				);

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.cellGroup',
					defaultMessage: 'Create Cell Group',
				})}
			</Title>
			<CellGroupForm onSubmit={handleSubmit} {...props} />
		</Modal>
	);
};

const mapStateToProps = ({ organization }) => {
	return {
		organization,
	};
};

export default connect(mapStateToProps, { createCellGroup })(Create);
