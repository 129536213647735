import React, { useCallback, useState } from 'react';
import {
	Form,
	Input,
	InputNumber,
	Button,
	Space,
	Select,
	DatePicker,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formErrorHandler } from '@common/utils/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
	EntitySelectForm,
	MemberSelectForm,
	OrganizationSelectForm,
	UserSelectForm,
	TransactionCategorySelectForm,
} from '@fragments';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

const ExpenseForm = ({ data, onSubmit, onCancel, organization }) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			onSubmit(values)
				.then(() => {
					form.resetFields();
					setLoading(false);
				})
				.catch((err) => {
					form.resetFields();
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={
				data && {
					...data,
					date: moment(data.date),
					beneficiary: data.to.organization
						? 'organization'
						: data.to.member
						? 'member'
						: data.to.user
						? 'user'
						: data.to.entity
						? 'entity'
						: null,
				}
			}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker
					placeholder={intl.formatMessage({
						id: 'common.date',
						defaultMessage: 'Date',
					})}
				/>
			</FormItem>

			<TransactionCategorySelectForm />

			<FormItem
				label={`Beneficiary`}
				name="beneficiary"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.beneficiary.required',
							defaultMessage: 'Please input beneficiary',
						}),
					},
				]}>
				<Select
					placeholder="Please select beneficiary"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="organization">Organization</Option>
					<Option value="member">Member</Option>
					<Option value="user">User</Option>
					<Option value="entity">Entity</Option>
				</Select>
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.beneficiary !== currentValues.beneficiary
				}>
				{({ getFieldValue }) => {
					return (
						<Form.List name="to" wrapperCol={{ span: 24 }}>
							{() =>
								getFieldValue('beneficiary') ===
								'organization' ? (
									<OrganizationSelectForm />
								) : getFieldValue('beneficiary') ===
								  'member' ? (
									<MemberSelectForm />
								) : getFieldValue('beneficiary') === 'user' ? (
									<UserSelectForm />
								) : getFieldValue('beneficiary') ===
								  'entity' ? (
									<EntitySelectForm />
								) : null
							}
						</Form.List>
					);
				}}
			</Form.Item>

			<Form.List name="items" wrapperCol={{ span: 24 }}>
				{(fields, { add, remove }) => (
					<>
						{fields.map((field) => (
							<Space key={field.key} align="baseline">
								<FormItem
									{...field}
									label={`Item`}
									name={[field.name, 'name']}
									fieldKey={[field.fieldKey, 'name']}
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id:
													'form.validation.item.required',
												defaultMessage:
													'Please input item',
											}),
										},
									]}>
									<Input
										placeholder={intl.formatMessage({
											id: 'common.title',
											defaultMessage: 'Title',
										})}
									/>
								</FormItem>
								<FormItem
									{...field}
									label={intl.formatMessage({
										id: 'common.qty',
										defaultMessage: 'Qty',
									})}
									name={[field.name, 'quantity']}
									fieldKey={[field.fieldKey, 'quantity']}
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id:
													'form.validation.quantity.required',
												defaultMessage:
													'Please input quantity',
											}),
										},
									]}>
									<InputNumber
										min={0}
										placeholder={intl.formatMessage({
											id: 'common.qty',
											defaultMessage: 'Qty',
										})}
									/>
								</FormItem>
								<FormItem
									{...field}
									label={intl.formatMessage({
										id: 'common.price',
										defaultMessage: 'Price',
									})}
									name={[field.name, 'price']}
									fieldKey={[field.fieldKey, 'price']}
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id:
													'form.validation.price.required',
												defaultMessage:
													'Please input price',
											}),
										},
									]}>
									<InputNumber
										min={0}
										placeholder={intl.formatMessage({
											id: 'common.price',
											defaultMessage: 'Price',
										})}
									/>
								</FormItem>

								<MinusCircleOutlined
									onClick={() => remove(field.name)}
								/>
							</Space>
						))}

						<Form.Item wrapperCol={{ span: 24 }}>
							<Button
								style={{ width: '100%' }}
								type="dashed"
								onClick={() => add()}
								icon={<PlusOutlined />}>
								Add transactional item
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>

			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, {})(ExpenseForm);
