import { API_ROOT } from '@config/api';
import {
	SET_PROGRAMMES,
	SET_PROGRAMME,
	ADD_PROGRAMME,
	UPDATE_PROGRAMME,
	CLEAR_PROGRAMME,
	CLEAR_PROGRAMMES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchProgrammes = () => (dispatch) =>
	request('get', `${API_ROOT}/programmes`)
		.then((response) => {
			dispatch({
				type: SET_PROGRAMMES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchProgrammesByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/programmes/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_PROGRAMMES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchProgramme = (id) => (dispatch) =>
	request('get', `${API_ROOT}/programmes/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PROGRAMME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createProgramme = (data) => (dispatch) =>
	request('post', `${API_ROOT}/programmes`, data)
		.then((response) => {
			dispatch({
				type: ADD_PROGRAMME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateProgramme = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/programmes/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PROGRAMME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearProgramme = () => (dispatch) =>
	dispatch({
		type: CLEAR_PROGRAMME,
	});

const clearProgrammes = () => (dispatch) =>
	dispatch({
		type: CLEAR_PROGRAMMES,
	});

export {
	fetchProgrammes,
	fetchProgrammesByOrganizationModule,
	fetchProgramme,
	createProgramme,
	updateProgramme,
	clearProgramme,
	clearProgrammes,
};
