import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchSuppliers,
	fetchSuppliersByOrganization,
	clearSuppliers,
} from '@resources/Supplier/actions';
import isEmpty from 'lodash.isempty';
import CreateSupplierModal from '@containers/Supplier/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const SupplierSelectForm = (props) => {
	const {
		suppliers,
		organization,
		fetchSuppliers,
		fetchSuppliersByOrganization,
		clearSuppliers,
		formItemProps,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			await clearSuppliers();
			organization?._id
				? await fetchSuppliersByOrganization(organization?._id)
				: await fetchSuppliers();
		};
		fetchData();
	}, [organization]);

	const renderSupplierOptions = useCallback(() => {
		if (!isEmpty(suppliers)) {
			return suppliers.map((supplier, index) => {
				return (
					<Option
						value={supplier._id}
						key={`supplier-${index}`}
						title={supplier?.name}>
						{supplier?.name}
					</Option>
				);
			});
		}
	}, [suppliers]);

	return (
		<>
			<CreateSupplierModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<FormItem
				label={`Supplier`}
				required
				style={{ marginBottom: 0 }}
				{...formItemProps}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'supplier'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.supplier.required',
										defaultMessage: 'Please input supplier',
									}),
								},
							]}>
							<Select
								style={{ width: '100%' }}
								placeholder="Please select supplier"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderSupplierOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreate(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, suppliers }) => {
	return {
		organization,
		suppliers,
	};
};

export default connect(mapStateToProps, {
	fetchSuppliers,
	fetchSuppliersByOrganization,
	clearSuppliers,
})(SupplierSelectForm);
