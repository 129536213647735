import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchCustomers,
	fetchCustomersByOrganization,
	clearCustomers,
} from '@resources/Customer/actions';
import isEmpty from 'lodash.isempty';
import CreateCustomerModal from '@containers/Customer/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const CustomerSelectForm = (props) => {
	const {
		customers,
		organization,
		fetchCustomers,
		fetchCustomersByOrganization,
		clearCustomers,
		formItemProps,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			await clearCustomers();
			organization?._id
				? await fetchCustomersByOrganization(organization?._id)
				: await fetchCustomers();
		};
		fetchData();
	}, [organization]);

	const renderCustomerOptions = useCallback(() => {
		if (!isEmpty(customers)) {
			return customers.map((customer, index) => {
				return (
					<Option
						value={customer._id}
						key={`customer-${index}`}
						title={`${customer?.user?.firstName} ${customer?.user?.lastName}`}>
						{customer?.user?.firstName} {customer?.user?.lastName}
					</Option>
				);
			});
		}
	}, [customers]);

	return (
		<>
			<CreateCustomerModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<FormItem label={`Customer`} {...formItemProps}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'customer'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.customer.required',
										defaultMessage: 'Please input customer',
									}),
								},
							]}>
							<Select
								style={{ width: '100%' }}
								placeholder="Please select customer"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderCustomerOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreate(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, customers }) => {
	return {
		organization,
		customers,
	};
};

export default connect(mapStateToProps, {
	fetchCustomers,
	fetchCustomersByOrganization,
	clearCustomers,
})(CustomerSelectForm);
