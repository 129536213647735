import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ParticipantForm from '../Form';
import {
	fetchParticipant,
	updateParticipant,
} from '@resources/Participant/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		module,
		title,
		fetchParticipant,
		participant,
		updateParticipant,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchParticipant(visible);
		};
		visible && fetchData();
	}, [fetchParticipant, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateParticipant(participant._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.participant.update',
							defaultMessage:
								'An existing Participant entry has been successfully updated',
						})
					);
					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[participant]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{title ||
					intl.formatMessage({
						id: 'common.update.participant',
						defaultMessage: 'Update Participant',
					})}
			</Title>
			{!isEmpty(participant) && (
				<ParticipantForm
					data={participant}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	participant,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		participant,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchParticipant,
	updateParticipant,
	fetchModulesBySubmoduleCode,
})(Update);
