import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchClass, clearClass } from '@resources/Class/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, Title, View } from '@common/components';
import { Menu, Typography } from 'antd';

const ClassInfo = ({
	classy,
	submodules,
	fetchClass,
	fetchSubmodulesByModuleCode,
	clearClass,
	history,
	match,
	organization,
}) => {
	const { classId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchClass(classId);
			await fetchSubmodulesByModuleCode('class');
		};

		fetchData();
	}, [fetchClass, fetchSubmodulesByModuleCode, clearClass]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/classes/${classId}/details`
					: `/classes/${classId}/details#`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/classes/${classId}/details/${e.key}`
					: `/classes/${classId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<View>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{classy.title}
					</Title>
					<Typography.Title level={2}>Class</Typography.Title>
				</Box>
				{organization.access.includes('class') && (
					<Menu
						onClick={handleClick}
						mode="horizontal"
						selectedKeys={[current]}>
						<Menu.Item key="details">Details</Menu.Item>
						{submodules &&
							submodules.map((submodule) => (
								<Menu.Item key={submodule.url}>
									{submodule.title}
								</Menu.Item>
							))}
					</Menu>
				)}
			</View>
		</Container>
	);
};

const mapStateToProps = ({ classy, submodules, organization }) => {
	return {
		classy,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchClass,
	fetchSubmodulesByModuleCode,
	clearClass,
})(ClassInfo);
