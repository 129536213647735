import {
  SET_PARTICIPANTS,
  SET_PARTICIPANT,
  CLEAR_PARTICIPANTS,
  CLEAR_PARTICIPANT,
  ADD_PARTICIPANT,
  UPDATE_PARTICIPANT,
  REMOVE_PARTICIPANT,
} from './actionTypes';

export const participant = (state = {}, action) => {
  switch (action.type) {
    case SET_PARTICIPANT:
      return action.payload;
    case UPDATE_PARTICIPANT:
      if (state._id.toString() !== action.payload._id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_PARTICIPANT:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_PARTICIPANTS:
      return action.payload;
    case ADD_PARTICIPANT:
      return [...state, action.payload];
    case UPDATE_PARTICIPANT:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_PARTICIPANT:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_PARTICIPANTS:
      return [];
    default:
      return state;
  }
};
