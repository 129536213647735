import { API_ROOT } from '@config/api';
import {
	SET_BOOKINGS,
	SET_BOOKING,
	ADD_BOOKING,
	UPDATE_BOOKING,
	CLEAR_BOOKING,
	CLEAR_BOOKINGS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchBookings = () => (dispatch) =>
	request('get', `${API_ROOT}/bookings`)
		.then((response) => {
			dispatch({
				type: SET_BOOKINGS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchBookingsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/bookings/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_BOOKINGS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchBooking = (id) => (dispatch) =>
	request('get', `${API_ROOT}/bookings/${id}`)
		.then((response) => {
			dispatch({
				type: SET_BOOKING,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createBooking = (data) => (dispatch) =>
	request('post', `${API_ROOT}/bookings`, data)
		.then((response) => {
			dispatch({
				type: ADD_BOOKING,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateBooking = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/bookings/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_BOOKING,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearBooking = () => (dispatch) =>
	dispatch({
		type: CLEAR_BOOKING,
	});

const clearBookings = () => (dispatch) =>
	dispatch({
		type: CLEAR_BOOKINGS,
	});

export {
	fetchBookings,
	fetchBookingsByOrganizationModule,
	fetchBooking,
	createBooking,
	updateBooking,
	clearBooking,
	clearBookings,
};
