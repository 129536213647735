import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { CrewTableItem } from '@fragments/items';

const Team = ({ team, crews, onEdit, onAddTeamMember, onDelete }) => {
	return (
		<Box className="p-5">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{team.title}
						</Typography.Title>
					</Col>

					<Box textAlign="right">
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(team)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(team)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Box>
				</Row>
				{team?.positions.map((position) => {
					const filtered = crews.filter(
						(crew) =>
							crew.team === team._id &&
							crew.position === position.title
					);
					return (
						<Box>
							<Typography.Title level={5}>
								{position.title} ({position.quantity})
							</Typography.Title>
							{filtered.map((crew) => (
								<CrewTableItem crew={crew} />
							))}
							{filtered.filter((fil) => fil.status !== 'rejected')
								.length < position.quantity && (
								<Button
									type="ghost"
									style={{ width: '100%' }}
									onClick={() =>
										onAddTeamMember &&
										onAddTeamMember({
											position: position.title,
											team: team._id,
										})
									}>
									Add {position.title}
								</Button>
							)}
						</Box>
					);
				})}
			</Section>
		</Box>
	);
};

export default Team;
