import React, { useCallback, useState, useEffect } from 'react';
import {
	DatePicker,
	Form,
	Input,
	Button,
	Checkbox,
	Switch,
	TimePicker,
	Space,
	Select,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchParticipantsByOrganizationModule } from '@resources/Participant/actions';
import { formErrorHandler } from '@common/utils/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import isEmpty from 'lodash.isempty';

const FormItem = Form.Item;
const Option = Select.Option;

const ActivityForm = (props) => {
	const {
		module,
		participants,
		data,
		onSubmit,
		fetchParticipantsByOrganizationModule,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => {
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchParticipantsByOrganizationModule(
				organization?._id,
				module.code,
				match?.params[`moduleId`]
			);
		};
		organization && module && match && fetchData();
	}, [fetchParticipantsByOrganizationModule, organization, module]);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	const renderParticipantOptions = useCallback(() => {
		if (!isEmpty(participants)) {
			return participants.map((participant, index) => {
				return (
					<Option
						value={participant._id}
						key={`participant-${index}`}
						title={`${participant?.user?.firstName}`}>
						{participant?.user?.firstName}
					</Option>
				);
			});
		}
	}, [participants]);

	return (
		<Form
			form={form}
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				date: data?.date && moment(data?.date),
				time: data?.time?.map((time) => moment(time)),
				roles: data?.roles?.map((role) => {
					return {
						participant: role?.participant?._id,
						role: role?.role,
					};
				}),
			}}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker disabledDate={disabledDate} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.all.day',
					defaultMessage: 'All Day',
				})}
				name="allDay"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.allDay !== currentValues.allDay
				}>
				{({ getFieldValue }) => {
					return !getFieldValue('allDay') ? (
						<FormItem
							label={intl.formatMessage({
								id: 'common.time',
								defaultMessage: 'Time',
							})}
							name="time"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.time.required',
										defaultMessage: 'Please input time',
									}),
								},
							]}>
							<TimePicker.RangePicker
								disabledDate={disabledDate}
							/>
						</FormItem>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.location',
					defaultMessage: 'Location',
				})}
				name="location"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.location.required',
							defaultMessage: 'Please input location',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.location',
						defaultMessage: 'Location',
					})}
				/>
			</FormItem>

			<Form.List name="roles" wrapperCol={{ span: 24 }}>
				{(fields, { add, remove }) => (
					<>
						{fields.map((field) => (
							<Space key={field.key} align="baseline">
								<FormItem
									{...field}
									label={`Participant`}
									name={[field.name, 'participant']}
									fieldKey={[field.fieldKey, 'participant']}
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id:
													'form.validation.participant.required',
												defaultMessage:
													'Please input participant',
											}),
										},
									]}>
									<Select
										style={{ width: 180 }}
										placeholder="Please select participant"
										optionFilterProp="title"
										filterOption={(input, option) =>
											option.props.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >=
											0
										}>
										{renderParticipantOptions()}
									</Select>
								</FormItem>
								<FormItem
									{...field}
									label={intl.formatMessage({
										id: 'common.role',
										defaultMessage: 'Role',
									})}
									name={[field.name, 'role']}
									fieldKey={[field.fieldKey, 'role']}
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id:
													'form.validation.role.required',
												defaultMessage:
													'Please input role',
											}),
										},
									]}>
									<Input
										placeholder={intl.formatMessage({
											id: 'common.role',
											defaultMessage: 'Role',
										})}
									/>
								</FormItem>

								<MinusCircleOutlined
									onClick={() => remove(field.name)}
								/>
							</Space>
						))}

						<Form.Item wrapperCol={{ span: 24 }}>
							<Button
								style={{ width: '100%' }}
								type="dashed"
								onClick={() => add()}
								icon={<PlusOutlined />}>
								Add role item
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, participants }) => {
	return {
		auth,
		participants,
	};
};

export default connect(mapStateToProps, {
	fetchParticipantsByOrganizationModule,
})(ActivityForm);
