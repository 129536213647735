import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Box, Container, View, Section, Title } from '@common/components';
import {
	Col,
	Row,
	Typography,
	Button,
	Form,
	Affix,
	Layout,
	Select,
} from 'antd';
import FormFieldForm from './Form';
import FormFieldItem from './Item';
import { fetchForm } from '@resources/Form/actions';
import { fetchFieldTypes } from '@resources/FieldType/actions';
import { fetchFieldsByForm, doReorder } from '@resources/Field/actions';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import {
	SortableContainer,
	SortableElement,
	sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const Option = Select.Option;
const { Content } = Layout;

const FormField = ({
	match,
	forma,
	fields,
	fieldTypes,
	fetchForm,
	fetchFieldTypes,
	fetchFieldsByForm,
	doReorder,
}) => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);
	const [fieldType, setFieldType] = useState({});
	const [id, setId] = useState(null);
	const [sortableFields, setSortableFields] = useState(fields);

	const handleEdit = (id) => {
		setId(id);
		setVisible(true);
	};

	const handleDelete = () => {};

	const SortableItem = SortableElement(({ data }) => (
		<FormFieldItem
			data={data}
			onEdit={handleEdit}
			onDelete={handleDelete}
			sortableHandle={sortableHandle}
			isEditable={forma?.isCreator}
		/>
	));

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Section pb={50}>
				{items && items.length > 0
					? items.map((content, index) => (
							<SortableItem
								key={`item-${index}`}
								index={index}
								data={content}
							/>
					  ))
					: intl.formatMessage({
							id: 'common.data.empty',
							defaultMessage: 'Data empty',
					  })}
			</Section>
		);
	});

	useEffect(() => {
		const fetchData = async () => {
			await fetchForm(match.params.id);
			await fetchFieldsByForm(match.params.id);
			await fetchFieldTypes();
		};
		fetchData();
	}, [fetchForm]);

	useEffect(() => {
		setSortableFields(fields);
	}, [fields]);

	const renderFieldTypeOptions = useCallback(() => {
		if (fieldTypes) {
			return fieldTypes.map((fieldType, index) => {
				return (
					<Option
						value={fieldType.alias}
						key={`fieldType-${index}`}
						title={fieldType.title}>
						{fieldType.title}
					</Option>
				);
			});
		}
	}, [fieldTypes]);

	const onFinish = (data) => {
		setId(null);
		setFieldType(
			fieldTypes.filter((fieldType) => fieldType.alias === data.type)[0]
		);
		setVisible(true);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.page.content',
						defaultMessage: 'Form Content',
					})}
				</title>
			</Helmet>
			<Container>
				<View>
					<Box py={50}>
						<Title fontSize={100} lineHeight={1} mb={10} primary>
							{forma.title}
						</Title>
						<Typography.Title level={2}>Form</Typography.Title>
					</Box>
					<Form layout="vertical">
						<SortableList
							useDragHandle
							items={sortableFields}
							onSortEnd={({ oldIndex, newIndex }) => {
								const newOrder = arrayMove(
									sortableFields,
									oldIndex,
									newIndex
								);
								doReorder(
									newOrder.map((order, index) => {
										return { _id: order._id, order: index };
									})
								);
								setSortableFields(newOrder);
							}}
						/>
					</Form>

					{forma?.isCreator && (
						<Affix offsetBottom={20}>
							<Row align="middle">
								<Col>
									<Typography.Title
										level={3}
										style={{ margin: 0 }}>
										New Field
									</Typography.Title>
								</Col>
								<Col>
									<Section>
										<Form
											layout="inline"
											onFinish={onFinish}>
											<Form.Item
												name="type"
												label="Type"
												rules={[{ required: true }]}>
												<Select
													placeholder={intl.formatMessage(
														{
															id:
																'form.placeholder.type',
															defaultMessage:
																'Please select a type',
														}
													)}
													style={{ width: '300px' }}
													optionFilterProp="title"
													filterOption={(
														input,
														option
													) =>
														option.props.children
															.toLowerCase()
															.indexOf(
																input.toLowerCase()
															) >= 0
													}>
													{renderFieldTypeOptions()}
												</Select>
											</Form.Item>
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit">
													{intl.formatMessage({
														id:
															'common.new.content',
														defaultMessage:
															'New Field',
													})}
												</Button>
											</Form.Item>
										</Form>
									</Section>
								</Col>
							</Row>
						</Affix>
					)}
					<FormFieldForm
						forma={forma}
						visible={visible}
						type={fieldType}
						id={id}
						onCancel={() => setVisible(false)}
					/>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ forma, fieldTypes, fields }) => {
	return {
		forma,
		fieldTypes,
		fields,
	};
};

export default connect(mapStateToProps, {
	fetchForm,
	fetchFieldTypes,
	fetchFieldsByForm,
	doReorder,
})(FormField);
