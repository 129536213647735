import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { authorizeStripe } from '../../../resources/Stripe/actions';
import { fetchOrganization } from '../../../resources/Organization/actions';
import { Box, Container, Title, View } from '../../../components';
import { Button, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

const { Content } = Layout;

const Authorize = ({ authorizeStripe, fetchOrganization, organization }) => {
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			if (organization?._id) {
				await fetchOrganization(organization?._id);
			}
		};

		fetchData();
	}, []);

	const handleAuthorize = useCallback(() => {
		const authorize = async () => {
			const authorization = await authorizeStripe(
				'organization',
				organization?._id
			);
			window.location.href = authorization.data.data;
		};

		authorize();
	}, []);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.cart',
						defaultMessage: 'Authorize',
					})}
				</title>
			</Helmet>

			<View>
				<Container>
					<Title>
						<FormattedMessage
							id="common.connect"
							defaultMessage="Connect"
						/>
					</Title>

					<Box>
						<Button onClick={handleAuthorize}>
							Authorize Stripe
						</Button>
					</Box>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({ organization }) => {
	return {
		organization,
	};
};

export default connect(mapStateToProps, { authorizeStripe, fetchOrganization })(
	Authorize
);
