import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchMembersByOrganization } from '@resources/Member/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const CrewForm = (props) => {
	const {
		crews,
		module,
		members,
		data,
		onSubmit,
		fetchMembersByOrganization,
		match,
		onCancel,
		organization,
		visible,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			if (!data && visible?.team) {
				values['team'] = visible?.team;
				values['position'] = visible?.position;
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMembersByOrganization(organization?._id);
		};
		fetchData();
	}, [organization]);

	const renderMemberOptions = useCallback(() => {
		if (!isEmpty(members)) {
			const cr = crews.map((crew) => crew.member._id);
			return members
				.filter((member) =>
					data
						? cr.indexOf(member._id) === -1 ||
						  data.member._id === member._id
						: cr.indexOf(member._id) === -1
				)
				.map((member, index) => {
					return (
						<Option
							value={member.user._id}
							key={`member-${index}`}
							title={`${member.user.firstName} ${member.user.lastName}`}>
							{member.user.firstName} {member.user.lastName}
						</Option>
					);
				});
		}
	}, [members, crews]);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, member: data?.member?._id }}>
			<FormItem
				label={`Member`}
				name="user"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.member.required',
							defaultMessage: 'Please input member',
						}),
					},
				]}>
				<Select
					placeholder="Please select member"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderMemberOptions()}
				</Select>
			</FormItem>

			<FormItem
				label={`Status`}
				name="status"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.status.required',
							defaultMessage: 'Please input status',
						}),
					},
				]}>
				<Select
					placeholder="Please select status"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="invited">Invited</Option>
					<Option value="accepted">Accepted</Option>
					<Option value="declined">Declined</Option>
				</Select>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, members, organization, crews }) => {
	return {
		auth,
		members,
		organization,
		crews,
	};
};

export default connect(mapStateToProps, { fetchMembersByOrganization })(
	CrewForm
);
