import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Mission = ({ mission, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col flex={1} onClick={() => onClick && onClick(mission)}>
						<Typography.Title level={3}>
							{mission.title}
						</Typography.Title>
						<Typography.Text level={3}>
							{mission.description}
						</Typography.Text>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(mission)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(mission)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Mission;
