import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchProgrammes,
	fetchProgrammesByOrganizationModule,
	clearProgrammes,
} from '@resources/Programme/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { ProgrammeItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Programme = (props) => {
	const {
		programmes,
		fetchProgrammes,
		fetchProgrammesByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearProgrammes,
		match,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('programme');
			await clearProgrammes();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchProgrammesByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchProgrammes();
		};

		func();
	}, [
		fetchProgrammes,
		fetchProgrammesByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearProgrammes,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return programmes.map((programme) => {
			return {
				key: programme._id,
				...programme,
			};
		});
	}, [programmes]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.programme.management',
						defaultMessage: 'Programme Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.programmes"
									defaultMessage="Programmes"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.programme"
									defaultMessage="New Programme"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((programme, index) => (
							<ProgrammeItem
								key={`programme-${index}`}
								programme={programme}
								onEdit={() => setUpdate(programme._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	programmes,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		programmes,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchProgrammes,
	fetchProgrammesByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearProgrammes,
})(Programme);
