import { API_ROOT } from '@config/api';
import {
	SET_FACILITIES,
	SET_FACILITY,
	ADD_FACILITY,
	UPDATE_FACILITY,
	CLEAR_FACILITIES,
	CLEAR_FACILITY,
} from './actionTypes';
import request from '@common/utils/request';

const fetchFacilities = () => (dispatch) =>
	request('get', `${API_ROOT}/facilities`)
		.then((response) => {
			dispatch({
				type: SET_FACILITIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchFacilitiesByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/facilities/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FACILITIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchFacility = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/facilities/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FACILITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createFacility = (data) => (dispatch) =>
	request('post', `${API_ROOT}/facilities`, data)
		.then((response) => {
			dispatch({
				type: ADD_FACILITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateFacility = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/facilities/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_FACILITY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearFacilities = () => (dispatch) =>
	dispatch({
		type: CLEAR_FACILITIES,
	});

const clearFacility = () => (dispatch) =>
	dispatch({
		type: CLEAR_FACILITY,
	});

export {
	fetchFacilities,
	fetchFacilitiesByOrganization,
	fetchFacility,
	createFacility,
	updateFacility,
	clearFacilities,
	clearFacility,
};
