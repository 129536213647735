import {
	SET_APPS,
	SET_APP,
	CLEAR_APPS,
	CLEAR_APP,
	ADD_APP,
	UPDATE_APP,
	REMOVE_APP,
} from './actionTypes';

export const app = (state = {}, action) => {
	switch (action.type) {
		case SET_APP:
			return action.payload;
		case UPDATE_APP:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_APP:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_APPS:
			return action.payload;
		case ADD_APP:
			return [...state, action.payload];
		case UPDATE_APP:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_APP:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_APPS:
			return [];
		default:
			return state;
	}
};
