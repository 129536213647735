import React from 'react';
import { Button, Box, Section } from '@common/components';
import { Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled, ShoppingFilled } from '@ant-design/icons';

const Item = ({ item, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3">
			<Section className="mb-3" hoverable shadowed>
				<Row
					type="flex"
					className="login-links"
					onClick={() => onClick && onClick(item)}>
					<Col>
						<Typography.Title level={3}>
							{item.title}
						</Typography.Title>
						<Typography.Text level={3}>
							{item.description}
						</Typography.Text>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button size="small" type="text">
							<ShoppingFilled style={{ color: '#00FF00' }} />
						</Button>
					)}
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(item)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(item)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Item;
