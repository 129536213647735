import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;

const DepartmentForm = ({ data, onSubmit, onCancel, organization }) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.desription',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, {})(DepartmentForm);
