import { API_ROOT } from '@config/api';
import {
	SET_ITEMS,
	SET_ITEM,
	ADD_ITEM,
	UPDATE_ITEM,
	CLEAR_ITEM,
	CLEAR_ITEMS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchItems = () => (dispatch) =>
	request('get', `${API_ROOT}/items`)
		.then((response) => {
			dispatch({
				type: SET_ITEMS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchItemsByOrganization = (organization) => (dispatch) =>
	request('get', `${API_ROOT}/items/organization/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_ITEMS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchItem = (id) => (dispatch) =>
	request('get', `${API_ROOT}/items/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ITEM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createItem = (data) => (dispatch) =>
	request('post', `${API_ROOT}/items`, data)
		.then((response) => {
			dispatch({
				type: ADD_ITEM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateItem = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/items/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ITEM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearItem = () => (dispatch) =>
	dispatch({
		type: CLEAR_ITEM,
	});

const clearItems = () => (dispatch) =>
	dispatch({
		type: CLEAR_ITEMS,
	});

export {
	fetchItems,
	fetchItemsByOrganization,
	fetchItem,
	createItem,
	updateItem,
	clearItem,
	clearItems,
};
