import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
	fetchServicesPublic,
	fetchServicesByOrganization,
} from '@resources/Service/actions';
import { FormattedMessage } from 'react-intl';
import { Container, Title, View } from '@common/components';
import { Button, Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ServiceItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Service = (props) => {
	const {
		services,
		organization,
		history,
		fetchServicesByOrganization,
	} = props;
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			organization?._id
				? await fetchServicesByOrganization(organization?._id)
				: await fetchServicesPublic();
		};

		fetchData();
	}, [organization]);

	const settingsOrganizations = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	const upcoming = useCallback(() => {
		return services.filter((service) => service.isUpcoming);
	}, [services]);

	const passed = useCallback(() => {
		return services.filter((service) => !service.isUpcoming);
	}, [services]);

	const handleClick = (service) => {
		history.push(
			`/${service.organization.alias}/services/${service._id}/details`
		);
	};

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Services</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.services"
									defaultMessage="Services"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.service"
									defaultMessage="New Service"
								/>
							</Button>
						</Col>
					</Row>
					{upcoming() && (
						<Slider {...settingsOrganizations}>
							{upcoming() &&
								upcoming().map((service) => (
									<ServiceItem
										service={service}
										onClick={handleClick}
									/>
								))}
						</Slider>
					)}

					<Row type="flex" gutter={16}>
						{passed() &&
							passed().map((service, index) => (
								<Col
									xs={24}
									sm={12}
									md={8}
									lg={6}
									key={`empty-post-${index}`}
									className="mt-4">
									<ServiceItem
										service={service}
										onClick={handleClick}
									/>
								</Col>
							))}
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ site, services, organization }) => {
	return { site, services, organization };
};

export default connect(mapStateToProps, {
	fetchServicesByOrganization,
})(Service);
