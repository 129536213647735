import React from 'react';
import { Box, Section } from '@common/components';
import { Row, Col, Typography } from 'antd';

const Class = ({ classy, onClick, onEdit, onDelete }) => {
  return (
    <Box className="p-5">
      <Section className="mb-3" hoverable shadowed>
        <Row
          type="flex"
          className="login-links"
          onClick={() => onClick && onClick(classy)}
        >
          <Col>
            <Typography.Title level={3}>{classy.title}</Typography.Title>
            <Typography.Text level={3}>{classy.description}</Typography.Text>
          </Col>
        </Row>
      </Section>
    </Box>
  );
};

export default Class;
