import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Space = ({ space, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick(space)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links" align="middle">
					<Col flex={1}>
						<Typography.Title level={4}>
							{space?.name}
						</Typography.Title>
						<Typography.Text level={3}>
							{space?.description}
						</Typography.Text>
					</Col>
					<Col style={{ width: 30 }}>
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(space)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(space)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Space;
