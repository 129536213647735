import { API_ROOT } from '@config/api';
import {
	SET_PARTICIPANTS,
	SET_PARTICIPANT,
	ADD_PARTICIPANT,
	UPDATE_PARTICIPANT,
	CLEAR_PARTICIPANT,
	CLEAR_PARTICIPANTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchParticipants = () => (dispatch) =>
	request('get', `${API_ROOT}/participants`)
		.then((response) => {
			dispatch({
				type: SET_PARTICIPANTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchParticipantsMine = (module, id) => (dispatch) =>
	request('get', `${API_ROOT}/participants/module/${module}/${id}/mine`)
		.then((response) => {
			dispatch({
				type: SET_PARTICIPANTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchParticipantsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/participants/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_PARTICIPANTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchParticipant = (id) => (dispatch) =>
	request('get', `${API_ROOT}/participants/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PARTICIPANT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createParticipant = (data) => (dispatch) =>
	request('post', `${API_ROOT}/participants`, data)
		.then((response) => {
			dispatch({
				type: ADD_PARTICIPANT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateParticipant = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/participants/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PARTICIPANT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const searchParticipantsUsers = (term, module, moduleId) => (dispatch) =>
	request('post', `${API_ROOT}/participants/users/search`, {
		term,
		module,
		moduleId,
	})
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const clearParticipant = () => (dispatch) =>
	dispatch({
		type: CLEAR_PARTICIPANT,
	});

const clearParticipants = () => (dispatch) =>
	dispatch({
		type: CLEAR_PARTICIPANTS,
	});

export {
	fetchParticipants,
	fetchParticipantsByOrganizationModule,
	fetchParticipant,
	createParticipant,
	updateParticipant,
	searchParticipantsUsers,
	fetchParticipantsMine,
	clearParticipant,
	clearParticipants,
};
