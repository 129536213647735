import React, { useCallback, useState, useEffect } from 'react';
import { Form, Button, Checkbox, Select, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
	fetchUsersMembers,
	fetchUser,
	searchUsersMembers,
	clearUsers,
} from '@resources/User/actions';
import { fetchApps } from '@resources/App/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';
import { Box } from '@common/components';

const FormItem = Form.Item;
const Option = Select.Option;

const AdminForm = ({
	data,
	apps,
	users,
	onSubmit,
	fetchApps,
	fetchUsersMembers,
	searchUsersMembers,
	clearUsers,
	organization,
	onCancel,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const orgApps = organization?.apps?.map((app) => app._id);

	useEffect(() => {
		const fetchData = async () => {
			await fetchApps();
		};

		fetchData();
	}, []);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	const renderUserOptions = useCallback(() => {
		if (!isEmpty(users)) {
			return users.map((user, index) => {
				return (
					<Option
						value={user._id}
						key={`user-${index}`}
						title={`${user.firstName} ${user.lastName}`}>
						{user.firstName} {user.lastName}
					</Option>
				);
			});
		} else {
			return (
				data &&
				data.user && (
					<Option
						value={data.user._id}
						key={`user-0`}
						title={`${data.user.firstName} ${data.user.lastName}`}>
						{data.user.firstName} {data.user.lastName}
					</Option>
				)
			);
		}
	}, [users]);

	const handleSearch = (value) => {
		const search = async () => {
			await searchUsersMembers(value);
		};

		if (value) search();
		else clearUsers();
	};

	const handleChange = (value) => {
		const search = async () => {
			await fetchUsersMembers();
		};

		if (value) search();
		else clearUsers();
	};

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				user: data?.user?._id,
				modules: data?.modules?.map((module) => module._id),
			}}>
			<FormItem
				label={`User`}
				name="user"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.user.required',
							defaultMessage: 'Please input user',
						}),
					},
				]}>
				<Select
					showSearch
					placeholder="Please select user"
					style={{ width: '100%' }}
					optionFilterProp="title"
					onSearch={handleSearch}
					onChange={handleChange}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={false}
					notFoundContent={null}>
					{renderUserOptions()}
				</Select>
			</FormItem>

			<Form.Item name="modules" label="Access">
				<Checkbox.Group>
					{apps
						?.filter((app) => orgApps.indexOf(app._id) > -1)
						.map((app) => (
							<Box>
								<strong>{app.title}</strong>
								<Row>
									{app?.modules?.map((module) => (
										<Col md={6}>
											<Checkbox
												value={module._id}
												style={{ lineHeight: '32px' }}>
												{module.title}
											</Checkbox>
										</Col>
									))}
								</Row>
							</Box>
						))}
				</Checkbox.Group>
			</Form.Item>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, users, apps, organization }) => {
	return {
		auth,
		users,
		apps,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchApps,
	fetchUsersMembers,
	searchUsersMembers,
	fetchUser,
	clearUsers,
})(AdminForm);
