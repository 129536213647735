import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchAccounts,
	fetchAccountsByOrganization,
	clearAccounts,
} from '@resources/Account/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Account = (props) => {
	const {
		organization,
		accounts,
		fetchAccounts,
		fetchAccountsByOrganization,
		clearAccounts,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearAccounts();

			organization?._id
				? await fetchAccountsByOrganization(organization?._id)
				: await fetchAccounts();
		};

		func();
	}, [
		fetchAccounts,
		fetchAccountsByOrganization,
		clearAccounts,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return accounts.map((account) => {
			return {
				key: account._id,
				...account,
			};
		});
	}, [accounts]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.account.management',
						defaultMessage: 'Account Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.accounts"
									defaultMessage="Accounts"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.account"
									defaultMessage="New Account"
								/>
							</Button>
						</Col>
					</Row>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5"
						gutter={24}>
						{parseDataSource() &&
							parseDataSource().map((account, index) => (
								<Col md={{ span: 12 }}>
									<AccountItem
										key={`account-${index}`}
										account={account}
										onEdit={() => setUpdate(account._id)}
									/>
								</Col>
							))}
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	accounts,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		accounts,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchAccounts,
	fetchAccountsByOrganization,
	clearAccounts,
})(Account);
