import React from 'react';
import { Box, Section, Image } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Project = ({ project, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-5" onClick={() => onClick && onClick(project)}>
			<Section className="mb-3" hoverable shadowed>
				<Image src={project?.thumbnail?.path} width="100%" mb={20} />
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{project.title}
						</Typography.Title>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(project)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(project)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Project;
