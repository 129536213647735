import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Select, Upload } from 'antd';
import { useIntl } from 'react-intl';
import { API_MEDIA } from '@common/config/api';
import { fetchAccesses } from '@resources/Access/actions';
import { fetchApps } from '@resources/App/actions';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { deleteMedia } from '@resources/Media/actions';
import { getCookie } from '@common/utils/cookie';
import ImgCrop from 'antd-img-crop';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const PageForm = ({
	auth,
	organization,
	onCancel,
	accesses,
	data,
	onSubmit,
	deleteMedia,
	fetchAccesses,
	fetchApps,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const authToken = getCookie('auth');
	const [loading, setLoading] = useState(false);
	const [banners, setBanners] = useState(
		data && data.banner
			? [
					{
						uid: '-1',
						name: data.banner.originalName,
						url: data.banner.path,
						response: { data: [data.banner] },
					},
			  ]
			: []
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchApps();
			await fetchAccesses();
		};
		fetchData();
	}, []);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			const convertedValues = { ...values };
			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}
			convertedValues['banner'] =
				banners &&
				banners.length > 0 &&
				banners[0].response &&
				banners[0].response.data &&
				banners[0].response.data.length > 0
					? banners[0].response.data[0]._id
					: undefined;

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, convertedValues, form);
				});
		},
		[onSubmit, banners, organization]
	);

	const handleFinishFailed = useCallback(() => {
		setLoading(false);
	}, []);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = ({ fileList: newFileList }) => {
		setBanners(newFileList);
	};

	const handleRemove = useCallback(
		(file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					setBanners(
						banners.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia, setBanners]
	);

	const renderAccessOptions = useCallback(() => {
		if (accesses) {
			return accesses.map((access, index) => {
				return (
					<Option
						value={access._id}
						key={`access-${index}`}
						title={access.title}>
						{access.title}
					</Option>
				);
			});
		}
	}, [accesses]);

	return (
		<Form
			name="role-form"
			layout="vertical"
			onFinish={handleFinish}
			onFinishFailed={handleFinishFailed}
			initialValues={
				data && {
					...data,
					apps: data.apps.map((app) => app._id),
					access: data.access && data.access._id,
				}
			}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.alias',
					defaultMessage: 'Alias',
				})}
				name="alias"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.alias.required',
							defaultMessage: 'Please input alias',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.alias',
						defaultMessage: 'Alias',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.access',
					defaultMessage: 'Access',
				})}
				name="access">
				<Select
					placeholder={intl.formatMessage({
						id: 'form.placeholder.access',
						defaultMessage: 'Please select an access',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderAccessOptions()}
				</Select>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.banner',
					defaultMessage: 'Banner',
				})}
				name="banner">
				<ImgCrop rotate aspect={3}>
					<Upload
						action={`${API_MEDIA}/image/page`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={banners}
						onPreview={handlePreview}
						onChange={handleChange}
						onRemove={handleRemove}>
						{banners.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ apps, auth, accesses, organization }) => {
	return {
		apps,
		auth,
		accesses,
		organization,
	};
};

export default connect(mapStateToProps, {
	deleteMedia,
	fetchAccesses,
	fetchApps,
})(PageForm);
