import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout, Calendar } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchBookings,
	fetchBookingsByOrganizationModule,
	clearBookings,
} from '@resources/Booking/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { BookingItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Booking = (props) => {
	const {
		bookings,
		fetchBookings,
		fetchBookingsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearBookings,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('booking');
			await clearBookings();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchBookingsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchBookings();
		};

		func();
	}, [
		fetchBookings,
		fetchModulesBySubmoduleCode,
		fetchBookingsByOrganizationModule,
		clearBookings,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return bookings.map((booking) => {
			return {
				key: booking._id,
				...booking,
			};
		});
	}, [bookings]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.booking.management',
						defaultMessage: 'Booking Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.booking"
									defaultMessage="Booking"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.bookingItem"
									defaultMessage="New Booking Item"
								/>
							</Button>
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((booking, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									key={`empty-post-${index}`}
									className="mt-4">
									<BookingItem
										booking={booking}
										onEdit={() => setUpdate(booking._id)}
									/>
								</Col>
							))}
					</Row>
					<Calendar />
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	bookings,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
}) => {
	return {
		bookings,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchBookings,
	fetchBookingsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearBookings,
})(Booking);
