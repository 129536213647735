import { API_ROOT } from '@config/api';
import {
	SET_APPS,
	SET_APP,
	ADD_APP,
	UPDATE_APP,
	CLEAR_APP,
} from './actionTypes';
import request from '@common/utils/request';

const fetchApps = (type) => (dispatch) =>
	request('get', `${API_ROOT}/apps`)
		.then((response) => {
			dispatch({
				type: SET_APPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchApp = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/apps/${id}`)
		.then((response) => {
			dispatch({
				type: SET_APP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createApp = (data) => (dispatch) =>
	request('post', `${API_ROOT}/apps`, data)
		.then((response) => {
			dispatch({
				type: ADD_APP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateApp = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/apps/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_APP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearApp = () => (dispatch) =>
	dispatch({
		type: CLEAR_APP,
	});

export { fetchApps, fetchApp, createApp, updateApp, clearApp };
