import { API_ROOT } from '@config/api';
import {
	SET_CONTENTS,
	SET_CONTENT,
	ADD_CONTENT,
	UPDATE_CONTENT,
	CLEAR_CONTENT,
	CLEAR_CONTENTS,
} from './actionTypes';
import request from '@common/utils/request';

const doReorder = (order) => () =>
	request('post', `${API_ROOT}/contents/reorder`, order)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchContents = () => (dispatch) =>
	request('get', `${API_ROOT}/contents`)
		.then((response) => {
			dispatch({
				type: SET_CONTENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchContentsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/contents/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_CONTENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchContentsByPage = (id) => (dispatch) =>
	request('get', `${API_ROOT}/contents/page/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CONTENTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchContent = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/contents/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CONTENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createContent = (data) => (dispatch) =>
	request('post', `${API_ROOT}/contents`, data)
		.then((response) => {
			dispatch({
				type: ADD_CONTENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateContent = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/contents/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_CONTENT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearContent = () => (dispatch) =>
	dispatch({
		type: CLEAR_CONTENT,
	});

const clearContents = () => (dispatch) =>
	dispatch({
		type: CLEAR_CONTENTS,
	});

export {
	doReorder,
	fetchContents,
	fetchContentsByOrganizationModule,
	fetchContentsByPage,
	fetchContent,
	createContent,
	updateContent,
	clearContent,
	clearContents,
};
