import { API_ROOT } from '@config/api';
import {
	SET_CONTENT_TYPES,
	SET_CONTENT_TYPE,
	ADD_CONTENT_TYPE,
	UPDATE_CONTENT_TYPE,
} from './actionTypes';
import request from '@common/utils/request';

const fetchContentTypes = (type) => (dispatch) =>
	request('get', `${API_ROOT}/contentTypes`)
		.then((response) => {
			dispatch({
				type: SET_CONTENT_TYPES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchContentType = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/contentTypes/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CONTENT_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createContentType = (data) => (dispatch) =>
	request('post', `${API_ROOT}/contentTypes`, data)
		.then((response) => {
			dispatch({
				type: ADD_CONTENT_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateContentType = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/contentTypes/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_CONTENT_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

export {
	fetchContentTypes,
	fetchContentType,
	createContentType,
	updateContentType,
};
