import React from 'react';
import { Box, Section } from '@common/components';
import { Row, Col, Typography } from 'antd';

const Booking = ({ booking, onClick }) => {
	return (
		<Box className="p-5">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col flex={1} onClick={() => onClick && onClick(booking)}>
						<Typography.Title level={3}>
							{booking.title}
						</Typography.Title>
						<Typography.Text level={3}>
							{booking.description}
						</Typography.Text>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Booking;
