import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchMinistries,
	fetchMinistriesByOrganization,
	clearMinistries,
} from '@resources/Ministry/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { MinistryItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Ministry = (props) => {
	const {
		ministries,
		fetchMinistries,
		fetchMinistriesByOrganization,
		clearMinistries,
		history,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearMinistries();
			organization?._id
				? await fetchMinistriesByOrganization(organization?._id)
				: await fetchMinistries();
		};
		fetchData();

		return () => clearMinistries();
	}, [
		clearMinistries,
		fetchMinistries,
		fetchMinistriesByOrganization,
		organization,
	]);

	const handleClick = (ministry) => {
		history.push(
			`/${ministry.organization.alias}/ministries/${ministry._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.ministry.management',
						defaultMessage: 'Ministry Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.ministries"
									defaultMessage="Ministries"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.ministry"
									defaultMessage="New Ministry"
								/>
							</Button>
						</Col>
					</Row>
					<Box className="mb-5">
						{ministries?.map((ministry, index) => (
							<MinistryItem
								key={`ministry-${index}`}
								ministry={ministry}
								onClick={handleClick}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, ministries }) => {
	return {
		organization,
		ministries,
	};
};

export default connect(mapStateToProps, {
	fetchMinistries,
	fetchMinistriesByOrganization,
	clearMinistries,
})(Ministry);
