import React, { useCallback, useState } from 'react';
import {
	DatePicker,
	Form,
	Input,
	Button,
	Checkbox,
	TimePicker,
	Upload,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { API_MEDIA } from '@common/config/api';
import { deleteMedia } from '@resources/Media/actions';
import { clearService } from '@resources/Service/actions';
import { getCookie } from '@common/utils/cookie';
import { formErrorHandler } from '@common/utils/form';
import ImgCrop from 'antd-img-crop';
import moment from 'moment';
const FormItem = Form.Item;

const ServiceForm = ({
	organization,
	data,
	onSubmit,
	deleteMedia,
	onCancel,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const authToken = getCookie('auth');
	const [loading, setLoading] = useState(false);
	const [banners, setBanners] = useState(
		data && data.banner
			? [
					{
						uid: 'banner',
						name: data.banner.originalName,
						url: data.banner.path,
						response: { data: [data.banner] },
					},
			  ]
			: []
	);

	const [thumbnails, setThumbnails] = useState(
		data && data.thumbnail
			? [
					{
						uid: 'thumbnail',
						name: data.thumbnail.originalName,
						url: data.thumbnail.path,
						response: { data: [data.thumbnail] },
					},
			  ]
			: []
	);

	const [images, setImages] = useState(
		data && data.images
			? data.images.map((image, index) => {
					return {
						uid: `images-${index}`,
						name: image.originalName,
						url: image.path,
						response: { data: [image] },
					};
			  })
			: []
	);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			const convertedValues = { ...values };

			convertedValues['banner'] =
				banners &&
				banners.length > 0 &&
				banners[0].response &&
				banners[0].response.data &&
				banners[0].response.data.length > 0
					? banners[0].response.data[0]._id
					: undefined;

			convertedValues['thumbnail'] =
				thumbnails &&
				thumbnails.length > 0 &&
				thumbnails[0].response &&
				thumbnails[0].response.data &&
				thumbnails[0].response.data.length > 0
					? thumbnails[0].response.data[0]._id
					: undefined;

			convertedValues['images'] =
				images && images.length > 0
					? images
							.filter(
								(image) =>
									image.response &&
									image.response.data &&
									image.response.data.length > 0
							)
							.map((image) => {
								return image.response &&
									image.response.data &&
									image.response.data.length > 0
									? image.response.data[0]._id
									: undefined;
							})
					: undefined;

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, images, thumbnails, banners, organization]
	);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = (set) => ({ fileList: newFileList }) => {
		set(newFileList);
	};

	const handleRemove = useCallback(
		(set, sources) => (file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					set(
						sources.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia]
	);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	return (
		<Form
			form={form}
			name="service-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={
				data && {
					...data,
					date: moment(data.date),
					time: moment(data.time),
				}
			}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker disabledDate={disabledDate} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.time',
					defaultMessage: 'Time',
				})}
				name="time"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.time.required',
							defaultMessage: 'Please input time',
						}),
					},
				]}>
				<TimePicker.RangePicker />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.location',
					defaultMessage: 'Location',
				})}
				name="location"
				rules={[]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.location',
						defaultMessage: 'Location',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.banner',
					defaultMessage: 'Banner',
				})}
				name="banner">
				<ImgCrop rotate aspect={5 / 3}>
					<Upload
						action={`${API_MEDIA}/image/service`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={banners}
						onPreview={handlePreview}
						onChange={handleChange(setBanners)}
						onRemove={handleRemove(setBanners, banners)}>
						{banners.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.thumbnail',
					defaultMessage: 'Thumbnail',
				})}
				name="thumbnail">
				<ImgCrop rotate aspect={1}>
					<Upload
						action={`${API_MEDIA}/image/service`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={thumbnails}
						onPreview={handlePreview}
						onChange={handleChange(setThumbnails)}
						onRemove={handleRemove(setThumbnails, thumbnails)}>
						{thumbnails.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.images',
					defaultMessage: 'Images',
				})}
				name="images">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/service`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={images}
						onPreview={handlePreview}
						onChange={handleChange(setImages)}
						onRemove={handleRemove(setImages)}>
						{images.length > 4 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem name="isVerified" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isVerified',
						defaultMessage: 'Is Verified',
					})}
				</Checkbox>
			</FormItem>

			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, {
	deleteMedia,
	clearService,
})(ServiceForm);
