import React, { useState, useEffect } from 'react';
import {
	CardElement,
	useElements,
	PaymentRequestButtonElement,
	useStripe,
} from '@stripe/react-stripe-js';
import { Button, Checkbox, Form, Radio } from 'antd';
import { useIntl } from 'react-intl';
import { formErrorHandler } from '../../../utils/form';
import { Box } from '../../../components';
import { connect } from 'react-redux';
import { paymentMethods } from '../../../resources/Stripe/actions';

const FormItem = Form.Item;

const CheckoutForm = ({
	auth,
	transaction,
	data,
	onSubmit,
	onCancel,
	paymentMethods,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const [methods, setMethods] = useState([]);
	const elements = useElements();
	const stripe = useStripe();
	const [paymentRequest, setPaymentRequest] = useState(null);
	useEffect(() => {
		const fetchData = async () => {
			const meds = await paymentMethods();

			setMethods(meds.data.data);
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest({
				country: 'US',
				currency: 'usd',
				total: {
					label: 'Demo total',
					amount: 1099,
				},
				requestPayerName: true,
				requestPayerEmail: true,
			});

			pr.canMakePayment().then((result) => {
				if (result) {
					console.log(result);
					setPaymentRequest(pr);
				}
			});
		}
	}, [stripe]);

	const handleFinish = async (values) => {
		values['transaction'] = transaction._id;
		// values['card'] = elements.getElement(CardElement);

		setLoading(true);

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		let setup_future_usage;
		let payment_method;

		if (values['payment'] === 0) {
			payment_method = {
				card: elements.getElement(CardElement),
				billing_details: {
					name: `${auth.firstName} ${auth.lastName}`,
				},
			};

			if (values['saveCard']) {
				setup_future_usage = 'off_session';
			}
		} else {
			payment_method = values['payment'];
		}

		const result = await stripe.confirmCardPayment(
			transaction.paymentIntent.client_secret,
			{
				payment_method,
				setup_future_usage,
			}
		);

		if (result.error) {
			// Show error to your customer (e.g., insufficient funds)
			console.log(result.error.message);
			setLoading(false);
		} else {
			// The payment has been processed!
			if (result.paymentIntent.status === 'succeeded') {
				// Show a success message to your customer
				// There's a risk of the customer closing the window before callback
				// execution. Set up a webhook or plugin to listen for the
				// payment_intent.succeeded event that handles any business critical
				// post-payment actions.
				console.log(result);

				values['result'] = result;
				onSubmit(values)
					.then(() => setLoading(false))
					.catch((err) => {
						setLoading(false);
						formErrorHandler(err, values, form);
					});
			} else {
				setLoading(false);
			}
		}
	};

	const CARD_OPTIONS = {
		iconStyle: 'solid',
		style: {
			base: {
				iconColor: '#A90AF2',
				color: '#fff',
				fontWeight: 500,
				fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':-webkit-autofill': { color: '#fce883' },
				'::placeholder': { color: '#87bbfd' },
			},
			invalid: {
				iconColor: '#ffc7ee',
				color: '#ffc7ee',
			},
		},
	};

	return (
		<>
			<Form
				form={form}
				layout="vertical"
				name="position-form"
				onFinish={handleFinish}>
				<FormItem
					label={intl.formatMessage({
						id: 'common.payment',
						defaultMessage: 'Payment',
					})}
					name="payment"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: 'form.validation.card.required',
								defaultMessage: 'Please input card',
							}),
						},
					]}>
					<Radio.Group style={{ width: '100%' }}>
						<Box>
							{methods &&
								methods.map((method) => (
									<Box pb={10}>
										<Radio value={method.id}>
											{method.card.brand.toUpperCase()}{' '}
											**** **** **** {method.card.last4}
										</Radio>
									</Box>
								))}
							<Radio value={0}>New payment method</Radio>
						</Box>
					</Radio.Group>
				</FormItem>

				<Form.Item
					noStyle
					shouldUpdate={(prevValues, currentValues) =>
						prevValues.payment !== currentValues.payment
					}>
					{({ getFieldValue }) => {
						return getFieldValue('payment') === 0 ? (
							<Box>
								<FormItem
									label={intl.formatMessage({
										id: 'common.card',
										defaultMessage: 'Card',
									})}
									name="card"
									rules={[
										{
											required: true,
											message: intl.formatMessage({
												id:
													'form.validation.card.required',
												defaultMessage:
													'Please input card',
											}),
										},
									]}>
									<CardElement options={CARD_OPTIONS} />
								</FormItem>
								<FormItem
									name="saveCard"
									valuePropName="checked">
									<Checkbox>
										{intl.formatMessage({
											id: 'common.saveCard',
											defaultMessage: 'Save Card',
										})}
									</Checkbox>
								</FormItem>
							</Box>
						) : null;
					}}
				</Form.Item>

				<FormItem className="mt-5">
					<Button
						type="secondary"
						onClick={() => onCancel && onCancel()}>
						{intl.formatMessage({
							id: 'common.cancel',
							defaultMessage: 'Cancel',
						})}
					</Button>
					&nbsp;&nbsp;
					<Button
						loading={loading}
						type="primary"
						htmlType="submit"
						className="login-form-button">
						{intl.formatMessage({
							id: 'common.submit',
							defaultMessage: 'Submit',
						})}
					</Button>
				</FormItem>
			</Form>

			{paymentRequest && (
				<PaymentRequestButtonElement options={{ paymentRequest }} />
			)}
		</>
	);
};

export default connect(null, { paymentMethods })(CheckoutForm);
