import { API_ROOT } from '@config/api';
import {
	SET_EVENT_CATEGORIES,
	SET_EVENT_CATEGORY,
	ADD_EVENT_CATEGORY,
	UPDATE_EVENT_CATEGORY,
	CLEAR_EVENT_CATEGORY,
	CLEAR_EVENT_CATEGORIES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchEventCategories = (type) => (dispatch) =>
	request('get', `${API_ROOT}/eventCategories`)
		.then((response) => {
			dispatch({
				type: SET_EVENT_CATEGORIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchEventCategory = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/eventCategories/${id}`)
		.then((response) => {
			dispatch({
				type: SET_EVENT_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createEventCategory = (data) => (dispatch) =>
	request('post', `${API_ROOT}/eventCategories`, data)
		.then((response) => {
			dispatch({
				type: ADD_EVENT_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateEventCategory = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/eventCategories/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_EVENT_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearEventCategory = () => (dispatch) =>
	dispatch({
		type: CLEAR_EVENT_CATEGORY,
	});

const clearEventCategories = () => (dispatch) =>
	dispatch({
		type: CLEAR_EVENT_CATEGORIES,
	});

export {
	fetchEventCategories,
	fetchEventCategory,
	createEventCategory,
	updateEventCategory,
	clearEventCategory,
	clearEventCategories,
};
