import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '@common/timelines';
import { connect } from 'react-redux';
import Dashboard from '@containers/Dashboard';
import Landing from '@containers/Landing';
import { Event, EventDetail } from '@containers/Event';
import Organization from '@containers/Organization';
import {
	Analytics,
	AuthorizedRoute,
	Middleware,
	Notifications,
	Subscription,
	Navbar,
} from '@common/fragments';
import Pusher from 'pusher-js';
import {
	FOREWARD_PUSHER_KEY,
	FOREWARD_PUSHER_CLUSTER,
} from '@common/config/app';
import { Image } from '@common/components';
import Cart from '@common/containers/Cart';
import {
	StripeAuthorize,
	StripeToken,
	StripeCheckout,
} from '@common/containers/Stripe';

import {
	LeftMenu,
	RightMenu,
	CellGroupInfo,
	ClassInfo,
	EventInfo,
	FacilityInfo,
	ItemInfo,
	MinistryInfo,
	MissionInfo,
	PrayerInfo,
	ShopInfo,
	ServiceInfo,
	StorageInfo,
	VenueInfo,
} from '@fragments';

import { Account, AccountToken } from '@containers/Account';
import { Admin } from '@containers/Admin';
import { CellGroup, CellGroupDetail } from '@containers/CellGroup';
import { Department } from '@containers/Department';
import { Expense } from '@containers/Expense';
import { Facility, FacilityDetail } from '@containers/Facility';
import { Forma, FormaDetail } from '@containers/Form';
import { Group } from '@containers/Group';
import { Guest } from '@containers/Guest';
import { Item, ItemDetail } from '@containers/Item';
import { Income } from '@containers/Income';
import { Member } from '@containers/Member';
import { Menu } from '@containers/Menu';
import { Class, ClassDetail } from '@containers/Class';
import { Ministry } from '@containers/Ministry';
import { Page, PageDetail } from '@containers/Page';
import { Order } from '@containers/Order';
import { Position } from '@containers/Position';
import { Purchase } from '@containers/Purchase';
import { Prayer } from '@containers/Prayer';
import { Preference } from '@containers/Preference';
import { Project } from '@containers/Project';
import { Service, ServiceDetail } from '@containers/Service';
import { Shop } from '@containers/Shop';
import { Storage } from '@containers/Storage';
import { Theme } from '@containers/Theme';
import { Tithe } from '@containers/Tithe';
import { Venue } from '@containers/Venue';

import { Activity } from '@containers/Activity';
import { Booking } from '@containers/Booking';
import { Crew } from '@containers/Crew';
import { Content } from '@containers/Content';
import { Discussion } from '@containers/Discussion';
import { Guide } from '@containers/Guide';
import { Journal } from '@containers/Journal';
import { Mission } from '@containers/Mission';
import { Participant } from '@containers/Participant';
import { Product } from '@containers/Product';
import { Programme } from '@containers/Programme';
import { Rentable } from '@containers/Rentable';
import { Rental } from '@containers/Rental';
import { Review } from '@containers/Review';
import { Space } from '@containers/Space';
import { Stock } from '@containers/Stock';
import { Supplier } from '@containers/Supplier';
import { Task } from '@containers/Task';

import ScrollToTop from '@common/fragments/ScrollToTop';
import { setPusherClient } from 'react-pusher';
import { Layout } from 'antd';

const { Footer } = Layout;

const pusherClient = new Pusher(FOREWARD_PUSHER_KEY, {
	cluster: FOREWARD_PUSHER_CLUSTER,
	encrypted: true,
});

setPusherClient(pusherClient);

const Base = (props) => {
	const { organization, siteTheme } = props;
	let basename = organization?.alias ? `/${organization?.alias}` : '';

	return (
		<BrowserRouter>
			<ScrollToTop>
				<Route
					path="/"
					render={(props) => {
						let authorise = true;
						let resource = true;

						return (
							<div>
								<Middleware
									{...props}
									resource={resource}
									authorise={authorise}>
									<Route
										render={(props) => {
											const { location } = props;
											const { pathname, key } = location;

											return (
												<>
													<Navbar
														logo={
															siteTheme?._id
																? siteTheme?.logo && (
																		<Image
																			height={
																				40
																			}
																			width="auto"
																			src={
																				siteTheme
																					.logo
																					.path
																			}
																		/>
																  )
																: organization &&
																  organization.logo && (
																		<Image
																			height={
																				40
																			}
																			width="auto"
																			src={
																				organization
																					.logo
																					.path
																			}
																		/>
																  )
														}
														leftMenu={
															<LeftMenu
																{...props}
															/>
														}
														rightMenu={
															<RightMenu
																{...props}
															/>
														}
													/>

													<Route
														path={`${basename}/classes/:classId/details`}
														exact
														component={ClassInfo}
													/>
													<Route
														path={`${basename}/classes/:classId/details/:submoduleCode`}
														component={ClassInfo}
													/>

													<Route
														path={`${basename}/events/:eventId/details`}
														exact
														component={EventInfo}
													/>
													<Route
														path={`${basename}/events/:eventId/details/:submoduleCode`}
														component={EventInfo}
													/>

													<Route
														path={`${basename}/facilities/:facilityId/details`}
														exact
														component={FacilityInfo}
													/>
													<Route
														path={`${basename}/facilities/:facilityId/details/:submoduleCode`}
														component={FacilityInfo}
													/>

													<Route
														path={`${basename}/cellGroups/:cellGroupId/details`}
														exact
														component={
															CellGroupInfo
														}
													/>
													<Route
														path={`${basename}/cellGroups/:cellGroupId/details/:submoduleCode`}
														component={
															CellGroupInfo
														}
													/>

													<Route
														path={`${basename}/items/:itemId/details`}
														exact
														component={ItemInfo}
													/>
													<Route
														path={`${basename}/items/:itemId/details/:submoduleCode`}
														component={ItemInfo}
													/>

													<Route
														path={`${basename}/ministries/:ministryId/details`}
														exact
														component={MinistryInfo}
													/>
													<Route
														path={`${basename}/ministries/:ministryId/details/:submoduleCode`}
														component={MinistryInfo}
													/>

													<Route
														path={`${basename}/missions/:missionId/details`}
														exact
														component={MissionInfo}
													/>
													<Route
														path={`${basename}/missions/:missionId/details/:submoduleCode`}
														component={MissionInfo}
													/>

													<Route
														path={`${basename}/prayers/:prayerId/details`}
														exact
														component={PrayerInfo}
													/>
													<Route
														path={`${basename}/prayers/:prayerId/details/:submoduleCode`}
														component={PrayerInfo}
													/>

													<Route
														path={`${basename}/services/:serviceId/details`}
														exact
														component={ServiceInfo}
													/>
													<Route
														path={`${basename}/services/:serviceId/details/:submoduleCode`}
														component={ServiceInfo}
													/>

													<Route
														path={`${basename}/shops/:shopId/details`}
														exact
														component={ShopInfo}
													/>
													<Route
														path={`${basename}/shops/:shopId/details/:submoduleCode`}
														component={ShopInfo}
													/>

													<Route
														path={`${basename}/storages/:storageId/details`}
														exact
														component={StorageInfo}
													/>
													<Route
														path={`${basename}/storages/:storageId/details/:submoduleCode`}
														component={StorageInfo}
													/>

													<Route
														path={`${basename}/venues/:venueId/details`}
														exact
														component={VenueInfo}
													/>
													<Route
														path={`${basename}/venues/:venueId/details/:submoduleCode`}
														component={VenueInfo}
													/>
													<TransitionGroup
														component={null}>
														<Transition
															key={key}
															appear={true}
															onEnter={(
																node,
																appears
															) => {
																if (node) {
																	return play(
																		pathname,
																		node,
																		appears
																	);
																}
															}}
															onExit={(
																node,
																appears
															) => {
																if (node) {
																	return exit(
																		node,
																		appears
																	);
																}
															}}
															timeout={{
																enter: 750,
																exit: 150,
															}}>
															<Switch
																location={
																	location
																}>
																<Route
																	exact
																	path="/"
																	component={
																		Landing
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path="/cart"
																	component={
																		Cart
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path="/dashboard"
																	component={
																		Dashboard
																	}
																	{...props}
																/>

																<Route
																	exact
																	path="/events"
																	component={
																		Event
																	}
																	{...props}
																/>

																<Route
																	exact
																	path="/"
																	component={
																		Organization
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path="/cart"
																	component={
																		Cart
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/accounts`}
																	component={
																		Account
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/accounts/token`}
																	component={
																		AccountToken
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/admins`}
																	component={
																		Admin
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/classes`}
																	component={
																		Class
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/events`}
																	component={
																		Event
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/cellGroups`}
																	component={
																		CellGroup
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/departments`}
																	component={
																		Department
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/expenses`}
																	component={
																		Expense
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/facilities`}
																	component={
																		Facility
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/forms`}
																	component={
																		Forma
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/forms/:id`}
																	component={
																		FormaDetail
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/groups`}
																	component={
																		Group
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/guests`}
																	component={
																		Guest
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/incomes`}
																	component={
																		Income
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/items`}
																	component={
																		Item
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/members`}
																	component={
																		Member
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/menus`}
																	component={
																		Menu
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/missions`}
																	component={
																		Mission
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/ministries`}
																	component={
																		Ministry
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/orders`}
																	component={
																		Order
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/pages`}
																	component={
																		Page
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/pages/:id`}
																	component={
																		PageDetail
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/positions`}
																	component={
																		Position
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/prayers`}
																	component={
																		Prayer
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/projects`}
																	component={
																		Project
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/purchases`}
																	component={
																		Purchase
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/services`}
																	component={
																		Service
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/shops`}
																	component={
																		Shop
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/storages`}
																	component={
																		Storage
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/tithes`}
																	component={
																		Tithe
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/venues`}
																	component={
																		Venue
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/preferences`}
																	component={
																		Preference
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/stripe/authorize`}
																	component={
																		StripeAuthorize
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/stripe/token`}
																	component={
																		StripeToken
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/stripe/checkout/:transactionId`}
																	component={
																		StripeCheckout
																	}
																	{...props}
																/>

																<Route
																	exact
																	path={`${basename}/themes`}
																	component={
																		Theme
																	}
																	{...props}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/events/:moduleId/details`}
																	component={
																		EventDetail
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/cellGroups/:moduleId/details`}
																	component={
																		CellGroupDetail
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/classes/:moduleId/details`}
																	component={
																		ClassDetail
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/facilities/:moduleId/details`}
																	component={
																		FacilityDetail
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/items/:moduleId/details`}
																	component={
																		ItemDetail
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/services/:moduleId/details`}
																	component={
																		ServiceDetail
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/rentables`}
																	component={
																		Rentable
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/stocks`}
																	component={
																		Stock
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/suppliers`}
																	component={
																		Supplier
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/activities`}
																	component={
																		Activity
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/bookings`}
																	component={
																		Booking
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/crews`}
																	component={
																		Crew
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/contents`}
																	component={
																		Content
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/discussions`}
																	component={
																		Discussion
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/groups`}
																	component={
																		Group
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/guides`}
																	component={
																		Guide
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/journals`}
																	component={
																		Journal
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/participants`}
																	component={
																		Participant
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/products`}
																	component={
																		Product
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/programmes`}
																	component={
																		Programme
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/rentals`}
																	component={
																		Rental
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/spaces`}
																	component={
																		Space
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/reviews`}
																	component={
																		Review
																	}
																/>

																<AuthorizedRoute
																	exact
																	path={`${basename}/:moduleUrl/:moduleId/details/tasks`}
																	component={
																		Task
																	}
																/>
															</Switch>
														</Transition>
													</TransitionGroup>

													<Footer
														style={{
															textAlign: 'center',
														}}>
														Foreward Network ©2020
													</Footer>
												</>
											);
										}}
									/>
								</Middleware>
							</div>
						);
					}}
				/>
				<Route path="/" component={Analytics} {...props} />
				<Route path="/" component={Notifications} {...props} />
				<Route path="/" component={Subscription} {...props} />
			</ScrollToTop>
		</BrowserRouter>
	);
};

const mapStateToProps = ({ site, events, organization, siteTheme }) => {
	return { site, events, organization, siteTheme };
};

export default connect(mapStateToProps)(Base);
