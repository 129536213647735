import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { tokenStripe } from '@common/resources/Stripe/actions';
import { fetchAccountBySession } from '@resources/Account/actions';
import { Container, View } from '@common/components';
import { Button, Layout, Result } from 'antd';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import qs from 'qs';

const { Content } = Layout;

const Token = ({
	tokenStripe,
	location,
	fetchAccountBySession,
	organization,
}) => {
	const intl = useIntl();
	const query = location.search.slice(1);
	const [result, setResult] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			if (organization?._id) {
				const code = qs.parse(query).code;
				const state = qs.parse(query).state;
				const account = await fetchAccountBySession(state);

				if (!account?.data?.data?.stripeAccountId) {
					await tokenStripe('account', code, state);
				}

				setResult({
					status: 'success',
					title: 'Stripe Authorization Successful',
					subTitle:
						'Your connection to Stripe has been successfully Authorised. You may start using all of the features that requires payment',
					extra: [
						<Button type="primary" key="console">
							Home
						</Button>,
					],
				});
			}
		};

		fetchData();
	}, [organization]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.token',
						defaultMessage: 'Token',
					})}
				</title>
			</Helmet>

			<View>
				<Container>{result && <Result {...result} />}</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({ organization }) => {
	return {
		organization,
	};
};

export default connect(mapStateToProps, {
	tokenStripe,
	fetchAccountBySession,
})(Token);
