import React, { useEffect, useCallback, useState } from 'react';
import {
	Col,
	Form,
	Input,
	Button,
	Checkbox,
	Icon,
	Row,
	Select,
	Upload,
	Switch,
	Divider,
	InputNumber,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearShop } from '@resources/Shop/actions';
import { formErrorHandler } from '@common/utils/form';
import { API_MEDIA } from '@common/config/api';
import { deleteMedia } from '@resources/Media/actions';
import ImgCrop from 'antd-img-crop';
import { getCookie } from '@common/utils/cookie';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const Option = Select.Option;

const ShopForm = ({
	auth,
	data,
	onSubmit,
	organization,
	clearShop,
	onCancel,
	shopCategories,
}) => {
	const intl = useIntl();
	const authToken = getCookie('auth');
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [logos, setLogos] = useState(
		data && data.logo
			? [
					{
						uid: 'logo',
						name: data.logo.originalName,
						url: data.logo.path,
						response: { data: [data.logo] },
					},
			  ]
			: []
	);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			const convertedValues = { ...values };

			convertedValues['logo'] =
				logos &&
				logos.length > 0 &&
				logos[0].response &&
				logos[0].response.data &&
				logos[0].response.data.length > 0
					? logos[0].response.data[0]._id
					: undefined;

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}
			onSubmit(convertedValues)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		return () => clearShop();
	}, []);

	const renderShopCategoryOptions = useCallback(() => {
		if (shopCategories) {
			return shopCategories.map((shopCategory, index) => {
				return (
					<Option
						value={shopCategory._id}
						key={`shopCategory-${index}`}
						title={shopCategory.title}>
						{shopCategory.title}
					</Option>
				);
			});
		}
	}, [shopCategories]);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = (set) => ({ fileList: newFileList }) => {
		set(newFileList);
	};

	const handleRemove = useCallback(
		(set, sources) => (file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					set(
						sources.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia]
	);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.name',
					defaultMessage: 'Name',
				})}
				name="name"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.name.required',
							defaultMessage: 'Please input name',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.name',
						defaultMessage: 'Name',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.shopCategory',
					defaultMessage: 'Shop Category',
				})}
				name="categories"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.shopCategory.required',
							defaultMessage: 'Please input shop Category',
						}),
					},
				]}>
				<Select
					mode="multiple"
					showSearch
					placeholder={intl.formatMessage({
						id: 'form.placeholder.shopCategory',
						defaultMessage: 'Please select a shop Category',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) => {
						return (
							option.props.title
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						);
					}}>
					{renderShopCategoryOptions()}
				</Select>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.logo',
					defaultMessage: 'Logo',
				})}
				name="logo">
				<ImgCrop rotate aspect={5 / 3}>
					<Upload
						action={`${API_MEDIA}/image/shop`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={logos}
						onPreview={handlePreview}
						onChange={handleChange(setLogos)}
						onRemove={handleRemove(setLogos, logos)}>
						{logos.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.physical.products',
					defaultMessage: 'Physical products',
				})}
				name="physical"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.physical !== currentValues.physical
				}>
				{({ getFieldValue }) => {
					return getFieldValue('physical') ? (
						<Form.List
							label="Delivery Options"
							name="deliveryOptions"
							wrapperCol={{ span: 24 }}>
							{(fields, { add, remove }) => (
								<>
									{fields.map((field) => (
										<>
											<Row gutter={24} align="middle">
												<Col flex={1}>
													<FormItem
														{...field}
														label={`Name`}
														name={[
															field.name,
															'name',
														]}
														fieldKey={[
															field.fieldKey,
															'name',
														]}
														rules={[
															{
																required: true,
																message: intl.formatMessage(
																	{
																		id:
																			'form.validation.name.required',
																		defaultMessage:
																			'Please input name',
																	}
																),
															},
														]}>
														<Input />
													</FormItem>
													<FormItem
														{...field}
														label={`Description`}
														name={[
															field.name,
															'description',
														]}
														fieldKey={[
															field.fieldKey,
															'description',
														]}
														rules={[
															{
																required: true,
																message: intl.formatMessage(
																	{
																		id:
																			'form.validation.description.required',
																		defaultMessage:
																			'Please input description',
																	}
																),
															},
														]}>
														<Input />
													</FormItem>
													<FormItem
														{...field}
														label={`Fee`}
														name={[
															field.name,
															'fee',
														]}
														fieldKey={[
															field.fieldKey,
															'fee',
														]}
														rules={[
															{
																required: true,
																message: intl.formatMessage(
																	{
																		id:
																			'form.validation.fee.required',
																		defaultMessage:
																			'Please input fee',
																	}
																),
															},
														]}>
														<InputNumber />
													</FormItem>
												</Col>
												<Col
													style={{
														width: 30,
														paddingTop: 14,
													}}>
													<MinusCircleOutlined
														onClick={() =>
															remove(field.name)
														}
													/>
												</Col>
											</Row>
											<Divider />
										</>
									))}

									<Form.Item wrapperCol={{ span: 24 }}>
										<Button
											style={{ width: '100%' }}
											type="dashed"
											onClick={() => add()}
											icon={<PlusOutlined />}>
											Add delivery option
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					) : null;
				}}
			</Form.Item>
			<FormItem
				label={intl.formatMessage({
					id: 'common.allow.selfPickUp',
					defaultMessage: 'Allow self pickup',
				})}
				name="selfPickUp"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.selfPickUp !== currentValues.selfPickUp
				}>
				{({ getFieldValue }) => {
					return getFieldValue('selfPickUp') ? (
						<FormItem
							label="Pick up address"
							name="address"
							rules={[]}>
							<Input
								placeholder={intl.formatMessage({
									id: 'common.address',
									defaultMessage: 'Address',
								})}
							/>
						</FormItem>
					) : null;
				}}
			</Form.Item>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization, shopCategories }) => {
	return {
		auth,
		organization,
		shopCategories,
	};
};

export default connect(mapStateToProps, {
	clearShop,
})(ShopForm);
