import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Box, Container, View, Section, Title } from '@common/components';
import {
	Col,
	Row,
	Typography,
	Button,
	Form,
	Affix,
	Layout,
	Select,
} from 'antd';
import PageContentForm from './Form';
import PageContentItem from './Item';
import { fetchPage } from '@resources/Page/actions';
import { fetchContentTypes } from '@resources/ContentType/actions';
import { fetchContentsByPage, doReorder } from '@resources/Content/actions';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import {
	SortableContainer,
	SortableElement,
	sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const Option = Select.Option;
const { Content } = Layout;

const PageContent = ({
	history,
	match,
	page,
	contents,
	contentTypes,
	fetchPage,
	fetchContentTypes,
	fetchContentsByPage,
	doReorder,
}) => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);
	const [contentType, setContentType] = useState({});
	const [id, setId] = useState(null);
	const [sortableContents, setSortableContents] = useState(contents);

	const handleEdit = (id) => {
		setId(id);
		setVisible(true);
	};

	const handleDelete = () => {};

	const SortableItem = SortableElement(({ data }) => (
		<PageContentItem
			data={data}
			onEdit={handleEdit}
			onDelete={handleDelete}
			sortableHandle={sortableHandle}
			isEditable={page?.isCreator}
		/>
	));

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Section pb={50}>
				{items && items.length > 0
					? items.map((content, index) => (
							<SortableItem
								key={`item-${index}`}
								index={index}
								data={content}
							/>
					  ))
					: intl.formatMessage({
							id: 'common.data.empty',
							defaultMessage: 'Data empty',
					  })}
			</Section>
		);
	});

	useEffect(() => {
		const fetchData = async () => {
			await fetchPage(match.params.id);
			await fetchContentsByPage(match.params.id);
			await fetchContentTypes();
		};
		fetchData();
	}, [fetchPage]);

	useEffect(() => {
		setSortableContents(contents);
	}, [contents]);

	const renderContentTypeOptions = useCallback(() => {
		if (contentTypes) {
			return contentTypes.map((contentType, index) => {
				return (
					<Option
						value={contentType.alias}
						key={`contentType-${index}`}
						title={contentType.title}>
						{contentType.title}
					</Option>
				);
			});
		}
	}, [contentTypes]);

	const onFinish = (data) => {
		setId(null);
		setContentType(
			contentTypes.filter(
				(contentType) => contentType.alias === data.type
			)[0]
		);
		setVisible(true);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.page.content',
						defaultMessage: 'Page Content',
					})}
				</title>
			</Helmet>
			<Container>
				<View>
					<Box py={50}>
						<Title fontSize={100} lineHeight={1} mb={10} primary>
							{page.title}
						</Title>
						<Typography.Title level={2}>Page</Typography.Title>
					</Box>
					<SortableList
						useDragHandle
						items={sortableContents}
						onSortEnd={({ oldIndex, newIndex }) => {
							const newOrder = arrayMove(
								sortableContents,
								oldIndex,
								newIndex
							);
							doReorder(
								newOrder.map((order, index) => {
									return { _id: order._id, order: index };
								})
							);
							setSortableContents(newOrder);
						}}
					/>

					{page?.isCreator && (
						<Affix offsetBottom={20}>
							<Row align="middle">
								<Col>
									<Typography.Title
										level={3}
										style={{ margin: 0 }}>
										New Content
									</Typography.Title>
								</Col>
								<Col>
									<Section>
										<Form
											layout="inline"
											onFinish={onFinish}>
											<Form.Item
												name="type"
												label="Type"
												rules={[{ required: true }]}>
												<Select
													placeholder={intl.formatMessage(
														{
															id:
																'form.placeholder.type',
															defaultMessage:
																'Please select a type',
														}
													)}
													style={{ width: '300px' }}
													optionFilterProp="title"
													filterOption={(
														input,
														option
													) =>
														option.props.children
															.toLowerCase()
															.indexOf(
																input.toLowerCase()
															) >= 0
													}>
													{renderContentTypeOptions()}
												</Select>
											</Form.Item>
											<Form.Item>
												<Button
													type="primary"
													htmlType="submit">
													{intl.formatMessage({
														id:
															'common.new.content',
														defaultMessage:
															'New Content',
													})}
												</Button>
											</Form.Item>
										</Form>
									</Section>
								</Col>
							</Row>
						</Affix>
					)}
					<PageContentForm
						page={page}
						visible={visible}
						type={contentType}
						id={id}
						onCancel={() => setVisible(false)}
					/>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ page, contentTypes, contents }) => {
	return {
		page,
		contentTypes,
		contents,
	};
};

export default connect(mapStateToProps, {
	fetchPage,
	fetchContentTypes,
	fetchContentsByPage,
	doReorder,
})(PageContent);
