import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ProductForm from '../Form';
import {
	fetchProduct,
	updateProduct,
	clearProduct,
} from '@resources/Product/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		module,
		fetchProduct,
		product,
		updateProduct,
		clearProduct,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await clearProduct();
			await fetchProduct(visible);
		};
		visible && fetchData();
	}, [fetchProduct, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateProduct(product._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.product.update',
							defaultMessage:
								'An existing Product entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[product]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.product',
					defaultMessage: 'Update Product',
				})}
			</Title>
			{!isEmpty(product) && (
				<ProductForm
					data={product}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ product, organization, modules }) => {
	return {
		product,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchProduct,
	updateProduct,
	clearProduct,
	fetchModulesBySubmoduleCode,
})(Update);
