import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchAccesses } from '@resources/Access/actions';
import { fetchForms, fetchFormsByOrganization } from '@resources/Form/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormaItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Forma = (props) => {
	const {
		organization,
		formas,
		fetchAccesses,
		fetchForms,
		fetchFormsByOrganization,
		history,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			organization?._id
				? await fetchFormsByOrganization(organization?._id)
				: await fetchForms();
		};
		fetchData();
	}, [fetchAccesses, fetchForms, fetchFormsByOrganization, organization]);

	const handleClick = (forma) => {
		history.push(`/${forma.organization.alias}/forms/${forma.alias}`);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.form.management',
						defaultMessage: 'Form Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.forms"
									defaultMessage="Forms"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.form"
									defaultMessage="New Form"
								/>
							</Button>
						</Col>
					</Row>
					<Box className="mb-5">
						{formas?.map((forma, index) => (
							<FormaItem
								key={`forma-${index}`}
								forma={forma}
								onClick={handleClick}
								onEdit={() => setUpdate(forma._id)}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ formas, organization }) => {
	return { formas, organization };
};

export default connect(mapStateToProps, {
	fetchFormsByOrganization,
	fetchAccesses,
	fetchForms,
})(Forma);
