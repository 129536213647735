import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Descriptions } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Supplier = ({ supplier, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick(supplier)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col flex={1}>
						<Descriptions
							title={supplier?.name}
							extra={[
								onEdit && (
									<Button
										size="small"
										type="text"
										onClick={() =>
											onEdit && onEdit(supplier)
										}>
										<EditFilled
											style={{ color: '#FFCC00' }}
										/>
									</Button>
								),
								onDelete && (
									<Button
										size="small"
										type="text"
										onClick={() =>
											onDelete && onDelete(supplier)
										}>
										<DeleteFilled
											style={{ color: '#FF0000' }}
										/>
									</Button>
								),
							]}>
							<Descriptions.Item label="Buy">
								{supplier?.price?.buy}
							</Descriptions.Item>
							<Descriptions.Item label="Sell">
								{supplier?.price?.sell}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Supplier;
