import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Container,
	Image,
	Modal,
	Section,
	View,
} from '@common/components';
import { Button, Form, Row, Col, Layout, Tag, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import {
	EnvironmentOutlined,
	CalendarOutlined,
	FieldTimeOutlined,
	UserOutlined,
} from '@ant-design/icons';
import {
	ProductHeaderItem,
	ProductFormItem,
	ProductTicketItem,
	ParticipantItem,
} from '@fragments/items';
import { fetchParticipantsMine } from '@resources/Participant/actions';
import { fetchEvent } from '@resources/Event/actions';
import {
	createTransaction,
	fetchTransactionsByModule,
} from '@common/resources/Transaction/actions';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import CreateParticipantModal from '@containers/Participant/Create';
import {
	fetchReviewsByOrganizationModule,
	clearReviews,
} from '@resources/Review/actions';
import {
	fetchActionsByOrganizationModule,
	clearActions,
} from '@resources/Action/actions';
import { ReviewItem } from '@fragments/items';
import CreateReviewModal from '@containers/Review/Create';
import UpdateReviewModal from '@containers/Review/Update';
import { Action } from '@fragments';
import { ThemeContext } from 'styled-components';
import { popIn } from '@common/utils/services';

const FormItem = Form.Item;
const { Content } = Layout;

const EventDetail = (props) => {
	const {
		event,
		transactions,
		participants,
		type,
		visible,
		onCancel,
		history,
		createTransaction,
		fetchTransactionsByModule,
		fetchParticipantsMine,
		organization,
		fetchReviewsByOrganizationModule,
		clearReviews,
		fetchActionsByOrganizationModule,
		clearActions,
		reviews,
		fetchEvent,
	} = props;
	const [form] = Form.useForm();
	const [purchases, setPurchases] = useState([]);
	const [createParticipant, setCreateParticipant] = useState(false);
	const startDate = event?.date && moment(event?.date[0]).format('DD MMM');
	const endDate = event?.date && moment(event?.date[1]).format('DD MMM');
	const [ev, setEv] = useState({});
	const [product, setProduct] = useState();

	const [createReview, setCreateReview] = useState(false);
	const [updateReview, setUpdateReview] = useState(false);
	const theme = useContext(ThemeContext);

	useEffect(() => {
		const fetchData = async () => {
			await fetchTransactionsByModule('event', event._id, {
				status: 'completed',
			});
			await fetchParticipantsMine('event', event._id);

			if (event.isCompleted) {
				await clearReviews();
				await fetchReviewsByOrganizationModule(
					organization?._id,
					'event',
					event._id
				);
			} else {
				fetchActions(true);
			}
		};

		if (ev?._id !== event?._id) {
			!isEmpty(event) && fetchData();
			setEv(event);
		}
	}, [event, organization]);

	const fetchActions = async (init) => {
		await clearActions();
		await fetchActionsByOrganizationModule(
			organization?._id,
			'event',
			event._id
		);

		if (!init) {
			await fetchEvent(event._id);
		}
	};

	useEffect(() => {
		const items = {};
		const products = [];
		transactions &&
			transactions.forEach((transaction) => {
				transaction.items.forEach((item) => {
					if (!items[item.product._id]) {
						items[item.product._id] = {
							quantity: 0,
							product: item.product,
							price: item.price,
						};
					}

					items[item.product._id]['quantity'] += item.quantity;
				});
			});

		Object.keys(items).forEach((item) => {
			products.push(items[item]);
		});

		setPurchases(products);
	}, [transactions]);

	const handleFinish = (values) => {
		let total = 0;

		values.products.forEach((product) => {
			total += product.quantity;
		});

		if (
			total >
			event.limit - event.transactionCount - event.participantCount
		) {
			popIn('error', 'You are buying more tickets than the limit');
			return null;
		} else {
			if (organization?._id) {
				values['organization'] = organization?._id;
			}
			values['module'] = 'event';
			values['event'] = event._id;
			values['account'] = event?.account?._id;
			values['products'] = values['products'].filter(
				(product) => product.quantity > 0
			);

			return createTransaction(values)
				.then((response) => {
					const { data } = response;
					history.push(
						`/${organization?.alias}/stripe/checkout/${data._id}`
					);
				})
				.catch();
		}
	};

	const content = event && (
		<>
			<Action entity="event" refresh={fetchActions} />
			<Row align="middle" gutter={24} justify="center">
				{event?.thumbnail?.path && (
					<Col md={12}>
						<Box mb={20} p={30}>
							<Section p={0} hoverable>
								<Image
									src={event?.thumbnail?.path}
									width="100%"
								/>
							</Section>
						</Box>
					</Col>
				)}
				<Col md={event?.thumbnail?.path ? 12 : 24}>
					<Tag color="orange">{event?.organization?.name}</Tag>
					{event?.category?.title && (
						<Tag color="blue">{event?.category?.title}</Tag>
					)}
					{event.date && event.time && (
						<>
							<Box>
								<CalendarOutlined
									style={{ color: theme.base.primary }}
								/>{' '}
								{startDate === endDate
									? startDate
									: `${startDate} - ${endDate}`}
							</Box>
							<Box>
								<FieldTimeOutlined
									style={{ color: theme.base.primary }}
								/>{' '}
								{moment(event?.time[0]).format('HH:mm')}
								{' - '}
								{moment(event?.time[1]).format('HH:mm')}
							</Box>
						</>
					)}
					<Box>
						<EnvironmentOutlined
							style={{ color: theme.base.primary }}
						/>{' '}
						<Typography.Text>{event.address}</Typography.Text>
					</Box>
					<Box>
						<UserOutlined style={{ color: theme.base.primary }} />{' '}
						<Typography.Text>
							{event.participantCount}
							{event.limit > 0 && `/${event.limit}`}
						</Typography.Text>{' '}
						{event.isParticipant && (
							<Tag color="green">You are a participant</Tag>
						)}
					</Box>

					<Box>
						<Typography.Text>{event.description}</Typography.Text>
					</Box>
				</Col>
			</Row>

			{!event?.isCompleted &&
				!event.soldout &&
				event?.products &&
				event?.products?.length > 0 && (
					<Box textAlign="center" py={50}>
						<Typography.Title level={3}>
							Purchase Tickets
						</Typography.Title>
						<Form form={form} onFinish={handleFinish}>
							<ProductHeaderItem />
							<Form.List
								name="products"
								wrapperCol={{ span: 24 }}>
								{() => (
									<>
										{event?.products?.length > 0 &&
											event?.products?.map(
												(product, index) => (
													<ProductFormItem
														key={`product-${index}`}
														list={index}
														product={product}
														editable
														free={!event.paid}
														max={event.maxPurchase}
														organization={
															organization
														}
													/>
												)
											)}
									</>
								)}
							</Form.List>

							<FormItem className="mt-5">
								<Button
									type="primary"
									htmlType="submit"
									className="login-form-button">
									Purchase
								</Button>
							</FormItem>
						</Form>
					</Box>
				)}

			<Box textAlign="center" py={50}>
				<Row gutter={24}>
					<Col xs={24} md={12}>
						{event?.isCompleted ? (
							<>
								<Typography.Title level={3}>
									Reviews
								</Typography.Title>
								{!event.hasReviewed && (
									<Button
										type="primary"
										onClick={() => {
											setCreateReview(true);
										}}>
										Leave Review
									</Button>
								)}
								{reviews?.map((review) => (
									<ReviewItem review={review} />
								))}
							</>
						) : event?.products && event?.products?.length > 0 ? (
							<>
								<Typography.Title level={3}>
									Purchased Tickets
								</Typography.Title>
								{purchases &&
								purchases.length >
									participants.filter(
										(participant) =>
											participant.status !== 'declined'
									).length
									? purchases.map((purchase) => (
											<ProductTicketItem
												product={{
													...purchase.product,
													price: purchase.price,
												}}
												quantity={
													purchase.quantity -
													(participants?.filter(
														(participant) =>
															participant.status !==
																'declined' &&
															participant.product
																._id ===
																purchase.product
																	._id
													)?.length || 0)
												}
												onAssign={(product) => {
													setProduct(product._id);
													setCreateParticipant(true);
												}}
											/>
									  ))
									: 'No tickets purchased'}
							</>
						) : (
							<>
								<Typography.Title level={3}>
									Join or invite someone!
								</Typography.Title>
								<Row gutter={24}>
									<Col flex={1}>
										<Section p={0} hoverable>
											<Button
												style={{ width: '100%' }}
												type="primary">
												Join Event
											</Button>
										</Section>
									</Col>
									<Col flex={1}>
										<Section p={0} hoverable>
											<Button
												style={{ width: '100%' }}
												onClick={() => {
													setCreateParticipant(true);
												}}>
												Invite &nbsp;
												<Tag>{event.maxPurchase}</Tag>
											</Button>
										</Section>
									</Col>
								</Row>
							</>
						)}
					</Col>
					<Col xs={24} md={12}>
						<Typography.Title level={3}>
							Your Participants
						</Typography.Title>
						{participants && participants.length > 0
							? participants.map((participant) => (
									<ParticipantItem
										participant={participant}
									/>
							  ))
							: 'No participants'}
					</Col>
				</Row>
			</Box>
		</>
	);

	if (type === 'modal') {
		return (
			<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
				{content}
			</Modal>
		);
	}

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Events</title>
			</Helmet>
			<CreateParticipantModal
				{...props}
				module={{ code: 'event' }}
				visible={createParticipant}
				product={product}
				onCancel={() => setCreateParticipant(false)}
			/>

			<CreateReviewModal
				{...props}
				module={module}
				visible={createReview}
				onCancel={() => setCreateReview(false)}
			/>

			<UpdateReviewModal
				{...props}
				module={module}
				visible={updateReview}
				onCancel={() => setUpdateReview(false)}
			/>
			<Container>
				<View>
					<Section>{content}</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	event,
	transactions,
	organization,
	participants,
	reviews,
	actions,
}) => {
	return {
		event,
		transactions,
		organization,
		participants,
		reviews,
		actions,
	};
};

export default connect(mapStateToProps, {
	createTransaction,
	fetchTransactionsByModule,
	fetchParticipantsMine,
	fetchReviewsByOrganizationModule,
	clearReviews,
	fetchActionsByOrganizationModule,
	clearActions,
	fetchEvent,
})(EventDetail);
