import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchClasses,
	fetchClassesByOrganization,
	clearClasses,
} from '@resources/Class/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClassItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Class = (props) => {
	const {
		classes,
		history,
		fetchClasses,
		fetchClassesByOrganization,
		clearClasses,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearClasses();
			organization?._id
				? await fetchClassesByOrganization(organization?._id)
				: await fetchClasses();
		};
		fetchData();

		return () => clearClasses();
	}, [clearClasses, fetchClasses, fetchClassesByOrganization]);

	const handleClick = (classy) => {
		history.push(
			`/${classy.organization.alias}/classes/${classy._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.class.management',
						defaultMessage: 'Class Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.classes"
									defaultMessage="Classes"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							{organization.access.includes('class') && (
								<Button
									type="primary"
									onClick={() => setCreate(true)}>
									<FormattedMessage
										id="common.new.class"
										defaultMessage="New Class"
									/>
								</Button>
							)}
						</Col>
					</Row>
					<Box className="mb-5">
						{classes?.map((classy, index) => (
							<ClassItem
								key={`classy-${index}`}
								classy={classy}
								onClick={handleClick}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, classes }) => {
	return {
		organization,
		classes,
	};
};

export default connect(mapStateToProps, {
	fetchClasses,
	fetchClassesByOrganization,
	clearClasses,
})(Class);
