import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchAccounts,
	fetchAccountsByOrganization,
	clearAccounts,
} from '@resources/Account/actions';
import isEmpty from 'lodash.isempty';

const FormItem = Form.Item;
const { Option } = Select;

const AccountSelectForm = ({
	accounts,
	organization,
	fetchAccounts,
	fetchAccountsByOrganization,
	clearAccounts,
	formItemProps,
}) => {
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await clearAccounts();
			organization?._id
				? await fetchAccountsByOrganization(organization?._id, {
						status: 'completed',
				  })
				: await fetchAccounts();
		};
		organization && fetchData();
	}, [organization]);

	const renderItemOptions = useCallback(() => {
		if (!isEmpty(accounts) && Array.isArray(accounts)) {
			return accounts.map((account, index) => {
				return (
					<Option
						value={account._id}
						key={`account-${index}`}
						title={account?.title}>
						{account?.title}
					</Option>
				);
			});
		}

		return null;
	}, [accounts]);

	return (
		<FormItem
			label={`Account`}
			name={'account'}
			rules={[
				{
					required: true,
					message: intl.formatMessage({
						id: 'form.validation.account.required',
						defaultMessage: 'Please input account',
					}),
				},
			]}
			{...formItemProps}>
			<Select
				style={{ width: 180 }}
				placeholder="Please select account"
				optionFilterProp="title"
				filterOption={(input, option) =>
					option.props.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}>
				{renderItemOptions()}
			</Select>
		</FormItem>
	);
};

const mapStateToProps = ({ organization, accounts }) => {
	return {
		organization,
		accounts,
	};
};

export default connect(mapStateToProps, {
	fetchAccounts,
	fetchAccountsByOrganization,
	clearAccounts,
})(AccountSelectForm);
