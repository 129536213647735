import React from 'react';
import { Box } from '@common/components';
import { Divider, Row, Col } from 'antd';
import {
	ClockCircleFilled,
	CheckCircleFilled,
	CloseCircleFilled,
} from '@ant-design/icons';

const Crew = ({ crew }) => {
	const user = crew?.member?.user;
	return (
		<Box textAlign="left">
			<Row type="flex" gutter={24} className="login-links" align="middle">
				<Col md={20}>
					{user?.firstName} {user?.lastName}
				</Col>
				<Col md={4} textAlign="right">
					{crew?.status === 'invited' ? (
						<ClockCircleFilled style={{ color: 'orange' }} />
					) : crew?.status === 'accepted' ? (
						<CheckCircleFilled style={{ color: 'green' }} />
					) : crew?.status === 'rejected' ? (
						<CloseCircleFilled style={{ color: 'red' }} />
					) : null}
				</Col>
			</Row>
			<Divider />
		</Box>
	);
};

export default Crew;
