import { API_ROOT } from '@config/api';
import {
	SET_STOCKS,
	SET_STOCK,
	ADD_STOCK,
	UPDATE_STOCK,
	CLEAR_STOCK,
	CLEAR_STOCKS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchStocks = () => (dispatch) =>
	request('get', `${API_ROOT}/stocks`)
		.then((response) => {
			dispatch({
				type: SET_STOCKS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchStocksByOrganization = (id, filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/stocks/organization/${id}?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_STOCKS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchStocksByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/stocks/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_STOCKS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchStock = (id) => (dispatch) =>
	request('get', `${API_ROOT}/stocks/${id}`)
		.then((response) => {
			dispatch({
				type: SET_STOCK,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createStock = (data) => (dispatch) =>
	request('post', `${API_ROOT}/stocks`, data)
		.then((response) => {
			dispatch({
				type: ADD_STOCK,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateStock = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/stocks/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_STOCK,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearStock = () => (dispatch) =>
	dispatch({
		type: CLEAR_STOCK,
	});

const clearStocks = () => (dispatch) =>
	dispatch({
		type: CLEAR_STOCKS,
	});

export {
	fetchStocks,
	fetchStocksByOrganization,
	fetchStocksByOrganizationModule,
	fetchStock,
	createStock,
	updateStock,
	clearStock,
	clearStocks,
};
