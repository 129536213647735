import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
	fetchGroups,
	fetchGroupsByOrganization,
} from '@resources/Group/actions';
import { formErrorHandler } from '@common/utils/form';
const FormItem = Form.Item;
const Option = Select.Option;

const CellGroupForm = ({
	data,
	groups,
	onSubmit,
	organization,
	onCancel,
	fetchGroups,
	fetchGroupsByOrganization,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			organization._id
				? await fetchGroupsByOrganization(organization._id)
				: await fetchGroups();
		};

		fetchData();
	}, [organization]);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}
			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	const renderGroupOptions = useCallback(() => {
		return groups?.map((group, index) => {
			return (
				<Option
					value={group._id}
					key={`group-${index}`}
					title={group.name}>
					{group.name}
				</Option>
			);
		});
	}, [groups]);

	return (
		<Form
			form={form}
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			<FormItem
				label={`Group`}
				name="group"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.group.required',
							defaultMessage: 'Please select group',
						}),
					},
				]}>
				<Select
					placeholder="Please select group"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option?.props?.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderGroupOptions()}
				</Select>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization, groups }) => {
	return {
		auth,
		organization,
		groups,
	};
};

export default connect(mapStateToProps, {
	fetchGroups,
	fetchGroupsByOrganization,
})(CellGroupForm);
