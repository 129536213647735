import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, View } from '@common/components';
import { Affix, Anchor, Col, Layout, Row } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchContentsByOrganizationModule,
	clearContents,
} from '@resources/Content/actions';
import PageContentItem from '@containers/Content/Item';

const { Content } = Layout;
const { Link } = Anchor;

const ClassDetail = ({
	contents,
	organization,
	classy,
	fetchContentsByOrganizationModule,
	clearContents,
}) => {
	useEffect(() => {
		const fetchData = async () => {
			await clearContents();

			organization?._id &&
				classy?._id &&
				(await fetchContentsByOrganizationModule(
					organization?._id,
					'class',
					classy._id
				));
		};
		organization?._id && classy?._id && fetchData();
	}, [
		clearContents,
		fetchContentsByOrganizationModule,
		organization,
		classy,
	]);

	const handleClick = (e) => {
		e.preventDefault();
	};

	return (
		<Content>
			<Helmet>
				<title>Foreward Network | Classes</title>
			</Helmet>
			<View>
				<Container>
					<Row gutter={24}>
						<Col md={4}>
							<Affix offsetTop={50}>
								<Anchor
									affix={false}
									targetOffset={50}
									onClick={handleClick}>
									{contents.map((content) => (
										<Link
											key={`link-${content._id}`}
											href={`#${content._id}`}
											title={content.subtitle}
										/>
									))}
								</Anchor>
							</Affix>
						</Col>
						<Col md={20}>
							{contents.map((content) => (
								<PageContentItem
									key={`content-${content._id}`}
									id={content._id}
									data={content}
								/>
							))}
						</Col>
					</Row>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({ classy, organization, contents }) => {
	return { classy, organization, contents };
};

export default connect(mapStateToProps, {
	fetchContentsByOrganizationModule,
	clearContents,
})(ClassDetail);
