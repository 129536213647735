import { API_ROOT } from '@config/api';
import {
	SET_USERS,
	SET_USER,
	ADD_USER,
	UPDATE_USER,
	REMOVE_USER,
	CLEAR_USER,
	CLEAR_USERS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchUsersByName = (keyword) => (dispatch) =>
	request('get', `${API_ROOT}/users${keyword ? `?keyword=${keyword}` : ''}`)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const fetchUsersMembers = () => (dispatch) =>
	request('get', `${API_ROOT}/users/members`)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const searchUsersMembers = (term) => (dispatch) =>
	request('post', `${API_ROOT}/users/members/search`, { term })
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const fetchUsersByRole = (roles) => (dispatch) =>
	request('post', `${API_ROOT}/users/roles`, roles)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const fetchUsers = () => (dispatch) =>
	request('get', `${API_ROOT}/users`)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchUser = (id) => (dispatch) =>
	request('get', `${API_ROOT}/users/${id}`)
		.then((response) => {
			dispatch({
				type: SET_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCommittee = (user) => (dispatch) =>
	request('get', `${API_ROOT}/users/committee`)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchLeaders = (user) => (dispatch) =>
	request('get', `${API_ROOT}/users/leaders`)
		.then((response) => {
			dispatch({
				type: SET_USERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createUser = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users`, data)
		.then((response) => {
			dispatch({
				type: ADD_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const inviteUser = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users/invite`, data)
		.then((response) => {
			dispatch({
				type: ADD_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateUser = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/users/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const deleteUser = (id, data) => (dispatch) =>
	request('delete', `${API_ROOT}/users/${id}`, data)
		.then((response) => {
			dispatch({
				type: REMOVE_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const updateUserInformation = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/users/${id}/update-info`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_USER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const forgotPassword = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users/forgot-password`, data)
		.then((response) => Promise.resolve(response))
		.catch((err) => Promise.reject(err));

const resetPassword = (data) => (dispatch) =>
	request('post', `${API_ROOT}/users/reset-password`, data)
		.then((response) => Promise.resolve(response))
		.catch((err) => Promise.reject(err));

const clearUser = () => (dispatch) =>
	dispatch({
		type: CLEAR_USER,
	});

const clearUsers = () => (dispatch) =>
	dispatch({
		type: CLEAR_USERS,
	});

export {
	fetchUsersByName,
	fetchUsersByRole,
	fetchUsersMembers,
	searchUsersMembers,
	fetchUsers,
	fetchUser,
	fetchLeaders,
	fetchCommittee,
	createUser,
	inviteUser,
	updateUserInformation,
	forgotPassword,
	resetPassword,
	updateUser,
	deleteUser,
	clearUser,
	clearUsers,
};
