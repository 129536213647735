import { API_ROOT } from '@config/api';
import {
	SET_TASKS,
	SET_TASK,
	ADD_TASK,
	UPDATE_TASK,
	CLEAR_TASK,
	CLEAR_TASKS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchTasks = () => (dispatch) =>
	request('get', `${API_ROOT}/tasks`)
		.then((response) => {
			dispatch({
				type: SET_TASKS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTasksByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/tasks/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_TASKS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTask = (id) => (dispatch) =>
	request('get', `${API_ROOT}/tasks/${id}`)
		.then((response) => {
			dispatch({
				type: SET_TASK,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createTask = (data) => (dispatch) =>
	request('post', `${API_ROOT}/tasks`, data)
		.then((response) => {
			dispatch({
				type: ADD_TASK,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateTask = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/tasks/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TASK,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const reorderTasks = (data) => (dispatch) =>
	request('post', `${API_ROOT}/tasks/reorder`, data)
		.then((response) => {
			dispatch({
				type: SET_TASKS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const clearTasks = () => (dispatch) =>
	dispatch({
		type: CLEAR_TASKS,
	});

const clearTask = () => (dispatch) =>
	dispatch({
		type: CLEAR_TASK,
	});

export {
	fetchTasks,
	fetchTasksByOrganizationModule,
	fetchTask,
	createTask,
	updateTask,
	reorderTasks,
	clearTasks,
	clearTask,
};
