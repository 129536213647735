import React from 'react';
import { connect } from 'react-redux';
import { View } from '@common/components';

import { fetchParticipantsMine } from '@resources/Participant/actions';
import {
	createTransaction,
	fetchTransactionsByModule,
} from '@common/resources/Transaction/actions';

const FacilityDetail = (props) => {
	return <View></View>;
};

const mapStateToProps = ({
	event,
	transactions,
	organization,
	participants,
}) => {
	return { event, transactions, organization, participants };
};

export default connect(mapStateToProps, {
	createTransaction,
	fetchTransactionsByModule,
	fetchParticipantsMine,
})(FacilityDetail);
