import {
	SET_STOCKS,
	SET_STOCK,
	CLEAR_STOCKS,
	CLEAR_STOCK,
	ADD_STOCK,
	UPDATE_STOCK,
	REMOVE_STOCK,
} from './actionTypes';

export const stock = (state = {}, action) => {
	switch (action.type) {
		case SET_STOCK:
			return action.payload;
		case UPDATE_STOCK:
			if (state?._id?.toString() !== action?.payload?._id?.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_STOCK:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_STOCKS:
			return action.payload;
		case ADD_STOCK:
			return [...state, action.payload];
		case UPDATE_STOCK:
			return state.map((item) => {
				if (item?._id !== action?.payload?._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_STOCK:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_STOCKS:
			return [];
		default:
			return state;
	}
};
