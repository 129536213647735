import React, { useEffect, useState, useCallback } from 'react';
import { Button } from '@common/components';
import { ChurchPreferenceForm } from '@fragments/Preference';
import { fetchCurrencies } from '@resources/Currency/actions';
import {
	fetchPreferenceByOrganization,
	updatePreference,
} from '@resources/Preference/actions';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { formErrorHandler } from '@common/utils/form';
import isEmpty from 'lodash.isempty';

const formItemLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 14 },
};

const FormItem = Form.Item;
const Option = Select.Option;

const OrganizationPreference = ({
	currencies,
	organization,
	preference,
	fetchPreferenceByOrganization,
	updatePreference,
	fetchCurrencies,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const apps = organization?.apps?.map((app) => app.alias) || [];
	useEffect(() => {
		const fetchData = async () => {
			await fetchPreferenceByOrganization(organization?._id);
			await fetchCurrencies();
		};

		fetchData();
	}, [organization]);

	const handleFinish = (values) => {
		setLoading(true);
		values['organization'] = organization?._id;
		return updatePreference(preference._id, values)
			.then((response) => setLoading(false))
			.catch((err) => {
				setLoading(false);
				formErrorHandler(err, values, form);
			});
	};

	const renderCurrencyOptions = useCallback(() => {
		if (currencies) {
			return currencies.map((currency, index) => {
				return (
					<Option
						value={currency._id}
						key={`currency-${index}`}
						title={currency.name}>
						{currency.name} [{currency.symbol}]
					</Option>
				);
			});
		}
	}, [currencies]);

	return (
		!isEmpty(preference) && (
			<Form
				name="position-form"
				{...formItemLayout}
				onFinish={handleFinish}
				initialValues={{
					...preference,
					currency: preference?.currency?._id,
					church: {
						...preference?.church,
						titheAccount: preference?.church?.titheAccount?._id,
					},
				}}>
				<FormItem
					label={intl.formatMessage({
						id: 'common.currency',
						defaultMessage: 'Currency',
					})}
					name="currency"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: 'form.validation.currency.required',
								defaultMessage: 'Please input currency',
							}),
						},
					]}>
					<Select
						showSearch
						placeholder={intl.formatMessage({
							id: 'form.placeholder.currency',
							defaultMessage: 'Please select a currency',
						})}
						style={{ width: '100%' }}
						optionFilterProp="title"
						filterOption={(input, option) => {
							return (
								option.props.title
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							);
						}}>
						{renderCurrencyOptions()}
					</Select>
				</FormItem>

				{apps.includes('church') && (
					<ChurchPreferenceForm organizationId={organization?._id} />
				)}

				<FormItem wrapperCol={{ span: 12, offset: 6 }} className="mt-5">
					<Button
						loading={loading}
						type="primary"
						htmlType="submit"
						className="login-form-button">
						{intl.formatMessage({
							id: 'common.submit',
							defaultMessage: 'Submit',
						})}
					</Button>
				</FormItem>
			</Form>
		)
	);
};

const mapStateToProps = ({ auth, organization, preference, currencies }) => {
	return {
		auth,
		organization,
		preference,
		currencies,
	};
};

export default connect(mapStateToProps, {
	fetchPreferenceByOrganization,
	updatePreference,
	fetchCurrencies,
})(OrganizationPreference);
