import { API_ROOT } from '@config/api';
import {
	SET_CUSTOMERS,
	SET_CUSTOMER,
	ADD_CUSTOMER,
	UPDATE_CUSTOMER,
	CLEAR_CUSTOMER,
	CLEAR_CUSTOMERS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchCustomers = () => (dispatch) =>
	request('get', `${API_ROOT}/customers`)
		.then((response) => {
			dispatch({
				type: SET_CUSTOMERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCustomersByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/customers/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CUSTOMERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCustomer = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/customers/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CUSTOMER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createCustomer = (data) => (dispatch) =>
	request('post', `${API_ROOT}/customers`, data)
		.then((response) => {
			dispatch({
				type: ADD_CUSTOMER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateCustomer = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/customers/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_CUSTOMER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearCustomer = () => (dispatch) =>
	dispatch({
		type: CLEAR_CUSTOMER,
	});

const clearCustomers = () => (dispatch) =>
	dispatch({
		type: CLEAR_CUSTOMERS,
	});

export {
	fetchCustomers,
	fetchCustomersByOrganization,
	fetchCustomer,
	createCustomer,
	updateCustomer,
	clearCustomer,
	clearCustomers,
};
