import React, { useCallback, useState } from 'react';
import { Form, Button, Checkbox, Select, Input } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const MenuForm = (props) => {
	const { modules, data, onSubmit, onCancel, organization, type } = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			onSubmit(
				data
					? {
							menus: {
								[type]: [
									...organization.menus[type].map((menu) => {
										if (data._id === menu._id) {
											return {
												...values,
												modules:
													values.type === 'menu'
														? [values.modules]
														: values.modules,
											};
										}
										return menu;
									}),
								],
							},
					  }
					: {
							menus: {
								[type]: [
									...organization.menus[type],
									{
										...values,
										modules:
											values.type === 'menu'
												? [values.modules]
												: values.modules,
									},
								],
							},
					  }
			)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	const renderModulesOptions = useCallback(() => {
		if (!isEmpty(modules)) {
			return modules.map((module, index) => {
				return (
					<Option
						value={module._id}
						key={`module-${index}`}
						title={module.title}>
						{module.title}
					</Option>
				);
			});
		}
	}, [modules]);

	return (
		<Form
			form={form}
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				modules: data?.modules?.map((module) => module._id),
			}}>
			<FormItem
				label={`Type`}
				name="type"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.type.required',
							defaultMessage: 'Please input type',
						}),
					},
				]}>
				<Select
					placeholder="Please select type"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="menu" title="menu">
						Menu
					</Option>
					<Option value="submenu" title="submenu">
						Sub Menu
					</Option>
				</Select>
			</FormItem>
			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.type !== currentValues.type
				}>
				{({ getFieldValue }) => {
					return getFieldValue('type') === 'submenu' ? (
						<FormItem
							label={intl.formatMessage({
								id: 'common.subtitle',
								defaultMessage: 'Subtitle',
							})}
							name="subtitle"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.subtitle.required',
										defaultMessage:
											'Please input sub title',
									}),
								},
							]}>
							<Input
								placeholder={intl.formatMessage({
									id: 'common.subtitle',
									defaultMessage: 'Subtitle',
								})}
							/>
						</FormItem>
					) : null;
				}}
			</Form.Item>
			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.type !== currentValues.type
				}>
				{({ getFieldValue }) => {
					return (
						<FormItem
							label={`Modules`}
							name="modules"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.modules.required',
										defaultMessage: 'Please input modules',
									}),
								},
							]}>
							<Select
								mode={
									getFieldValue('type') === 'submenu'
										? 'multiple'
										: null
								}
								placeholder="Please select modules"
								style={{ width: '100%' }}
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderModulesOptions()}
							</Select>
						</FormItem>
					);
				}}
			</Form.Item>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, members, organization, modules }) => {
	return {
		auth,
		members,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {})(MenuForm);
