import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchMembers,
	fetchMembersByOrganization,
	clearMembers,
} from '@resources/Member/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { MemberItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Member = (props) => {
	const {
		members,
		fetchMembers,
		fetchMembersByOrganization,
		clearMembers,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearMembers();

			organization?._id
				? await fetchMembersByOrganization(organization?._id)
				: await fetchMembers();
		};

		func();
	}, [fetchMembers, fetchMembersByOrganization, clearMembers, organization]);

	const parseDataSource = useCallback(() => {
		return members.map((member) => {
			return {
				key: member._id,
				...member,
			};
		});
	}, [members]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.member.management',
						defaultMessage: 'Member Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.members"
									defaultMessage="Members"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.member"
									defaultMessage="New Member"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((member, index) => (
							<MemberItem
								key={`member-${index}`}
								member={member}
								onEdit={() => setUpdate(member._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	members,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		members,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchMembers,
	fetchMembersByOrganization,
	clearMembers,
})(Member);
