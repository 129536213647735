import React from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import BookingForm from '../Form';
import { createBooking } from '@resources/Booking/actions';
import { useIntl } from 'react-intl';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Create = (props) => {
	const { createBooking, visible, onCancel, module } = props;
	const intl = useIntl();

	const handleSubmit = (data) => {
		return createBooking(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.booking.create',
						defaultMessage:
							'A new Booking has been successfully created',
					})
				);
				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.booking',
					defaultMessage: 'Create Booking',
				})}
			</Title>
			<BookingForm onSubmit={handleSubmit} {...props} module={module} />
		</Modal>
	);
};

const mapStateToProps = ({
	bookings,
	modules,
	event,
	facility,
	ministry,
	cellGroup,
	organization,
}) => {
	return {
		bookings,
		ministry,
		facility,
		modules,
		event,
		cellGroup,
		organization,
	};
};

export default connect(mapStateToProps, {
	createBooking,
	fetchModulesBySubmoduleCode,
})(Create);
