import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProject } from '@resources/Project/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Container } from '@common/components';
import { Menu, PageHeader } from 'antd';
import { MailOutlined } from '@ant-design/icons';

const ProjectInfo = ({
	project,
	submodules,
	fetchProject,
	fetchSubmodulesByModuleCode,
	history,
	match,
	organization,
}) => {
	const { projectId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchProject(projectId);
			await fetchSubmodulesByModuleCode('project');
		};

		fetchData();
	}, [fetchProject]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/details/projects/${projectId}/details`
					: `/details/projects/${projectId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/details/projects/${projectId}/details/${e.key}`
					: `/details/projects/${projectId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<PageHeader
				className="site-page-header"
				title={project.title}
				subTitle="Project"
			/>
			<Menu
				onClick={handleClick}
				mode="horizontal"
				selectedKeys={[current]}>
				<Menu.Item key="details" icon={<MailOutlined />}>
					Details
				</Menu.Item>
				{submodules &&
					submodules.map((submodule) => (
						<Menu.Item key={submodule.url} icon={<MailOutlined />}>
							{submodule.title}
						</Menu.Item>
					))}
			</Menu>
		</Container>
	);
};

const mapStateToProps = ({ project, submodules, organization }) => {
	return {
		project,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchProject,
	fetchSubmodulesByModuleCode,
})(ProjectInfo);
