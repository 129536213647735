import React from 'react';
import { connect } from 'react-redux';
import { Section, Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import AccountForm from '../Form';
import { createAccount } from '@resources/Account/actions';
import { authorizeStripe } from '@common/resources/Stripe/actions';
import { useIntl } from 'react-intl';
import { APP_ROOT } from '@config/app';

const Create = (props) => {
	const {
		authorizeStripe,
		createAccount,
		visible,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();

	const handleSubmit = (data) => {
		return createAccount(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.account.create',
						defaultMessage:
							'A new Account has been successfully created',
					})
				);

				if (data.type === 'credit-card') {
					const authorize = async () => {
						const authorization = await authorizeStripe(
							'account',
							response.data.data._id,
							{
								organization: organization?._id,
								type: organization?._id && 'organization',
								redirect_uri: `${APP_ROOT}/${organization?.alias}/accounts/token`,
							}
						);
						window.location.href = authorization.data.data;
					};

					authorize();
				}

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.account',
					defaultMessage: 'Create Account',
				})}
			</Title>
			<Section className="mb-5">
				<AccountForm onSubmit={handleSubmit} {...props} />
			</Section>
		</Modal>
	);
};

const mapStateToProps = ({
	items,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		items,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	authorizeStripe,
	createAccount,
})(Create);
