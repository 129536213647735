import React, { useCallback, useState, useEffect } from 'react';
import {
	Form,
	Icon,
	Input,
	InputNumber,
	Button,
	Checkbox,
	Upload,
	Select,
} from 'antd';
import { useIntl } from 'react-intl';
import { API_MEDIA } from '@common/config/api';
import { connect } from 'react-redux';
import { deleteMedia } from '@resources/Media/actions';
import { fetchMembersByOrganization } from '@resources/Member/actions';
import { fetchCurrencies, clearCurrencies } from '@resources/Currency/actions';
import { getCookie } from '@common/utils/cookie';
import ImgCrop from 'antd-img-crop';
import { GroupSelectForm } from '@fragments';

const FormItem = Form.Item;
const Option = Select.Option;

const ProductForm = (props) => {
	const {
		currencies,
		module,
		data,
		onSubmit,
		fetchCurrencies,
		fetchMembersByOrganization,
		clearCurrencies,
		match,
		onCancel,
		deleteMedia,
		organization,
	} = props;
	const intl = useIntl();
	const authToken = getCookie('auth');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMembersByOrganization(organization?._id);
		};
		fetchData();
	}, [organization]);

	const [thumbnail, setLogos] = useState(
		data && data.thumbnail
			? [
					{
						uid: 'thumbnail',
						name: data.thumbnail.originalName,
						url: data.thumbnail.path,
						response: { data: [data.thumbnail] },
					},
			  ]
			: []
	);

	const [images, setImages] = useState(
		data && data.images
			? data.images.map((image, index) => {
					return {
						uid: `images-${index}`,
						name: image.originalName,
						url: image.path,
						response: { data: [image] },
					};
			  })
			: []
	);

	useEffect(() => {
		const fetchData = async () => {
			await clearCurrencies();
			await fetchCurrencies();
		};
		fetchData();
	}, []);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			const convertedValues = { ...values };
			convertedValues['thumbnail'] =
				thumbnail &&
				thumbnail.length > 0 &&
				thumbnail[0].response &&
				thumbnail[0].response.data &&
				thumbnail[0].response.data.length > 0
					? thumbnail[0].response.data[0]._id
					: undefined;

			convertedValues['images'] =
				images && images.length > 0
					? images
							.filter(
								(image) =>
									image.response &&
									image.response.data &&
									image.response.data.length > 0
							)
							.map((image) => {
								return image.response &&
									image.response.data &&
									image.response.data.length > 0
									? image.response.data[0]._id
									: undefined;
							})
					: undefined;

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			if (module) {
				convertedValues['module'] = module._id;
				convertedValues[module.code] = match?.params[`moduleId`];
			}

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => {});
		},
		[onSubmit, thumbnail, images, organization]
	);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = (set) => ({ fileList: newFileList }) => {
		set(newFileList);
	};

	const handleRemove = useCallback(
		(set, sources) => (file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					set(
						sources.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia]
	);

	const renderCurrencyOptions = useCallback(() => {
		if (currencies) {
			return currencies.map((currency, index) => {
				return (
					<Option
						value={currency._id}
						key={`currency-${index}`}
						title={currency.name}>
						{currency.name} [{currency.symbol}]
					</Option>
				);
			});
		}
	}, [currencies]);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={
				data && {
					...data,
					currency: data?.currency?._id,
				}
			}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.name',
					defaultMessage: 'Name',
				})}
				name="name"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.name.required',
							defaultMessage: 'Please input name',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.name',
						defaultMessage: 'Name',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.currency',
					defaultMessage: 'Currency',
				})}
				name="currency"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.currency.required',
							defaultMessage: 'Please input currency',
						}),
					},
				]}>
				<Select
					showSearch
					placeholder={intl.formatMessage({
						id: 'form.placeholder.currency',
						defaultMessage: 'Please select a currency',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) => {
						return (
							option.props.title
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						);
					}}>
					{renderCurrencyOptions()}
				</Select>
			</FormItem>

			<Form.List name="price" wrapperCol={{ span: 24 }}>
				{() => (
					<>
						<FormItem
							label={intl.formatMessage({
								id: 'common.basicPrice',
								defaultMessage: 'Basic Price',
							})}
							name="basic"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id:
											'form.validation.basicPrice.required',
										defaultMessage:
											'Please input basic price',
									}),
								},
							]}>
							<InputNumber
								placeholder={intl.formatMessage({
									id: 'common.basicPrice',
									defaultMessage: 'Basic Price',
								})}
							/>
						</FormItem>

						<FormItem
							label={intl.formatMessage({
								id: 'common.memberPrice',
								defaultMessage: 'Member Price',
							})}
							name="member"
							rules={[]}>
							<InputNumber
								placeholder={intl.formatMessage({
									id: 'common.memberPrice',
									defaultMessage: 'Member Price',
								})}
							/>
						</FormItem>
					</>
				)}
			</Form.List>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<GroupSelectForm
				{...props}
				formItemProps={{ required: false, rules: [] }}
			/>

			<FormItem
				label={intl.formatMessage({
					id: 'common.thumbnail',
					defaultMessage: 'Thumbnail',
				})}
				name="thumbnail">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/product`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={thumbnail}
						onPreview={handlePreview}
						onChange={handleChange(setLogos)}
						onRemove={handleRemove(setLogos, thumbnail)}>
						{thumbnail.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.images',
					defaultMessage: 'Images',
				})}
				name="images">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/product`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={images}
						onPreview={handlePreview}
						onChange={handleChange(setImages)}
						onRemove={handleRemove(setImages, images)}>
						{images.length > 4 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem name="isPublic" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isPublic',
						defaultMessage: 'Is Public',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, currencies, members, organization }) => {
	return {
		auth,
		currencies,
		members,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchCurrencies,

	fetchMembersByOrganization,
	clearCurrencies,
	deleteMedia,
})(ProductForm);
