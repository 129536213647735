import React, { useState } from 'react';
import { Box, Image } from '@common/components';
import {
	Form,
	Divider,
	Row,
	Col,
	Input,
	InputNumber,
	Typography,
	Space,
} from 'antd';

const FormItem = Form.Item;

const Product = ({
	product,
	organization,
	free,
	list,
	onClick,
	max,
	editable,
}) => {
	const [quantity, setQuantity] = useState([]);
	return (
		<Box textAlign="left">
			{editable && (
				<FormItem
					style={{ marginBottom: 0, height: 0 }}
					name={[list, 'product']}
					initialValue={product._id}>
					<Input type="hidden" />
				</FormItem>
			)}
			<Row type="flex" gutter={24} className="login-links" align="middle">
				<Col xs={8} md={2} onClick={() => onClick && onClick(product)}>
					{product?.thumbnail?.path && (
						<Image src={product?.thumbnail?.path} width="100%" />
					)}
				</Col>
				<Col xs={16} md={11}>
					<Space direction="vertical">
						<Typography.Text strong>{product.name}</Typography.Text>
						<Typography.Text>{product.description}</Typography.Text>
					</Space>
				</Col>
				<Col xs={8} md={4}>
					{product?.currency?.symbol}{' '}
					{free
						? 0
						: organization?.isMember
						? product?.price?.member && product?.price?.basic
							? product?.price?.member
							: product?.price
						: product?.price?.basic
						? product?.price?.basic
						: product?.price}
				</Col>
				<Col xs={8} sm={24} md={3}>
					{editable ? (
						<FormItem
							name={[list, 'quantity']}
							initialValue={0}
							style={{ marginBottom: 0 }}>
							<InputNumber
								min={0}
								max={max}
								onChange={(value) => {
									setQuantity(value);
								}}
							/>
						</FormItem>
					) : (
						product.quantity
					)}
				</Col>
				<Col md={4}>
					{product?.currency?.symbol}{' '}
					{free
						? 0
						: editable
						? organization?.isMember
							? product?.price?.member
								? quantity * product?.price?.member
								: product?.price?.basic
								? quantity * product?.price?.basic
								: quantity * product?.price
							: product?.price?.basic
							? quantity * product?.price?.basic
							: quantity * product?.price
						: organization?.isMember
						? product?.price?.member
							? product.quantity * product?.price?.member
							: product.quantity * product?.price
						: product?.price?.basic
						? product.quantity * product?.price?.basic
						: product.quantity * product?.price}
				</Col>
			</Row>
			<Divider />
		</Box>
	);
};

export default Product;
