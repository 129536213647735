import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchDiscussions,
	fetchDiscussionsByOrganizationModule,
	clearDiscussions,
} from '@resources/Discussion/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { DiscussionItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';
import DetailModal from './Detail';

const { Content } = Layout;

const Discussion = (props) => {
	const {
		discussions,
		fetchDiscussions,
		fetchDiscussionsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearDiscussions,
		match,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [detail, setDetail] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('discussion');
			await clearDiscussions();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchDiscussionsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchDiscussions();
		};

		func();
	}, [
		fetchDiscussions,
		fetchDiscussionsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearDiscussions,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return discussions.map((discussion) => {
			return {
				key: discussion._id,
				...discussion,
			};
		});
	}, [discussions]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.discussion.management',
						defaultMessage: 'Discussion Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>

			<DetailModal
				{...props}
				module={module}
				submodule={'discussion'}
				visible={detail}
				onCancel={() => setDetail(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.discussions"
									defaultMessage="Discussions"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.discussion"
									defaultMessage="New Discussion"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((discussion, index) => (
							<DiscussionItem
								key={`discussion-${index}`}
								discussion={discussion}
								onEdit={() => setUpdate(discussion._id)}
								onClick={() => setDetail(discussion._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	discussions,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		discussions,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchDiscussions,
	fetchDiscussionsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearDiscussions,
})(Discussion);
