import React from 'react';
import { connect } from 'react-redux';
import { Section, Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import EntityForm from '../Form';
import { createEntity } from '@resources/Entity/actions';
import { useIntl } from 'react-intl';

const Create = (props) => {
	const { createEntity, visible, onCancel } = props;
	const intl = useIntl();

	const handleSubmit = (data) => {
		return createEntity(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.entity.create',
						defaultMessage:
							'A new Entity has been successfully created',
					})
				);

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.entity',
					defaultMessage: 'Create Entity',
				})}
			</Title>
			<Section className="mb-5">
				<EntityForm onSubmit={handleSubmit} {...props} />
			</Section>
		</Modal>
	);
};

const mapStateToProps = ({
	items,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		items,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	createEntity,
})(Create);
