import { API_ROOT } from '@config/api';
import {
	SET_SPACES,
	SET_SPACE,
	ADD_SPACE,
	UPDATE_SPACE,
	CLEAR_SPACE,
	CLEAR_SPACES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchSpaces = () => (dispatch) =>
	request('get', `${API_ROOT}/spaces`)
		.then((response) => {
			dispatch({
				type: SET_SPACES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSpacesByOrganization = (organization) => (dispatch) =>
	request('get', `${API_ROOT}/spaces/organization/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_SPACES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSpacesByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/spaces/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_SPACES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSpace = (id) => (dispatch) =>
	request('get', `${API_ROOT}/spaces/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SPACE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createSpace = (data) => (dispatch) =>
	request('post', `${API_ROOT}/spaces`, data)
		.then((response) => {
			dispatch({
				type: ADD_SPACE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateSpace = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/spaces/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SPACE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearSpace = () => (dispatch) =>
	dispatch({
		type: CLEAR_SPACE,
	});

const clearSpaces = () => (dispatch) =>
	dispatch({
		type: CLEAR_SPACES,
	});

export {
	fetchSpaces,
	fetchSpacesByOrganization,
	fetchSpacesByOrganizationModule,
	fetchSpace,
	createSpace,
	updateSpace,
	clearSpace,
	clearSpaces,
};
