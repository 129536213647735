import { API_ROOT } from '@config/api';
import {
	SET_ITEM_TYPES,
	SET_ITEM_TYPE,
	ADD_ITEM_TYPE,
	UPDATE_ITEM_TYPE,
} from './actionTypes';
import request from '@common/utils/request';

const fetchItemTypes = (type) => (dispatch) =>
	request('get', `${API_ROOT}/itemTypes`)
		.then((response) => {
			dispatch({
				type: SET_ITEM_TYPES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchItemType = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/itemTypes/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ITEM_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createItemType = (data) => (dispatch) =>
	request('post', `${API_ROOT}/itemTypes`, data)
		.then((response) => {
			dispatch({
				type: ADD_ITEM_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateItemType = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/itemTypes/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ITEM_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

export { fetchItemTypes, fetchItemType, createItemType, updateItemType };
