import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCellGroup, clearCellGroup } from '@resources/CellGroup/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, Title, View } from '@common/components';
import { Menu, Typography } from 'antd';

const CellGroupInfo = ({
	cellGroup,
	submodules,
	fetchCellGroup,
	fetchSubmodulesByModuleCode,
	clearCellGroup,
	history,
	match,
	organization,
}) => {
	const { cellGroupId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchCellGroup(cellGroupId);
			await fetchSubmodulesByModuleCode('cellGroup');
		};

		fetchData();
	}, [fetchCellGroup, fetchSubmodulesByModuleCode, clearCellGroup]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/cellGroups/${cellGroupId}/details`
					: `/cellGroups/${cellGroupId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/cellGroups/${cellGroupId}/details/${e.key}`
					: `/cellGroups/${cellGroupId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<View>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{cellGroup.title}
					</Title>
					<Typography.Title level={2}>Cell Group</Typography.Title>
				</Box>
				{organization.access.includes('cellGroup') && (
					<Menu
						onClick={handleClick}
						mode="horizontal"
						selectedKeys={[current]}>
						<Menu.Item key="details">Details</Menu.Item>
						{submodules &&
							submodules.map((submodule) => (
								<Menu.Item key={submodule.url}>
									{submodule.title}
								</Menu.Item>
							))}
					</Menu>
				)}
			</View>
		</Container>
	);
};

const mapStateToProps = ({ cellGroup, submodules, organization }) => {
	return {
		cellGroup,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchCellGroup,
	fetchSubmodulesByModuleCode,
	clearCellGroup,
})(CellGroupInfo);
