import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Tag, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Account = ({ account, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick(account)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{account.title}
						</Typography.Title>
						<Box>
							{account.isDefault && (
								<Tag color="orange">Default</Tag>
							)}{' '}
							{!account.stripeAccountId && (
								<Tag color="red">Incomplete</Tag>
							)}
						</Box>
					</Col>
				</Row>

				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(account)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(account)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Account;
