import React, { useContext } from 'react';
import { Modal as AntdModal } from 'antd';

import { ThemeContext } from '@common/contexts/ThemeStore';
import './assets/modal.scss';

const Modal = (props) => {
	const { theme } = useContext(ThemeContext);
	return (
		<AntdModal
			wrapClassName="fn-modal"
			maskStyle={{
				backgroundColor: theme?.backgroundColor
					? `rgba(${theme?.backgroundColor?.rgb?.r || 0},${theme
							?.backgroundColor?.rgb?.g || 0},${theme
							?.backgroundColor?.rgb?.b || 0},0.6)`
					: `rgba(0,0,0,0.6)`,
				backdropFilter: 'blur(10px)',
				WebkitBackdropFilter: 'blur(10px)',
			}}
			maskClosable={true}
			footer={false}
			closable={false}
			forceRender={true}
			{...props}
			onCancel={() => {}}
		/>
	);
};

export default Modal;
