import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deleteMedia } from '@resources/Media/actions';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const PageForm = ({ auth, organization, onCancel, data, onSubmit }) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			const convertedValues = { ...values };
			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, convertedValues, form);
				});
		},
		[onSubmit, organization]
	);

	const handleFinishFailed = useCallback(() => {
		setLoading(false);
	}, []);

	return (
		<Form
			name="role-form"
			layout="vertical"
			onFinish={handleFinish}
			onFinishFailed={handleFinishFailed}
			initialValues={
				data && {
					...data,
					apps: data.apps.map((app) => app._id),
					access: data.access && data.access._id,
					account: data.account._id,
				}
			}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.alias',
					defaultMessage: 'Alias',
				})}
				name="alias"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.alias.required',
							defaultMessage: 'Please input alias',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.alias',
						defaultMessage: 'Alias',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.type',
					defaultMessage: 'Type',
				})}
				name="type"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.type.required',
							defaultMessage: 'Please input type',
						}),
					},
				]}>
				<Select
					placeholder="Please select type"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="participant">Participant</Option>
					<Option value="review">Review</Option>
				</Select>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, {
	deleteMedia,
})(PageForm);
