import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import GroupForm from '../Form';
import { fetchGroup, updateGroup, clearGroup } from '@resources/Group/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		fetchGroup,
		group,
		updateGroup,
		clearGroup,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await clearGroup();
			await fetchGroup(visible);
		};
		visible && fetchData();
	}, [fetchGroup, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateGroup(group._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.group.update',
							defaultMessage:
								'An existing Group entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[group]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.group',
					defaultMessage: 'Update Group',
				})}
			</Title>
			{!isEmpty(group) && (
				<GroupForm data={group} onSubmit={handleSubmit} {...props} />
			)}
		</Modal>
	);
};

const mapStateToProps = ({ group }) => {
	return {
		group,
	};
};

export default connect(mapStateToProps, {
	fetchGroup,
	updateGroup,
	clearGroup,
})(Update);
