import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import RentableForm from '../Form';
import {
	fetchRentable,
	updateRentable,
	clearRentable,
} from '@resources/Rentable/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchRentable,
		rentable,
		updateRentable,
		visible,
		clearRentable,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchRentable(visible);
		};
		visible ? fetchData() : clearRentable();
	}, [fetchRentable, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateRentable(rentable._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.rentable.update',
							defaultMessage:
								'An existing Rentable entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[rentable]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.rentable',
					defaultMessage: 'Update Rentable',
				})}
			</Title>
			{!isEmpty(rentable) && (
				<RentableForm
					data={rentable}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	rentable,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		rentable,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchRentable,
	updateRentable,
	fetchModulesBySubmoduleCode,
	clearRentable,
})(Update);
