import React, { useState, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { Divider, Form, Select, Spin } from 'antd';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { searchUsersMembers } from '@resources/User/actions';
import InviteUserModal from '@containers/User/Invite';
import { PlusOutlined } from '@ant-design/icons';
import { ThemeContext } from 'styled-components';
import { Box } from '@common/components';

const FormItem = Form.Item;
const { Option } = Select;

const UserSelectForm = (props) => {
	const { code, id, searchUsersMembers } = props;
	const intl = useIntl();
	const [invite, setInvite] = useState(false);
	const [fetching, setFetching] = useState(null);
	const [users, setUsers] = useState([]);
	const theme = useContext(ThemeContext);

	const renderUserOptions = useCallback(() => {
		if (!isEmpty(users)) {
			return users.map((user, index) => {
				return (
					<Option
						value={user._id}
						key={`user-${index}`}
						title={`${user.firstName} ${user.lastName}`}>
						{user.firstName} {user.lastName}
					</Option>
				);
			});
		}
	}, [users]);

	const handleChange = (value) => {};

	const handleSearch = useCallback(
		(value) => {
			setFetching(true);
			const search = async () => {
				const us = await searchUsersMembers(value, code, id);

				setUsers(us.data.data);
			};

			if (value && code && id) search();
			else setUsers([]);
			setFetching(false);
		},
		[code, id]
	);

	return (
		<>
			<InviteUserModal
				{...props}
				visible={invite}
				onCancel={() => setInvite(false)}
			/>
			<FormItem
				label={`User`}
				name="user"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.user.required',
							defaultMessage: 'Please input user',
						}),
					},
				]}>
				<Select
					showSearch
					placeholder="Please select user"
					style={{ width: '100%' }}
					optionFilterProp="title"
					onSearch={handleSearch}
					onChange={handleChange}
					defaultActiveFirstOption={false}
					showArrow={false}
					filterOption={false}
					notFoundContent={
						fetching ? (
							<Spin size="small" />
						) : (
							<div>
								<span style={{ color: theme.base.text }}>
									Search empty
								</span>
								<Divider style={{ margin: '4px 0' }} />
								<div
									style={{
										display: 'flex',
										flexWrap: 'nowrap',
									}}>
									<Box
										style={{
											flex: 'none',
											padding: '8px',
											display: 'block',
											cursor: 'pointer',
											color: theme.base.primary,
										}}
										onClick={() => setInvite(true)}>
										<PlusOutlined /> Invite User
									</Box>
								</div>
							</div>
						)
					}
					dropdownRender={(menu) => (
						<div>
							{menu}
							<Divider style={{ margin: '4px 0' }} />
							<div
								style={{
									display: 'flex',
									flexWrap: 'nowrap',
									color: theme.base.primary,
								}}>
								<Box
									style={{
										flex: 'none',
										padding: '8px',
										display: 'block',
										cursor: 'pointer',
										color: theme.base.primary,
									}}
									onClick={() => setInvite(true)}>
									<PlusOutlined /> Invite User
								</Box>
							</div>
						</div>
					)}>
					{renderUserOptions()}
				</Select>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization }) => {
	return {
		organization,
	};
};

export default connect(mapStateToProps, { searchUsersMembers })(UserSelectForm);
