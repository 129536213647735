import { API_ROOT } from '@config/api';
import {
	SET_TRANSACTIONS,
	SET_TRANSACTION,
	ADD_TRANSACTION,
	UPDATE_TRANSACTION,
	CLEAR_TRANSACTION,
	CLEAR_TRANSACTIONS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchTransactions = (filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/transactions?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_TRANSACTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTransactionsByModule = (module, id, filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/transactions/module/${module}/${id}?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_TRANSACTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTransactionsByOrganization = (organization, filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/transactions/organization/${organization}?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_TRANSACTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTransaction = (id) => (dispatch) =>
	request('get', `${API_ROOT}/transactions/${id}`)
		.then((response) => {
			dispatch({
				type: SET_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response.data);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createTransaction = (data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions`, data)
		.then((response) => {
			dispatch({
				type: ADD_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response.data);
		})
		.catch((err) => Promise.reject(err));

const createTransactionExpense = (data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions/expense`, data)
		.then((response) => {
			dispatch({
				type: ADD_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response.data);
		})
		.catch((err) => Promise.reject(err));

const createTransactionIncome = (data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions/income`, data)
		.then((response) => {
			dispatch({
				type: ADD_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response.data);
		})
		.catch((err) => Promise.reject(err));

const updateTransaction = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const updateTransactionExpense = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions/expense/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const updateTransactionIncome = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions/income/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const completeTransaction = (data) => (dispatch) =>
	request('post', `${API_ROOT}/transactions/complete`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TRANSACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearTransaction = () => (dispatch) =>
	dispatch({
		type: CLEAR_TRANSACTION,
	});

const clearTransactions = () => (dispatch) =>
	dispatch({
		type: CLEAR_TRANSACTIONS,
	});

export {
	fetchTransactions,
	fetchTransactionsByOrganization,
	fetchTransactionsByModule,
	fetchTransaction,
	createTransaction,
	createTransactionExpense,
	createTransactionIncome,
	updateTransaction,
	updateTransactionExpense,
	updateTransactionIncome,
	completeTransaction,
	clearTransaction,
	clearTransactions,
};
