import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View, Box } from '@common/components';
import { Row, Col, Layout, Statistic, Pagination } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchTransactions,
	fetchTransactionsByOrganization,
	clearTransactions,
} from '@common/resources/Transaction/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { IncomeItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Income = (props) => {
	const {
		transactions,
		fetchTransactions,
		fetchTransactionsByOrganization,
		clearTransactions,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [total, setTotal] = useState({ income: 0, count: 0 });
	const [current, setCurrent] = useState(1);

	useEffect(() => {
		const func = async () => {
			await clearTransactions();

			organization?._id
				? await fetchTransactionsByOrganization(organization?._id, {
						'to.organization': organization?._id,
				  })
				: await fetchTransactions();
		};

		func();
	}, [
		fetchTransactions,
		fetchTransactionsByOrganization,
		clearTransactions,
		organization,
	]);

	useEffect(() => {
		let tot = 0;
		transactions.forEach((transaction) => {
			tot += transaction.total;
		});
		setTotal({ income: tot, count: transactions.length });
	}, [transactions]);

	const parseDataSource = useCallback(() => {
		const t = transactions.map((transaction) => {
			return {
				key: transaction._id,
				...transaction,
			};
		});

		return t;
	}, [transactions]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.income.management',
						defaultMessage: 'Income Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.incomes"
									defaultMessage="Incomes"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.income"
									defaultMessage="New Income"
								/>
							</Button>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col span={12}>
							<Statistic
								title="Total Income"
								value={total.income}
							/>
						</Col>
						<Col span={12}>
							<Statistic
								title="Total Transactions"
								value={total.count}
							/>
						</Col>
					</Row>

					<Box py={20}>
						<Pagination
							current={current}
							pageSize={10}
							total={total.count}
							onChange={(page) => setCurrent(page)}
						/>
					</Box>

					{parseDataSource() &&
						parseDataSource()
							.filter(
								(income, index) =>
									index >= (current - 1) * 10 &&
									index < current * 10
							)
							.map((income, index) => (
								<IncomeItem
									key={`income-${index}`}
									income={income}
									onEdit={() => setUpdate(income._id)}
								/>
							))}
					<Box py={20}>
						<Pagination
							current={current}
							pageSize={10}
							total={total.count}
							onChange={(page) => setCurrent(page)}
						/>
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	transactions,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		transactions,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchTransactions,
	fetchTransactionsByOrganization,
	clearTransactions,
})(Income);
