import {
	SET_PRAYERS,
	SET_PRAYER,
	CLEAR_PRAYERS,
	CLEAR_PRAYER,
	ADD_PRAYER,
	UPDATE_PRAYER,
	REMOVE_PRAYER,
} from './actionTypes';

export const prayer = (state = {}, action) => {
	switch (action.type) {
		case SET_PRAYER:
			return action.payload;
		case UPDATE_PRAYER:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_PRAYER:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_PRAYERS:
			return action.payload;
		case ADD_PRAYER:
			return [...state, action.payload];
		case UPDATE_PRAYER:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_PRAYER:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_PRAYERS:
			return [];
		default:
			return state;
	}
};
