import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ProgrammeForm from '../Form';
import { fetchProgramme, updateProgramme } from '@resources/Programme/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		module,
		fetchProgramme,
		programme,
		updateProgramme,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchProgramme(visible);
		};
		visible && fetchData();
	}, [fetchProgramme, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateProgramme(programme._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.programme.update',
							defaultMessage:
								'An existing Programme entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[programme]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.programme',
					defaultMessage: 'Update Programme',
				})}
			</Title>
			{!isEmpty(programme) && (
				<ProgrammeForm
					data={programme}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	programme,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		programme,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchProgramme,
	updateProgramme,
	fetchModulesBySubmoduleCode,
})(Update);
