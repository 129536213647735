import { API_ROOT } from '@config/api';
import {
	SET_POSITIONS,
	SET_POSITION,
	ADD_POSITION,
	UPDATE_POSITION,
	CLEAR_POSITION,
	CLEAR_POSITIONS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchPositions = () => (dispatch) =>
	request('get', `${API_ROOT}/positions`)
		.then((response) => {
			dispatch({
				type: SET_POSITIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPositionsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/positions/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_POSITIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPosition = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/positions/${id}`)
		.then((response) => {
			dispatch({
				type: SET_POSITION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createPosition = (data) => (dispatch) =>
	request('post', `${API_ROOT}/positions`, data)
		.then((response) => {
			dispatch({
				type: ADD_POSITION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updatePosition = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/positions/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_POSITION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearPosition = () => (dispatch) =>
	dispatch({
		type: CLEAR_POSITION,
	});

const clearPositions = () => (dispatch) =>
	dispatch({
		type: CLEAR_POSITIONS,
	});

export {
	fetchPositions,
	fetchPositionsByOrganization,
	fetchPosition,
	createPosition,
	updatePosition,
	clearPosition,
	clearPositions,
};
