import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import CrewForm from '../Form';
import { fetchCrew, updateCrew, clearCrew } from '@resources/Crew/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchCrew,
		crew,
		updateCrew,
		visible,
		clearCrew,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchCrew(visible);
		};
		visible ? fetchData() : clearCrew();
	}, [fetchCrew, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateCrew(crew._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.crew.update',
							defaultMessage:
								'An existing Crew entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[crew]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.crew',
					defaultMessage: 'Update Crew',
				})}
			</Title>
			{!isEmpty(crew) && (
				<CrewForm
					data={crew}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	crew,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		crew,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchCrew,
	updateCrew,
	fetchModulesBySubmoduleCode,
	clearCrew,
})(Update);
