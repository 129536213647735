export const dataTranslation = (intl, type, id) => {
	const translations = {
		contentType: {
			'content.type.paragraph': intl.formatMessage({
				id: 'content.type.paragraph',
				defaultMessage: 'Paragraph',
			}),
			'content.type.paragraph.description': intl.formatMessage({
				id: 'content.type.paragraph.description',
				defaultMessage: 'Paragraph description',
			}),
		},
		fieldType: {
			'field.type.text': intl.formatMessage({
				id: 'content.type.text',
				defaultMessage: 'Text',
			}),
			'field.type.text.description': intl.formatMessage({
				id: 'content.type.text.description',
				defaultMessage: 'Text description',
			}),

			'field.type.number': intl.formatMessage({
				id: 'content.type.number',
				defaultMessage: 'Number',
			}),
			'field.type.number.description': intl.formatMessage({
				id: 'content.type.number.description',
				defaultMessage: 'Number description',
			}),

			'field.type.date': intl.formatMessage({
				id: 'content.date.number',
				defaultMessage: 'Date',
			}),
			'field.type.date.description': intl.formatMessage({
				id: 'content.type.date.description',
				defaultMessage: 'Date description',
			}),
		},
	};

	return translations[type][id];
};
