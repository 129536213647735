import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox, Select, Upload } from 'antd';
import { Editor } from '@common/components';
import { useIntl } from 'react-intl';
import { API_MEDIA } from '@common/config/api';
import { connect } from 'react-redux';
import { deleteMedia } from '@resources/Media/actions';
import ImgCrop from 'antd-img-crop';
import { PlusOutlined } from '@ant-design/icons';
import { CONTENT_TYPES } from '@common/constants';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};

const tailLayout = {
	wrapperCol: { offset: 6, span: 14 },
};

const ContentParagraphForm = ({
	auth,
	contentTypes,
	data,
	page,
	onSubmit,
	onCancel,
	deleteMedia,
	match,
	organization,
	module,
}) => {
	const intl = useIntl();
	const [loading, setLoading] = useState(false);
	const [uploadImage, setUploadImage] = useState(data ? data.position : null);
	const [images, setImages] = useState(
		data && data.image
			? [
					{
						uid: '-1',
						name: data.image.originalName,
						url: data.image.path,
						response: { data: [data.image] },
					},
			  ]
			: []
	);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			const convertedValues = { ...values };

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			if (module) {
				convertedValues['module'] = module._id;
				convertedValues[module.code] = match?.params[`moduleId`];
			}

			convertedValues['page'] = page._id;
			convertedValues['type'] = contentTypes.filter(
				(contentType) => contentType.alias === CONTENT_TYPES.PARAGRAPH
			)[0]._id;
			convertedValues['image'] =
				images &&
				images.length > 0 &&
				images[0].response &&
				images[0].response.data &&
				images[0].response.data.length > 0
					? images[0].response.data[0]._id
					: undefined;

			onSubmit(convertedValues)
				.then(() => {})
				.catch((err) => {})
				.finally(() => setLoading(false));
		},
		[onSubmit, images, setLoading, organization, module]
	);

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = ({ fileList: newFileList }) => {
		console.log(newFileList);
		setImages(newFileList);
	};

	const handleRemove = useCallback(
		(file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					setImages(
						images.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia, setImages]
	);

	return (
		<Form
			name="paragraph-form"
			{...formItemLayout}
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.subtitle',
					defaultMessage: 'Subtitle',
				})}
				name="subtitle">
				<Input
					placeholder={intl.formatMessage({
						id: 'common.subtitle',
						defaultMessage: 'Subtitle',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.detail',
					defaultMessage: 'Detail',
				})}
				name="detail"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.detail.required',
							defaultMessage: 'Please input detail',
						}),
					},
				]}>
				<Editor />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.image.position',
					defaultMessage: 'Image Position',
				})}
				name="position"
				initialValue={null}>
				<Select
					placeholder={intl.formatMessage({
						id: 'form.placeholder.image.position',
						defaultMessage: 'Please select an image position',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					onChange={(value) => {
						if (value) {
							setUploadImage(true);
						} else {
							setUploadImage(false);
						}
					}}>
					<Option value={null} title="None">
						{intl.formatMessage({
							id: 'common.none',
							defaultMessage: 'None',
						})}
					</Option>
					<Option value={'left'} title="Left">
						{intl.formatMessage({
							id: 'common.left',
							defaultMessage: 'Left',
						})}
					</Option>
					<Option value={'center'} title="Center">
						{intl.formatMessage({
							id: 'common.center',
							defaultMessage: 'Center',
						})}
					</Option>
					<Option value={'right'} title="Right">
						{intl.formatMessage({
							id: 'common.right',
							defaultMessage: 'Right',
						})}
					</Option>
				</Select>
			</FormItem>

			{uploadImage && (
				<FormItem
					label={intl.formatMessage({
						id: 'common.image',
						defaultMessage: 'Image',
					})}
					name="image">
					<ImgCrop rotate>
						<Upload
							action={`${API_MEDIA}/image/content`}
							headers={{ authorization: auth.token }}
							listType="picture-card"
							fileList={images}
							onPreview={handlePreview}
							onChange={handleChange}
							onRemove={handleRemove}>
							{images.length > 0 ? null : uploadButton}
						</Upload>
					</ImgCrop>
				</FormItem>
			)}

			<FormItem {...tailLayout} name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem wrapperCol={{ span: 12, offset: 6 }} className="mt-5">
				<Button type="secondary" onClick={onCancel && onCancel}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, contentTypes, organization }) => {
	return {
		auth,
		contentTypes,
		organization,
	};
};

export default connect(mapStateToProps, { deleteMedia })(ContentParagraphForm);
