import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { updateOrganization } from '@common/resources/Organization/actions';
import { fetchApps } from '@resources/App/actions';
import { Container, Title, View } from '@common/components';
import { Card, Col, Row } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import OrganizationPreference from './Organization';

const Detail = ({
	apps,
	siteTheme,
	organization,
	fetchApps,
	updateOrganization,
}) => {
	useEffect(() => {
		const fetchData = async () => {
			await fetchApps();
		};

		fetchData();
	}, [organization, siteTheme]);

	const handleApp = useCallback(
		(app, state) => {
			if (organization?._id) {
				const orgAppIds = organization.apps.map((ap) => ap._id);
				let updatedOrgAppIds = orgAppIds;
				if (state) {
					orgAppIds.push(app);
					updatedOrgAppIds = orgAppIds;
				} else {
					updatedOrgAppIds = orgAppIds.filter((oa) => oa !== app);
				}

				const update = async () => {
					await updateOrganization(organization._id, {
						apps: updatedOrgAppIds,
					});
				};

				update();
			}
		},
		[organization]
	);

	return (
		<Container>
			<View>
				<Title mt={20}>Preference</Title>
				<Row gutter={24}>
					{apps.map((app, index) => {
						const enabled =
							organization.apps.filter((a) => a._id === app._id)
								.length > 0;
						return (
							<Col lg={8} key={`app-${index}`}>
								<Card
									onClick={() => handleApp(app._id, !enabled)}
									hoverable
									title={
										<span>
											{app.title}{' '}
											{enabled ? (
												<CheckCircleTwoTone twoToneColor="#52c41a" />
											) : (
												''
											)}
										</span>
									}
									description={app.description}
									bordered={true}
									actions={[enabled ? 'Disable' : 'Enable']}
									style={{ marginBottom: 30 }}></Card>
							</Col>
						);
					})}
				</Row>

				{organization?._id && (
					<OrganizationPreference
						organizationId={organization?._id}
					/>
				)}
			</View>
		</Container>
	);
};

const mapStateToProps = ({ apps, organization, siteTheme }) => {
	return {
		apps,
		organization,
		siteTheme,
	};
};

export default connect(mapStateToProps, {
	fetchApps,
	updateOrganization,
})(Detail);
