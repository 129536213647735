import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchItems,
	fetchItemsByOrganization,
	clearItems,
} from '@resources/Item/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ItemItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Item = (props) => {
	const {
		items,
		siteTheme,
		fetchItems,
		fetchItemsByOrganization,
		clearItems,
		organization,
		history,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearItems();

			organization?._id
				? await fetchItemsByOrganization(organization?._id)
				: await fetchItems();
		};

		func();
	}, [
		fetchItems,
		fetchItemsByOrganization,
		clearItems,
		organization,
		siteTheme,
	]);

	const parseDataSource = useCallback(() => {
		return items.map((item) => {
			return {
				key: item._id,
				...item,
			};
		});
	}, [items]);

	const handleClick = (item) => {
		history.push(`/${item.organization.alias}/items/${item._id}/details`);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.item.management',
						defaultMessage: 'Item Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.items"
									defaultMessage="Items"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => {
									setCreate(true);
								}}>
								<FormattedMessage
									id="common.new.item"
									defaultMessage="New Item"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((item, index) => (
							<ItemItem
								key={`item-${index}`}
								item={item}
								onClick={handleClick}
								onEdit={() => setUpdate(item._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	items,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
	siteTheme,
}) => {
	return {
		items,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
		siteTheme,
	};
};

export default connect(mapStateToProps, {
	fetchItems,
	fetchItemsByOrganization,
	clearItems,
})(Item);
