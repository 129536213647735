import { API_ROOT } from '@config/api';
import {
	SET_ADMINS,
	SET_ADMIN,
	ADD_ADMIN,
	UPDATE_ADMIN,
	CLEAR_ADMIN,
	CLEAR_ADMINS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchAdmins = () => (dispatch) =>
	request('get', `${API_ROOT}/admins`)
		.then((response) => {
			dispatch({
				type: SET_ADMINS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAdminsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/admins/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ADMINS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAdmin = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/admins/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ADMIN,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createAdmin = (data) => (dispatch) =>
	request('post', `${API_ROOT}/admins`, data)
		.then((response) => {
			dispatch({
				type: ADD_ADMIN,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateAdmin = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/admins/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ADMIN,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearAdmin = () => (dispatch) =>
	dispatch({
		type: CLEAR_ADMIN,
	});

const clearAdmins = () => (dispatch) =>
	dispatch({
		type: CLEAR_ADMINS,
	});

export {
	fetchAdmins,
	fetchAdminsByOrganization,
	fetchAdmin,
	createAdmin,
	updateAdmin,
	clearAdmin,
	clearAdmins,
};
