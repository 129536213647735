import React, { useContext, useState, useCallback } from 'react';
import {
	background,
	fontFamily,
	textAlign,
	lineHeight,
	fontWeight,
	fontSize,
	letterSpacing,
	color,
	space,
	fontStyle,
	flexWrap,
	width,
} from 'styled-system';
import { Box, Button, Header, Nav } from '../../components';
import { Affix, Drawer } from 'antd';
import './assets/styles.scss';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';
import { getCookie } from '@common/utils/cookie';

const Navbar = ({ headerStyle, logo, leftMenu, rightMenu }) => {
	const authCookie = getCookie('auth');
	const theme = useContext(ThemeContext);
	const [visible, setVisible] = useState(false);

	const showDrawer = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const handleClose = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	const Bars = styled.span`
	${background}
	font-weight: bold;
	background: ${theme.base.primary};
	&:before {
		background: ${theme.base.primary};
	}
	&:after {
		background: ${theme.base.primary};
	}
		
	${fontSize}
	${fontFamily}
	${textAlign}
	${lineHeight}
	${fontWeight}
	${letterSpacing}
	${color}
	${space}
	${fontStyle}
	${flexWrap}
	${width}
`;

	return (
		<Affix>
			<Header px={20} {...headerStyle} className="fn-navbar">
				<Box floatLeft width={150} height={50} p={10}>
					{logo}
				</Box>
				<Nav className="fn-navbar-menu">
					<Box
						floatLeft
						width={[
							`calc(100% - 150px)`,
							`calc(100% - ${authCookie ? '150px' : '220px'})`,
						]}
						display={['none', 'block']}>
						{leftMenu}
					</Box>
					<Box floatRight display={['none', 'block']}>
						{rightMenu}
					</Box>
					<Box floatRight display={['block', 'none']}>
						<Button onClick={showDrawer} border={0}>
							<Bars className="bars-button"></Bars>
						</Button>
					</Box>
					<Drawer
						placement="right"
						closable={false}
						onClose={handleClose}
						visible={visible}>
						{leftMenu}
						{rightMenu}
					</Drawer>
				</Nav>
			</Header>
		</Affix>
	);
};

export default Navbar;
