import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import CellGroupForm from '../Form';
import {
	clearCellGroup,
	fetchCellGroup,
	updateCellGroup,
} from '@resources/CellGroup/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const {
		clearCellGroup,
		fetchCellGroup,
		cellGroup,
		updateCellGroup,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await clearCellGroup();
			await fetchCellGroup(visible);
		};
		visible && fetchData();
	}, [fetchCellGroup, clearCellGroup, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateCellGroup(cellGroup._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.cellGroup.update',
							defaultMessage:
								'An existing Cell Group entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[cellGroup]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.cellGroup',
					defaultMessage: 'Update Cell Group',
				})}
			</Title>
			{!isEmpty(cellGroup) && (
				<CellGroupForm
					data={cellGroup}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ cellGroup }) => {
	return {
		cellGroup,
	};
};

export default connect(mapStateToProps, {
	clearCellGroup,
	fetchCellGroup,
	updateCellGroup,
})(Update);
