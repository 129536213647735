import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox, Upload, Icon } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearTheme } from '@resources/Theme/actions';
import { formErrorHandler } from '@common/utils/form';
import { deleteMedia } from '@resources/Media/actions';
import { getCookie } from '@common/utils/cookie';
import { API_MEDIA } from '@common/config/api';
import ImgCrop from 'antd-img-crop';
import { ChromePicker } from 'react-color';

const FormItem = Form.Item;

const ThemeForm = (props) => {
	const { data, onSubmit, onCancel, organization, deleteMedia } = props;
	const intl = useIntl();
	const authToken = getCookie('auth');
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const [logo, setLogos] = useState(
		data && data.logo
			? [
					{
						uid: 'logo',
						name: data.logo.originalName,
						url: data.logo.path,
						response: { data: [data.logo] },
					},
			  ]
			: []
	);

	const handleFinish = useCallback(
		(values) => {
			// setLoading(true);
			const convertedValues = { ...values };
			convertedValues['logo'] =
				logo &&
				logo.length > 0 &&
				logo[0].response &&
				logo[0].response.data &&
				logo[0].response.data.length > 0
					? logo[0].response.data[0]._id
					: undefined;

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, logo, organization]
	);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = (set) => ({ fileList: newFileList }) => {
		set(newFileList);
	};

	const handleRemove = useCallback(
		(set, sources) => (file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					set(
						sources.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia]
	);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
			}}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.primaryColor',
					defaultMessage: 'Primary Color',
				})}
				valuePropName="color"
				name="primaryColor"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.primaryColor.required',
							defaultMessage: 'Please input primary color',
						}),
					},
				]}>
				<ChromePicker disableAlpha={true} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.backgroundColor',
					defaultMessage: 'Background Color',
				})}
				valuePropName="color"
				name="backgroundColor"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.backgroundColor.required',
							defaultMessage: 'Please input background color',
						}),
					},
				]}>
				<ChromePicker disableAlpha={true} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.componentBackground',
					defaultMessage: 'Component Background',
				})}
				valuePropName="color"
				name="componentBackground"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.componentBackground.required',
							defaultMessage: 'Please input component background',
						}),
					},
				]}>
				<ChromePicker disableAlpha={true} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.textColor',
					defaultMessage: 'Text Color',
				})}
				valuePropName="color"
				name="textColor"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.textColor.required',
							defaultMessage: 'Please input text color',
						}),
					},
				]}>
				<ChromePicker disableAlpha={true} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.shadowColor',
					defaultMessage: 'Shadow Color',
				})}
				valuePropName="color"
				name="shadowColor"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.shadowColor.required',
							defaultMessage: 'Please input shadow color',
						}),
					},
				]}>
				<ChromePicker disableAlpha={true} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.logo',
					defaultMessage: 'Logo',
				})}
				name="logo">
				<ImgCrop rotate aspect={5 / 3}>
					<Upload
						action={`${API_MEDIA}/image/theme`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={logo}
						onPreview={handlePreview}
						onChange={handleChange(setLogos)}
						onRemove={handleRemove(setLogos, logo)}>
						{logo.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, {
	clearTheme,
	deleteMedia,
})(ThemeForm);
