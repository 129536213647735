import { API_ROOT } from '@config/api';
import {
	SET_JOURNALS,
	SET_JOURNAL,
	ADD_JOURNAL,
	UPDATE_JOURNAL,
	CLEAR_JOURNAL,
	CLEAR_JOURNALS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchJournals = () => (dispatch) =>
	request('get', `${API_ROOT}/journals`)
		.then((response) => {
			dispatch({
				type: SET_JOURNALS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchJournalsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/journals/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_JOURNALS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchJournal = (id) => (dispatch) =>
	request('get', `${API_ROOT}/journals/${id}`)
		.then((response) => {
			dispatch({
				type: SET_JOURNAL,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createJournal = (data) => (dispatch) =>
	request('post', `${API_ROOT}/journals`, data)
		.then((response) => {
			dispatch({
				type: ADD_JOURNAL,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateJournal = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/journals/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_JOURNAL,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearJournal = () => (dispatch) =>
	dispatch({
		type: CLEAR_JOURNAL,
	});

const clearJournals = () => (dispatch) =>
	dispatch({
		type: CLEAR_JOURNALS,
	});

export {
	fetchJournals,
	fetchJournalsByOrganizationModule,
	fetchJournal,
	createJournal,
	updateJournal,
	clearJournal,
	clearJournals,
};
