import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchOrganization } from '@common/resources/Organization/actions';
import {
	fetchPositions,
	fetchPositionsByOrganization,
	clearPositions,
} from '@resources/Position/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { PositionItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Position = (props) => {
	const {
		positions,
		fetchPositions,
		fetchPositionsByOrganization,
		clearPositions,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearPositions();

			organization?._id
				? await fetchPositionsByOrganization(organization?._id)
				: await fetchPositions();
		};

		func();
	}, [
		fetchPositions,
		fetchPositionsByOrganization,
		clearPositions,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return positions.map((position) => {
			return {
				key: position._id,
				...position,
			};
		});
	}, [positions]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.position.management',
						defaultMessage: 'Position Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.positions"
									defaultMessage="Positions"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.position"
									defaultMessage="New Position"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((position, index) => (
							<PositionItem
								key={`position-${index}`}
								position={position}
								onEdit={() => setUpdate(position._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	positions,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		positions,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchOrganization,
	fetchPositions,
	fetchPositionsByOrganization,
	clearPositions,
})(Position);
