import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchOrganization } from '@common/resources/Organization/actions';
import { fetchStorage } from '@resources/Storage/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, Title, View } from '@common/components';
import { Menu, Typography } from 'antd';

const StorageInfo = ({
	storage,
	submodules,
	organization,
	fetchStorage,
	fetchSubmodulesByModuleCode,
	history,
	match,
}) => {
	const { storageId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchStorage(storageId);
			await fetchSubmodulesByModuleCode('storage');
		};

		fetchData();
	}, [fetchStorage]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/storages/${storageId}/details`
					: `/storages/${storageId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/storages/${storageId}/details/${e.key}`
					: `/storages/${storageId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<View>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{storage.title}
					</Title>
					<Typography.Title level={2}>Storage</Typography.Title>
				</Box>
				<Menu
					onClick={handleClick}
					mode="horizontal"
					selectedKeys={[current]}>
					<Menu.Item key="details">Details</Menu.Item>
					{submodules &&
						submodules.map((submodule) => (
							<Menu.Item key={submodule.url}>
								{submodule.title}
							</Menu.Item>
						))}
				</Menu>
			</View>
		</Container>
	);
};

const mapStateToProps = ({ storage, submodules, organization }) => {
	return {
		storage,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchOrganization,
	fetchStorage,
	fetchSubmodulesByModuleCode,
})(StorageInfo);
