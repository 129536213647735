import { API_ROOT } from '@config/api';
import {
	SET_MINISTRIES,
	SET_MINISTRY,
	ADD_MINISTRY,
	UPDATE_MINISTRY,
	CLEAR_MINISTRIES,
	CLEAR_MINISTRY,
} from './actionTypes';
import request from '@common/utils/request';

const fetchMinistries = () => (dispatch) =>
	request('get', `${API_ROOT}/ministries`)
		.then((response) => {
			dispatch({
				type: SET_MINISTRIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchMinistriesByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/ministries/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MINISTRIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchMinistry = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/ministries/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MINISTRY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createMinistry = (data) => (dispatch) =>
	request('post', `${API_ROOT}/ministries`, data)
		.then((response) => {
			dispatch({
				type: ADD_MINISTRY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateMinistry = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/ministries/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_MINISTRY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearMinistries = () => (dispatch) =>
	dispatch({
		type: CLEAR_MINISTRIES,
	});

const clearMinistry = () => (dispatch) =>
	dispatch({
		type: CLEAR_MINISTRY,
	});

export {
	fetchMinistries,
	fetchMinistriesByOrganization,
	fetchMinistry,
	createMinistry,
	updateMinistry,
	clearMinistries,
	clearMinistry,
};
