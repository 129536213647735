import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchOrganization } from '@common/resources/Organization/actions';
import { fetchEvent, clearEvent } from '@resources/Event/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, View, Title } from '@common/components';
import { Affix, Menu, Typography } from 'antd';

const EventInfo = ({
	event,
	submodules,
	fetchEvent,
	fetchSubmodulesByModuleCode,
	organization,
	history,
	match,
}) => {
	const { eventId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchEvent(eventId);
			await fetchSubmodulesByModuleCode('event');
		};

		fetchData();
	}, [fetchEvent]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization.alias
					? `/${organization.alias}/events/${eventId}/details`
					: `/events/${eventId}/details`
			);
		} else {
			history.push(
				organization.alias
					? `/${organization.alias}/events/${eventId}/details/${e.key}`
					: `/events/${eventId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<View>
			<Container>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{event.title}
					</Title>
					<Typography.Title level={2}>Event</Typography.Title>
				</Box>
				{organization?.access?.includes('event') && (
					<Affix offsetTop={100}>
						<Menu
							onClick={handleClick}
							mode="horizontal"
							selectedKeys={[current]}>
							<Menu.Item key="details">Details</Menu.Item>
							{submodules &&
								submodules.map((submodule) => (
									<Menu.Item key={submodule.url}>
										{submodule.title}
									</Menu.Item>
								))}
						</Menu>
					</Affix>
				)}
			</Container>
		</View>
	);
};

const mapStateToProps = ({ event, submodules, organization }) => {
	return {
		event,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchEvent,
	fetchSubmodulesByModuleCode,
	fetchOrganization,
	clearEvent,
})(EventInfo);
