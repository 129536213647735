import { API_ROOT } from '@config/api';
import {
	SET_MODULES,
	SET_MODULE,
	ADD_MODULE,
	UPDATE_MODULE,
	CLEAR_MODULE,
} from './actionTypes';
import request from '@common/utils/request';

const fetchModules = (type) => (dispatch) =>
	request('get', `${API_ROOT}/modules`)
		.then((response) => {
			dispatch({
				type: SET_MODULES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchModulesByApps = (apps) => (dispatch) =>
	request('post', `${API_ROOT}/modules/apps`, { apps })
		.then((response) => {
			dispatch({
				type: SET_MODULES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchModulesBySubmoduleCode = (code) => (dispatch) =>
	request('get', `${API_ROOT}/modules/submodule/code/${code}`)
		.then((response) => {
			dispatch({
				type: SET_MODULES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchModule = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/modules/${id}`)
		.then((response) => {
			dispatch({
				type: SET_MODULE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createModule = (data) => (dispatch) =>
	request('post', `${API_ROOT}/modules`, data)
		.then((response) => {
			dispatch({
				type: ADD_MODULE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateModule = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/modules/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_MODULE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearModule = () => (dispatch) =>
	dispatch({
		type: CLEAR_MODULE,
	});

export {
	fetchModules,
	fetchModulesByApps,
	fetchModulesBySubmoduleCode,
	fetchModule,
	createModule,
	updateModule,
	clearModule,
};
