import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ value = {}, onChange }) => {
	const triggerChange = (changedValue) => {
		if (onChange) {
			onChange(changedValue);
		}
	};
	return <ReactQuill theme="snow" value={value} onChange={triggerChange} />;
};

export default Editor;
