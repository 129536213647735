import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchMinistry } from '@resources/Ministry/actions';
import { fetchOrganization } from '@common/resources/Organization/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, Title, View } from '@common/components';
import { Menu, Typography } from 'antd';

const MinistryInfo = ({
	ministry,
	submodules,
	fetchMinistry,
	fetchSubmodulesByModuleCode,
	organization,
	history,
	match,
}) => {
	const { ministryId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchMinistry(ministryId);
			await fetchSubmodulesByModuleCode('ministry');
		};

		fetchData();
	}, [fetchMinistry]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/ministries/${ministryId}/details`
					: `/ministries/${ministryId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/ministries/${ministryId}/details/${e.key}`
					: `/ministries/${ministryId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<View>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{ministry.name}
					</Title>
					<Typography.Title level={2}>Ministry</Typography.Title>
				</Box>
				<Menu
					onClick={handleClick}
					mode="horizontal"
					selectedKeys={[current]}>
					<Menu.Item key="details">Details</Menu.Item>
					{submodules &&
						submodules.map((submodule) => (
							<Menu.Item key={submodule.url}>
								{submodule.title}
							</Menu.Item>
						))}
				</Menu>
			</View>
		</Container>
	);
};

const mapStateToProps = ({ ministry, submodules, organization }) => {
	return {
		ministry,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchMinistry,
	fetchSubmodulesByModuleCode,
	fetchOrganization,
})(MinistryInfo);
