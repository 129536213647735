import { API_ROOT } from '@config/api';
import {
	SET_FIELD_TYPES,
	SET_FIELD_TYPE,
	ADD_FIELD_TYPE,
	UPDATE_FIELD_TYPE,
} from './actionTypes';
import request from '@common/utils/request';

const fetchFieldTypes = (type) => (dispatch) =>
	request('get', `${API_ROOT}/fieldTypes`)
		.then((response) => {
			dispatch({
				type: SET_FIELD_TYPES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchFieldType = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/fieldTypes/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FIELD_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createFieldType = (data) => (dispatch) =>
	request('post', `${API_ROOT}/fieldTypes`, data)
		.then((response) => {
			dispatch({
				type: ADD_FIELD_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateFieldType = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/fieldTypes/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_FIELD_TYPE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

export { fetchFieldTypes, fetchFieldType, createFieldType, updateFieldType };
