import { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { fetchAuth } from '../../resources/Auth/actions';
import { fetchCart } from '../../resources/Cart/actions';
import { fetchSiteTheme } from '../../resources/SiteTheme/actions';
import {
	fetchOrganization,
	clearOrganization,
} from '../../resources/Organization/actions';
import { getCookie } from '@common/utils/cookie';
import { parseOrganizationId } from '@common/utils/organization';
import isEmpty from 'lodash.isempty';
import { ThemeContext } from '../../contexts/ThemeStore';

const Middleware = ({
	clearOrganization,
	fetchAuth,
	siteTheme,
	organization,
	children,
	fetchCart,
	fetchOrganization,
	fetchSiteTheme,
	location,
}) => {
	const authCookie = getCookie('auth');
	const [loading, setLoading] = useState(true);
	const [org, setOrg] = useState(null);
	const { updateTheme } = useContext(ThemeContext);
	useEffect(() => {
		setLoading(true);
		const fetchData = async () => {
			await fetchAuth();
			await fetchCart();
		};

		!isEmpty(authCookie) && fetchData();
	}, [authCookie]);

	useEffect(() => {
		const fetchOrg = async () => {
			const orgId = parseOrganizationId();
			try {
				await fetchOrganization(orgId);
			} catch (e) {
				await clearOrganization();
			}
			setOrg(orgId);
		};

		org !== parseOrganizationId() && fetchOrg();
	}, [location]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchSiteTheme(organization._id);
		};

		fetchData();

		setLoading(false);
	}, [organization]);

	useEffect(() => {
		if (!isEmpty(siteTheme)) {
			updateTheme(siteTheme);
			window.less
				.modifyVars({
					'@primary-color': siteTheme?.primaryColor?.hex || '#a90af2',
					'@normal-color': '#d9d9d9',
					'@text-color': siteTheme?.textColor?.hex || '#FFFFFF',
					'@text-color-secondary':
						siteTheme?.textColor?.hex || '#FFFFFF',
					'@heading-color':
						siteTheme?.textColor?.hex || 'fade(@white, 85%)',
					'@body-background':
						siteTheme?.backgroundColor?.hex || '#333333',
					'@select-item-selected-bg':
						siteTheme?.backgroundColor?.hex || '#333333',
					'@background-color-base':
						siteTheme?.backgroundColor?.hex || '#333333',
					'@background-color-light':
						siteTheme?.componentBackground?.hex || '#444',
					'@component-background':
						siteTheme?.componentBackground?.hex || '#555',
					'@checkbox-text-color':
						siteTheme?.textColor?.hex || '#FFFFFF',
					'@disabled-color':
						siteTheme?.componentBackground?.hex ||
						'rgba(255, 255, 255, 255, 0.25)',
					'@divider-color':
						siteTheme?.componentBackground?.hex || '#555',
					'@rate-star-bg':
						siteTheme?.componentBackground?.hex || '#555',
				})
				.then(() => {});
		} else {
			updateTheme({
				primaryColor: { hex: '#a90af2' },
				textColor: { hex: '#FFFFFF' },
				componentBackground: { hex: '#555' },
			});
			window.less
				.modifyVars({
					'@primary-color': '#a90af2',
					'@secondary-color': '#c1d8de',
					'@normal-color': '#d9d9d9',
					'@text-color': '#FFFFFF',
					'@text-color-secondary': '#FFFFFF',
					'@heading-color': 'fade(@white, 85%)',
					'@body-background': '#333',
					'@select-item-selected-bg': '#333',
					'@background-color-base': '#333',
					'@background-color-light': '#444',
					'@component-background': '#555',
					'@select-item-active-bg': '#aaa',
					'@checkbox-text-color': '#FFFFFF',
					'@disabled-color': 'rgba(255, 255, 255, 255, 0.25)',
					'@divider-color': '#555',
					'@rate-star-bg': '#555',
				})
				.then(() => {
					console.log('update');
				});
		}
	}, [siteTheme]);

	return loading ? null : children;
};

const mapStateToProps = ({ auth, organization, siteTheme }) => ({
	auth,
	organization,
	siteTheme,
});

export default connect(mapStateToProps, {
	clearOrganization,
	fetchAuth,
	fetchCart,
	fetchOrganization,
	fetchSiteTheme,
})(Middleware);
