import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchPrayers,
	fetchPrayersByOrganization,
	clearPrayers,
} from '@resources/Prayer/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrayerItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Prayer = (props) => {
	const {
		prayers,
		history,
		fetchPrayers,
		fetchPrayersByOrganization,
		clearPrayers,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearPrayers();
			organization?._id
				? await fetchPrayersByOrganization(organization?._id)
				: await fetchPrayers();
		};
		fetchData();

		return () => clearPrayers();
	}, [clearPrayers, fetchPrayers, fetchPrayersByOrganization, organization]);

	const handleClick = (prayer) => {
		history.push(
			organization?._id
				? `/${prayer.organization.alias}/prayers/${prayer._id}/details`
				: `/prayers/${prayer._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.prayer.management',
						defaultMessage: 'Prayer Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.prayers"
									defaultMessage="Prayers"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.prayer"
									defaultMessage="New Prayer"
								/>
							</Button>
						</Col>
					</Row>
					<Section className="mb-5">
						{prayers?.map((prayer, index) => (
							<PrayerItem
								key={`prayer-${index}`}
								prayer={prayer}
								onClick={handleClick}
							/>
						))}
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, prayers }) => {
	return {
		organization,
		prayers,
	};
};

export default connect(mapStateToProps, {
	fetchPrayers,
	fetchPrayersByOrganization,
	clearPrayers,
})(Prayer);
