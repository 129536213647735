import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Storage = ({ storage, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-5" onClick={() => onClick && onClick(storage)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col>
						<Typography.Title level={4}>
							{storage.name}
						</Typography.Title>
					</Col>
				</Row>
				<Box textAlign="right">
					{onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(storage)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(storage)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Storage;
