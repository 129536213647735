import React, { useEffect, useCallback, useState } from 'react';
import { Form, InputNumber, Button } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearTithe } from '@resources/Tithe/actions';
import { formErrorHandler } from '@common/utils/form';
import { Box } from '@common/components';

const FormItem = Form.Item;

const TitheForm = ({ data, organization, onSubmit, clearTithe }) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}
			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		return () => clearTithe();
	}, []);

	return (
		<Box textAlign="center">
			<Form
				name="position-form"
				onFinish={handleFinish}
				initialValues={data}
				layout="inline"
				style={{
					justifyContent: 'center',
				}}>
				<FormItem
					name="amount"
					rules={[
						{
							required: true,
							message: intl.formatMessage({
								id: 'form.validation.title.required',
								defaultMessage: 'Please input title',
							}),
						},
					]}>
					<InputNumber
						style={{
							fontSize: 50,
							height: 100,
							width: 400,
							lineHeight: '100px',
							paddingLeft: 30,
							paddingRight: 30,
						}}
						formatter={(value) =>
							`${organization?.preference?.currency?.symbol ||
								organization?.currency
									?.symbol} ${value}`.replace(
								/\B(?=(\d{3})+(?!\d))/g,
								','
							)
						}
						step={0.01}
						parser={(value) =>
							value
								.replace(
									`${organization?.preference?.currency
										?.symbol ||
										organization?.currency?.symbol} `,
									''
								)
								.replace(/\$\s?|(,*)/g, '')
						}
						placeholder={intl.formatMessage({
							id: 'common.amount',
							defaultMessage: 'Amount',
						})}
					/>
				</FormItem>
				<FormItem>
					<Button
						style={{
							fontSize: 50,
							height: 100,
							paddingLeft: 30,
							paddingRight: 30,
						}}
						loading={loading}
						type="primary"
						htmlType="submit"
						className="login-form-button">
						{intl.formatMessage({
							id: 'common.tithe',
							defaultMessage: 'Tithe',
						})}
					</Button>
				</FormItem>
			</Form>
		</Box>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, { clearTithe })(TitheForm);
