import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Rate, Typography } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import moment from 'moment';

const Review = ({ review, onClick, onEdit, onDelete }) => {
	const user = review?.user || {};
	return (
		<Box className="p-3" onClick={() => onClick && onClick()}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col flex={1}>
						<Rate disabled defaultValue={review.rating} />
						<Typography.Title level={4}>
							{review.title}
						</Typography.Title>
						<Typography.Text>{review.detail}</Typography.Text>
						<Box mt={20}>
							<Typography.Text>
								Reviewed by {user.firstName} {user.lastName}{' '}
								{moment(review.createdDate).fromNow()}
							</Typography.Text>
						</Box>
					</Col>
				</Row>
				<Box textAlign="right">
					{review.isCreator && onEdit && (
						<Button
							size="small"
							type="text"
							onClick={() => onEdit && onEdit(review)}>
							<EditFilled style={{ color: '#FFCC00' }} />
						</Button>
					)}
					{review.isCreator && onDelete && (
						<Button
							size="small"
							type="text"
							onClick={() => onDelete && onDelete(review)}>
							<DeleteFilled style={{ color: '#FF0000' }} />
						</Button>
					)}
				</Box>
			</Section>
		</Box>
	);
};

export default Review;
