import { API_ROOT } from '@config/api';
import {
	SET_DISCUSSIONS,
	SET_DISCUSSION,
	ADD_DISCUSSION,
	UPDATE_DISCUSSION,
	CLEAR_DISCUSSION,
	CLEAR_DISCUSSIONS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchDiscussions = () => (dispatch) =>
	request('get', `${API_ROOT}/discussions`)
		.then((response) => {
			dispatch({
				type: SET_DISCUSSIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchDiscussionsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/discussions/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_DISCUSSIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchDiscussion = (id) => (dispatch) =>
	request('get', `${API_ROOT}/discussions/${id}`)
		.then((response) => {
			dispatch({
				type: SET_DISCUSSION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createDiscussion = (data) => (dispatch) =>
	request('post', `${API_ROOT}/discussions`, data)
		.then((response) => {
			dispatch({
				type: ADD_DISCUSSION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateDiscussion = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/discussions/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_DISCUSSION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearDiscussions = () => (dispatch) =>
	dispatch({
		type: CLEAR_DISCUSSIONS,
	});

const clearDiscussion = () => (dispatch) =>
	dispatch({
		type: CLEAR_DISCUSSION,
	});

export {
	fetchDiscussions,
	fetchDiscussionsByOrganizationModule,
	fetchDiscussion,
	createDiscussion,
	updateDiscussion,
	clearDiscussions,
	clearDiscussion,
};
