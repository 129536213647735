import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import AccountForm from '../Form';
import {
	fetchAccount,
	updateAccount,
	clearAccount,
} from '@resources/Account/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { authorizeStripe } from '@common/resources/Stripe/actions';
import { APP_ROOT } from '@config/app';

const Update = (props) => {
	const {
		authorizeStripe,
		fetchAccount,
		clearAccount,
		account,
		updateAccount,
		visible,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await clearAccount();
			await fetchAccount(visible);
		};
		visible && fetchData();
	}, [fetchAccount, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateAccount(account._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.account.update',
							defaultMessage:
								'An existing Account entry has been successfully updated',
						})
					);

					if (data.type === 'credit-card' && !data.stripeAccountId) {
						const authorize = async () => {
							const authorization = await authorizeStripe(
								'account',
								response.data.data._id,
								{
									organization: organization?._id,
									type: organization?._id && 'organization',
									redirect_uri: `${APP_ROOT}/${organization?.alias}/accounts/token`,
								}
							);
							window.location.href = authorization.data.data;
						};

						authorize();
					}

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[account]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.account',
					defaultMessage: 'Update Account',
				})}
			</Title>
			{!isEmpty(account) && (
				<AccountForm
					data={account}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	account,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		account,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchAccount,
	updateAccount,
	clearAccount,
	authorizeStripe,
})(Update);
