import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchAccesses } from '@resources/Access/actions';
import { fetchApps } from '@resources/App/actions';
import { fetchPages, fetchPagesByOrganization } from '@resources/Page/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Page = (props) => {
	const {
		organization,
		pages,
		fetchAccesses,
		fetchApps,
		fetchPages,
		fetchPagesByOrganization,
		history,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			organization?._id
				? await fetchPagesByOrganization(organization?._id)
				: await fetchPages();
			await fetchApps();
			await fetchAccesses();
		};
		fetchData();
	}, [
		fetchAccesses,
		fetchApps,
		fetchPages,
		fetchPagesByOrganization,
		organization,
	]);

	const handleClick = (page) => {
		history.push(`/${page?.organization?.alias}/pages/${page?.alias}`);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.page.management',
						defaultMessage: 'Page Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.pages"
									defaultMessage="Pages"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.page"
									defaultMessage="New Page"
								/>
							</Button>
						</Col>
					</Row>
					<Box className="mb-5">
						{pages?.map((page, index) => (
							<PageItem
								key={`page-${index}`}
								page={page}
								onClick={handleClick}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ pages, organization }) => {
	return { pages, organization };
};

export default connect(mapStateToProps, {
	fetchPagesByOrganization,
	fetchAccesses,
	fetchApps,
	fetchPages,
})(Page);
