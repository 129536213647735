import { API_ROOT } from '@config/api';
import {
	SET_GUIDES,
	SET_GUIDE,
	ADD_GUIDE,
	UPDATE_GUIDE,
	CLEAR_GUIDE,
	CLEAR_GUIDES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchGuides = () => (dispatch) =>
	request('get', `${API_ROOT}/guides`)
		.then((response) => {
			dispatch({
				type: SET_GUIDES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGuidesByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/guides/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_GUIDES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchGuide = (id) => (dispatch) =>
	request('get', `${API_ROOT}/guides/${id}`)
		.then((response) => {
			dispatch({
				type: SET_GUIDE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createGuide = (data) => (dispatch) =>
	request('post', `${API_ROOT}/guides`, data)
		.then((response) => {
			dispatch({
				type: ADD_GUIDE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateGuide = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/guides/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_GUIDE,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearGuide = () => (dispatch) =>
	dispatch({
		type: CLEAR_GUIDE,
	});

const clearGuides = () => (dispatch) =>
	dispatch({
		type: CLEAR_GUIDES,
	});

export {
	fetchGuides,
	fetchGuidesByOrganizationModule,
	fetchGuide,
	createGuide,
	updateGuide,
	clearGuide,
	clearGuides,
};
