import { API_ROOT } from '@config/api';
import {
	SET_ACTIONS,
	SET_ACTION,
	CLEAR_ACTION,
	CLEAR_ACTIONS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchActionsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/actions/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_ACTIONS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAction = (id) => (dispatch) =>
	request('get', `${API_ROOT}/actions/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ACTION,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearAction = () => (dispatch) =>
	dispatch({
		type: CLEAR_ACTION,
	});

const clearActions = () => (dispatch) =>
	dispatch({
		type: CLEAR_ACTIONS,
	});

export {
	fetchActionsByOrganizationModule,
	fetchAction,
	clearAction,
	clearActions,
};
