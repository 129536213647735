import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import EventForm from '../Form';
import { fetchEvent, updateEvent } from '@resources/Event/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchEvent, event, updateEvent, visible, onCancel } = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchEvent(visible);
		};
		visible && fetchData();
	}, [fetchEvent, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateEvent(event._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.event.update',
							defaultMessage:
								'An existing Event entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[event]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.event',
					defaultMessage: 'Update Event',
				})}
			</Title>
			{!isEmpty(event) && (
				<EventForm {...props} data={event} onSubmit={handleSubmit} />
			)}
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		event: state.event,
	};
};

export default connect(mapStateToProps, {
	fetchEvent,
	updateEvent,
})(Update);
