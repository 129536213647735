import { API_ROOT } from '@config/api';
import {
	SET_PRODUCTS,
	SET_PRODUCT,
	ADD_PRODUCT,
	UPDATE_PRODUCT,
	CLEAR_PRODUCTS,
	CLEAR_PRODUCT,
} from './actionTypes';
import request from '@common/utils/request';

const fetchProducts = () => (dispatch) =>
	request('get', `${API_ROOT}/products`)
		.then((response) => {
			dispatch({
				type: SET_PRODUCTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchProductsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/products/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_PRODUCTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchProduct = (id) => (dispatch) =>
	request('get', `${API_ROOT}/products/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PRODUCT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createProduct = (data) => (dispatch) =>
	request('post', `${API_ROOT}/products`, data)
		.then((response) => {
			dispatch({
				type: ADD_PRODUCT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateProduct = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/products/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PRODUCT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearProducts = () => (dispatch) =>
	dispatch({
		type: CLEAR_PRODUCTS,
	});

const clearProduct = () => (dispatch) =>
	dispatch({
		type: CLEAR_PRODUCT,
	});

export {
	fetchProducts,
	fetchProductsByOrganizationModule,
	fetchProduct,
	createProduct,
	updateProduct,
	clearProducts,
	clearProduct,
};
