export const CONTENT_TYPES = {
	PARAGRAPH: 'paragraph',
};

export const FIELD_TYPES = {
	NUMBER: 'number',
	TEXT: 'text',
	DATE: 'date',
	RADIO: 'radio',
	SELECT: 'select',
};
