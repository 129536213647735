import React, { useCallback, useState, useEffect } from 'react';
import { Form, InputNumber, Button, Checkbox } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formErrorHandler } from '@common/utils/form';
import {
	clearItems,
	fetchItemsByOrganization,
	fetchItems,
} from '@resources/Item/actions';
import { ItemSelectForm, SupplierSelectForm } from '@fragments/Form';

const FormItem = Form.Item;

const OrderForm = (props) => {
	const { data, items, onSubmit, onCancel, organization } = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await clearItems();

			organization?._id
				? await fetchItemsByOrganization(organization?._id)
				: await fetchItems();
		};

		fetchData();
	}, [items, organization]);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={
				data && {
					...data,
					item: data?.item?._id,
					supplier: data?.supplier?._id,
				}
			}>
			<ItemSelectForm {...props} form={form} formItemProps={{}} />

			<FormItem
				label={intl.formatMessage({
					id: 'common.quantity',
					defaultMessage: 'Quantity',
				})}
				name="quantity"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.quantity.required',
							defaultMessage: 'Please input quantity',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.quantity',
						defaultMessage: 'Quantity',
					})}
				/>
			</FormItem>

			<SupplierSelectForm {...props} form={form} formItemProps={{}} />

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, items, organization }) => {
	return {
		auth,
		items,
		organization,
	};
};

export default connect(mapStateToProps, {})(OrderForm);
