import React, { useCallback, useState, useEffect } from 'react';
import { Form, Input, InputNumber, Button, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchMembersByOrganization } from '@resources/Member/actions';
import { formErrorHandler } from '@common/utils/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const TeamForm = (props) => {
	const {
		module,
		data,
		onSubmit,
		fetchMembersByOrganization,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchMembersByOrganization(organization?._id);
		};
		fetchData();
	}, [organization]);

	return (
		<Form
			name="team-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, member: data?.member?._id }}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={3}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			<Form.List name="positions" wrapperCol={{ span: 24 }}>
				{(fields, { add, remove }) => (
					<>
						{fields.map((field) => (
							<Row key={field.key} align="baseline">
								<Col flex={2}>
									<FormItem
										{...field}
										label={`Position`}
										name={[field.name, 'title']}
										fieldKey={[field.fieldKey, 'title']}
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id:
														'form.validation.position.required',
													defaultMessage:
														'Please input position',
												}),
											},
										]}>
										<Input
											placeholder={intl.formatMessage({
												id: 'common.position',
												defaultMessage: 'Position',
											})}
										/>
									</FormItem>
								</Col>
								<Col flex={1}>
									<FormItem
										{...field}
										label={intl.formatMessage({
											id: 'common.qty',
											defaultMessage: 'Qty',
										})}
										name={[field.name, 'quantity']}
										fieldKey={[field.fieldKey, 'quantity']}
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id:
														'form.validation.quantity.required',
													defaultMessage:
														'Please input quantity',
												}),
											},
										]}>
										<InputNumber
											min={0}
											placeholder={intl.formatMessage({
												id: 'common.qty',
												defaultMessage: 'Qty',
											})}
										/>
									</FormItem>
								</Col>
								<Col style={{ width: 20, paddingTop: 15 }}>
									<MinusCircleOutlined
										onClick={() => remove(field.name)}
									/>
								</Col>
							</Row>
						))}

						<Form.Item wrapperCol={{ span: 24 }}>
							<Button
								style={{ width: '100%' }}
								type="dashed"
								onClick={() => add()}
								icon={<PlusOutlined />}>
								Add Position
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, members, organization, teams }) => {
	return {
		auth,
		members,
		organization,
		teams,
	};
};

export default connect(mapStateToProps, { fetchMembersByOrganization })(
	TeamForm
);
