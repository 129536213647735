import { API_ROOT } from '@config/api';
import {
	SET_SUPPLIERS,
	SET_SUPPLIER,
	ADD_SUPPLIER,
	UPDATE_SUPPLIER,
	CLEAR_SUPPLIER,
	CLEAR_SUPPLIERS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchSuppliers = () => (dispatch) =>
	request('get', `${API_ROOT}/suppliers`)
		.then((response) => {
			dispatch({
				type: SET_SUPPLIERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSuppliersByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/suppliers/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SUPPLIERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSuppliersByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/suppliers/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_SUPPLIERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchSupplier = (id) => (dispatch) =>
	request('get', `${API_ROOT}/suppliers/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SUPPLIER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createSupplier = (data) => (dispatch) =>
	request('post', `${API_ROOT}/suppliers`, data)
		.then((response) => {
			dispatch({
				type: ADD_SUPPLIER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateSupplier = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/suppliers/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SUPPLIER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearSupplier = () => (dispatch) =>
	dispatch({
		type: CLEAR_SUPPLIER,
	});

const clearSuppliers = () => (dispatch) =>
	dispatch({
		type: CLEAR_SUPPLIERS,
	});

export {
	fetchSuppliers,
	fetchSuppliersByOrganization,
	fetchSuppliersByOrganizationModule,
	fetchSupplier,
	createSupplier,
	updateSupplier,
	clearSupplier,
	clearSuppliers,
};
