import React, { useCallback, useState, useEffect } from 'react';
import { Form, InputNumber, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchItemsByOrganization } from '@resources/Item/actions';
import isEmpty from 'lodash.isempty';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const RentableForm = (props) => {
	const {
		module,
		items,
		data,
		onSubmit,
		fetchItemsByOrganization,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchItemsByOrganization(organization?._id);
		};
		fetchData();
	}, [organization]);

	const renderItemOptions = useCallback(() => {
		if (!isEmpty(items)) {
			return items.map((item, index) => {
				return (
					<Option
						value={item._id}
						key={`item-${index}`}
						title={item.title}>
						{item.title}
					</Option>
				);
			});
		}
	}, [items]);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{ ...data, item: data?.item?._id }}>
			<FormItem
				label={`Item`}
				name="item"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.item.required',
							defaultMessage: 'Please input item',
						}),
					},
				]}>
				<Select
					placeholder="Please select item"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderItemOptions()}
				</Select>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.quantity',
					defaultMessage: 'Quantity',
				})}
				name="quantity"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.quantity.required',
							defaultMessage: 'Please input quantity',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.quantity',
						defaultMessage: 'Quantity',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.rentalFee',
					defaultMessage: 'Rental Fee',
				})}
				name="rentalFee"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.rentalFee.required',
							defaultMessage: 'Please input rental fee',
						}),
					},
				]}>
				<InputNumber placeholder={0} />
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.maxRentalQuantity',
					defaultMessage: 'Max Rental Quantity',
				})}
				name="maxRentalQuantity"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.maxRentalQuantity.required',
							defaultMessage: 'Please input max Rental Quantity',
						}),
					},
				]}>
				<InputNumber placeholder={0} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.maxRentalDuration',
					defaultMessage: 'Max Rental Duration',
				})}
				name="maxRentalDuration"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.maxRentalDuration.required',
							defaultMessage: 'Please input max Rental Duration',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.hours',
						defaultMessage: 'Hours',
					})}
				/>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, items, organization }) => {
	return {
		auth,
		items,
		organization,
	};
};

export default connect(mapStateToProps, { fetchItemsByOrganization })(
	RentableForm
);
