import { API_ROOT } from '@config/api';
import {
	SET_RENTALS,
	SET_RENTAL,
	ADD_RENTAL,
	UPDATE_RENTAL,
	CLEAR_RENTAL,
	CLEAR_RENTALS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchRentals = () => (dispatch) =>
	request('get', `${API_ROOT}/rentals`)
		.then((response) => {
			dispatch({
				type: SET_RENTALS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchRentalsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/rentals/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_RENTALS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchRental = (id) => (dispatch) =>
	request('get', `${API_ROOT}/rentals/${id}`)
		.then((response) => {
			dispatch({
				type: SET_RENTAL,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createRental = (data) => (dispatch) =>
	request('post', `${API_ROOT}/rentals`, data)
		.then((response) => {
			dispatch({
				type: ADD_RENTAL,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateRental = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/rentals/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_RENTAL,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearRental = () => (dispatch) =>
	dispatch({
		type: CLEAR_RENTAL,
	});

const clearRentals = () => (dispatch) =>
	dispatch({
		type: CLEAR_RENTALS,
	});

export {
	fetchRentals,
	fetchRentalsByOrganizationModule,
	fetchRental,
	createRental,
	updateRental,
	clearRental,
	clearRentals,
};
