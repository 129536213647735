import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import FormaForm from '../Form';
import { fetchForm, updateForm } from '@resources/Form/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchForm, forma, updateForm, visible, onCancel } = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchForm(visible);
		};
		visible && fetchData();
	}, [fetchForm, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateForm(forma._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.form.update',
							defaultMessage:
								'An existing Form entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[forma]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.form',
					defaultMessage: 'Update Form',
				})}
			</Title>
			{!isEmpty(forma) && (
				<FormaForm data={forma} onSubmit={handleSubmit} {...props} />
			)}
		</Modal>
	);
};

const mapStateToProps = ({ forma }) => {
	return {
		forma,
	};
};

export default connect(mapStateToProps, {
	fetchForm,
	updateForm,
})(Update);
