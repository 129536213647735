import React from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import SupplierForm from '../Form';
import { createSupplier } from '@resources/Supplier/actions';
import { useIntl } from 'react-intl';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Create = (props) => {
	const { createSupplier, visible, onCancel, module } = props;
	const intl = useIntl();

	const handleSubmit = (data) => {
		return createSupplier(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.supplier.create',
						defaultMessage:
							'A new Supplier has been successfully created',
					})
				);
				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.supplier',
					defaultMessage: 'Create Supplier',
				})}
			</Title>
			<SupplierForm {...props} onSubmit={handleSubmit} module={module} />
		</Modal>
	);
};

const mapStateToProps = ({
	suppliers,
	modules,
	event,
	facility,
	ministry,
	cellGroup,
	organization,
}) => {
	return {
		suppliers,
		ministry,
		facility,
		modules,
		event,
		cellGroup,
		organization,
	};
};

export default connect(mapStateToProps, {
	createSupplier,
	fetchModulesBySubmoduleCode,
})(Create);
