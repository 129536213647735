import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Title, View } from '@common/components';
import { Layout, Typography } from 'antd';
import { setSite } from '@resources/Site/actions';
import {
	fetchEventsByOrganization,
	clearEvents,
} from '@resources/Event/actions';
import {
	fetchCellGroupsByOrganization,
	clearCellGroups,
} from '@resources/CellGroup/actions';
import {
	fetchMissionsByOrganization,
	clearMissions,
} from '@resources/Mission/actions';
import {
	fetchMinistriesByOrganization,
	clearMinistries,
} from '@resources/Ministry/actions';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import isEmpty from 'lodash.isempty';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
	PastEventItem,
	CellGroupItem,
	MissionItem,
	MinistryItem,
} from '@fragments/items';

const { Content } = Layout;

const Landing = ({
	events,
	missions,
	ministries,
	cellGroups,
	organization,
	history,
	location,
	setSite,
	fetchCellGroupsByOrganization,
	clearCellGroups,
	fetchEventsByOrganization,
	clearEvents,
	fetchMissionsByOrganization,
	clearMissions,
	fetchMinistriesByOrganization,
	clearMinistries,
}) => {
	const queryStrings = qs.parse(location.search.slice(1));

	useEffect(() => {
		setSite(queryStrings.s);
		const fetchData = async () => {
			await fetchEventsByOrganization(organization?._id);
			await fetchMissionsByOrganization(organization?._id);
			await fetchMinistriesByOrganization(organization?._id);
			await fetchCellGroupsByOrganization(organization?._id);
		};

		fetchData();

		return () => {
			clearCellGroups();
			clearEvents();
			clearMissions();
			clearMinistries();
		};
	}, [organization]);

	const settingsOrganizations = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		swipeToSlide: true,

		variableWidth: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: false,
					dots: true,
					variableWidth: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Organization</title>
			</Helmet>
			<View>
				<Container>
					<Title size="lg">{organization.title}</Title>
				</Container>
				<Box py={20}>
					<Container>
						<Typography.Title level={2}>Events</Typography.Title>
					</Container>
					<Slider {...settingsOrganizations}>
						{events &&
							events.map((event) => (
								<PastEventItem
									event={event}
									history={history}
								/>
							))}
					</Slider>
				</Box>

				{!isEmpty(missions) && (
					<Box py={50}>
						<Container>
							<Typography.Title level={2}>
								Missions
							</Typography.Title>
						</Container>
						<Slider {...settingsOrganizations}>
							{missions &&
								missions.map((mission) => (
									<MissionItem mission={mission} />
								))}
						</Slider>
					</Box>
				)}

				{!isEmpty(ministries) && (
					<Box py={50}>
						<Container>
							<Typography.Title level={2}>
								Ministries
							</Typography.Title>
						</Container>
						<Slider {...settingsOrganizations}>
							{ministries &&
								ministries.map((ministry) => (
									<MinistryItem ministry={ministry} />
								))}
						</Slider>
					</Box>
				)}

				{!isEmpty(cellGroups) && (
					<Box py={50}>
						<Container>
							<Typography.Title level={2}>
								Cell Groups
							</Typography.Title>
						</Container>
						<Slider {...settingsOrganizations}>
							{cellGroups &&
								cellGroups.map((cellGroup) => (
									<CellGroupItem cellGroup={cellGroup} />
								))}
						</Slider>
					</Box>
				)}
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	site,
	events,
	organization,
	missions,
	ministries,
	cellGroups,
}) => {
	return { site, events, organization, missions, ministries, cellGroups };
};

export default connect(mapStateToProps, {
	setSite,
	fetchCellGroupsByOrganization,
	clearCellGroups,
	fetchEventsByOrganization,
	clearEvents,
	fetchMissionsByOrganization,
	clearMissions,
	fetchMinistriesByOrganization,
	clearMinistries,
})(Landing);
