import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchGroups,
	fetchGroupsByOrganization,
	fetchGroupsByOrganizationModule,
	clearGroups,
} from '@resources/Group/actions';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { GroupItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Group = (props) => {
	const {
		groups,
		history,
		fetchGroups,
		fetchGroupsByOrganizationModule,
		fetchGroupsByOrganization,
		fetchModulesBySubmoduleCode,
		clearGroups,
		organization,
		siteTheme,
		match,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			const { data } = await fetchModulesBySubmoduleCode('group');
			await clearGroups();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id
				? modByParam
					? await fetchGroupsByOrganizationModule(
							organization?._id,
							modByParam && modByParam._id,
							param[`moduleId`]
					  )
					: await fetchGroupsByOrganization(organization?._id)
				: await fetchGroups();
		};
		fetchData();
	}, [
		clearGroups,
		fetchGroups,
		fetchGroupsByOrganization,
		organization,
		siteTheme,
	]);

	const handleClick = (group) => {
		history.push(
			`/${group.organization.alias}/groups/${group._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.group.management',
						defaultMessage: 'Group Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.groups"
									defaultMessage="Groups"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.group"
									defaultMessage="New Group"
								/>
							</Button>
						</Col>
					</Row>
					<Box className="mb-5">
						{groups?.map((group, index) => (
							<GroupItem
								key={`group-${index}`}
								group={group}
								onClick={handleClick}
								onEdit={() => setUpdate(group._id)}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, groups, siteTheme }) => {
	return {
		organization,
		groups,
		siteTheme,
	};
};

export default connect(mapStateToProps, {
	fetchGroups,
	fetchGroupsByOrganization,
	clearGroups,
	fetchModulesBySubmoduleCode,
	fetchGroupsByOrganizationModule,
})(Group);
