import { API_ROOT } from '@config/api';
import {
	SET_CELLGROUPS,
	SET_CELLGROUP,
	ADD_CELLGROUP,
	UPDATE_CELLGROUP,
	CLEAR_CELLGROUPS,
	CLEAR_CELLGROUP,
} from './actionTypes';
import request from '@common/utils/request';

const fetchCellGroups = () => (dispatch) =>
	request('get', `${API_ROOT}/cellGroups`)
		.then((response) => {
			dispatch({
				type: SET_CELLGROUPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCellGroupsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/cellGroups/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CELLGROUPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchCellGroup = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/cellGroups/${id}`)
		.then((response) => {
			dispatch({
				type: SET_CELLGROUP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createCellGroup = (data) => (dispatch) =>
	request('post', `${API_ROOT}/cellGroups`, data)
		.then((response) => {
			dispatch({
				type: ADD_CELLGROUP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateCellGroup = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/cellGroups/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_CELLGROUP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearCellGroups = () => (dispatch) =>
	dispatch({
		type: CLEAR_CELLGROUPS,
	});

const clearCellGroup = () => (dispatch) =>
	dispatch({
		type: CLEAR_CELLGROUP,
	});

export {
	fetchCellGroups,
	fetchCellGroupsByOrganization,
	fetchCellGroup,
	createCellGroup,
	updateCellGroup,
	clearCellGroups,
	clearCellGroup,
};
