import { API_ROOT } from '@config/api';
import {
	SET_REVIEWS,
	SET_REVIEW,
	ADD_REVIEW,
	UPDATE_REVIEW,
	CLEAR_REVIEW,
	CLEAR_REVIEWS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchReviews = () => (dispatch) =>
	request('get', `${API_ROOT}/reviews`)
		.then((response) => {
			dispatch({
				type: SET_REVIEWS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchReviewsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/reviews/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_REVIEWS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchReview = (id) => (dispatch) =>
	request('get', `${API_ROOT}/reviews/${id}`)
		.then((response) => {
			dispatch({
				type: SET_REVIEW,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createReview = (data) => (dispatch) =>
	request('post', `${API_ROOT}/reviews`, data)
		.then((response) => {
			dispatch({
				type: ADD_REVIEW,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateReview = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/reviews/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_REVIEW,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearReview = () => (dispatch) =>
	dispatch({
		type: CLEAR_REVIEW,
	});

const clearReviews = () => (dispatch) =>
	dispatch({
		type: CLEAR_REVIEWS,
	});

export {
	fetchReviews,
	fetchReviewsByOrganizationModule,
	fetchReview,
	createReview,
	updateReview,
	clearReview,
	clearReviews,
};
