import React, { useCallback, useState } from 'react';
import { Form, Button, Select, Input } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const InvitationForm = (props) => {
	const { onSubmit, onCancel, organization } = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	return (
		<Form name="position-form" layout="vertical" onFinish={handleFinish}>
			<FormItem
				label={`Response`}
				name="status"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.response.required',
							defaultMessage: 'Please input response',
						}),
					},
				]}>
				<Select
					placeholder="Please select response"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="accepted">Accepted</Option>
					<Option value="declined">Declined</Option>
				</Select>
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.status !== currentValues.status
				}>
				{({ getFieldValue }) => {
					return getFieldValue('status') === 'declined' ? (
						<FormItem
							label={`Reason`}
							name="reason"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.reason.required',
										defaultMessage: 'Please input reason',
									}),
								},
							]}>
							<Input.TextArea rows={3} />
						</FormItem>
					) : null;
				}}
			</Form.Item>

			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, members, organization, crews }) => {
	return {
		auth,
		members,
		organization,
		crews,
	};
};

export default connect(mapStateToProps, {})(InvitationForm);
