import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import SupplierForm from '../Form';
import {
	fetchSupplier,
	updateSupplier,
	clearSupplier,
} from '@resources/Supplier/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchSupplier,
		supplier,
		updateSupplier,
		visible,
		clearSupplier,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchSupplier(visible);
		};
		visible ? fetchData() : clearSupplier();
	}, [fetchSupplier, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateSupplier(supplier._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.supplier.update',
							defaultMessage:
								'An existing Supplier entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[supplier]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.supplier',
					defaultMessage: 'Update Supplier',
				})}
			</Title>
			{!isEmpty(supplier) && (
				<SupplierForm
					data={supplier}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	supplier,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		supplier,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchSupplier,
	updateSupplier,
	fetchModulesBySubmoduleCode,
	clearSupplier,
})(Update);
