import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchProjects,
	fetchProjectsByOrganization,
	clearProjects,
} from '@resources/Project/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ProjectItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Project = (props) => {
	const {
		projects,
		history,
		fetchProjects,
		fetchProjectsByOrganization,
		clearProjects,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearProjects();
			organization?._id
				? await fetchProjectsByOrganization(organization?._id)
				: await fetchProjects();
		};
		fetchData();

		return () => clearProjects();
	}, [
		clearProjects,
		fetchProjects,
		fetchProjectsByOrganization,
		organization,
	]);

	const handleClick = (project) => {
		history.push(
			organization?._id
				? `/${project.organization.alias}/projects/${project._id}/details`
				: `/projects/${project._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.project.management',
						defaultMessage: 'Project Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.projects"
									defaultMessage="Projects"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.project"
									defaultMessage="New Project"
								/>
							</Button>
						</Col>
					</Row>
					<Section className="mb-5">
						{projects?.map((project, index) => (
							<ProjectItem
								key={`project-${index}`}
								project={project}
								onClick={handleClick}
							/>
						))}
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, projects }) => {
	return {
		organization,
		projects,
	};
};

export default connect(mapStateToProps, {
	fetchProjects,
	fetchProjectsByOrganization,
	clearProjects,
})(Project);
