import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchOrganization } from '@common/resources/Organization/actions';
import { fetchMission } from '@resources/Mission/actions';
import { fetchSubmodulesByModuleCode } from '@resources/Submodule/actions';
import { Box, Container, Title, View } from '@common/components';
import { Menu, Typography } from 'antd';

const MissionInfo = ({
	mission,
	submodules,
	organization,
	fetchMission,
	fetchSubmodulesByModuleCode,
	history,
	match,
}) => {
	const { missionId, submoduleCode } = match?.params || {};
	const [current, setCurrent] = useState(submoduleCode || 'details');

	useEffect(() => {
		const fetchData = async () => {
			await fetchMission(missionId);
			await fetchSubmodulesByModuleCode('mission');
		};

		fetchData();
	}, [fetchMission]);

	const handleClick = (e) => {
		if (e.key === 'details') {
			history.push(
				organization?.alias
					? `/${organization?.alias}/missions/${missionId}/details`
					: `/missions/${missionId}/details`
			);
		} else {
			history.push(
				organization?.alias
					? `/${organization?.alias}/missions/${missionId}/details/${e.key}`
					: `/missions/${missionId}/details/${e.key}`
			);
		}

		setCurrent(e.key);
	};

	return (
		<Container>
			<View>
				<Box py={50}>
					<Title fontSize={100} lineHeight={1} mb={10}>
						{mission.title}
					</Title>
					<Typography.Title level={2}>Mission</Typography.Title>
				</Box>
				<Menu
					onClick={handleClick}
					mode="horizontal"
					selectedKeys={[current]}>
					<Menu.Item key="details">Details</Menu.Item>
					{submodules &&
						submodules.map((submodule) => (
							<Menu.Item key={submodule.url}>
								{submodule.title}
							</Menu.Item>
						))}
				</Menu>
			</View>
		</Container>
	);
};

const mapStateToProps = ({ mission, submodules, organization }) => {
	return {
		mission,
		submodules,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchOrganization,
	fetchMission,
	fetchSubmodulesByModuleCode,
})(MissionInfo);
