import { API_ROOT } from '@config/api';
import {
	SET_PROJECTS,
	SET_PROJECT,
	ADD_PROJECT,
	UPDATE_PROJECT,
	CLEAR_PROJECT,
	CLEAR_PROJECTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchProjects = () => (dispatch) =>
	request('get', `${API_ROOT}/projects`)
		.then((response) => {
			dispatch({
				type: SET_PROJECTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchProjectsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/projects/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PROJECTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchProject = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/projects/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PROJECT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createProject = (data) => (dispatch) =>
	request('post', `${API_ROOT}/projects`, data)
		.then((response) => {
			dispatch({
				type: ADD_PROJECT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateProject = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/projects/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PROJECT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearProject = () => (dispatch) =>
	dispatch({
		type: CLEAR_PROJECT,
	});

const clearProjects = () => (dispatch) =>
	dispatch({
		type: CLEAR_PROJECTS,
	});

export {
	fetchProjects,
	fetchProjectsByOrganization,
	fetchProject,
	createProject,
	updateProject,
	clearProject,
	clearProjects,
};
