import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Modal, Title } from '@common/components';
import { popIn } from '@common/utils/services';
import {
	fetchContent,
	createContent,
	updateContent,
	clearContent,
} from '@resources/Content/actions';
import { useIntl } from 'react-intl';
import { ContentParagraphForm } from '@containers/Content/forms';
import { dataTranslation } from '@common/utils/translations';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PageContentForm = ({
	id,
	page,
	content,
	fetchContent,
	updateContent,
	createContent,
	clearContent,
	type = {},
	visible,
	onCancel,
}) => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			if (visible && id) {
				await fetchContent(id);
			}
		};
		fetchData();
		setIsLoading(false);
	}, [fetchContent, visible, id]);

	const handleSubmit = useCallback(
		(data) => {
			if (id) {
				return updateContent(id, data)
					.then((response) => {
						popIn(
							'success',
							intl.formatMessage({
								id: 'message.success.content.update',
								defaultMessage:
									'An existing Content entry has been successfully updated',
							})
						);
					})
					.catch((err) => Promise.reject(err));
			} else {
				return createContent(data)
					.then((response) => {
						popIn(
							'success',
							intl.formatMessage({
								id: 'message.success.content.create',
								defaultMessage:
									'A new Content entry has been successfully created',
							})
						);
					})
					.catch((err) => Promise.reject(err));
			}
		},
		[page, id]
	);

	const renderContentForm = (type) => {
		switch (type) {
			case 'paragraph':
				return (
					<ContentParagraphForm
						data={content}
						page={page}
						onSubmit={handleSubmit}
						onCancel={() => {
							clearContent();
							onCancel && onCancel();
						}}
					/>
				);
			default:
				break;
		}
	};

	return (
		<Modal
			visible={visible}
			footer={null}
			onCancel={() => {
				clearContent();
				onCancel && onCancel();
			}}>
			<Title>
				{dataTranslation(intl, 'contentType', type.translation)}
			</Title>
			{isLoading && <Spin indicator={antIcon} />}

			{visible && !isLoading && id && content
				? renderContentForm(
						content && content.type && content.type.alias
				  )
				: visible &&
				  !isLoading &&
				  type &&
				  renderContentForm(type.alias)}
		</Modal>
	);
};

const mapStateToProps = ({ page, contentTypes, content, contents }) => {
	return {
		page,
		content,
		contentTypes,
		contents,
	};
};

export default connect(mapStateToProps, {
	fetchContent,
	createContent,
	updateContent,
	clearContent,
})(PageContentForm);
