import React from 'react';
import { Box } from '@common/components';
import { Divider, Row, Col, Typography, Space } from 'antd';

const Tithe = ({ tithe }) => {
	return (
		<Box textAlign="left">
			<Row type="flex" gutter={24} className="login-links" align="middle">
				<Col md={20}>
					<Space direction="vertical">
						<Typography.Text>
							Tithe for {tithe?.organization?.name}
						</Typography.Text>
					</Space>
				</Col>
				<Col md={4}>
					{tithe?.organization?.currency?.symbol} {tithe.amount}
				</Col>
			</Row>
			<Divider />
		</Box>
	);
};

export default Tithe;
