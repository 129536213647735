import React from 'react';
import { Collapse, Typography } from 'antd';

const { Panel } = Collapse;

const Instruction = ({ instruction, onClick, onEdit, onDelete }) => {
	return (
		<Collapse>
			<Panel header={instruction.title} key="1">
				<Typography.Text>{instruction.detail}</Typography.Text>
			</Panel>
		</Collapse>
	);
};

export default Instruction;
