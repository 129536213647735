import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import BookingForm from '../Form';
import {
	fetchBooking,
	updateBooking,
	clearBooking,
} from '@resources/Booking/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchBooking,
		booking,
		updateBooking,
		visible,
		clearBooking,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchBooking(visible);
		};
		visible ? fetchData() : clearBooking();
	}, [fetchBooking, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateBooking(booking._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.booking.update',
							defaultMessage:
								'An existing Booking entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[booking]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.booking',
					defaultMessage: 'Update Booking',
				})}
			</Title>
			{!isEmpty(booking) && (
				<BookingForm
					data={booking}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	booking,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		booking,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchBooking,
	updateBooking,
	fetchModulesBySubmoduleCode,
	clearBooking,
})(Update);
