import { API_ROOT } from '@config/api';
import {
	SET_SHOPS,
	SET_SHOP,
	ADD_SHOP,
	UPDATE_SHOP,
	CLEAR_SHOPS,
	CLEAR_SHOP,
} from './actionTypes';
import request from '@common/utils/request';

const fetchShops = () => (dispatch) =>
	request('get', `${API_ROOT}/shops`)
		.then((response) => {
			dispatch({
				type: SET_SHOPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchShopsByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/shops/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SHOPS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchShop = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/shops/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SHOP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createShop = (data) => (dispatch) =>
	request('post', `${API_ROOT}/shops`, data)
		.then((response) => {
			dispatch({
				type: ADD_SHOP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateShop = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/shops/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SHOP,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearShops = () => (dispatch) =>
	dispatch({
		type: CLEAR_SHOPS,
	});

const clearShop = () => (dispatch) =>
	dispatch({
		type: CLEAR_SHOP,
	});

export {
	fetchShops,
	fetchShopsByOrganization,
	fetchShop,
	createShop,
	updateShop,
	clearShops,
	clearShop,
};
