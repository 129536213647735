import React from 'react';
import { Button } from 'antd';
import {
	FieldDate,
	FieldText,
	FieldNumber,
	FieldSelect,
	FieldRadio,
} from '@common/fragments/Field';
import { Box, Section } from '@common/components';
import { DragOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import './assets/styles.scss';

const FormFieldItem = ({
	data,
	onEdit,
	onDelete,
	sortableHandle,
	isEditable,
}) => {
	const DragHandle = sortableHandle(() => (
		<div className="btn-drag">
			<DragOutlined />
		</div>
	));

	const renderFieldForm = () => {
		switch (data.type.alias) {
			case 'text':
				return <FieldText data={data} />;
			case 'number':
				return <FieldNumber data={data} />;
			case 'date':
				return <FieldDate data={data} />;
			case 'select':
				return <FieldSelect data={data} />;
			case 'radio':
				return <FieldRadio data={data} />;
			default:
				break;
		}
	};

	return isEditable ? (
		<Box className={`fn-content-item-container`}>
			<Section hoverable bordered p={0}>
				<Box
					p={30}
					className={`fn-content-item-body ${
						data.isActive ? 'is-active' : ''
					}`}>
					{renderFieldForm()}
				</Box>
			</Section>
			<Box textAlign="right" className="fn-content-item-actions">
				<DragHandle />
				<Button
					type="text"
					size="small"
					onClick={() => {
						onEdit && onEdit(data._id);
					}}>
					<EditFilled style={{ color: '#FFCC00' }} />
				</Button>
				&nbsp;
				<Button
					type="text"
					size="small"
					danger
					onClick={() => onDelete && onDelete(data._id)}>
					<DeleteFilled style={{ color: '#FF0000' }} />
				</Button>
			</Box>
		</Box>
	) : (
		renderFieldForm()
	);
};

export default FormFieldItem;
