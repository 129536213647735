import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchAdmins,
	fetchAdminsByOrganization,
	clearAdmins,
} from '@resources/Admin/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Admin = (props) => {
	const {
		organization,
		admins,
		fetchAdmins,
		fetchAdminsByOrganization,
		clearAdmins,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearAdmins();

			organization?._id
				? await fetchAdminsByOrganization(organization?._id)
				: await fetchAdmins();
		};

		func();
	}, [fetchAdmins, fetchAdminsByOrganization, clearAdmins, organization]);

	const parseDataSource = useCallback(() => {
		return admins.map((admin) => {
			return {
				key: admin._id,
				...admin,
			};
		});
	}, [admins]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.admin.management',
						defaultMessage: 'Admin Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.admins"
									defaultMessage="Admins"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.admin"
									defaultMessage="New Admin"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((admin, index) => (
							<AdminItem
								key={`admin-${index}`}
								admin={admin}
								onEdit={() => setUpdate(admin._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	admins,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		admins,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchAdmins,
	fetchAdminsByOrganization,
	clearAdmins,
})(Admin);
