import React from 'react';
import { connect } from 'react-redux';
import { Container, View } from '@common/components';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';

const { Content } = Layout;

const ServiceDetail = ({ event }) => {
	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Services</title>
			</Helmet>
			<Container>
				<View></View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ event }) => {
	return { event };
};

export default connect(mapStateToProps, {})(ServiceDetail);
