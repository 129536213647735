import React, { useCallback, useState, useEffect } from 'react';
import { DatePicker, Form, InputNumber, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchItemsByOrganization } from '@resources/Item/actions';
import { formErrorHandler } from '@common/utils/form';
import {
	CustomerSelectForm,
	SupplierSelectForm,
	StorageSpaceSelectForm,
} from '@fragments/Form';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

const StockForm = (props) => {
	const {
		module,
		data,
		onSubmit,
		fetchItemsByOrganization,
		match,
		onCancel,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchItemsByOrganization(organization?._id);
		};
		fetchData();
	}, [organization]);

	return (
		<Form
			form={form}
			name="stock-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				item: data?.item?._id,
				customer: data?.customer?._id,
				supplier: data?.supplier?._id,
				storage: data?.storage?._id,
				space: data?.space?._id,
				date: moment(data?.date),
			}}>
			<FormItem
				label={`Type`}
				name="type"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.type.required',
							defaultMessage: 'Please input type',
						}),
					},
				]}>
				<Select
					placeholder="Please select type"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					<Option value="purchase">Purchase</Option>
					<Option value="sale">Sale</Option>
				</Select>
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.type !== currentValues.type
				}>
				{({ getFieldValue }) => {
					return getFieldValue('type') === 'sale' ? (
						<CustomerSelectForm
							{...props}
							formItemProps={{
								name: 'customer',
								label: 'Customer',
							}}
						/>
					) : null;
				}}
			</Form.Item>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.type !== currentValues.type
				}>
				{({ getFieldValue }) => {
					return getFieldValue('type') === 'purchase' ? (
						<SupplierSelectForm
							{...props}
							formItemProps={{
								name: 'supplier',
								label: 'Supplier',
							}}
						/>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.quantity',
					defaultMessage: 'Quantity',
				})}
				name="quantity"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.quantity.required',
							defaultMessage: 'Please input quantity',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.quantity',
						defaultMessage: 'Quantity',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker />
			</FormItem>

			<StorageSpaceSelectForm {...props} form={form} formItemProps={{}} />

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, items, organization }) => {
	return {
		auth,
		items,
		organization,
	};
};

export default connect(mapStateToProps, { fetchItemsByOrganization })(
	StockForm
);
