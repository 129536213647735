import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
	clearEvents,
	fetchEventsPublic,
	fetchEventsByOrganization,
} from '@resources/Event/actions';
import { Box, Container, Title, View, Carousel } from '@common/components';
import { Button, Row, Col, Layout, Typography } from 'antd';
import { UpcomingEventItem, PastEventItem } from '@fragments/items';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Event = (props) => {
	const {
		events,
		history,
		organization,
		clearEvents,
		fetchEventsByOrganization,
	} = props;
	useEffect(() => {
		const fetchData = async () => {
			await clearEvents();
			organization?._id
				? await fetchEventsByOrganization(organization?._id)
				: await fetchEventsPublic();
		};

		fetchData();
	}, [organization]);
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	const [upcoming, setUpcoming] = useState(null);
	const [slide, setSlide] = useState(0);

	const settingsOrganizations = {
		dots: false,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		swipeToSlide: true,
		initialSlide: 0,
		infinite: upcoming?.length > 2,
		arrows: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: false,
					dots: true,
					arrows: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: false,
					dots: true,
					arrows: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	useEffect(() => {
		setUpcoming(events.filter((event) => event.isUpcoming));
		setTimeout(() => {
			slide && slide.slickGoTo(0);
		}, 1000);
	}, [events, slide]);

	const passed = useCallback(() => {
		return events.filter((event) => !event.isUpcoming);
	}, [events]);

	const handleClick = (event) => {
		history.push(
			`/${event.organization.alias}/events/${event._id}/details`
		);
	};

	return (
		<Content className="view">
			<Helmet>
				<title>Foreward Network | Events</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="space-between"
						align="middle"
						className="my-5">
						<Col>
							<Title m={0}>
								<FormattedMessage
									id="common.events"
									defaultMessage="Events"
								/>
							</Title>
						</Col>
						<Col>
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.event"
									defaultMessage="New Event"
								/>
							</Button>
						</Col>
					</Row>

					{upcoming && (
						<Box mb={20}>
							<Typography.Title level={3}>
								Upcoming
							</Typography.Title>

							<Carousel
								ref={(slider) => setSlide(slider)}
								{...settingsOrganizations}>
								{upcoming.map((event) => (
									<UpcomingEventItem
										event={event}
										onClick={handleClick}
										onEdit={() => setUpdate(event._id)}
									/>
								))}
							</Carousel>
						</Box>
					)}

					{passed() && (
						<>
							<Typography.Title level={3}>
								Past Events
							</Typography.Title>
							<Row type="flex" gutter={16}>
								{passed().map((event, index) => (
									<Col
										xs={24}
										sm={12}
										md={6}
										lg={6}
										key={`empty-post-${index}`}
										className="mb-4">
										<PastEventItem
											event={event}
											onClick={handleClick}
											onEdit={() => setUpdate(event._id)}
										/>
									</Col>
								))}
							</Row>
						</>
					)}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ site, events, organization }) => {
	return { site, events, organization };
};

export default connect(mapStateToProps, {
	clearEvents,
	fetchEventsByOrganization,
})(Event);
