import { API_ROOT } from '@config/api';
import {
	SET_THEMES,
	SET_THEME,
	ADD_THEME,
	UPDATE_THEME,
	CLEAR_THEME,
	CLEAR_THEMES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchThemes = () => (dispatch) =>
	request('get', `${API_ROOT}/themes`)
		.then((response) => {
			dispatch({
				type: SET_THEMES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchThemesByOrganization = (organization) => (dispatch) =>
	request('get', `${API_ROOT}/themes/organization/${organization}`)
		.then((response) => {
			dispatch({
				type: SET_THEMES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTheme = (id) => (dispatch) =>
	request('get', `${API_ROOT}/themes/${id}`)
		.then((response) => {
			dispatch({
				type: SET_THEME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createTheme = (data) => (dispatch) =>
	request('post', `${API_ROOT}/themes`, data)
		.then((response) => {
			dispatch({
				type: ADD_THEME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateTheme = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/themes/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_THEME,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearTheme = () => (dispatch) =>
	dispatch({
		type: CLEAR_THEME,
	});

const clearThemes = () => (dispatch) =>
	dispatch({
		type: CLEAR_THEMES,
	});

export {
	fetchThemes,
	fetchThemesByOrganization,
	fetchTheme,
	createTheme,
	updateTheme,
	clearTheme,
	clearThemes,
};
