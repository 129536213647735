import {
  SET_TASKS,
  SET_TASK,
  CLEAR_TASKS,
  CLEAR_TASK,
  ADD_TASK,
  UPDATE_TASK,
  REMOVE_TASK,
} from './actionTypes';

export const task = (state = {}, action) => {
  switch (action.type) {
    case SET_TASK:
      return action.payload;
    case UPDATE_TASK:
      if (state._id.toString() !== action.payload._id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_TASK:
      return {};
    default:
      return state;
  }
};

export default (state = [], action) => {
  switch (action.type) {
    case SET_TASKS:
      return action.payload;
    case ADD_TASK:
      return [...state, action.payload];
    case UPDATE_TASK:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
    case REMOVE_TASK:
      return state.map((item) => {
        if (item._id !== action.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_TASKS:
      return [];
    default:
      return state;
  }
};
