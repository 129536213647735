import {
	SET_FIELDS,
	SET_FIELD,
	CLEAR_FIELDS,
	CLEAR_FIELD,
	ADD_FIELD,
	UPDATE_FIELD,
	REMOVE_FIELD,
} from './actionTypes';

export const field = (state = {}, action) => {
	switch (action.type) {
		case SET_FIELD:
			return action.payload;
		case UPDATE_FIELD:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_FIELD:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_FIELDS:
			return action.payload;
		case ADD_FIELD:
			return [...state, action.payload];
		case UPDATE_FIELD:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_FIELD:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_FIELDS:
			return [];
		default:
			return state;
	}
};
