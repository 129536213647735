import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Title, Modal } from '@common/components';
import { fetchGuide } from '@resources/Guide/actions';
import {
	createInstruction,
	fetchInstructionsByOrganizationSubmodule,
} from '@resources/Instruction/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import styled from '@emotion/styled';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import { Button, Typography } from 'antd';
import { InstructionItem } from '@fragments/items';
import { InstructionCreate, InstructionUpdate } from '@containers/Instruction';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import reorder from '@common/components/Board/utils/reorder';
import { grid } from '@common/components/Board/utils/constants';

const portal = document.createElement('div');
portal.classList.add('my-super-cool-portal');
if (!document.body) {
	throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

const Detail = (props) => {
	const {
		module,
		instructions,
		fetchGuide,
		guide,
		visible,
		onCancel,
		fetchInstructionsByOrganizationSubmodule,
		organization,
		onReorder,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	const [data, setData] = useState(instructions);

	useEffect(() => {
		setData(instructions);
	}, [instructions]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchGuide(visible);
		};
		visible && fetchData();
	}, [fetchGuide, visible]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchInstructionsByOrganizationSubmodule(
				organization?._id,
				'guide',
				visible
			);
		};
		visible && !isEmpty(module) && fetchData();
	}, [
		fetchInstructionsByOrganizationSubmodule,
		visible,
		module,
		organization,
	]);

	const getList = (id) => data;

	const onDragEnd = (result) => {
		const { source, destination } = result;
		// dropped outside the list
		if (!destination) {
			return;
		}

		let newOrder = {};

		if (source.droppableId === destination.droppableId) {
			const items = reorder(getList(), source.index, destination.index);

			newOrder = items;
		}

		console.log(newOrder);

		onReorder && onReorder(newOrder);
		setData(newOrder);
	};

	const DraggableItem = styled.div`
		/* used for positioning the after content */
		position: relative;
		/* stylelint-disable  comment-empty-line-before */
		/* add little portal indicator when in a portal */
		${(props) =>
			props.inPortal
				? `
    ::after {
      position: absolute;
      background: lightgreen;
      padding: ${grid}px;
      bottom: 0;
      right: 0;
      content: "in portal";
    }
  `
				: ''} /* stylelint-enable */;
	`;

	const PortalItem = ({
		provided,
		snapshot,
		item,
		listItem,
		...restProps
	}) => {
		const usePortal = snapshot.isDragging;
		const child = (
			<DraggableItem
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				inPortal={usePortal}>
				<InstructionItem instruction={item} {...restProps} />
			</DraggableItem>
		);

		if (!usePortal) {
			return child;
		}

		// if dragging - put the item in a portal
		return ReactDOM.createPortal(child, portal);
	};

	const DraggableContainer = styled.div`
		margin: 0 auto;
		width: 100%;
		min-height: 20px;
	`;

	return (
		<Modal visible={visible} onCancel={() => onCancel()} width={1000}>
			<InstructionCreate
				{...props}
				submodule={{ code: 'guide', _id: guide._id }}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<InstructionUpdate
				{...props}
				submodule={{ code: 'guide', _id: guide._id }}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Title>
				{intl.formatMessage({
					id: 'common.update.guide',
					defaultMessage: 'Detail Guide',
				})}
			</Title>
			<Typography.Title>{guide.title}</Typography.Title>
			<Typography.Paragraph>{guide.detail}</Typography.Paragraph>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={'detail'}>
					{(droppableProvided) => (
						<DraggableContainer
							ref={droppableProvided.innerRef}
							{...droppableProvided.droppableProps}>
							{data.map((item, index) => (
								<Draggable
									draggableId={item._id}
									index={index}
									key={item._id}>
									{(draggableProvided, draggableSnapshot) => (
										<PortalItem
											item={item}
											provided={draggableProvided}
											snapshot={draggableSnapshot}
										/>
									)}
								</Draggable>
							))}
						</DraggableContainer>
					)}
				</Droppable>
			</DragDropContext>

			<Button type="primary" onClick={() => setCreate(true)}>
				New Instruction
			</Button>
		</Modal>
	);
};

const mapStateToProps = ({
	guide,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	instructions,
}) => {
	return {
		guide,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		instructions,
	};
};

export default connect(mapStateToProps, {
	fetchGuide,
	fetchModulesBySubmoduleCode,
	createInstruction,
	fetchInstructionsByOrganizationSubmodule,
})(Detail);
