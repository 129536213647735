import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchEventCategories,
	clearEventCategories,
} from '@resources/EventCategory/actions';
import isEmpty from 'lodash.isempty';

const FormItem = Form.Item;
const { Option } = Select;

const EventCategorySelectForm = (props) => {
	const {
		eventCategories,
		organization,
		fetchEventCategories,
		clearEventCategories,
		formItemProps,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await clearEventCategories();
			await fetchEventCategories();
		};
		fetchData();
	}, [organization]);

	const renderEventCategoryOptions = useCallback(() => {
		if (!isEmpty(eventCategories)) {
			return eventCategories.map((eventCategory, index) => {
				return (
					<Option
						value={eventCategory._id}
						key={`eventCategory-${index}`}
						title={eventCategory?.title}>
						{eventCategory?.title}
					</Option>
				);
			});
		}
	}, [eventCategories]);

	return (
		<FormItem
			label={'Event Category'}
			name={'category'}
			rules={[
				{
					required: true,
					message: intl.formatMessage({
						id: 'form.validation.eventCategory.required',
						defaultMessage: 'Please input event Category',
					}),
				},
			]}
			{...formItemProps}>
			<Select
				style={{ width: '100%' }}
				placeholder="Please select event Category"
				optionFilterProp="title"
				filterOption={(input, option) =>
					option.props.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}>
				{renderEventCategoryOptions()}
			</Select>
		</FormItem>
	);
};

const mapStateToProps = ({ organization, eventCategories }) => {
	return {
		organization,
		eventCategories,
	};
};

export default connect(mapStateToProps, {
	fetchEventCategories,
	clearEventCategories,
})(EventCategorySelectForm);
