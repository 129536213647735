import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Section, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchShops,
	fetchShopsByOrganization,
	clearShops,
} from '@resources/Shop/actions';
import {
	clearShopCategories,
	fetchShopCategories,
} from '@resources/ShopCategory/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShopItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Shop = (props) => {
	const {
		shops,
		history,
		fetchShops,
		fetchShopsByOrganization,
		clearShops,
		organization,
		clearShopCategories,
		fetchShopCategories,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearShops();
			organization?._id
				? await fetchShopsByOrganization(organization?._id)
				: await fetchShops();

			await clearShopCategories();
			await fetchShopCategories();
		};
		fetchData();

		return () => clearShops();
	}, [clearShops, fetchShops, fetchShopsByOrganization, organization]);

	const handleClick = (shop) => {
		history.push(`/${shop.organization.alias}/shops/${shop._id}/details`);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.shop.management',
						defaultMessage: 'Shop Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.shops"
									defaultMessage="Shops"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.shop"
									defaultMessage="New Shop"
								/>
							</Button>
						</Col>
					</Row>
					<Section className="mb-5">
						{shops?.map((shop, index) => (
							<ShopItem
								key={`shop-${index}`}
								shop={shop}
								onClick={handleClick}
							/>
						))}
					</Section>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, shops, shopCategories }) => {
	return {
		organization,
		shops,
		shopCategories,
	};
};

export default connect(mapStateToProps, {
	fetchShops,
	fetchShopsByOrganization,
	clearShops,
	clearShopCategories,
	fetchShopCategories,
})(Shop);
