import React from 'react';
import { Box, Section } from '@common/components';
import { Button, Row, Col, Typography, Tag } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Task = ({ item, onClick, onEdit, onDelete }) => {
	return (
		<Section onClick={() => onClick && onClick()}>
			<Row type="flex" className="login-links">
				<Col flex={1}>
					<Typography.Title level={4}>{item.title}</Typography.Title>
					<Typography.Paragraph
						ellipsis={{
							rows: 2,
							expandable: true,
							symbol: 'more',
						}}>
						{item.detail}
					</Typography.Paragraph>
					<Tag color="red" className={`mt-3`}>
						{item.organization.name}
					</Tag>
					<Tag color="green" className={`mt-3`}>
						{item.module.code}
					</Tag>
					<Tag color="orange" className={`mt-3`}>
						{item[item.module.code].title}
					</Tag>
					<Box textAlign="right">
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(item)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(item)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Box>
				</Col>
			</Row>
		</Section>
	);
};

export default Task;
