import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import InvitationForm from '../forms/Invitation';
import { fetchCrew, updateCrew } from '@resources/Crew/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchCrew, crew, updateCrew, visible, onSuccess, onCancel } = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchCrew(visible);
		};
		visible && fetchData();
	}, [fetchCrew, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateCrew(crew._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.crew.update',
							defaultMessage:
								'An existing Crew entry has been successfully updated',
						})
					);

					onCancel && onCancel();

					onSuccess && onSuccess();
				})
				.catch((err) => Promise.reject(err));
		},
		[crew]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.crew',
					defaultMessage: 'Update Crew',
				})}
			</Title>
			{visible && !isEmpty(crew) && (
				<InvitationForm
					data={crew}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ crew }) => {
	return {
		crew,
	};
};

export default connect(mapStateToProps, {
	fetchCrew,
	updateCrew,
})(Update);
