import React, { useEffect, useCallback, useState } from 'react';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
	clearGroup,
	clearGroups,
	fetchGroupsByOrganization,
	fetchGroups,
} from '@resources/Group/actions';
import { fetchMembersByOrganization } from '@resources/Member/actions';
import { formErrorHandler } from '@common/utils/form';

const FormItem = Form.Item;
const Option = Select.Option;

const GroupForm = ({
	data,
	groups,
	members,
	onSubmit,
	organization,
	fetchGroups,
	fetchGroupsByOrganization,
	fetchMembersByOrganization,
	clearGroup,
	onCancel,
	module,
	match,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}
			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			organization?._id
				? !module
					? (await fetchGroupsByOrganization(organization?._id)) &&
					  (await fetchMembersByOrganization(organization?._id))
					: await fetchGroupsByOrganization(organization?._id)
				: await fetchGroups();
		};
		fetchData();
	}, [
		clearGroups,
		clearGroup,
		fetchGroups,
		fetchGroupsByOrganization,
		fetchMembersByOrganization,
		organization,
	]);

	const renderGroupOptions = useCallback(() => {
		return groups
			?.filter((group) => (data ? group._id !== data._id : true))
			.map((group, index) => {
				return (
					<Option
						value={group._id}
						key={`group-${index}`}
						title={group.name}>
						{group.name}
					</Option>
				);
			});
	}, [data, groups]);

	const renderMemberOptions = useCallback(() => {
		return members?.map((member, index) => {
			return (
				<Option
					value={member._id}
					key={`member-${index}`}
					title={member.title}>
					{member.user.firstName} {member.user.lastName}
				</Option>
			);
		});
	}, [members]);

	return (
		<Form
			form={form}
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={
				data && {
					...data,
					leads: data?.leads?.map((lead) => lead._id),
					parent: data?.parent?._id,
				}
			}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.name',
					defaultMessage: 'Name',
				})}
				name="name"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.name.required',
							defaultMessage: 'Please input name',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.name',
						defaultMessage: 'Name',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			{!module && (
				<FormItem label={`Leads`} name="leads" rules={[]}>
					<Select
						mode="multiple"
						placeholder="Please select leads"
						style={{ width: '100%' }}
						optionFilterProp="title"
						filterOption={(input, option) =>
							option?.props?.children
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						}>
						{renderMemberOptions()}
					</Select>
				</FormItem>
			)}
			<FormItem
				label={intl.formatMessage({
					id: 'common.parent',
					defaultMessage: 'Parent',
				})}
				name="parent"
				rules={[]}>
				<Select
					showSearch
					placeholder={intl.formatMessage({
						id: 'form.placeholder.parent',
						defaultMessage: 'Please select a parent',
					})}
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) => {
						return (
							option.props.title
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						);
					}}>
					{renderGroupOptions()}
				</Select>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization, groups, members }) => {
	return {
		auth,
		organization,
		groups,
		members,
	};
};

export default connect(mapStateToProps, {
	clearGroup,
	clearGroups,
	fetchGroupsByOrganization,
	fetchMembersByOrganization,
	fetchGroups,
})(GroupForm);
