import { API_ROOT } from '@config/api';
import {
	SET_TEAMS,
	SET_TEAM,
	ADD_TEAM,
	UPDATE_TEAM,
	CLEAR_TEAM,
	CLEAR_TEAMS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchTeams = () => (dispatch) =>
	request('get', `${API_ROOT}/teams`)
		.then((response) => {
			dispatch({
				type: SET_TEAMS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTeamsByOrganizationModule = (organization, module, id) => (
	dispatch
) =>
	request(
		'get',
		`${API_ROOT}/teams/organization/${organization}/module/${module}/${id}`
	)
		.then((response) => {
			dispatch({
				type: SET_TEAMS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchTeam = (id) => (dispatch) =>
	request('get', `${API_ROOT}/teams/${id}`)
		.then((response) => {
			dispatch({
				type: SET_TEAM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createTeam = (data) => (dispatch) =>
	request('post', `${API_ROOT}/teams`, data)
		.then((response) => {
			dispatch({
				type: ADD_TEAM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateTeam = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/teams/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_TEAM,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearTeam = () => (dispatch) =>
	dispatch({
		type: CLEAR_TEAM,
	});

const clearTeams = () => (dispatch) =>
	dispatch({
		type: CLEAR_TEAMS,
	});

export {
	fetchTeams,
	fetchTeamsByOrganizationModule,
	fetchTeam,
	createTeam,
	updateTeam,
	clearTeam,
	clearTeams,
};
