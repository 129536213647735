import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	Container,
	Title,
	View,
	Section,
} from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchCrews,
	fetchCrewsByOrganizationModule,
	clearCrews,
} from '@resources/Crew/actions';
import {
	fetchTeams,
	fetchTeamsByOrganizationModule,
	clearTeams,
} from '@resources/Team/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { TeamItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

import CreateTeamModal from '@containers/Team/Create';
import UpdateTeamModal from '@containers/Team/Update';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { Content } = Layout;

const Crew = (props) => {
	const {
		teams,
		crews,
		fetchCrews,
		fetchCrewsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearCrews,
		fetchTeams,
		fetchTeamsByOrganizationModule,
		clearTeams,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	const [createTeam, setCreateTeam] = useState(false);
	const [updateTeam, setUpdateTeam] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('crew');
			await clearCrews();
			await clearTeams();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchCrewsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchCrews();

			organization?._id && modByParam
				? await fetchTeamsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchTeams();
		};

		func();
	}, [
		fetchCrews,
		fetchModulesBySubmoduleCode,
		fetchCrewsByOrganizationModule,
		clearCrews,
		fetchTeams,
		fetchTeamsByOrganizationModule,
		clearTeams,
		organization,
	]);

	const settingsOrganizations = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: false,
					dots: true,
				},
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.crew.management',
						defaultMessage: 'Crew Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>

			<CreateTeamModal
				{...props}
				module={module}
				visible={createTeam}
				onCancel={() => setCreateTeam(false)}
			/>

			<UpdateTeamModal
				{...props}
				module={module}
				visible={updateTeam}
				onCancel={() => setUpdateTeam(false)}
			/>
			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.crews"
									defaultMessage="Crews"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							{/* <Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.crew"
									defaultMessage="New Crew"
								/>
							</Button> */}
						</Col>
					</Row>
					<Slider {...settingsOrganizations}>
						{teams &&
							teams.map((team) => (
								<TeamItem
									team={team}
									crews={crews}
									onEdit={() => setUpdateTeam(team._id)}
									onAddTeamMember={(position) =>
										setCreate(position)
									}
								/>
							))}
						<Box className="p-5">
							<Section hoverable shadowed>
								<Button
									style={{ width: '100%' }}
									onClick={() => setCreateTeam(true)}>
									Add Team
								</Button>
							</Section>
						</Box>
					</Slider>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	crews,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
	teams,
}) => {
	return {
		crews,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
		teams,
	};
};

export default connect(mapStateToProps, {
	fetchCrews,
	fetchCrewsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearCrews,
	fetchTeams,
	fetchTeamsByOrganizationModule,
	clearTeams,
})(Crew);
