export const moduleByParam = (modules, params) => {
  const mod = modules.filter((module) => module.url === params['moduleUrl']);
  return mod.length > 0 && mod[0];
};

export const moduleBreadcrumbs = (organization, module, mod) => {
  return organization && mod
    ? [
        {
          path:
            organization && module && mod
              ? `/organizations/${organization._id}/details/${module.url}`
              : `/${module.url}`,
          title: module.title,
        },
        {
          path:
            organization && module && mod
              ? `/organizations/${organization._id}/details/${module.url}/${mod._id}/details`
              : `/${module.url}/${mod._id}`,
          title: mod.title,
        },
      ]
    : [];
};
