import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchSpaces,
	fetchSpacesByOrganizationModule,
	clearSpaces,
} from '@resources/Space/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { SpaceItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Space = (props) => {
	const {
		spaces,
		fetchSpaces,
		fetchSpacesByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearSpaces,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('space');
			await clearSpaces();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchSpacesByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchSpaces();
		};

		func();
	}, [
		fetchSpaces,
		fetchModulesBySubmoduleCode,
		fetchSpacesByOrganizationModule,
		clearSpaces,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return spaces.map((space) => {
			return {
				key: space._id,
				...space,
			};
		});
	}, [spaces]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.space.management',
						defaultMessage: 'Space Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.space"
									defaultMessage="Space"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.spaceItem"
									defaultMessage="New Space Item"
								/>
							</Button>
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((space, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									key={`empty-post-${index}`}
									className="mt-4">
									<SpaceItem
										space={space}
										onEdit={() => setUpdate(space._id)}
									/>
								</Col>
							))}
					</Row>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	spaces,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
}) => {
	return {
		spaces,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchSpaces,
	fetchSpacesByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearSpaces,
})(Space);
