import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { popIn } from '@common/utils/services';
import RentalForm from '../Form';
import {
	fetchRental,
	updateRental,
	clearRental,
} from '@resources/Rental/actions';
import { Title, Modal } from '@common/components';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';

const Update = (props) => {
	const {
		fetchRental,
		rental,
		updateRental,
		visible,
		clearRental,
		module,
		onCancel,
	} = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchRental(visible);
		};
		visible ? fetchData() : clearRental();
	}, [fetchRental, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateRental(rental._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.rental.update',
							defaultMessage:
								'An existing Rental entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[rental]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.rental',
					defaultMessage: 'Update Rental',
				})}
			</Title>
			{!isEmpty(rental) && (
				<RentalForm
					data={rental}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	rental,
	event,
	facility,
	ministry,
	mission,
	organization,
	modules,
}) => {
	return {
		rental,
		event,
		facility,
		ministry,
		mission,
		organization,
		modules,
	};
};

export default connect(mapStateToProps, {
	fetchRental,
	updateRental,
	fetchModulesBySubmoduleCode,
	clearRental,
})(Update);
