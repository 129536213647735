import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import MissionForm from '../Form';
import { fetchMission, updateMission } from '@resources/Mission/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchMission, mission, updateMission, visible, onCancel } = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchMission(visible);
		};
		visible && fetchData();
	}, [fetchMission, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateMission(mission._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.mission.update',
							defaultMessage:
								'An existing Mission entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[mission]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.mission',
					defaultMessage: 'Update Mission',
				})}
			</Title>
			{!isEmpty(mission) && (
				<MissionForm
					data={mission}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ mission }) => {
	return {
		mission,
	};
};

export default connect(mapStateToProps, {
	fetchMission,
	updateMission,
})(Update);
