import { API_ROOT } from '@config/api';
import {
	SET_FIELDS,
	SET_FIELD,
	ADD_FIELD,
	UPDATE_FIELD,
	CLEAR_FIELD,
} from './actionTypes';
import request from '@common/utils/request';

const doReorder = (order) => () =>
	request('post', `${API_ROOT}/fields/reorder`, order)
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchFields = () => (dispatch) =>
	request('get', `${API_ROOT}/fields`)
		.then((response) => {
			dispatch({
				type: SET_FIELDS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchFieldsByForm = (id) => (dispatch) =>
	request('get', `${API_ROOT}/fields/form/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FIELDS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchField = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/fields/${id}`)
		.then((response) => {
			dispatch({
				type: SET_FIELD,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createField = (data) => (dispatch) =>
	request('post', `${API_ROOT}/fields`, data)
		.then((response) => {
			dispatch({
				type: ADD_FIELD,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateField = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/fields/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_FIELD,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearField = () => (dispatch) =>
	dispatch({
		type: CLEAR_FIELD,
	});

export {
	doReorder,
	fetchFields,
	fetchFieldsByForm,
	fetchField,
	createField,
	updateField,
	clearField,
};
