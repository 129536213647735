import { API_ROOT } from '@config/api';
import {
	SET_SHOP_CATEGORIES,
	SET_SHOP_CATEGORY,
	ADD_SHOP_CATEGORY,
	UPDATE_SHOP_CATEGORY,
	CLEAR_SHOP_CATEGORY,
	CLEAR_SHOP_CATEGORIES,
} from './actionTypes';
import request from '@common/utils/request';

const fetchShopCategories = (type) => (dispatch) =>
	request('get', `${API_ROOT}/shopCategories`)
		.then((response) => {
			dispatch({
				type: SET_SHOP_CATEGORIES,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchShopCategory = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/shopCategories/${id}`)
		.then((response) => {
			dispatch({
				type: SET_SHOP_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createShopCategory = (data) => (dispatch) =>
	request('post', `${API_ROOT}/shopCategories`, data)
		.then((response) => {
			dispatch({
				type: ADD_SHOP_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateShopCategory = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/shopCategories/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_SHOP_CATEGORY,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearShopCategory = () => (dispatch) =>
	dispatch({
		type: CLEAR_SHOP_CATEGORY,
	});

const clearShopCategories = () => (dispatch) =>
	dispatch({
		type: CLEAR_SHOP_CATEGORIES,
	});

export {
	fetchShopCategories,
	fetchShopCategory,
	createShopCategory,
	updateShopCategory,
	clearShopCategory,
	clearShopCategories,
};
