import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ProjectForm from '../Form';
import { fetchProject, updateProject } from '@resources/Project/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchProject, project, updateProject, visible, onCancel } = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchProject(visible);
		};
		visible && fetchData();
	}, [fetchProject, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateProject(project._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.project.update',
							defaultMessage:
								'An existing Project entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[project]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.project',
					defaultMessage: 'Update Project',
				})}
			</Title>
			{!isEmpty(project) && (
				<ProjectForm
					data={project}
					onSubmit={handleSubmit}
					{...props}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({ project }) => {
	return {
		project,
	};
};

export default connect(mapStateToProps, {
	fetchProject,
	updateProject,
})(Update);
