import {
	SET_MEMBERS,
	SET_MEMBER,
	CLEAR_MEMBERS,
	CLEAR_MEMBER,
	ADD_MEMBER,
	UPDATE_MEMBER,
	REMOVE_MEMBER,
} from './actionTypes';

export const member = (state = {}, action) => {
	switch (action.type) {
		case SET_MEMBER:
			return action.payload;
		case UPDATE_MEMBER:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_MEMBER:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_MEMBERS:
			return action.payload;
		case ADD_MEMBER:
			return [...state, action.payload];
		case UPDATE_MEMBER:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_MEMBER:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_MEMBERS:
			return [];
		default:
			return state;
	}
};
