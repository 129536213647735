import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchActivities,
	fetchActivitiesByOrganizationModule,
	clearActivities,
} from '@resources/Activity/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { ActivityItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Activity = (props) => {
	const {
		activities,
		fetchActivities,
		fetchActivitiesByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearActivities,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('activity');
			await clearActivities();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchActivitiesByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchActivities();
		};

		func();
	}, [
		fetchActivities,
		fetchModulesBySubmoduleCode,
		fetchActivitiesByOrganizationModule,
		clearActivities,
	]);

	const parseDataSource = useCallback(() => {
		return activities.map((activity) => {
			return {
				key: activity._id,
				...activity,
			};
		});
	}, [activities]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.activity.management',
						defaultMessage: 'Activity Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.activity"
									defaultMessage="Activity"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.activityItem"
									defaultMessage="New Activity Item"
								/>
							</Button>
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((activity, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={12}
									key={`empty-post-${index}`}
									className="mt-4">
									<ActivityItem
										activity={activity}
										onEdit={() => setUpdate(activity._id)}
									/>
								</Col>
							))}
					</Row>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	siteTheme,
	activities,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
}) => {
	return {
		siteTheme,
		activities,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchActivities,
	fetchActivitiesByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearActivities,
})(Activity);
