import React from 'react';
import { Form, Select } from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;

const FieldSelect = ({ data }) => {
	const { label, name, required, options, selection } = data || {};

	return (
		<FormItem label={label} name={name} required={required}>
			<Select
				mode={selection === 'multiple' ? selection : undefined}
				placeholder={`Please select ${label}`}
				optionFilterProp="title"
				filterOption={(input, option) =>
					option.props.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}>
				{options.map((option, idx) => (
					<Option key={`option-${idx}`} value={option.value}>
						{option.option}
					</Option>
				))}
			</Select>
		</FormItem>
	);
};

export default FieldSelect;
