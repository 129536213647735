import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ItemForm from '../Form';
import { fetchItem, updateItem } from '@resources/Item/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { module, fetchItem, item, updateItem, visible, onCancel } = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchItem(visible);
		};
		visible && fetchData();
	}, [fetchItem, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateItem(item._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.item.update',
							defaultMessage:
								'An existing Item entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[item]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.item',
					defaultMessage: 'Update Item',
				})}
			</Title>
			{!isEmpty(item) && visible && (
				<ItemForm
					data={item}
					onSubmit={handleSubmit}
					{...props}
					module={module}
				/>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	item,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		item,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchItem,
	updateItem,
})(Update);
