import React from 'react';
import { Box, Section, Text } from '@common/components';
import { Button, Row, Col, Typography } from 'antd';
import { DeleteFilled, EditFilled, CheckCircleFilled } from '@ant-design/icons';
import moment from 'moment';

const Purchase = ({ stock, onClick, onEdit, onDelete, onComplete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick(stock)}>
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links" align="middle">
					<Col flex={1}>
						<Row type="flex" align="middle">
							<Col md={4}>
								{moment(stock?.date).format('DD MMM YYYY')}
							</Col>
							<Col md={4}>
								<Text fontSize={20}>{stock.quantity}</Text>
							</Col>
							<Col md={6}>
								<Typography.Title
									level={4}
									style={{ margin: 0, lineHeight: 1 }}>
									{stock.item?.title}
								</Typography.Title>
							</Col>
							<Col>
								<Typography.Title
									level={4}
									style={{ margin: 0, lineHeight: 1 }}>
									{stock?.supplier?.name ||
										'No supplier specified'}
								</Typography.Title>
							</Col>
						</Row>
					</Col>
					<Col style={{ width: 50 }}>
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(stock)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}

						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(stock)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Col>
					{onComplete && (
						<Col style={{ width: 50 }}>
							<Button
								size="small"
								type="text"
								onClick={() => onComplete && onComplete(stock)}>
								<CheckCircleFilled
									style={{ color: 'green', fontSize: 30 }}
								/>
							</Button>
						</Col>
					)}
				</Row>
			</Section>
		</Box>
	);
};

export default Purchase;
