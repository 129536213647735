import React from 'react';
import { Box } from '@common/components';
import { Divider, Row, Col, Typography } from 'antd';

const Header = () => {
	return (
		<Box textAlign="left">
			<Row type="flex" gutter={24} className="login-links" align="middle">
				<Col md={2}></Col>
				<Col md={11}>
					<Typography.Text strong>Product</Typography.Text>
				</Col>
				<Col md={4}>
					<Typography.Text strong>Price</Typography.Text>
				</Col>
				<Col md={3}>
					<Typography.Text strong>Qty</Typography.Text>
				</Col>
				<Col md={4}>
					<Typography.Text strong>Subtotal</Typography.Text>
				</Col>
			</Row>
			<Divider />
		</Box>
	);
};

export default Header;
