import React, { useCallback, useState } from 'react';
import {
	Form,
	Input,
	Button,
	Checkbox,
	DatePicker,
	TimePicker,
	InputNumber,
	Row,
	Col,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearClass } from '@resources/Class/actions';
import { formErrorHandler } from '@common/utils/form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const FormItem = Form.Item;

const ClassForm = ({ data, onSubmit, organization, clearClass, onCancel }) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			if (organization?._id) {
				values['organization'] = organization?._id;
			}
			onSubmit(values)
				.then(() => {
					setLoading(false);
					form.resetFields();
				})
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={data}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>
			<Form.List name="timings" wrapperCol={{ span: 24 }}>
				{(fields, { add, remove }) => (
					<>
						{fields.map((field) => (
							<Row key={field.key}>
								<Col flex={1}>
									<FormItem
										{...field}
										label={`Date`}
										name={[field.name, 'date']}
										fieldKey={[field.fieldKey, 'date']}
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id:
														'form.validation.date.required',
													defaultMessage:
														'Please input date',
												}),
											},
										]}>
										<DatePicker
											disabledDate={disabledDate}
										/>
									</FormItem>
								</Col>
								<Col flex={2}>
									<FormItem
										{...field}
										label={intl.formatMessage({
											id: 'common.time',
											defaultMessage: 'Time',
										})}
										name={[field.name, 'time']}
										fieldKey={[field.fieldKey, 'time']}
										rules={[
											{
												required: true,
												message: intl.formatMessage({
													id:
														'form.validation.time.required',
													defaultMessage:
														'Please input time',
												}),
											},
										]}>
										<TimePicker.RangePicker />
									</FormItem>
								</Col>
								<Col style={{ width: 30, paddingTop: 14 }}>
									<MinusCircleOutlined
										onClick={() => remove(field.name)}
									/>
								</Col>
							</Row>
						))}

						<Form.Item wrapperCol={{ span: 24 }}>
							<Button
								style={{ width: '100%' }}
								type="dashed"
								onClick={() => add()}
								icon={<PlusOutlined />}>
								Add class timing
							</Button>
						</Form.Item>
					</>
				)}
			</Form.List>
			<FormItem
				label={intl.formatMessage({
					id: 'common.capacity',
					defaultMessage: 'Capacity',
				})}
				name="capacity"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.capacity.required',
							defaultMessage: 'Please input capacity',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.capacity',
						defaultMessage: 'Capacity',
					})}
				/>
			</FormItem>
			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem layout="vertical" className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, organization }) => {
	return {
		auth,
		organization,
	};
};

export default connect(mapStateToProps, { clearClass })(ClassForm);
