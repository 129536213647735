import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchTransactionCategories,
	clearTransactionCategories,
} from '@resources/TransactionCategory/actions';
import isEmpty from 'lodash.isempty';

const FormItem = Form.Item;
const { Option } = Select;

const TransactionCategorySelectForm = (props) => {
	const {
		transactionCategories,
		organization,
		fetchTransactionCategories,
		clearTransactionCategories,
		formTransactionCategoryProps,
	} = props;
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await clearTransactionCategories();
			await fetchTransactionCategories();
		};
		fetchData();
	}, [organization]);

	const renderTransactionCategoryOptions = useCallback(() => {
		if (!isEmpty(transactionCategories)) {
			return transactionCategories.map((transactionCategory, index) => {
				return (
					<Option
						value={transactionCategory._id}
						key={`transactionCategory-${index}`}
						title={transactionCategory?.title}>
						{transactionCategory?.title}
					</Option>
				);
			});
		}
	}, [transactionCategories]);

	return (
		<FormItem
			label={'Transaction Category'}
			name={'transactionCategory'}
			rules={[
				{
					required: true,
					message: intl.formatMessage({
						id: 'form.validation.transaction Category.required',
						defaultMessage: 'Please input transaction Category',
					}),
				},
			]}
			{...formTransactionCategoryProps}>
			<Select
				style={{ width: '100%' }}
				placeholder="Please select transaction Category"
				optionFilterProp="title"
				filterOption={(input, option) =>
					option.props.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}>
				{renderTransactionCategoryOptions()}
			</Select>
		</FormItem>
	);
};

const mapStateToProps = ({ organization, transactionCategories }) => {
	return {
		organization,
		transactionCategories,
	};
};

export default connect(mapStateToProps, {
	fetchTransactionCategories,
	clearTransactionCategories,
})(TransactionCategorySelectForm);
