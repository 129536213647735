import React from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import InstructionForm from '../Form';
import { createInstruction } from '@resources/Instruction/actions';
import { useIntl } from 'react-intl';

const Create = (props) => {
	const { createInstruction, visible, onCancel } = props;
	const intl = useIntl();
	const handleSubmit = (data) => {
		return createInstruction(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.instruction.create',
						defaultMessage:
							'A new Instruction has been successfully created',
					})
				);
				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.instruction',
					defaultMessage: 'Create Instruction',
				})}
			</Title>
			<InstructionForm onSubmit={handleSubmit} {...props} />
		</Modal>
	);
};

const mapStateToProps = ({ organization }) => {
	return {
		organization,
	};
};

export default connect(mapStateToProps, { createInstruction })(Create);
