import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import OrderForm from '../Form';
import { fetchStock, updateStock } from '@resources/Stock/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';

const Update = (props) => {
	const { fetchStock, stock, updateStock, visible, onCancel } = props;
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			await fetchStock(visible);
		};
		visible && fetchData();
	}, [fetchStock, visible]);

	const handleSubmit = useCallback(
		(data) => {
			return updateStock(stock._id, data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.order.update',
							defaultMessage:
								'An existing Order entry has been successfully updated',
						})
					);

					onCancel && onCancel();
				})
				.catch((err) => Promise.reject(err));
		},
		[stock]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.stock',
					defaultMessage: 'Update Order',
				})}
			</Title>
			{!isEmpty(stock) && (
				<OrderForm data={stock} onSubmit={handleSubmit} {...props} />
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	stock,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
}) => {
	return {
		stock,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
	};
};

export default connect(mapStateToProps, {
	fetchStock,
	updateStock,
})(Update);
