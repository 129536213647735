import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import TaskForm from '../Form';
import { createTask } from '@resources/Task/actions';
import { useIntl } from 'react-intl';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import { moduleByParam } from '@common/utils/module';

const Create = (props) => {
	const {
		modules,
		createTask,
		match,
		fetchModulesBySubmoduleCode,
		visible,
		onCancel,
	} = props;
	const intl = useIntl();
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			await fetchModulesBySubmoduleCode('task');
		};

		func();
	}, [fetchModulesBySubmoduleCode]);

	useEffect(() => {
		setModule(moduleByParam(modules, match?.params || {}));
	}, [modules]);

	const handleSubmit = (data) => {
		return createTask(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.task.create',
						defaultMessage:
							'A new Task has been successfully created',
					})
				);

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.task',
					defaultMessage: 'Create Task',
				})}
			</Title>
			<TaskForm onSubmit={handleSubmit} {...props} module={module} />
		</Modal>
	);
};

const mapStateToProps = ({
	tasks,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		tasks,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	createTask,
	fetchModulesBySubmoduleCode,
})(Create);
