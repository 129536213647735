import {
	SET_FORMS,
	SET_FORM,
	CLEAR_FORMS,
	CLEAR_FORM,
	ADD_FORM,
	UPDATE_FORM,
	REMOVE_FORM,
} from './actionTypes';

export const forma = (state = {}, action) => {
	switch (action.type) {
		case SET_FORM:
			return action.payload;
		case UPDATE_FORM:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_FORM:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_FORMS:
			return action.payload;
		case ADD_FORM:
			return [...state, action.payload];
		case UPDATE_FORM:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_FORM:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_FORMS:
			return [];
		default:
			return state;
	}
};
