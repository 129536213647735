import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchEntities,
	fetchEntitiesByOrganization,
	clearEntities,
} from '@resources/Entity/actions';
import isEmpty from 'lodash.isempty';
import CreateEntityModal from '@containers/Entity/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const EntitySelectForm = (props) => {
	const {
		entities,
		organization,
		fetchEntities,
		fetchEntitiesByOrganization,
		clearEntities,
		formItemProps,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			await clearEntities();
			organization?._id
				? await fetchEntitiesByOrganization(organization?._id)
				: await fetchEntities();
		};
		fetchData();
	}, [organization]);

	const renderEntityOptions = useCallback(() => {
		if (!isEmpty(entities)) {
			return entities.map((entity, index) => {
				return (
					<Option
						value={entity._id}
						key={`entity-${index}`}
						title={entity?.name}>
						{entity?.name}
					</Option>
				);
			});
		}
	}, [entities]);

	return (
		<>
			<CreateEntityModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<FormItem
				label={`Entity`}
				required
				style={{ marginBottom: 0 }}
				{...formItemProps}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'entity'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.entity.required',
										defaultMessage: 'Please input entity',
									}),
								},
							]}>
							<Select
								style={{ width: '100%' }}
								placeholder="Please select entity"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderEntityOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreate(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, entities }) => {
	return {
		organization,
		entities,
	};
};

export default connect(mapStateToProps, {
	fetchEntities,
	fetchEntitiesByOrganization,
	clearEntities,
})(EntitySelectForm);
