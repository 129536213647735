import {
	SET_MINISTRIES,
	SET_MINISTRY,
	CLEAR_MINISTRIES,
	CLEAR_MINISTRY,
	ADD_MINISTRY,
	UPDATE_MINISTRY,
	REMOVE_MINISTRY,
} from './actionTypes';

export const ministry = (state = {}, action) => {
	switch (action.type) {
		case SET_MINISTRY:
			return action.payload;
		case UPDATE_MINISTRY:
			if (state._id.toString() !== action.payload._id.toString()) {
				return state;
			}

			// Otherwise, this is the one we want - return an updated value
			return {
				...state,
				...action.payload,
			};
		case CLEAR_MINISTRY:
			return {};
		default:
			return state;
	}
};

export default (state = [], action) => {
	switch (action.type) {
		case SET_MINISTRIES:
			return action.payload;
		case ADD_MINISTRY:
			return [...state, action.payload];
		case UPDATE_MINISTRY:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
					...action.payload,
				};
			});
		case REMOVE_MINISTRY:
			return state.map((item) => {
				if (item._id !== action.payload._id) {
					return item;
				}

				// Otherwise, this is the one we want - return an updated value
				return {
					...item,
				};
			});
		case CLEAR_MINISTRIES:
			return [];
		default:
			return state;
	}
};
