import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchGroupsByOrganization,
	fetchGroupsByOrganizationModule,
	clearGroups,
} from '@resources/Group/actions';
import isEmpty from 'lodash.isempty';
import CreateGroupModal from '@containers/Group/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const GroupSelectForm = (props) => {
	const {
		groups,
		organization,
		fetchGroupsByOrganization,
		fetchGroupsByOrganizationModule,
		clearGroups,
		formItemProps = {},
		module,
		match,
	} = props;

	const { label, required, ...restFormItemProps } = formItemProps;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			console.log(props);
			await clearGroups();

			if (organization) {
				if (module) {
					await fetchGroupsByOrganizationModule(
						organization?._id,
						module._id,
						match?.params[`moduleId`]
					);
				} else {
					organization?._id &&
						(await fetchGroupsByOrganization(organization?._id));
				}
			}
		};
		fetchData();
	}, [organization, module]);

	const renderGroupOptions = useCallback(() => {
		if (!isEmpty(groups)) {
			return groups.map((supplier, index) => {
				return (
					<Option
						value={supplier._id}
						key={`supplier-${index}`}
						title={supplier?.name}>
						{supplier?.name}
					</Option>
				);
			});
		}
	}, [groups]);

	return (
		<>
			<CreateGroupModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<FormItem
				label={label || `Groups`}
				required={required}
				style={{ marginBottom: 0 }}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'groups'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.group.required',
										defaultMessage: 'Please input group',
									}),
								},
							]}
							{...restFormItemProps}>
							<Select
								style={{ width: '100%' }}
								placeholder="Please select group"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderGroupOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreate(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, groups }) => {
	return {
		organization,
		groups,
	};
};

export default connect(mapStateToProps, {
	fetchGroupsByOrganization,
	fetchGroupsByOrganizationModule,
	clearGroups,
})(GroupSelectForm);
