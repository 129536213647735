import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchThemes,
	fetchThemesByOrganization,
	clearThemes,
} from '@resources/Theme/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { ThemeItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Theme = (props) => {
	const {
		themes,
		fetchThemes,
		fetchThemesByOrganization,
		clearThemes,
		organization,
	} = props;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const func = async () => {
			await clearThemes();

			organization?._id
				? await fetchThemesByOrganization(organization?._id)
				: await fetchThemes();
		};

		func();
	}, [fetchThemes, fetchThemesByOrganization, clearThemes, organization]);

	const parseDataSource = useCallback(() => {
		return themes.map((theme) => {
			return {
				key: theme._id,
				...theme,
			};
		});
	}, [themes]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.item.management',
						defaultMessage: 'Theme Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>

			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title primary>
								<FormattedMessage
									id="common.items"
									defaultMessage="Themes"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => {
									setCreate(true);
								}}>
								<FormattedMessage
									id="common.new.item"
									defaultMessage="New Theme"
								/>
							</Button>
						</Col>
					</Row>
					{parseDataSource() &&
						parseDataSource().map((item, index) => (
							<ThemeItem
								key={`item-${index}`}
								item={item}
								onEdit={() => setUpdate(item._id)}
							/>
						))}
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	items,
	themes,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		themes,
		items,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchThemes,
	fetchThemesByOrganization,
	clearThemes,
})(Theme);
