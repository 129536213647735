import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchCart } from '../../resources/Cart/actions';
import { Container, Title, View } from '../../components';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

const { Content } = Layout;

const Cart = ({ fetchCart }) => {
	const intl = useIntl();
	useEffect(() => {
		const fetchData = async () => {
			await fetchCart();
		};

		fetchData();
	}, []);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.cart',
						defaultMessage: 'Cart',
					})}
				</title>
			</Helmet>

			<View>
				<Container>
					<Title>
						<FormattedMessage
							id="common.cart"
							defaultMessage="Cart"
						/>
					</Title>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({ cart }) => {
	return {
		cart,
	};
};

export default connect(mapStateToProps, { fetchCart })(Cart);
