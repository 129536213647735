import React from 'react';
import { connect } from 'react-redux';
import { Section, Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import GuideForm from '../Form';
import { createGuide } from '@resources/Guide/actions';
import { useIntl } from 'react-intl';

const Create = (props) => {
	const { module, createGuide, visible, onCancel } = props;
	const intl = useIntl();

	const handleSubmit = (data) => {
		return createGuide(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.guide.create',
						defaultMessage:
							'A new Guide has been successfully created',
					})
				);

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal visible={visible} onCancel={() => onCancel && onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.guide',
					defaultMessage: 'Create Guide',
				})}
			</Title>
			<Section className="mb-5">
				<GuideForm onSubmit={handleSubmit} {...props} module={module} />
			</Section>
		</Modal>
	);
};

const mapStateToProps = ({
	guides,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		guides,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	createGuide,
})(Create);
