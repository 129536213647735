import React, { useState, useCallback } from 'react';
import { Avatar, Menu } from 'antd';
import { UserOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import {
	FOREWARD_APP_USER,
	FOREWARD_APP_ORGANIZATION,
} from '@common/config/app';
import { getCookie } from '@common/utils/cookie';
const SubMenu = Menu.SubMenu;

const RightMenu = (props) => {
	const authCookie = getCookie('auth');
	const { auth, location } = props;
	const [current, setCurrent] = useState(location.pathname);

	const initials = useCallback(() => {
		let init = 'User';
		if (authCookie && !isEmpty(auth)) {
			const { firstName, lastName } = auth;
			if (firstName || lastName) {
				init = '';
			}

			if (firstName) {
				init += firstName.substring(0, 1);
			}

			if (lastName) {
				init += lastName.substring(0, 1);
			}

			return init;
		}

		return init;
	}, [auth]);

	const handleClick = (e) => {
		setCurrent(e.key);
	};

	const menus = useCallback(
		(menuProps) =>
			authCookie && !isEmpty(auth) ? (
				window.matchMedia('(min-width: 650px)').matches ? (
					<Menu {...menuProps} current={current}>
						<SubMenu
							key="account"
							title={
								<Avatar
									style={{
										backgroundColor: '#333',
										verticalAlign: 'middle',
										color: '#FFF',
									}}
									size="small">
									{initials()}
								</Avatar>
							}>
							<Menu.Item
								key="/logout"
								onClick={() => {
									window.location.href = `${FOREWARD_APP_USER}/logout?s=${FOREWARD_APP_ORGANIZATION}`;
								}}>
								Logout
							</Menu.Item>
						</SubMenu>
					</Menu>
				) : (
					<Menu {...menuProps}>
						<Menu.Item
							key="/logout"
							onClick={() => {
								window.location.href = `${FOREWARD_APP_USER}/logout?s=${FOREWARD_APP_ORGANIZATION}`;
							}}>
							Logout
						</Menu.Item>
					</Menu>
				)
			) : (
				<Menu {...menuProps}>
					<Menu.Item key="/register" icon={<UserAddOutlined />}>
						<Link to="/register" className="link">
							Register
						</Link>
					</Menu.Item>
					<Menu.Item
						key="/login"
						icon={<UserOutlined />}
						onClick={() => {
							window.location.href = `${FOREWARD_APP_USER}/login?s=${window.location.href}`;
						}}>
						Login
					</Menu.Item>
				</Menu>
			),
		[auth]
	);

	if (window.matchMedia('(min-width: 650px)').matches) {
		return menus({ mode: 'horizontal', selectedKeys: [location.pathname] });
	} else {
		return menus({
			mode: 'inline',
			selectedKeys: [location.pathname],
			defaultOpenKeys: ['setting'],
			onClick: handleClick,
		});
	}
};

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	};
};

export default connect(mapStateToProps, {})(RightMenu);
