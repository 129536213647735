import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchJournals,
	fetchJournalsByOrganizationModule,
	clearJournals,
} from '@resources/Journal/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { JournalItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Journal = (props) => {
	const {
		journals,
		fetchJournals,
		fetchJournalsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearJournals,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('journal');
			await clearJournals();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchJournalsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchJournals();
		};

		func();
	}, [
		fetchJournals,
		fetchModulesBySubmoduleCode,
		fetchJournalsByOrganizationModule,
		clearJournals,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return journals.map((journal) => {
			return {
				key: journal._id,
				...journal,
			};
		});
	}, [journals]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.journal.management',
						defaultMessage: 'Journal Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<View>
				<Container>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.journal"
									defaultMessage="Journal"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.journalItem"
									defaultMessage="New Journal Item"
								/>
							</Button>
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((journal, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									key={`empty-post-${index}`}
									className="mt-4">
									<JournalItem
										journal={journal}
										onEdit={() => setUpdate(journal._id)}
									/>
								</Col>
							))}
					</Row>
				</Container>
			</View>
		</Content>
	);
};

const mapStateToProps = ({
	journals,
	modules,
	event,
	facility,
	cellGroup,
	ministry,
	organization,
	mission,
	service,
}) => {
	return {
		journals,
		modules,
		event,
		facility,
		cellGroup,
		ministry,
		organization,
		mission,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchJournals,
	fetchJournalsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearJournals,
})(Journal);
