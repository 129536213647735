import {
	ADD_EVENT,
	UPDATE_EVENT,
	REMOVE_EVENT,
} from '@resources/Event/actionTypes';

import {
	ADD_ORGANIZATION,
	UPDATE_ORGANIZATION,
	REMOVE_ORGANIZATION,
} from '@common/resources/Organization/actionTypes';

const Refresh = (noti) => (dispatch) => {
	console.log('NOTI', noti);
	switch (noti.module) {
		case 'event':
			if (noti.action === 'create') {
				dispatch({
					type: ADD_EVENT,
					payload: noti.data,
				});
			} else if (noti.action === 'update') {
				dispatch({
					type: UPDATE_EVENT,
					payload: noti.data,
				});
			} else if (noti.action === 'delete') {
				dispatch({
					type: REMOVE_EVENT,
					payload: noti.data,
				});
			}
			break;
		case 'organization':
			if (noti.action === 'create') {
				dispatch({
					type: ADD_ORGANIZATION,
					payload: noti.data,
				});
			} else if (noti.action === 'update') {
				dispatch({
					type: UPDATE_ORGANIZATION,
					payload: noti.data,
				});
			} else if (noti.action === 'delete') {
				dispatch({
					type: REMOVE_ORGANIZATION,
					payload: noti.data,
				});
			}
			break;
		default:
	}
};

export default Refresh;
