import React from 'react';
import { Form, InputNumber } from 'antd';
const FormItem = Form.Item;

const FieldText = ({ data }) => {
	const { label, name, required, min, max, step } = data || {};

	return (
		<FormItem label={label} name={name} required={required}>
			<InputNumber min={min} max={max} step={step} />
		</FormItem>
	);
};

export default FieldText;
