import React, { useCallback, useEffect, useState } from 'react';
import {
	DatePicker,
	Form,
	Input,
	InputNumber,
	Button,
	Checkbox,
	Switch,
	TimePicker,
	Upload,
	Select,
} from 'antd';
import { AccountSelectForm, EventCategorySelectForm } from '@fragments';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { API_MEDIA } from '@common/config/api';
import { deleteMedia } from '@resources/Media/actions';
import { clearEvent } from '@resources/Event/actions';
import ImgCrop from 'antd-img-crop';
import moment from 'moment';
import { getCookie } from '@common/utils/cookie';
import { fetchFormsByOrganization } from '@resources/Form/actions';
import isEmpty from 'lodash.isempty';

const FormItem = Form.Item;
const Option = Select.Option;

const EventForm = ({
	organization,
	formas,
	data,
	onSubmit,
	onCancel,
	deleteMedia,
	fetchFormsByOrganization,
}) => {
	const intl = useIntl();
	const [form] = Form.useForm();

	const authToken = getCookie('auth');
	const [loading, setLoading] = useState(false);
	const [banners, setBanners] = useState(
		data && data.banner
			? [
					{
						uid: 'banner',
						name: data.banner.originalName,
						url: data.banner.path,
						response: { data: [data.banner] },
					},
			  ]
			: []
	);

	const [thumbnails, setThumbnails] = useState(
		data && data.thumbnail
			? [
					{
						uid: 'thumbnail',
						name: data.thumbnail.originalName,
						url: data.thumbnail.path,
						response: { data: [data.thumbnail] },
					},
			  ]
			: []
	);

	const [images, setImages] = useState(
		data && data.images
			? data.images.map((image, index) => {
					return {
						uid: `images-${index}`,
						name: image.originalName,
						url: image.path,
						response: { data: [image] },
					};
			  })
			: []
	);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			const convertedValues = { ...values };

			convertedValues['banner'] =
				banners &&
				banners.length > 0 &&
				banners[0].response &&
				banners[0].response.data &&
				banners[0].response.data.length > 0
					? banners[0].response.data[0]._id
					: undefined;

			convertedValues['thumbnail'] =
				thumbnails &&
				thumbnails.length > 0 &&
				thumbnails[0].response &&
				thumbnails[0].response.data &&
				thumbnails[0].response.data.length > 0
					? thumbnails[0].response.data[0]._id
					: undefined;

			convertedValues['images'] =
				images && images.length > 0
					? images
							.filter(
								(image) =>
									image.response &&
									image.response.data &&
									image.response.data.length > 0
							)
							.map((image) => {
								return image.response &&
									image.response.data &&
									image.response.data.length > 0
									? image.response.data[0]._id
									: undefined;
							})
					: undefined;

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => setLoading(false));
		},
		[onSubmit, images, thumbnails, banners, organization]
	);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = (set) => ({ fileList: newFileList }) => {
		set(newFileList);
	};

	const handleRemove = useCallback(
		(set, sources) => (file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					set(
						sources.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia]
	);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	useEffect(() => {
		const fetchData = async () => {
			await fetchFormsByOrganization(organization?._id);
		};

		fetchData();
	}, [fetchFormsByOrganization]);

	const renderFormOptions = useCallback(
		(type) => {
			if (!isEmpty(formas)) {
				return formas
					.filter((forma) => (type ? forma.type === type : true))
					.map((forma, index) => {
						return (
							<Option
								value={forma._id}
								key={`forma-${index}`}
								title={forma.title}>
								{forma.title}
							</Option>
						);
					});
			}
		},
		[formas]
	);

	return (
		<Form
			form={form}
			name="event-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				date: data?.date?.map((date) => moment(date)),
				time: data?.time?.map((time) => moment(time)),
				account: data?.account?._id,
				category: data?.category?._id,
			}}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker.RangePicker disabledDate={disabledDate} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.all.day',
					defaultMessage: 'All Day',
				})}
				name="allDay"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.allDay !== currentValues.allDay
				}>
				{({ getFieldValue }) => {
					return !getFieldValue('allDay') ? (
						<FormItem
							label={intl.formatMessage({
								id: 'common.time',
								defaultMessage: 'Time',
							})}
							name="time"
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.time.required',
										defaultMessage: 'Please input time',
									}),
								},
							]}>
							<TimePicker.RangePicker />
						</FormItem>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.address',
					defaultMessage: 'Address',
				})}
				name="address"
				rules={[]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.address',
						defaultMessage: 'Address',
					})}
				/>
			</FormItem>

			<EventCategorySelectForm />

			<FormItem
				label={intl.formatMessage({
					id: 'common.paid',
					defaultMessage: 'Paid',
				})}
				name="paid"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.paid !== currentValues.paid
				}>
				{({ getFieldValue }) => {
					return getFieldValue('paid') ? (
						<>
							<AccountSelectForm />
						</>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.limit',
					defaultMessage: 'Limit',
				})}
				name="limit"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.limit.required',
							defaultMessage: 'Please input limit',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.limit',
						defaultMessage: 'Limit',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.maxPurchase',
					defaultMessage: 'Max Purchase',
				})}
				name="maxPurchase"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.maxPurchase.required',
							defaultMessage: 'Please input max purchase',
						}),
					},
				]}>
				<InputNumber
					placeholder={intl.formatMessage({
						id: 'common.maxPurchase',
						defaultMessage: 'Max Purchase',
					})}
				/>
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.paymentType !== currentValues.paymentType
				}>
				{({ getFieldValue }) => {
					return getFieldValue('paymentType') === 'flat' ? (
						<>
							<FormItem
								label={intl.formatMessage({
									id: 'common.flatFeeDesciption',
									defaultMessage: 'Flat fee desciption',
								})}
								name="flatFeeDescription"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.flatFeeDesciption.required',
											defaultMessage:
												'Please input flat fee description',
										}),
									},
								]}>
								<Input
									placeholder={intl.formatMessage({
										id: 'common.flatFeeDescription',
										defaultMessage: 'Flat fee description',
									})}
								/>
							</FormItem>

							<FormItem
								label={intl.formatMessage({
									id: 'common.flatFee',
									defaultMessage: 'Flat fee',
								})}
								name="flatFee"
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.flatFee.required',
											defaultMessage:
												'Please input flat fee',
										}),
									},
								]}>
								<InputNumber
									placeholder={intl.formatMessage({
										id: 'common.flatFee',
										defaultMessage: 'Flat fee',
									})}
								/>
							</FormItem>
						</>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.banner',
					defaultMessage: 'Banner',
				})}
				name="banner">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/event`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={banners}
						onPreview={handlePreview}
						onChange={handleChange(setBanners)}
						onRemove={handleRemove(setBanners, banners)}>
						{banners.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.thumbnail',
					defaultMessage: 'Thumbnail',
				})}
				name="thumbnail">
				<ImgCrop rotate aspect={1}>
					<Upload
						action={`${API_MEDIA}/image/event`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={thumbnails}
						onPreview={handlePreview}
						onChange={handleChange(setThumbnails)}
						onRemove={handleRemove(setThumbnails, thumbnails)}>
						{thumbnails.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.images',
					defaultMessage: 'Images',
				})}
				name="images">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/event`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={images}
						onPreview={handlePreview}
						onChange={handleChange(setImages)}
						onRemove={handleRemove(setImages)}>
						{images.length > 4 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<Form.List name="forms" wrapperCol={{ span: 24 }}>
				{() => (
					<>
						<FormItem label={`Participant Form`} name="participant">
							<Select
								placeholder="Please select participant"
								style={{ width: '100%' }}
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderFormOptions('participant')}
							</Select>
						</FormItem>

						<FormItem label={`Review Form`} name="review">
							<Select
								placeholder="Please select review"
								style={{ width: '100%' }}
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderFormOptions('review')}
							</Select>
						</FormItem>
					</>
				)}
			</Form.List>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, formas, organization }) => {
	return {
		auth,
		formas,
		organization,
	};
};

export default connect(mapStateToProps, {
	deleteMedia,
	clearEvent,
	fetchFormsByOrganization,
})(EventForm);
