import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchParticipants,
	fetchParticipantsByOrganizationModule,
	clearParticipants,
} from '@resources/Participant/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { ParticipantItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Participant = (props) => {
	const {
		participants,
		fetchParticipants,
		fetchParticipantsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearParticipants,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	// useEffect(() => {
	// 	subscribe({
	// 		channel: 'privates',
	// 		events: [
	// 			{
	// 				id: 'test',
	// 				func: () => {
	// 					alert('hello');
	// 				},
	// 			},
	// 		],
	// 	});
	// }, []);

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('participant');
			await clearParticipants();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchParticipantsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchParticipants();
		};

		func();
	}, [
		fetchParticipants,
		fetchModulesBySubmoduleCode,
		fetchParticipantsByOrganizationModule,
		clearParticipants,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return participants.map((participant) => {
			return {
				key: participant._id,
				...participant,
			};
		});
	}, [participants]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.participant.management',
						defaultMessage: 'Participant Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="mt-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.participants"
									defaultMessage="Participants"
								/>
							</Title>
						</Col>

						<Col md={{ span: 12 }} className="text-right">
							{props[module.code]?.isFree && (
								<Button
									type="primary"
									onClick={() => setCreate(true)}>
									<FormattedMessage
										id="common.new.participant"
										defaultMessage="New Participant"
									/>
								</Button>
							)}
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((participant, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									key={`empty-post-${index}`}
									className="mt-4">
									<ParticipantItem
										participant={participant}
										onEdit={() =>
											setUpdate(participant._id)
										}
									/>
								</Col>
							))}
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	participants,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	service,
}) => {
	return {
		participants,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		service,
	};
};

export default connect(mapStateToProps, {
	fetchParticipants,
	fetchParticipantsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearParticipants,
})(Participant);
