import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FormFieldItem from '../Detail/Item';
import { fetchForm } from '@resources/Form/actions';
import { fetchFieldsByForm } from '@resources/Field/actions';
import { sortableHandle } from 'react-sortable-hoc';

const FormField = ({ form, fields, fetchForm, fetchFieldsByForm }) => {
	useEffect(() => {
		const fetchData = async () => {
			await fetchForm(form);
			await fetchFieldsByForm(form);
		};
		form && fetchData();
	}, [fetchForm, form]);

	return fields && fields.length > 0
		? fields.map((field, index) => (
				<FormFieldItem
					key={`field-${index}`}
					data={field}
					sortableHandle={sortableHandle}
					isEditable={false}
				/>
		  ))
		: null;
};

const mapStateToProps = ({ fields }) => {
	return {
		fields,
	};
};

export default connect(mapStateToProps, {
	fetchForm,
	fetchFieldsByForm,
})(FormField);
