import { API_ROOT } from '@config/api';
import {
	SET_PRAYERS,
	SET_PRAYER,
	ADD_PRAYER,
	UPDATE_PRAYER,
	CLEAR_PRAYERS,
	CLEAR_PRAYER,
} from './actionTypes';
import request from '@common/utils/request';

const fetchPrayers = () => (dispatch) =>
	request('get', `${API_ROOT}/prayers`)
		.then((response) => {
			dispatch({
				type: SET_PRAYERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPrayersByOrganization = (id) => (dispatch) =>
	request('get', `${API_ROOT}/prayers/organization/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PRAYERS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchPrayer = (id, type) => (dispatch) =>
	request('get', `${API_ROOT}/prayers/${id}`)
		.then((response) => {
			dispatch({
				type: SET_PRAYER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createPrayer = (data) => (dispatch) =>
	request('post', `${API_ROOT}/prayers`, data)
		.then((response) => {
			dispatch({
				type: ADD_PRAYER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updatePrayer = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/prayers/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_PRAYER,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearPrayers = () => (dispatch) =>
	dispatch({
		type: CLEAR_PRAYERS,
	});

const clearPrayer = () => (dispatch) =>
	dispatch({
		type: CLEAR_PRAYER,
	});

export {
	fetchPrayers,
	fetchPrayersByOrganization,
	fetchPrayer,
	createPrayer,
	updatePrayer,
	clearPrayers,
	clearPrayer,
};
