import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import {
	fetchCellGroups,
	fetchCellGroupsByOrganization,
	clearCellGroups,
} from '@resources/CellGroup/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { CellGroupItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const CellGroup = (props) => {
	const {
		history,
		cellGroups,
		fetchCellGroups,
		fetchCellGroupsByOrganization,
		clearCellGroups,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await clearCellGroups();

			organization?._id
				? await fetchCellGroupsByOrganization(organization?._id)
				: await fetchCellGroups();
		};
		organization?._id && fetchData();
	}, [
		clearCellGroups,
		fetchCellGroups,
		fetchCellGroupsByOrganization,
		organization,
	]);

	const handleClick = (cellGroup) => {
		history.push(
			`/${cellGroup.organization.alias}/cellGroups/${cellGroup._id}/details`
		);
	};

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.cellGroup.management',
						defaultMessage: 'Cell Group Management',
					})}
				</title>
			</Helmet>

			<CreateModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.cellGroups"
									defaultMessage="Cell Groups"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							{organization.access.includes('cellGroup') && (
								<Button
									type="primary"
									onClick={() => setCreate(true)}>
									<FormattedMessage
										id="common.new.cellGroup"
										defaultMessage="New Cell Group"
									/>
								</Button>
							)}
						</Col>
					</Row>
					<Box className="mb-5">
						{cellGroups?.map((cellGroup) => (
							<CellGroupItem
								cellGroup={cellGroup}
								onClick={handleClick}
								onEdit={
									organization.access.includes('cellGroup') &&
									(() => setUpdate(cellGroup._id))
								}
							/>
						))}
					</Box>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({ organization, cellGroups }) => {
	return {
		organization,
		cellGroups,
	};
};

export default connect(mapStateToProps, {
	fetchCellGroups,
	fetchCellGroupsByOrganization,
	clearCellGroups,
})(CellGroup);
