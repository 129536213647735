import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Title, View } from '@common/components';
import { Row, Col, Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import {
	fetchProducts,
	fetchProductsByOrganizationModule,
	clearProducts,
} from '@resources/Product/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { moduleByParam } from '@common/utils/module';
import { ProductItem } from '@fragments/items';
import CreateModal from './Create';
import UpdateModal from './Update';

const { Content } = Layout;

const Product = (props) => {
	const {
		products,
		fetchProducts,
		fetchProductsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearProducts,
		match,
		organization,
	} = props;
	const intl = useIntl();

	const [create, setCreate] = useState(false);
	const [update, setUpdate] = useState(false);
	const [module, setModule] = useState({});

	useEffect(() => {
		const func = async () => {
			const { data } = await fetchModulesBySubmoduleCode('product');
			await clearProducts();
			const param = match?.params || {};
			const modByParam = moduleByParam(data?.data, param);

			setModule(modByParam);

			organization?._id && modByParam
				? await fetchProductsByOrganizationModule(
						organization?._id,
						modByParam && modByParam._id,
						param[`moduleId`]
				  )
				: await fetchProducts();
		};

		func();
	}, [
		fetchProducts,
		fetchProductsByOrganizationModule,
		fetchModulesBySubmoduleCode,
		clearProducts,
		organization,
	]);

	const parseDataSource = useCallback(() => {
		return products.map((product) => {
			return {
				key: product._id,
				...product,
			};
		});
	}, [products]);

	return (
		<Content>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: 'common.product.management',
						defaultMessage: 'Product Management',
					})}
				</title>
			</Helmet>
			<CreateModal
				{...props}
				module={module}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<UpdateModal
				{...props}
				module={module}
				visible={update}
				onCancel={() => setUpdate(false)}
			/>
			<Container>
				<View>
					<Row
						type="flex"
						justify="center"
						align="middle"
						className="my-5">
						<Col md={{ span: 12 }}>
							<Title>
								<FormattedMessage
									id="common.products"
									defaultMessage="Products"
								/>
							</Title>
						</Col>
						<Col md={{ span: 12 }} className="text-right">
							<Button
								type="primary"
								onClick={() => setCreate(true)}>
								<FormattedMessage
									id="common.new.product"
									defaultMessage="New Product"
								/>
							</Button>
						</Col>
					</Row>
					<Row type="flex" gutter={16}>
						{parseDataSource() &&
							parseDataSource().map((product, index) => (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={8}
									key={`empty-post-${index}`}
									className="mt-4">
									<ProductItem
										product={product}
										onEdit={() => setUpdate(product._id)}
									/>
								</Col>
							))}
					</Row>
				</View>
			</Container>
		</Content>
	);
};

const mapStateToProps = ({
	products,
	modules,
	event,
	shop,
	cellGroup,
	organization,
}) => {
	return {
		products,
		modules,
		event,
		cellGroup,
		organization,
		shop,
	};
};

export default connect(mapStateToProps, {
	fetchProducts,
	fetchProductsByOrganizationModule,
	fetchModulesBySubmoduleCode,
	clearProducts,
})(Product);
