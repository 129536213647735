import React from 'react';
import { Box, Section, Image } from '@common/components';
import { Button, Row, Col, Typography, Space } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';

const Product = ({ product, onClick, onEdit, onDelete }) => {
	return (
		<Box className="p-3" onClick={() => onClick && onClick()}>
			<Section className="mb-3" p={0} hoverable shadowed>
				{product?.thumbnail?.path && (
					<Image src={product?.thumbnail?.path} width="100%" />
				)}
				<Box p={20} textAlign="left">
					<Row
						type="flex"
						className="login-links mb-4"
						justify="start">
						<Col flex={1}>
							<Space direction="vertical">
								<Typography.Text>
									{product.name}
								</Typography.Text>
								<Row>
									<Col>
										<Typography.Text>
											Non-member:{' '}
											{product?.currency?.symbol}{' '}
											{product?.price?.basic} | Member:{' '}
											{product?.currency?.symbol}{' '}
											{product?.price?.member}
										</Typography.Text>
									</Col>
								</Row>
							</Space>
						</Col>
					</Row>

					<Box textAlign="right">
						{onEdit && (
							<Button
								size="small"
								type="text"
								onClick={() => onEdit && onEdit(product)}>
								<EditFilled style={{ color: '#FFCC00' }} />
							</Button>
						)}
						{onDelete && (
							<Button
								size="small"
								type="text"
								onClick={() => onDelete && onDelete(product)}>
								<DeleteFilled style={{ color: '#FF0000' }} />
							</Button>
						)}
					</Box>
				</Box>
			</Section>
		</Box>
	);
};

export default Product;
