import accounts, { account } from '@resources/Account/reducers';
import activities, { activity } from '@resources/Activity/reducers';
import accesses, { access } from '@resources/Access/reducers';
import actions, { action } from '@resources/Action/reducers';
import admins, { admin } from '@resources/Admin/reducers';
import auth from '@common/resources/Auth/reducers';
import apps, { app } from '@resources/App/reducers';
import bookings, { booking } from '@resources/Booking/reducers';
import cart from '@common/resources/Cart/reducers';
import cellGroups, { cellGroup } from '@resources/CellGroup/reducers';
import classes, { classy } from '@resources/Class/reducers';
import comments, { comment } from '@resources/Comment/reducers';
import contents, { content } from '@resources/Content/reducers';
import contentTypes, { contentType } from '@resources/ContentType/reducers';
import crews, { crew } from '@resources/Crew/reducers';
import currencies, { currency } from '@resources/Currency/reducers';
import customers, { customer } from '@resources/Customer/reducers';
import departments, { department } from '@resources/Department/reducers';
import discussions, { discussion } from '@resources/Discussion/reducers';
import entities, { entity } from '@resources/Entity/reducers';
import facilities, { facility } from '@resources/Facility/reducers';
import fields, { field } from '@resources/Field/reducers';
import fieldTypes, { fieldType } from '@resources/FieldType/reducers';
import formas, { forma } from '@resources/Form/reducers';
import site from '@resources/Site/reducers';
import siteTheme from '@common/resources/SiteTheme/reducers';
import events, { event } from '@resources/Event/reducers';
import eventCategories, {
	eventCategory,
} from '@resources/EventCategory/reducers';
import groups, { group } from '@resources/Group/reducers';
import guests, { guest } from '@resources/Guest/reducers';
import guides, { guide } from '@resources/Guide/reducers';
import instructions, { instruction } from '@resources/Instruction/reducers';
import items, { item } from '@resources/Item/reducers';
import itemTypes, { itemType } from '@resources/ItemType/reducers';
import journals, { journal } from '@resources/Journal/reducers';
import members, { member } from '@resources/Member/reducers';
import ministries, { ministry } from '@resources/Ministry/reducers';
import missions, { mission } from '@resources/Mission/reducers';
import modules, { module } from '@resources/Module/reducers';
import organizations, {
	organization,
} from '@common/resources/Organization/reducers';
import pages, { page } from '@resources/Page/reducers';
import participants, { participant } from '@resources/Participant/reducers';
import positions, { position } from '@resources/Position/reducers';
import prayers, { prayer } from '@resources/Prayer/reducers';
import preferences, { preference } from '@resources/Preference/reducers';
import products, { product } from '@resources/Product/reducers';
import programmes, { programme } from '@resources/Programme/reducers';
import projects, { project } from '@resources/Project/reducers';
import rentables, { rentable } from '@resources/Rentable/reducers';
import rentals, { rental } from '@resources/Rental/reducers';
import reviews, { review } from '@resources/Review/reducers';
import shops, { shop } from '@resources/Shop/reducers';
import shopCategories, { shopCategory } from '@resources/ShopCategory/reducers';
import spaces, { space } from '@resources/Space/reducers';
import services, { service } from '@resources/Service/reducers';
import stocks, { stock } from '@resources/Stock/reducers';
import storages, { storage } from '@resources/Storage/reducers';
import submodules, { submodule } from '@resources/Submodule/reducers';
import subscriptions, { subscription } from '@resources/Subscription/reducers';
import suppliers, { supplier } from '@resources/Supplier/reducers';
import tasks, { task } from '@resources/Task/reducers';
import teams, { team } from '@resources/Team/reducers';
import themes, { theme } from '@resources/Theme/reducers';
import tithes, { tithe } from '@resources/Tithe/reducers';
import transactions, {
	transaction,
} from '@common/resources/Transaction/reducers';
import transactionCategories, {
	transactionCategory,
} from '@resources/TransactionCategory/reducers';
import venues, { venue } from '@resources/Venue/reducers';

import users, { user } from '@resources/User/reducers';

export const reducers = {
	accounts,
	account,
	activities,
	activity,
	accesses,
	access,
	actions,
	action,
	admins,
	admin,
	auth,
	apps,
	app,
	bookings,
	booking,
	cart,
	cellGroups,
	cellGroup,
	classes,
	classy,
	comments,
	comment,
	contents,
	content,
	contentTypes,
	contentType,
	crews,
	crew,
	currencies,
	currency,
	customers,
	customer,
	departments,
	department,
	discussions,
	discussion,
	entities,
	entity,
	events,
	event,
	eventCategories,
	eventCategory,
	facilities,
	facility,
	fields,
	field,
	fieldTypes,
	fieldType,
	formas,
	forma,
	groups,
	group,
	guests,
	guest,
	guides,
	guide,
	instructions,
	instruction,
	items,
	item,
	itemTypes,
	itemType,
	journals,
	journal,
	members,
	member,
	ministries,
	ministry,
	missions,
	mission,
	modules,
	module,
	organizations,
	organization,
	pages,
	page,
	participants,
	participant,
	prayers,
	prayer,
	preferences,
	preference,
	positions,
	position,
	products,
	product,
	programmes,
	programme,
	projects,
	project,
	rentables,
	rentable,
	rentals,
	rental,
	reviews,
	review,
	shops,
	shop,
	shopCategories,
	shopCategory,
	spaces,
	space,
	services,
	service,
	stocks,
	stock,
	storages,
	storage,
	submodules,
	submodule,
	subscriptions,
	subscription,
	suppliers,
	supplier,
	site,
	siteTheme,
	tasks,
	task,
	teams,
	team,
	themes,
	theme,
	tithes,
	tithe,
	transactions,
	transaction,
	transactionCategories,
	transactionCategory,
	users,
	user,
	venues,
	venue,
};
