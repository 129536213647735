import React from 'react';
import { Box, Section } from '@common/components';
import { Row, Col, Typography } from 'antd';

const Facility = ({ facility, onClick }) => {
	return (
		<Box className="p-3">
			<Section className="mb-3" hoverable shadowed>
				<Row type="flex" className="login-links">
					<Col flex={1} onClick={() => onClick && onClick(facility)}>
						<Typography.Title level={3}>
							{facility.title}
						</Typography.Title>
						<Typography.Text level={3}>
							{facility.description}
						</Typography.Text>
					</Col>
				</Row>
			</Section>
		</Box>
	);
};

export default Facility;
