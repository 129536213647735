import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import CommentForm from '../../Comment/Form';
import { fetchDiscussion } from '@resources/Discussion/actions';
import {
	createComment,
	fetchCommentsByOrganizationSubmodule,
} from '@resources/Comment/actions';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash.isempty';
import { fetchModulesBySubmoduleCode } from '@resources/Module/actions';
import { Affix, Typography } from 'antd';
import { CommentItem } from '@fragments/items';

const Detail = (props) => {
	const {
		module,
		comments,
		fetchDiscussion,
		discussion,
		visible,
		onCancel,
		createComment,
		fetchCommentsByOrganizationSubmodule,
		organization,
	} = props;
	const intl = useIntl();
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			await fetchDiscussion(visible);
		};
		visible && fetchData();
	}, [fetchDiscussion, visible]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchCommentsByOrganizationSubmodule(
				organization?._id,
				'discussion',
				visible
			);

			setTimeout(() => {
				setLoaded(true);
			}, 300);
		};
		visible && !isEmpty(module) && fetchData();
	}, [fetchCommentsByOrganizationSubmodule, visible, module, organization]);

	const handleSubmit = useCallback(
		(data) => {
			return createComment(data)
				.then((response) => {
					popIn(
						'success',
						intl.formatMessage({
							id: 'message.success.discussion.update',
							defaultMessage:
								'An existing Discussion entry has been successfully updated',
						})
					);
				})
				.catch((err) => Promise.reject(err));
		},
		[discussion]
	);

	return (
		<Modal visible={visible} onCancel={() => onCancel()}>
			<Title>
				{intl.formatMessage({
					id: 'common.update.discussion',
					defaultMessage: 'Detail Discussion',
				})}
			</Title>
			<Typography.Title>{discussion.title}</Typography.Title>
			<Typography.Paragraph>{discussion.detail}</Typography.Paragraph>

			<Box mb={100}>
				{!isEmpty(comments) &&
					comments.map((comment, index) => (
						<CommentItem
							key={`comment-${index}`}
							comment={comment}
						/>
					))}
			</Box>
			{!isEmpty(discussion) && loaded && (
				<Affix offsetBottom={20}>
					<CommentForm
						onSubmit={handleSubmit}
						{...props}
						submodule={{
							code: 'discussion',
							_id: discussion._id,
						}}
					/>
				</Affix>
			)}
		</Modal>
	);
};

const mapStateToProps = ({
	discussion,
	modules,
	cellGroup,
	event,
	facility,
	ministry,
	mission,
	organization,
	prayer,
	testimony,
	comments,
}) => {
	return {
		discussion,
		modules,
		cellGroup,
		event,
		facility,
		ministry,
		mission,
		organization,
		prayer,
		testimony,
		comments,
	};
};

export default connect(mapStateToProps, {
	fetchDiscussion,
	fetchModulesBySubmoduleCode,
	createComment,
	fetchCommentsByOrganizationSubmodule,
})(Detail);
