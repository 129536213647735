import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThemeContext } from 'styled-components';

const Arrow = (props) => {
	const theme = useContext(ThemeContext);
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{
				...style,
				display: 'block',
				background: theme.base.primary,
				borderRadius: 25,
			}}
			onClick={onClick}>
			ce
		</div>
	);
};

const Carousel = (props) => {
	return <Slider {...props} nextArrow={<Arrow />} prevArrow={<Arrow />} />;
};

export default Carousel;
