import { API_ROOT } from '@config/api';
import {
	SET_ACCOUNTS,
	SET_ACCOUNT,
	ADD_ACCOUNT,
	UPDATE_ACCOUNT,
	CLEAR_ACCOUNT,
	CLEAR_ACCOUNTS,
} from './actionTypes';
import request from '@common/utils/request';

const fetchAccounts = (filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/accounts?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_ACCOUNTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAccountsByOrganization = (id, filter) => (dispatch) =>
	request(
		'get',
		`${API_ROOT}/accounts/organization/${id}?${
			filter ? `&filter=${JSON.stringify(filter)}` : ''
		}`
	)
		.then((response) => {
			dispatch({
				type: SET_ACCOUNTS,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAccountBySession = (id) => (dispatch) =>
	request('get', `${API_ROOT}/accounts/session/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ACCOUNT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const fetchAccount = (id) => (dispatch) =>
	request('get', `${API_ROOT}/accounts/${id}`)
		.then((response) => {
			dispatch({
				type: SET_ACCOUNT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const createAccount = (data) => (dispatch) =>
	request('post', `${API_ROOT}/accounts`, data)
		.then((response) => {
			dispatch({
				type: ADD_ACCOUNT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => Promise.reject(err));

const updateAccount = (id, data) => (dispatch) =>
	request('post', `${API_ROOT}/accounts/${id}`, data)
		.then((response) => {
			dispatch({
				type: UPDATE_ACCOUNT,
				payload: response.data.data,
			});
			return Promise.resolve(response);
		})
		.catch((err) => {
			return Promise.reject(err);
		});

const clearAccount = () => (dispatch) =>
	dispatch({
		type: CLEAR_ACCOUNT,
	});

const clearAccounts = () => (dispatch) =>
	dispatch({
		type: CLEAR_ACCOUNTS,
	});

export {
	fetchAccounts,
	fetchAccountsByOrganization,
	fetchAccount,
	fetchAccountBySession,
	createAccount,
	updateAccount,
	clearAccount,
	clearAccounts,
};
