import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
	fetchFacilities,
	fetchFacilitiesByOrganization,
	clearFacilities,
} from '@resources/Facility/actions';
import isEmpty from 'lodash.isempty';
import CreateFacilityModal from '@containers/Facility/Create';
import { PlusOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const { Option } = Select;

const FacilitySelectForm = (props) => {
	const {
		facilities,
		organization,
		fetchFacilities,
		fetchFacilitiesByOrganization,
		clearFacilities,
		formItemProps = {},
	} = props;

	const { label, required, ...restFormItemProps } = formItemProps;
	const intl = useIntl();
	const [create, setCreate] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			await clearFacilities();
			organization?._id
				? await fetchFacilitiesByOrganization(organization?._id)
				: await fetchFacilities();
		};
		fetchData();
	}, [organization]);

	const renderFacilityOptions = useCallback(() => {
		if (!isEmpty(facilities)) {
			return facilities.map((facility, index) => {
				return (
					<Option
						value={facility._id}
						key={`facility-${index}`}
						title={facility?.title}>
						{facility?.title}
					</Option>
				);
			});
		}
	}, [facilities]);

	return (
		<>
			<CreateFacilityModal
				{...props}
				visible={create}
				onCancel={() => setCreate(false)}
			/>
			<FormItem
				label={label || `Facility`}
				required={required}
				style={{ marginBottom: 0 }}>
				<Row type="flex" gutter={24}>
					<Col flex={1}>
						<FormItem
							name={'facility'}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'form.validation.facility.required',
										defaultMessage: 'Please input facility',
									}),
								},
							]}
							{...restFormItemProps}>
							<Select
								style={{ width: '100%' }}
								placeholder="Please select facility"
								optionFilterProp="title"
								filterOption={(input, option) =>
									option.props.children
										.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0
								}>
								{renderFacilityOptions()}
							</Select>
						</FormItem>
					</Col>
					<Col style={{ width: 50 }}>
						<Button onClick={() => setCreate(true)}>
							<PlusOutlined />
						</Button>
					</Col>
				</Row>
			</FormItem>
		</>
	);
};

const mapStateToProps = ({ organization, facilities }) => {
	return {
		organization,
		facilities,
	};
};

export default connect(mapStateToProps, {
	fetchFacilities,
	fetchFacilitiesByOrganization,
	clearFacilities,
})(FacilitySelectForm);
