import React from 'react';
import { connect } from 'react-redux';
import { Title, Modal } from '@common/components';
import { popIn } from '@common/utils/services';
import ClassForm from '../Form';
import { createClass } from '@resources/Class/actions';
import { useIntl } from 'react-intl';

const Create = (props) => {
	const { createClass, visible, onCancel } = props;
	const intl = useIntl();
	const handleSubmit = (data) => {
		return createClass(data)
			.then((response) => {
				popIn(
					'success',
					intl.formatMessage({
						id: 'message.success.class.create',
						defaultMessage:
							'A new Class has been successfully created',
					})
				);

				onCancel && onCancel();
			})
			.catch((err) => Promise.reject(err));
	};

	return (
		<Modal
			visible={visible}
			onCancel={() => onCancel && onCancel()}
			width={700}>
			<Title>
				{intl.formatMessage({
					id: 'common.new.class',
					defaultMessage: 'Create Class',
				})}
			</Title>
			<ClassForm onSubmit={handleSubmit} {...props} />
		</Modal>
	);
};

const mapStateToProps = ({ organization }) => {
	return {
		organization,
	};
};

export default connect(mapStateToProps, { createClass })(Create);
