import React, { useCallback, useState, useEffect } from 'react';
import {
	Form,
	Input,
	Button,
	Checkbox,
	Select,
	DatePicker,
	TimePicker,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchCrewsByOrganizationModule } from '@resources/Crew/actions';
import { fetchTeamsByOrganizationModule } from '@resources/Team/actions';
import { clearProgramme } from '@resources/Programme/actions';
import { formErrorHandler } from '@common/utils/form';
import isEmpty from 'lodash.isempty';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

const ProgrammeForm = (props) => {
	const {
		crews,
		teams,
		module,
		data,
		onSubmit,
		clearProgramme,
		match,
		onCancel,
		fetchCrewsByOrganizationModule,
		fetchTeamsByOrganizationModule,
		organization,
	} = props;
	const intl = useIntl();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);

			if (organization?._id) {
				values['organization'] = organization?._id;
			}

			if (module) {
				values['module'] = module._id;
				values[module.code] = match?.params[`moduleId`];
			}

			onSubmit(values)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, organization]
	);

	useEffect(() => {
		return () => clearProgramme();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			await fetchCrewsByOrganizationModule(
				organization?._id,
				module._id,
				match?.params[`moduleId`]
			);

			await fetchTeamsByOrganizationModule(
				organization?._id,
				module._id,
				match?.params[`moduleId`]
			);
		};
		!isEmpty(module) && fetchData();
	}, [module, organization]);

	const renderCrewOptions = useCallback(() => {
		if (!isEmpty(crews)) {
			return crews.map((crew, index) => {
				return (
					<Option
						value={crew._id}
						key={`crew-${index}`}
						title={`${crew?.member?.user?.firstName} 
						${crew?.member?.user?.lastName}`}>
						{crew?.member?.user?.firstName}{' '}
						{crew?.member?.user?.lastName}
					</Option>
				);
			});
		}
	}, [crews]);

	const renderTeamOptions = useCallback(() => {
		if (!isEmpty(teams)) {
			return teams.map((team, index) => {
				return (
					<Option
						value={team._id}
						key={`team-${index}`}
						title={team.title}>
						{team?.title}
					</Option>
				);
			});
		}
	}, [teams]);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current < moment().endOf('day');
	};

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				crews: data?.crews?.map((crew) => crew._id),
				teams: data?.teams?.map((team) => team._id),
				date: data?.date && moment(data?.date),
				time: data?.time && data?.time?.map((time) => moment(time)),
			}}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.detail',
					defaultMessage: 'Detail',
				})}
				name="detail"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.detail.required',
							defaultMessage: 'Please input detail',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.detail',
						defaultMessage: 'Detail',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.date',
					defaultMessage: 'Date',
				})}
				name="date"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.date.required',
							defaultMessage: 'Please input date',
						}),
					},
				]}>
				<DatePicker disabledDate={disabledDate} />
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.time',
					defaultMessage: 'Time',
				})}
				name="time"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.time.required',
							defaultMessage: 'Please input time',
						}),
					},
				]}>
				<TimePicker.RangePicker />
			</FormItem>
			<FormItem label={`Crews`} name="crews">
				<Select
					mode="multiple"
					placeholder="Please select crew"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderCrewOptions()}
				</Select>
			</FormItem>

			<FormItem label={`Teams`} name="teams">
				<Select
					mode="multiple"
					placeholder="Please select team"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderTeamOptions()}
				</Select>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, crews, teams, organization }) => {
	return {
		auth,
		crews,
		teams,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchCrewsByOrganizationModule,
	fetchTeamsByOrganizationModule,
	clearProgramme,
})(ProgrammeForm);
