import React, { useCallback, useState, useEffect } from 'react';
import {
	Form,
	Input,
	Button,
	Checkbox,
	Select,
	InputNumber,
	Upload,
	Icon,
	Switch,
} from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchItemTypes } from '@resources/ItemType/actions';
import { clearItem } from '@resources/Item/actions';
import { formErrorHandler } from '@common/utils/form';
import isEmpty from 'lodash.isempty';
import { deleteMedia } from '@resources/Media/actions';
import { getCookie } from '@common/utils/cookie';
import { API_MEDIA } from '@common/config/api';
import ImgCrop from 'antd-img-crop';
import { FacilitySelectForm } from '@fragments';

const FormItem = Form.Item;
const Option = Select.Option;

const ItemForm = (props) => {
	const {
		itemTypes,
		module,
		data,
		onSubmit,
		clearItem,
		match,
		onCancel,
		fetchItemTypes,
		organization,
	} = props;
	const intl = useIntl();
	const authToken = getCookie('auth');
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const [thumbnail, setLogos] = useState(
		data && data.thumbnail
			? [
					{
						uid: 'thumbnail',
						name: data.thumbnail.originalName,
						url: data.thumbnail.path,
						response: { data: [data.thumbnail] },
					},
			  ]
			: []
	);

	const [images, setImages] = useState(
		data && data.images
			? data.images.map((image, index) => {
					return {
						uid: `images-${index}`,
						name: image.originalName,
						url: image.path,
						response: { data: [image] },
					};
			  })
			: []
	);

	const handleFinish = useCallback(
		(values) => {
			setLoading(true);
			const convertedValues = { ...values };
			convertedValues['thumbnail'] =
				thumbnail &&
				thumbnail.length > 0 &&
				thumbnail[0].response &&
				thumbnail[0].response.data &&
				thumbnail[0].response.data.length > 0
					? thumbnail[0].response.data[0]._id
					: undefined;

			convertedValues['images'] =
				images && images.length > 0
					? images
							.filter(
								(image) =>
									image.response &&
									image.response.data &&
									image.response.data.length > 0
							)
							.map((image) => {
								return image.response &&
									image.response.data &&
									image.response.data.length > 0
									? image.response.data[0]._id
									: undefined;
							})
					: undefined;

			if (organization?._id) {
				convertedValues['organization'] = organization?._id;
			}

			if (module) {
				convertedValues['module'] = module._id;
				convertedValues[module.code] = match?.params[`moduleId`];
			}

			onSubmit(convertedValues)
				.then(() => setLoading(false))
				.catch((err) => {
					setLoading(false);
					formErrorHandler(err, values, form);
				});
		},
		[onSubmit, thumbnail, images, organization]
	);

	useEffect(() => {
		const fetchData = async () => {
			await fetchItemTypes();
		};
		fetchData();

		return () => clearItem();
	}, []);

	const uploadButton = (
		<div>
			<Icon type="plus" />
			<div className="ant-upload-text">
				{intl.formatMessage({
					id: 'common.upload',
					defaultMessage: 'Upload',
				})}
			</div>
		</div>
	);

	const handlePreview = useCallback(() => {}, []);

	const handleChange = (set) => ({ fileList: newFileList }) => {
		set(newFileList);
	};

	const handleRemove = useCallback(
		(set, sources) => (file) => {
			const id =
				file.response &&
				file.response.data &&
				file.response.data.length > 0
					? file.response.data[0]._id
					: file._id;
			deleteMedia(id)
				.then((res) => {
					set(
						sources.filter((fileItem) => fileItem.uid !== file.uid)
					);
				})
				.catch((err) => {
					console.log(err);
				});

			return false;
		},
		[deleteMedia]
	);

	const renderItemTypeOptions = useCallback(() => {
		if (!isEmpty(itemTypes)) {
			return itemTypes.map((itemType, index) => {
				return (
					<Option
						value={itemType._id}
						key={`itemType-${index}`}
						title={itemType.title}>
						{itemType?.title}
					</Option>
				);
			});
		}
	}, [itemTypes]);

	return (
		<Form
			name="position-form"
			layout="vertical"
			onFinish={handleFinish}
			initialValues={{
				...data,
				itemTypes: data?.itemTypes?.map((itemType) => itemType._id),
			}}>
			<FormItem
				label={intl.formatMessage({
					id: 'common.title',
					defaultMessage: 'Title',
				})}
				name="title"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.title.required',
							defaultMessage: 'Please input title',
						}),
					},
				]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.title',
						defaultMessage: 'Title',
					})}
				/>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.description',
					defaultMessage: 'Description',
				})}
				name="description"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.description.required',
							defaultMessage: 'Please input description',
						}),
					},
				]}>
				<Input.TextArea
					rows={4}
					placeholder={intl.formatMessage({
						id: 'common.description',
						defaultMessage: 'Description',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.sku',
					defaultMessage: 'SKU',
				})}
				name="sku"
				rules={[]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.sku',
						defaultMessage: 'SKU',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.brand',
					defaultMessage: 'Brand',
				})}
				name="brand"
				rules={[]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.brand',
						defaultMessage: 'Brand',
					})}
				/>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.model',
					defaultMessage: 'Model',
				})}
				name="model"
				rules={[]}>
				<Input
					placeholder={intl.formatMessage({
						id: 'common.model',
						defaultMessage: 'Model',
					})}
				/>
			</FormItem>

			<FormItem
				label={`Types`}
				name="types"
				rules={[
					{
						required: true,
						message: intl.formatMessage({
							id: 'form.validation.type.required',
							defaultMessage: 'Please input type',
						}),
					},
				]}>
				<Select
					mode="multiple"
					placeholder="Please select type"
					style={{ width: '100%' }}
					optionFilterProp="title"
					filterOption={(input, option) =>
						option.props.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}>
					{renderItemTypeOptions()}
				</Select>
			</FormItem>

			<FormItem
				label={intl.formatMessage({
					id: 'common.rentable',
					defaultMessage: 'Rentable',
				})}
				name="rentable"
				valuePropName="checked">
				<Switch />
			</FormItem>

			<Form.Item
				noStyle
				shouldUpdate={(prevValues, currentValues) =>
					prevValues.rentable !== currentValues.rentable
				}>
				{({ getFieldValue }) => {
					return getFieldValue('rentable') ? (
						<>
							<FacilitySelectForm />

							<FormItem
								label={`Max Rental Quantity`}
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.maxRentalQuantity.required',
											defaultMessage:
												'Please input max rental quantity',
										}),
									},
								]}>
								<InputNumber
									placeholder={`0`}
									style={{ width: '100%' }}
									min={0}
								/>
							</FormItem>

							<FormItem
								label={`Max Rental Duration`}
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.maxRentalDuration.required',
											defaultMessage:
												'Please input max rental duration',
										}),
									},
								]}>
								<InputNumber
									style={{ width: '100%' }}
									min={0}
									formatter={(value) => `${value} hours`}
									parser={(value) =>
										value.replace('hours', '')
									}
								/>
							</FormItem>

							<FormItem
								label={`Rental Fee`}
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id:
												'form.validation.rentalFee.required',
											defaultMessage:
												'Please input max rental fee',
										}),
									},
								]}>
								<InputNumber
									min={0}
									style={{ width: '100%' }}
									formatter={(value) => `$${value}/hour`}
									parser={(value) =>
										value
											.replace('$', '')
											.replace('/hour', '')
									}
								/>
							</FormItem>
						</>
					) : null;
				}}
			</Form.Item>

			<FormItem
				label={intl.formatMessage({
					id: 'common.thumbnail',
					defaultMessage: 'Thumbnail',
				})}
				name="thumbnail">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/item`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={thumbnail}
						onPreview={handlePreview}
						onChange={handleChange(setLogos)}
						onRemove={handleRemove(setLogos, thumbnail)}>
						{thumbnail.length > 0 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>
			<FormItem
				label={intl.formatMessage({
					id: 'common.images',
					defaultMessage: 'Images',
				})}
				name="images">
				<ImgCrop rotate>
					<Upload
						action={`${API_MEDIA}/image/item`}
						headers={{ authorization: authToken }}
						listType="picture-card"
						fileList={images}
						onPreview={handlePreview}
						onChange={handleChange(setImages)}
						onRemove={handleRemove(setImages, images)}>
						{images.length > 4 ? null : uploadButton}
					</Upload>
				</ImgCrop>
			</FormItem>

			<FormItem name="isActive" valuePropName="checked">
				<Checkbox>
					{intl.formatMessage({
						id: 'common.isActive',
						defaultMessage: 'Is Active',
					})}
				</Checkbox>
			</FormItem>
			<FormItem className="mt-5">
				<Button type="secondary" onClick={() => onCancel && onCancel()}>
					{intl.formatMessage({
						id: 'common.cancel',
						defaultMessage: 'Cancel',
					})}
				</Button>
				&nbsp;&nbsp;
				<Button
					loading={loading}
					type="primary"
					htmlType="submit"
					className="login-form-button">
					{intl.formatMessage({
						id: 'common.submit',
						defaultMessage: 'Submit',
					})}
				</Button>
			</FormItem>
		</Form>
	);
};

const mapStateToProps = ({ auth, itemTypes, organization }) => {
	return {
		auth,
		itemTypes,
		organization,
	};
};

export default connect(mapStateToProps, {
	fetchItemTypes,
	clearItem,
})(ItemForm);
